import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Tooltip } from "@material-ui/core";
import circlelogo from "assets/img/byn-logo-circle.png";
import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import useWindowSize from 'utils/useWindowSize';

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import { ThemeProvider } from "@material-ui/styles";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
// import errorMaterialTheme from "utils/Themes/errorMaterialTheme";

import { primaryColor, grayColor } from "assets/jss/material-kit-pro-react.js";

import { CREATE_CASE_MUTATION } from "utils/Graphql/mutations";
import { CASES_BY_CLIENT_NOLOGS_QUERY } from "utils/Graphql/queries";
import { createUserLog } from "utils/CreateUserLog";
// import Transition from "utils/Transition";





// import FormHelperText from "@material-ui/core/FormHelperText";

// const style = {
//   grid: {
//     width: "80%",
//   },
//   step1: {
//     display: "grid",
//     gridTemplateColumns: "1fr",
//     gridTemplateRows: "auto auto",
//   },
//   step1Client: {
//     display: "grid",
//     gridTemplateColumns: "1fr ",
//     gridTemplateRows: "auto auto",
//     marginBottom: "40px",
//   },
// };

var _CreateCase = {
  id: "",
  caseCode: "",
  caseTitle: "",
  line: [],
};

function CreateCase(props) {
  const { classes } = props;
  console.log(props);
  const [openNotification, setOpenNotification] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const size = useWindowSize()
  const [line1, setLine1] = useState("");
  const [line3, setLine3] = useState("");

  const handleCloseNotification = () => {
    debugger;
    setOpenNotification(false);
  };

  function clientOnGrid(item) {
    return item ? item.lastName && item.firstName
      ? item.lastName + ", " + item.firstName
      : item.lastName && !item.firstName
        ? item.lastName
        : !item.lastName && item.firstName
          ? item.firstName
          : ""
      : ""
  }
  // function nrmlizeTitle(caseTitle) {
  //   let list_phils = [
  //     "PHILS.", "PHILS",
  //     "PHL", "PHL.",
  //     "PHIL", "PHIL.",
  //     "PH", "PH.",
  //     "PHILIPPINES"
  //   ]
  //   let list_people = [
  //     "PEOPLE", "PEEPL", "PEEPLE", "PEOPL", "PEPLE"]
  //   let list_vs = [
  //     "VS", "VS.",
  //     "V", "V."]

  //   let people = false
  //   let of_ = false
  //   let ofOrd = 0
  //   let the = false
  //   let theOrd = 0
  //   let phils = false
  //   let vs = false
  //   let retValue = ''
  //   let temp = caseTitle.toUpperCase().split(" ")
  //   for (let i = 0; i < temp.length; ++i) {
  //     let t = temp[i].trim()
  //     for (let j = 0; j < list_people.length; j++) {
  //       if (t === list_people[j]) {
  //         people = true
  //         break
  //       }
  //     }
  //     if (t === "OF") {
  //       of_ = true
  //       ofOrd = i
  //     }
  //     if (t === "THE") {
  //       the = true
  //       theOrd = i
  //     }
  //     for (let j = 0; j < list_phils.length; j++) {
  //       if (t === list_phils[j]) {
  //         phils = true
  //         break
  //       }
  //     }
  //     for (let j = 0; j < list_vs.length; j++) {
  //       if (t === list_vs[j]) {
  //         vs = true
  //         break
  //       }
  //     }


  //     if (!(phils || people || vs || (people && of_ && the && (theOrd - ofOrd === 1)) || (of_ && the && phils && (theOrd - ofOrd === 1)))) {
  //       retValue = retValue + t + " "
  //     }
  //   }
  //   return retValue.trim()

  // }
  function nrmlizeTitle(caseTitle) {
    console.log("")
    console.log('nrmlizeTitle')
    console.log(caseTitle)
    let list_phils = [
      "PHILS.", "PHILS",
      "PHL", "PHL.",
      "PHIL", "PHIL.",
      "PH", "PH.",
      "PHILIPPINES"
    ]
    let list_people = [
      "PEOPLE", "PEEPL", "PEEPLE", "PEOPL", "PEPLE"]
    let list_vs = [
      "VS", "VS.",
      "V", "V."]

    let people = false
    let peopleOrd = 0
    let ofthe = false
    let oftheOrd = 0
    let phils = false
    let philsOrd = 0
    let vs = false
    let vsOrd = 0
    let retValue = ''
    let temp = caseTitle.toUpperCase().split(" ")
    for (let i = 0; i < temp.length; ++i) {
      let latest = false
      let t = temp[i].trim()
      console.log('-----iter; ' + i + " " + t)
      for (let j = 0; j < list_people.length; j++) {
        if (t === list_people[j]) {
          people = true
          peopleOrd = i
          latest = true
          break
        }
      }
      if (t === "OF" && (((i + 1) < temp.length) && (temp[i + 1] === "THE"))) {
        ofthe = true
        oftheOrd = (i + 1)
        i = i + 1
        latest = true
      }
      for (let j = 0; j < list_phils.length; j++) {
        if (t === list_phils[j]) {
          phils = true
          philsOrd = i
          latest = true
          break
        }
      }
      for (let j = 0; j < list_vs.length; j++) {
        if (t === list_vs[j]) {
          vs = true
          vsOrd = i
          latest = true
          break
        }
      }

      console.log('  people: ' + people + ' ' + peopleOrd)
      console.log('  ofthe: ' + ofthe + ' ' + oftheOrd)
      console.log('  phils: ' + phils + ' ' + philsOrd)
      console.log('  vs: ' + vs + ' ' + vsOrd)

      if (people || phils ||
        vs
        ||
        (people && vs && (vsOrd - peopleOrd === 1)) ||
        (phils && vs && (vsOrd - philsOrd === 1)) ||
        (people && vs && (peopleOrd - vsOrd === 1)) ||
        (phils && vs && (philsOrd - vsOrd === 1)) ||
        // of the
        (people && ofthe && (oftheOrd - peopleOrd === 1)) ||
        (ofthe && phils && (philsOrd - oftheOrd === 1))
      ) {
        if (!latest) {
          retValue = retValue + t + " "
          console.log('  YES')
          console.log('   ' + retValue.trim())
        } else {
          console.log('  NO..no...')
          console.log('  ' + retValue.trim())
        }
      } else {
        if (i === 0) {
          retValue = retValue + t + " "
          console.log('  YES..yes..')
          console.log('  ' + retValue.trim())
        } else {
          console.log('  NO...')
          console.log('  ' + retValue.trim())
        }
      }
    }
    console.log('======' + retValue.trim())
    return retValue.trim()
  }

  const callCreateCase = async (values, setFieldValue) => {
    return new Promise((resolve, reject) => {
      const { _client, _member } = props;
      const { id } = _member;
      const { caseCode, caseTitle } = values;
      const clientId = props.currentClient.id;
      debugger;
      _client
        .query({
          query: CASES_BY_CLIENT_NOLOGS_QUERY,
          variables: {
            clientId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { casesByClient } = response.data;
          console.log("casesByClient");
          console.log(casesByClient);

          debugger;
          let case_ = [];
          casesByClient
            .filter(
              (s) =>
                (s.status === "ACTIVE" || s.status === "INACTIVE") &&
                (s.caseCode === caseCode && s.caseTitle === caseTitle)
            )
            .map((s) => {
              case_.push({
                caseCode: s.codeCode,
                caseTitle: s.caseTitle,
              });
            });

          if (case_.length === 1) {
            let s = "";
            if (caseCode && caseTitle) {
              s = s + caseTitle + " (" + caseCode + ")";
            } else if (caseCode && !caseTitle) {
              s = s + " (" + caseCode + ")";
            } else if (!caseCode && caseTitle) {
              s = s + caseTitle
            } else if (!caseCode && !caseTitle) {
              s = s + "";
            }
            setLine1(s);
            reject(new Error(s));
          } else {
            let cases_ = [];
            casesByClient
              .filter(
                (s) =>
                  (s.status === "ACTIVE" || s.status === "INACTIVE")
              )
              .filter(
                (s) =>
                  (s.caseCode === caseCode) ||
                  (s.caseTitle &&
                    caseTitle &&
                    s.caseTitle.length > 0 &&
                    caseTitle.length > 0 &&
                    (nrmlizeTitle(s.caseTitle) === nrmlizeTitle(caseTitle))
                  )
              )
              .map((s) => {
                cases_.push({
                  caseCode: s.caseCode,
                  caseTitle: s.caseTitle,
                });
              });
            if (cases_.length > 0) {
              let s = "";
              if (caseCode && caseTitle) {
                s = caseTitle + " (" + caseCode + ")";
              } else if (caseCode && !caseTitle) {
                s = "[blank case title] " + " (" + caseCode + ")";
              } else if (!caseCode && caseTitle) {
                s = caseTitle
              } else if (!caseCode && !caseTitle) {
                s = "";
              }
              console.log('s entered reference');
              console.log(s);
              setLine3(s);
              let ts = "";
              console.log('cases_');
              console.log(cases_);
              debugger
              _CreateCase.line = [];
              for (let i = 0; i < cases_.length; i++) {
                if (cases_[i].caseCode && cases_[i].caseTitle) {
                  ts = cases_[i].caseTitle + " (" + cases_[i].caseCode + ")";
                } else if (cases_[i].caseCode && !cases_[i].caseTitle) {
                  ts = "[blank case title] " + " (" + cases_[i].caseCode + ")";
                } else if (!cases_[i].caseCode && cases_[i].caseTitle) {
                  ts = cases_[i].caseTitle
                }

                if (_CreateCase.line.filter((ss) => ss === ts).length === 0)
                  _CreateCase.line.push(ts);
              }
              setLine1(ts);
              setOpenNotification(true);
            } else {
              debugger;
              let fixedRate = 0.0;
              _client
                .mutate({
                  mutation: CREATE_CASE_MUTATION,
                  variables: {
                    caseCode,
                    caseTitle,
                    billingType: "",
                    fixedRate,
                    memberId: id,
                    clientId,
                  },
                })
                .then((response) => {
                  debugger;
                  console.log(response);
                  const { id } = response.data.createCase;
                  debugger;
                  _CreateCase.id = id
                  props.handleCloseCase();
                  setOpenSuccess(true)


                  // props.initialValues.case_ = id;
                  // props.initialValues.freshCase_ = id;
                  // debugger;
                  // props.getClients().then((response) => {
                  //   debugger;
                  //   //postHandleSubmitClient(response, values, createClient);
                  //   const { clientsByEmail } = response.data;
                  //   console.log(clientsByEmail);

                  //   props
                  //     .changeCase(id, clientsByEmail.cases, clientsByEmail)
                  //     .then(() => {
                  //       console.log("out ");
                  //       setOpenNotification(false);
                  //       props.handleCloseCase();
                  //     });
                  // });
                });
              debugger;
            }
          }
        });
    });
  };

  const createCase = async (values) => {
    const { _client, _member } = props;
    const { id } = _member;
    const clientId = props.currentClient.id;
    // const { caseCode, caseTitle } = values;
    let fixedRate = 0.0;

    debugger;
    return await _client
      .mutate({
        mutation: CREATE_CASE_MUTATION,
        variables: {
          caseCode: _CreateCase.caseCode.toUpperCase(),
          caseTitle: _CreateCase.caseTitle.toUpperCase(),
          billingType: "",
          fixedRate,
          memberId: id,
          clientId,
        },
      })
      .then((response) => {
        debugger;
        console.log(response);
        const { id } = response.data.createCase;
        debugger;
        _CreateCase.id = id
        props.handleCloseCase();
        setOpenSuccess(true)
        // props.initialValues.case_ = id;
        // props.initialValues.freshCase_ = id;
        // debugger;
        // props.getClients().then((response) => {
        //   debugger;
        //   //postHandleSubmitClient(response, values, createClient);
        //   const { clientsByEmail } = response.data;
        //   console.log(clientsByEmail);

        //   props
        //     .changeCase(id, clientsByEmail.cases, clientsByEmail)
        //     .then(() => {
        //       console.log("out ");
        //       setOpenNotification(false);
        //       props.handleCloseCase();
        //     });
        // });
      });
    debugger;
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        // TransitionComponent={props.Transition}
        keepMounted
        open={props.openCase}
        onClose={props.handleCloseCase}
        scroll={props.scroll}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <GridContainer justify="center">
            <GridItem zeroMinWidth>
              <div
                style={{
                  width: `${size.width <= 414 ? size.width - 120 : 400}px`,
                }}
              >
                <ThemeProvider theme={defaultMaterialTheme}>
                  <div
                    style={{
                      margin: `10px 0 0 0`,
                      color: primaryColor[0],
                      fontSize: "30px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    Add Case
                  </div>
                  <ThemeProvider theme={defaultBtnMaterialTheme}>
                    <Tooltip
                      id="tooltip-top"
                      title={"Home"}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        style={{
                          margin: `-40px -20px 0 0 `,
                        }}
                        className={classes.modalCloseButtonBYN}
                        onClick={e => {
                          e.preventDefault()
                          props.history.push("/app/dashboard")
                        }}
                      >
                        <img
                          style={{ width: "50px" }}
                          src={circlelogo}
                          alt="billyounow.com"
                        />
                      </Button>
                    </Tooltip>
                  </ThemeProvider>
                </ThemeProvider>
              </div>
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent>
          <ThemeProvider theme={defaultMaterialTheme}>
            <GridContainer justify="center">
              <GridItem zeroMinWidth>
                <div
                  style={{
                    width: `${size.width <= 414 ? size.width - 120 : 400}px`,
                  }}
                >
                  <Formik
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    initialValues={{
                      caseCode: "",
                      caseTitle: "",
                    }}
                    validate={(values) => {
                      // debugger
                      console.log("validate...");
                      const errors = {};

                      if (!values.caseCode && !values.caseTitle) {
                        // errors.caseCode = 'Please fill at least one field.';
                        errors.caseTitle = "Please fill at least one field.";
                      }
                      console.log("errors");
                      console.log(errors);
                      console.log("...validate");
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                      debugger;
                      setSubmitting(true);
                      // handleSubmitCase(values, isSubmitting);
                      createUserLog(props, "CREATE NEW CASE", "Created a new Case", "")
                      callCreateCase(values, setFieldValue).catch((e) => {
                        setSubmitting(false);
                        setOpenDuplicate(true);
                      });
                    }}
                    render={({
                      values,
                      isSubmitting,
                      setSubmitting,
                      handleChange,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <Form mode="themed">
                        <GridContainer justify="center">
                          <GridItem zeroMinWidth>
                            <div
                              style={{
                                width: `${size.width <= 414 ? size.width - 120 : 400
                                  }px`,
                              }}
                            >
                              <ThemeProvider theme={defaultMaterialTheme}>
                                <GridContainer justify="center">
                                  <GridItem zeroMinWidth>
                                    <Typography
                                      style={{
                                        textAlign: "center",
                                        margin: "-5px 0 0 0",
                                      }}
                                    >
                                      {clientOnGrid(props.currentClient)}
                                    </Typography>
                                    <Typography
                                      style={{
                                        textAlign: "center",
                                        margin: "0 0 20px 0",
                                      }}
                                    >
                                      {props.currentClient ?
                                        props.currentClient.company
                                          ? "(" +
                                          props.currentClient.company +
                                          ")"
                                          : ""
                                        : ""}
                                    </Typography>
                                  </GridItem>
                                </GridContainer>
                                <div
                                  style={{
                                    overflow: "auto",
                                    height: `${size.height - 240}px`,
                                  }}
                                >
                                  <TextField
                                    style={{
                                      margin: "20px 0 0 0",
                                      textTranform: "uppercase",
                                    }}
                                    fullWidth
                                    name="caseCode"
                                    label="Case Code"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={values.caseCode.length === 0 && values.caseTitle.length === 0}
                                    onChange={(event) => {
                                      values.caseCode = event.target.value.toUpperCase();
                                      setFieldValue(
                                        "caseCode",
                                        event.target.value.toUpperCase()
                                      );
                                      _CreateCase.caseCode = event.target.value.toUpperCase();
                                    }}
                                    value={values.caseCode}
                                  />
                                  <TextField
                                    style={{
                                      margin: "20px 0 20px 0",
                                      textTranform: "uppercase",
                                    }}
                                    fullWidth
                                    name="caseTitle"
                                    // placeholder="Enter case/matter title"
                                    label="Case Title"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={values.caseCode.length === 0 && values.caseTitle.length === 0}
                                    onChange={(event) => {
                                      values.caseTitle = event.target.value.toUpperCase();
                                      setFieldValue(
                                        "caseTitle",
                                        event.target.value.toUpperCase()
                                      );
                                      _CreateCase.caseTitle = event.target.value.toUpperCase();
                                    }}
                                    value={values.caseTitle}
                                  />
                                  <Typography
                                    style={{
                                      textAlign: "center", fontSize: '16px', color: primaryColor[0],
                                      display: values.caseCode === "" && values.caseTitle === "" ? 'block' : 'none'
                                    }}>
                                    <em>* Required</em>
                                  </Typography>
                                  <Typography
                                    style={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: grayColor[1],
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Fill in at least one field.
                                  </Typography>
                                </div>
                              </ThemeProvider>
                              <DialogActions className={classes.modalFooter}>
                                <ThemeProvider theme={defaultBtnMaterialTheme}>
                                  <Button
                                    style={{ width: "60%" }}
                                    disabled={
                                      isSubmitting ||
                                      (!isSubmitting &&
                                        ((values.caseCode === "" || values.caseCode.length < 2) &&
                                          (values.caseTitle === "" || values.caseTitle.length < 2))
                                      )
                                    }
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="md"
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    style={{ width: "40%" }}
                                    variant="outlined"
                                    color="primary"
                                    size="md"
                                    onClick={props.handleCloseCase}
                                  >
                                    Cancel
                                  </Button>
                                </ThemeProvider>
                              </DialogActions>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </Form>
                    )}
                  ></Formik>
                </div>
              </GridItem>
            </GridContainer>
          </ThemeProvider>
        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <GridContainer justify="center">
            <GridItem zeroMinWidth>
              <div
                style={{
                  width: `${size.width <= 414 ? size.width - 120 : 400}px`,
                }}
              >
                <ThemeProvider theme={defaultMaterialTheme}>
                  <div
                    style={{
                      margin: `10px 0 0 0`,
                      color: primaryColor[0],
                      fontSize: "30px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    Verify
                  </div>
                  <ThemeProvider theme={defaultBtnMaterialTheme}>
                    <Tooltip
                      id="tooltip-top"
                      title={"Home"}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        style={{
                          margin: `-40px -20px 0 0 `,
                        }}
                        className={classes.modalCloseButtonBYN}
                        onClick={e => {
                          e.preventDefault()
                          props.history.push("/app/dashboard")
                        }}
                      >
                        <img
                          style={{ width: "50px" }}
                          src={circlelogo}
                          alt="billyounow.com"
                        />
                      </Button>
                    </Tooltip>
                  </ThemeProvider>
                </ThemeProvider>
              </div>
            </GridItem>
          </GridContainer>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >

              <Typography
                style={{
                  margin: "60px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                The following case/s
                  </Typography>
              <Typography
                style={{
                  margin: "0 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                have similar title/s
                  </Typography>
              <Typography
                style={{
                  margin: "0 0 20px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                and/or code/s:
                  </Typography>

              {_CreateCase.line.map((s) => (
                <Typography
                  style={{
                    margin: "-10px 0 10px 0",
                    fontSize: "14px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  {s}
                </Typography>
              ))}
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "16px",
                  color: primaryColor[0],
                  textAlign: "center",
                }}
              >
                {line3}
              </Typography>
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Verify case addition.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    createCase();
                    // setOpenNotification(false)
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenNotification(false);
                  }}
                >
                  No
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openDuplicate}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 400}px` }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              <div
                style={{
                  margin: `10px 0px 20px  0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Add Case
              </div>

              <Tooltip
                id="tooltip-top"
                title={!props.isOpenWaitingOnConfirm ? "Confirmation" : "Saving..."}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-60px  ${size.width <= 414 ? -10 : -20}px 0 0`,
                  }}
                  className={classes.modalCloseButtonBYN}
                // href="/app/app"
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 400}px` }}
          >
            <div>

              <div
                className={classes.container}
                style={{
                  overflow: "auto",
                  width: `${size.width <= 414 ? size.width - 40 : 360}px`,
                  height: `${size.height - 135}px`,
                }}
              >
                <Typography
                  style={{
                    margin: "80px 0 10px 0",
                    fontSize: "18px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  Case already exists, cannot add same case
                </Typography>
              </div>

              <DialogActions className={classes.modalFooter}>
                <ThemeProvider theme={defaultBtnMaterialTheme}>
                  <Button
                    style={{ width: "100%" }}
                    variant="outlined"
                    color="primary"
                    size="md"
                    onClick={() => {
                      setOpenDuplicate(false);
                    }}
                  >
                    Close
                </Button>
                </ThemeProvider>
              </DialogActions>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openSuccess}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 10px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Case added.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenSuccess(false);
                    props.initialValues.case_ = _CreateCase.id;
                    props.initialValues.freshCase_ = _CreateCase.id;
                    debugger;
                    props.getClients().then((response) => {
                      debugger;
                      const { clientsByEmail } = response.data;
                      console.log(clientsByEmail);

                      props
                        .changeCase(_CreateCase.id, clientsByEmail.cases, clientsByEmail)
                        .then(() => {
                          console.log("out ");
                          setOpenNotification(false);
                          // props.handleCloseCase();
                        });
                    });
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>
    </>
  );
}

export default withStyles(javascriptStyles)(
  withStyles(basicsStyle)(withStyles(modalStyle)(CreateCase))
);
