import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import {
  primaryColor,
  grayColor
} from 'assets/jss/material-kit-pro-react.js';

import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards-pellucid.js";

import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import circlelogo from "assets/img/byn-logo-circle.png";
import useWindowSize from 'utils/useWindowSize';

const LogCountMessage = (props) => {
  const { classes } = props
  const size = useWindowSize()

  if (!props.openMessageDialog)
    return null;



  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLogCounter,
        }}
        open={props.openMessageDialog}
        // TransitionComponent={Transition}
        keepMounted
        onClose={props.handleCloseMessageDialog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      // maxWidth="sm"
      // fullWidth
      // fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 80 : 320}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: `${size.width <= 320 ? 26 : 30}px`,
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                {props.messageDialogTitle}
              </div>
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ margin: `-40px  ${size.width <= 414 ? -30 : 30}px 0 0` }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 80 : 280}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                // marginLeft: "-10px",
                width: `${size.width <= 414 ? size.width - 100 : 260}px`,
                // overflow: "auto",
                // height: `${size.height - 300}px`,
              }}
            >

              <Typography style={{ margin: "20px 0 20px 0", fontSize: `${props.fontSize1 ? props.fontSize1 : 20}px`, color: grayColor[1], textAlign: "center" }}>
                {props.messageOnMessageDialog}
              </Typography>
              {/* <Typography style={{ margin: "0px 0 0px 0", fontSize: `${props.fontSize2 ? props.fontSize2 : 24}px`, color: grayColor[1], textAlign: "center" }}>
                {props.messageOnMessageDialog2}
              </Typography>
              <Typography style={{ margin: "0px 0 0px 0", fontSize: `${props.fontSize3 ? props.fontSize3 : 24}px`, color: grayColor[1], textAlign: "center" }}>
                {props.messageOnMessageDialog3}
              </Typography>
              <Typography style={{ margin: "0px 0 0px 0", fontSize: `${props.fontSize4 ? props.fontSize4 : 24}px`, color: grayColor[1], textAlign: "center" }}>
                {props.messageOnMessageDialog4}
              </Typography>
              <Typography style={{ margin: "0px 0 0px 0", fontSize: `${props.fontSize5 ? props.fontSize5 : 24}px`, color: grayColor[1], textAlign: "center" }}>
                {props.messageOnMessageDialog5}
              </Typography>
              <Typography style={{ margin: "0px 0 0px 0", fontSize: `${props.fontSize6 ? props.fontSize6 : 24}px`, color: grayColor[1], textAlign: "center" }}>
                {props.messageOnMessageDialog6}
              </Typography> */}
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>

                {props.push ?
                  <Button

                    style={{ width: "100%" }}

                    variant={props.variant ? props.variant : "contained"}
                    // type="submit"
                    // href={`${props.push}`}
                    color="primary"
                    onClick={e => {
                      e.preventDefault()
                      props.history.push(`${props.push}`)
                    }}
                  >
                    {props.messageLabelBtn ? props.messageLabelBtn : "CLOSE"}
                  </Button>
                  :
                  <Button
                    style={{ width: "100%" }}

                    variant={props.variant ? props.variant : "contained"}
                    onClick={() => {
                      props.handleCloseMessageDialog()
                    }}
                    color="primary"
                  >
                    {props.messageLabelBtn ? props.messageLabelBtn : "CLOSE"}
                  </Button>
                }
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>
    </>
  );
}


export default withStyles(styles)(withStyles(modalStyle)(LogCountMessage));