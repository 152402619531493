import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import { Formik } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import useWindowSize from 'utils/useWindowSize';

import Tooltip from "@material-ui/core/Tooltip";
// import { useMediaQuery } from "react-responsive";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";

import { formatMoney } from "utils/FormatMoney.js";
import { formatNumber_ } from "utils/FormatNumber_.js";
import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import circlelogo from "assets/img/byn-logo-circle.png";

// import TextFieldComponent from "utils/TextFieldComponent";

// import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";

import TextField from "@material-ui/core/TextField";
import DateTimeLog from "screens/LogMyWork/DateTimeLog";

import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import sameTheme from "utils/Themes/viewLogMaterialTheme";
import { primaryColor } from "assets/jss/material-kit-pro-react.js";
import { createUserLog } from "utils/CreateUserLog";

const style = {
  grid: {
    width: "60%",
  },
  step3Client480: {
    marginLeft: "0px",
    marginTop: "40px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Case480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Category: {
    marginTop: "0px",
    marginBottom: "2px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step2ActivityL1480: {
    marginTop: "0px",
    marginBottom: "2px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step2Activity480: {
    marginTop: "0px",
    marginBottom: "2px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3duration: {
    marginLeft: "2px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3hrmin: {
    marginLeft: "0px",
    marginTop: "0px",
    display: "grid",
    gridTemplateColumns: ".49fr .02fr .49fr",
    gridTemplateRows: "auto auto",
  },
  step3TimeRenderInput480: {
    marginLeft: "0px",
    marginTop: "5px",
    marginBottom: "-10px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3billing: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    margin: "12px 0 0px 0px",
  },
  step3fixedRate: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    margin: "20px 0 0px 0px",
  },
  step3hourly: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    margin: "20px 0 0 0px",
  },
  step3amount: {
    marginLeft: "0px",
    marginTop: "35px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3startTime: {
    marginLeft: "0px",
    marginTop: "10px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3endTime: {
    marginLeft: "0px",
    marginTop: "10px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Date: {
    marginTop: "5px",
    marginBottom: "15px",
    display: "grid",
    gridTemplateColumns: "0px 1fr",
    gridTemplateRows: "auto auto",
  },
  step3DateError: {
    marginTop: "-10px",
    display: "grid",
    gridTemplateColumns: "0px 200px 10px",
    color: "primary",
    gridTemplateRows: "auto auto",
  },
  step3Notes: {
    marginTop: "20px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "0px 1fr",
    gridTemplateRows: "auto auto",
  },
};

const mmm = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function normalizeDate(dt) {
  return (
    mmm[dt.getMonth()] +
    " " +
    (dt.getDate() < 10 ? "0" + dt.getDate() : "" + dt.getDate()) +
    ", " +
    dt.getFullYear()
  );
}

export function normalizeMinutes(dt) {
  let d = new Date(dt);
  let min = d.getMinutes();
  if (0 <= min && min <= 14) min = 0;
  else if (15 <= min && min <= 29) min = 15;
  else if (30 <= min && min <= 44) min = 30;
  else min = 45;

  let date_ = new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    min,
    0,
    0
  );
  return date_;
}

const ViewLogModal = (props) => {
  const { hasDeleteRequest } = props
  const size = useWindowSize()

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px");
  // const [closeRightMargin, setCloseRightMargin] = useState("0px");
  // const [elemWidth, setElemWidth] = useState("280px");

  if (!props.open) return null;

  function handleClose() {
    // debugger;

    props.setIsOpenViewLog(false);
  }

  const { classes } = props;

  const getDuration = (duration) => {
    let d = new DateTimeLog(duration);
    let hr = d.getDurationHours();
    let min = d.getDurationMinutes();
    console.log(hr, min);
    return { hours: hr, minutes: min };
  };

  const getValidDate = (date) => {
    let validDate = date;
    if (typeof date === "object") {
      if (Object.prototype.toString.call(date) !== "[object Date]") {
        validDate = date.toDate();
      }
    }
    return validDate;
  };
  const constraintDate = (date) => {
    let _date = getValidDate(date);
    let mins = _date.getMinutes();
    console.log(mins);
    if (0 <= mins && mins <= 14) mins = 0;
    else if (15 <= mins && mins <= 29) mins = 15;
    else if (30 <= mins && mins <= 44) mins = 30;
    else mins = 45;
    console.log(mins);
    let newDate = new Date(_date);
    newDate.setMinutes(mins, 0, 0);
    console.log(newDate);
    return newDate;
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={props.open}
        onClose={handleClose}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 600}px` }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              {!isConfirmingDelete ? (
                <div
                  style={{
                    margin: `10px 0px 20px  0`,
                    color: primaryColor[0],
                    fontSize: "30px",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  Log Details
                </div>
              ) : (
                <div
                  style={{
                    margin: `10px 0px 20px  0`,
                    color: primaryColor[0],
                    fontSize: "30px",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  Confirm Delete
                </div>
              )}
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-60px  ${size.width <= 414 ? -10 : -20}px 0 0`,
                  }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 600}px` }}
          >
            <div
              style={{
                width: `${size.width <= 320 ? size.width - 60 :
                  size.width <= 360 ? size.width - 60 :
                    size.width <= 375 ? size.width - 60 :
                      size.width <= 414 ? size.width - 60 : 600
                  }px`,
              }}

            >
              <div
                className={classes.container}
                style={{
                  overflow: "auto",
                  width: `${size.width <= 320 ? size.width - 40 :
                    size.width <= 360 ? size.width - 40 :
                      size.width <= 375 ? size.width - 40 :
                        size.width <= 414 ? size.width - 40 : 600
                    }px`,
                  height: `${size.height - 170}px`,
                }}
              >
                <div
                  style={{
                    margin: "0 auto 0 auto",
                    width: `${size.width <= 320 ? size.width - 80 :
                      size.width <= 360 ? size.width - 80 :
                        size.width <= 375 ? size.width - 80 :
                          size.width <= 414 ? size.width - 80 : 370
                      }px`,
                  }}

                >
                  <div style={{ margin: "40px 0 0 0 0" }} />

                  {hasDeleteRequest ?
                    <Typography>
                      You have a pending delete request. Cancel the delete request to edit or delete logs.
              </Typography>
                    : ''}

                  <div style={style.step3Client480}>
                    <ThemeProvider theme={sameTheme}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          fullWidth
                          labelId="client_"
                          id="client_"
                          value={props.rowNode.client}
                          variant="outlined"
                          label="Client"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          onChange={(e) => {
                            e.target.value = props.rowNode.client;
                          }}
                        />
                      </FormControl>
                    </ThemeProvider>
                  </div>

                  <div style={style.step3Case480}>
                    <ThemeProvider theme={sameTheme}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          fullWidth
                          labelId="case_"
                          id="case_"
                          value={props.rowNode.case}
                          variant="outlined"
                          label="Case"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          onChange={(e) => {
                            e.target.value = props.rowNode.case;
                          }}
                        />
                      </FormControl>
                    </ThemeProvider>
                  </div>

                  {props.rowNode.logType === "EXPENSE_LOG" ? (
                    <>
                      <div style={style.step3Date}>
                        <div></div>
                        <ThemeProvider theme={sameTheme}>
                          <FormControl className={classes.formControl}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                name="date"
                                margin="normal"
                                autoOk
                                maxDate={constraintDate(new Date())}
                                value={props.rowNode.date}
                                format="MMM dd, yyyy"
                                onChange={(value) => { }}
                                readOnly={true}
                                TextFieldComponent={(props) => (
                                  <ThemeProvider theme={sameTheme}>
                                    <TextField
                                      {...props}
                                      variant="outlined"
                                      setOpen={false}
                                      inputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                      label="Expense Date"
                                    />
                                  </ThemeProvider>
                                )}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </ThemeProvider>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={style.step3Date}>
                        <div></div>
                        <ThemeProvider theme={sameTheme}>
                          <FormControl className={classes.formControl}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                name="date"
                                margin="normal"
                                autoOk
                                maxDate={constraintDate(new Date())}
                                value={props.rowNode.date}
                                format="MMM dd, yyyy"
                                onChange={(value) => { }}
                                readOnly={true}
                                TextFieldComponent={(props) => (
                                  <ThemeProvider theme={sameTheme}>
                                    <TextField
                                      {...props}
                                      variant="outlined"
                                      setOpen={false}
                                      inputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                      label="Work Date"
                                    />
                                  </ThemeProvider>
                                )}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </ThemeProvider>
                      </div>
                    </>
                  )}

                  {props.rowNode.logType === "EXPENSE_LOG" ? (
                    <div style={style.step3Category}>
                      <ThemeProvider theme={sameTheme}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            labelId="expense"
                            id="expense"
                            multiline="true"
                            rowsMax="3"
                            value={props.rowNodeClone.expenselogType}
                            variant="outlined"
                            label="Category"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            onChange={(e) => {
                              e.target.value = props.rowNodeClone.expenselogType;
                            }}
                          ></TextField>
                        </FormControl>
                      </ThemeProvider>
                    </div>
                  ) : props.rowNodeClone.activityLogType &&
                    props.rowNodeClone.activityLogSubType &&
                    props.rowNodeClone.activityLogSubSubType ? (
                    <>
                      <div style={style.step2ActivityL1480}>
                        <ThemeProvider theme={sameTheme}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              labelId="activityL1"
                              id="activityL1"
                              multiline="true"
                              rowsMax="3"
                              value={props.rowNodeClone.activityLogType}
                              variant="outlined"
                              label="Category"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                e.target.value = props.rowNodeClone.activityLogType;
                              }}
                            ></TextField>
                          </FormControl>
                        </ThemeProvider>
                      </div>
                      <div style={style.step2Activity480}>
                        <ThemeProvider theme={sameTheme}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              labelId="activityL2"
                              id="activityL2"
                              multiline="true"
                              rowsMax="3"
                              value={props.rowNodeClone.activityLogSubType}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                e.target.value =
                                  props.rowNodeClone.activityLogSubType;
                              }}
                            ></TextField>
                          </FormControl>
                        </ThemeProvider>
                      </div>
                      <div style={style.step2Activity480}>
                        <ThemeProvider theme={sameTheme}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              labelId="activityL3"
                              id="activityL3"
                              multiline="true"
                              rowsMax="3"
                              value={props.rowNodeClone.activityLogSubSubType}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                e.target.value =
                                  props.rowNodeClone.activityLogSubSubType;
                              }}
                            ></TextField>
                          </FormControl>
                        </ThemeProvider>
                      </div>
                    </>
                  ) : props.rowNodeClone.activityLogType &&
                    props.rowNodeClone.activityLogSubType &&
                    !props.rowNodeClone.activityLogSubSubType ? (
                    <>
                      <div style={style.step2ActivityL1480}>
                        <ThemeProvider theme={sameTheme}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              labelId="activityL1"
                              id="activityL1"
                              multiline="true"
                              rowsMax="3"
                              value={props.rowNodeClone.activityLogType}
                              variant="outlined"
                              label="Category"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                e.target.value = props.rowNodeClone.activityLogType;
                              }}
                            ></TextField>
                          </FormControl>
                        </ThemeProvider>
                      </div>
                      <div style={style.step2Activity480}>
                        <ThemeProvider theme={sameTheme}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              labelId="activityL2"
                              id="activityL2"
                              multiline="true"
                              rowsMax="3"
                              value={props.rowNodeClone.activityLogSubType}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                e.target.value =
                                  props.rowNodeClone.activityLogSubType;
                              }}
                            ></TextField>
                          </FormControl>
                        </ThemeProvider>
                      </div>
                    </>
                  ) : props.rowNodeClone.activityLogType ? (
                    <div style={style.step2ActivityL1480}>
                      <ThemeProvider theme={sameTheme}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            labelId="activityL1"
                            id="activityL1"
                            multiline="true"
                            rowsMax="3"
                            value={props.rowNodeClone.activityLogType}
                            variant="outlined"
                            label="Category"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            onChange={(e) => {
                              e.target.value = props.rowNodeClone.activityLogType;
                            }}
                          ></TextField>
                        </FormControl>
                      </ThemeProvider>
                    </div>
                  ) : null}

                  <div style={style.step3Notes}>
                    <div></div>
                    <div style={{ margin: "2px 0 0px 0" }}>
                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          fullWidth
                          multiline="true"
                          rowsMax="3"
                          name="notes"
                          label="Notes"
                          variant="outlined"
                          onChange={(e) => {
                            e.target.value = props.rowNode.notes;
                          }}
                          style={{ marginBottom: "10px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          value={props.rowNode.notes.replace(/\\n/gm, " ")}
                        />
                      </ThemeProvider>
                    </div>
                  </div>
                  <div style={style.step3billing}>
                    <ThemeProvider theme={sameTheme}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          labelId="billingType"
                          id="billingType"
                          multiline="true"
                          rowsMax="3"
                          value={props.rowNode.billingType}
                          variant="outlined"
                          label="Bill Type"
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                          onChange={(e) => {
                            e.target.value = props.rowNode.billingType;
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        ></TextField>
                      </FormControl>
                    </ThemeProvider>
                  </div>

                  {props.rowNode.billingType === "FIXED" ? (
                    <div style={style.step3fixedRate}>
                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          name="fixedRate"
                          label="Fixed Amount"
                          multiline="true"
                          rowsMax="3"
                          variant="outlined"
                          onChange={(e) => {
                            e.target.value = props.rowNode.fixedRate;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          value={formatMoney(
                            formatNumber_(props.rowNode.fixedRate)
                          )}
                        />
                      </ThemeProvider>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.rowNode.billingType === "HOURLY" ? (
                    <div style={style.step3hourly}>
                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          name="hourlyRate"
                          label="Hourly Rate"
                          multiline="true"
                          rowsMax="3"
                          placeholder="Enter rate/hour *"
                          variant="outlined"
                          onChange={(e) => {
                            e.target.value = props.rowNode.hourlyRate;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          value={formatMoney(
                            formatNumber_(props.rowNode.hourlyRate)
                          )}
                        />
                      </ThemeProvider>
                    </div>
                  )
                    : null
                  }

                  {props.rowNode.billingType === "HOURLY" ||
                    (props.rowNode.billingType === "NO CHARGE" && props.rowNode.logType === "SERVICE_LOG") ? (
                    <>
                      <div style={style.step3duration}>
                        <ThemeProvider theme={sameTheme}>
                          <div
                            style={{ marginBottom: "10px", display: "inline-flex" }}
                          >
                            <Typography variant="body1">Duration</Typography>
                          </div>
                        </ThemeProvider>
                      </div>

                      <ThemeProvider theme={sameTheme}>
                        <div style={style.step3hrmin}>
                          <FormControl style={{ textAlign: "center" }}>
                            <TextField
                              labelId="durationHour"
                              id="durationHour"
                              multiline="true"
                              rowsMax="3"
                              value={getDuration(props.rowNode.hours).hours}
                              label="Hours"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                e.target.value = getDuration(
                                  props.rowNode.hours
                                ).hours;
                              }}
                            ></TextField>
                          </FormControl>
                          <div />
                          <FormControl style={{ textAlign: "center" }}>
                            <TextField
                              labelId="durationMinutes"
                              id="durationMinutes"
                              multiline="true"
                              rowsMax="3"
                              value={
                                getDuration(props.rowNode.hours).minutes === 0
                                  ? "00"
                                  : getDuration(props.rowNode.hours).minutes
                              }
                              label="Minutes"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                e.target.value = getDuration(
                                  props.rowNode.hours
                                ).minutes;
                              }}
                            ></TextField>
                          </FormControl>
                        </div>
                      </ThemeProvider>
                    </>
                  ) : null}

                  {((props.rowNode.billingType === "HOURLY") ||
                    (props.rowNode.billingType === "NO CHARGE" && props.rowNode.logType === "SERVICE_LOG")) &&

                    (props.rowNode.startTime
                      ? props.rowNode.startTime
                        .toISOString()
                        .split("T")[1]
                        .substring(0, 5)
                      : "1") !==
                    (props.rowNode.endTime
                      ? props.rowNode.endTime
                        .toISOString()
                        .split("T")[1]
                        .substring(0, 5)
                      : "1")
                    ? (
                      <>
                        <div style={style.step3startTime}>
                          <FormControl className={classes.margin}>
                            <ThemeProvider theme={sameTheme}>
                              <TextField
                                label="Start time"
                                variant="outlined"
                                multiline="true"
                                rowsMax="3"
                                onChange={(e) => {
                                  e.target.value = props.rowNode.startTime;
                                }}
                                style={{ margin: "8px 0 0px 0" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={
                                  normalizeMinutes(props.rowNode.startTime)
                                    .toLocaleTimeString()
                                    .split(":")
                                    .slice(0, 2)
                                    .join(":") +
                                  " " +
                                  normalizeMinutes(props.rowNode.startTime)
                                    .toLocaleTimeString()
                                    .split(" ")[1]
                                }
                                id="startTime-input"
                              />
                            </ThemeProvider>
                          </FormControl>
                        </div>
                        <div style={style.step3endTime}>
                          <FormControl className={classes.margin}>
                            <ThemeProvider theme={sameTheme}>
                              <TextField
                                label="End time"
                                variant="outlined"
                                multiline="true"
                                rowsMax="3"
                                onChange={(e) => {
                                  e.target.value = props.rowNode.endTime;
                                }}
                                style={{ margin: "8px 0 0px 0" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={
                                  normalizeMinutes(props.rowNode.endTime)
                                    .toLocaleTimeString()
                                    .split(":")
                                    .slice(0, 2)
                                    .join(":") +
                                  " " +
                                  normalizeMinutes(props.rowNode.endTime)
                                    .toLocaleTimeString()
                                    .split(" ")[1]
                                }
                                id="endTime-input"
                              />
                            </ThemeProvider>
                          </FormControl>
                        </div>
                      </>
                    ) : null
                  }

                  {props.rowNode.billingType === "HOURLY" ? (
                    <div style={style.step3amount}>
                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          name="amount"
                          label="Amount"
                          multiline="true"
                          rowsMax="3"
                          variant="outlined"
                          value={formatMoney(formatNumber_(props.rowNode.amount))}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          onChange={(e) => {
                            e.target.value = formatMoney(
                              formatNumber_(props.rowNode.amount)
                            );
                          }}
                        />
                      </ThemeProvider>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <DialogActions>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                {!isConfirmingDelete ? (
                  <>
                    <Button
                      style={{ width: "30%" }}
                      variant="contained"
                      color="primary"
                      disabled={hasDeleteRequest}
                      onClick={(e) => {
                        e.preventDefault()
                        createUserLog(props, "VIEW_LOG_FOR_EDIT", "clicked on EDIT", "")
                        props.rowNode.notes = props.rowNode.notes.replace(
                          /\\n/gm,
                          " "
                        );
                        props.contextParent.updateLog(props.rowNode);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      style={{ width: "40%" }}
                      variant="outlined"
                      color="primary"
                      disabled={hasDeleteRequest}
                      onClick={(e) => {
                        e.preventDefault()
                        createUserLog(props, "VIEW_LOG_FOR_EDIT", "Opened DELETE Confirm screen", "")
                        setIsConfirmingDelete(true);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      style={{ width: "30%" }}
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault()
                        createUserLog(props, "VIEW_LOG_FOR_EDIT", "Closed LOG Details screen", "")
                        setIsConfirmingDelete(false);
                        handleClose();
                      }}
                    >
                      Back
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      style={{ width: "60%" }}
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        createUserLog(props, "VIEW_LOG_FOR_EDIT", "clicked on (Confirming) DELETE", "")
                        setIsConfirmingDelete(false);
                        if (props.rowNode.logType === "EXPENSE_LOG") {
                          props.handleCloseDeleteExpenseLog(props.rowNode);
                        } else {
                          props.handleCloseDeleteServiceLog(props.rowNode);
                        }
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      style={{ width: "40%" }}
                      variant="outlined"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault()
                        createUserLog(props, "VIEW_LOG_FOR_EDIT", "clicked on BACK", "")
                        setIsConfirmingDelete(false);
                      }}
                    >
                      Back
                    </Button>
                  </>
                )}
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>
    </>
  );
};

// export default withStyles(style)(withStyles(modalStyle)(ViewLogModal)));
export default withStyles(styles)(
  withStyles(style)(
    withStyles(javascriptStyles)(
      withStyles(basicsStyle)(withStyles(modalStyle)(ViewLogModal))
    )
  )
);
