import React, { useState, useEffect } from 'react'
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import useWindowSize from 'utils/useWindowSize';
import GridContainer from "components/Grid/GridContainer";

import {
  Dialog, DialogTitle, withStyles,
  ThemeProvider,
  Tooltip, Button, DialogActions, Divider
} from '@material-ui/core';
import {
  primaryColor,
} from 'assets/jss/material-kit-pro-react.js';

import Faqs from './Faqs';
import Testimonials from './Testimonials';
import Terms from './Terms';
import UsersGuide from './UsersGuide';
import QuickStartGuide from './QuickStartGuide';
import circlelogo from 'assets/img/byn-logo-circle.png';
import aboutPageMaterialTheme from "utils/Themes/aboutPageMaterialTheme"
import PrivacyPolicy from './PrivacyPolicy';
import {
  withAuthentication,
} from "utils/Session";
import { createUserLog } from "utils/CreateUserLog";


/*
  https://github.com/mui-org/material-ui/issues/11517#issuecomment-407509327
 */
function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

const styles = theme => ({
  root: {
    maxWidth: '100%',
    width: '100%',
    textAlign: 'left'
  },
})

const combinedStyles = combineStyles(styles, modalStyle, javascriptStyles, basicsStyle)

const About = (props) => {
  const { classes } = props
  console.log(classes)
  
  const size = useWindowSize()

  const [openAbout, setOpenAbout] = useState(true)
  const [page, setPage] = useState('about')
  const [title, setTitle] = useState('About')

  const handleCloseAbout = () => {
    setOpenAbout(false)
  }

  const handleChangePage = (page) => {
    setPage(page)
    if (page === 'about') {
      setTitle('About')
    } else if (page === 'quickguide') {
      setTitle('Quick Start Guide')
    } else if (page === 'faq') {
      setTitle('Frequently Asked')
    } else if (page === 'terms') {
      setTitle('Terms of Use')
    } else if (page === 'privacypolicy') {
      setTitle('Privacy Policy')
    } else if (page === 'testimonials') {
      setTitle('Testimonials')
    }
  }

  const handleChangePageContent = () => {
    if (page === 'about') {
      return (

        <div
          className={classes.container}
          style={{
            width: `${size.width <= 414 ? size.width - 60 : 700}px`,
            overflow: "auto",
            height: `${size.height - 140}px`
          }}
        >
          <ThemeProvider theme={aboutPageMaterialTheme}>
            <Button variant="text" style={{ margin: `40px 0 0 0`, width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                createUserLog(props, "QUICK START GUIDE", "Opened QUICK START GUIDE", "")
                handleChangePage('quickguide')
              }}>
              {"QUICK START GUIDE >"}
            </Button>
            <Button variant="text" style={{ width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                createUserLog(props, "FREQUENTLY ASKED", "Opened FREQUENTLY ASKED", "")
                handleChangePage('faq')
              }}>
              {"FREQUENTLY ASKED >"}
            </Button>
            <Button variant="text" style={{ width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                createUserLog(props, "USER GUIDE", "Opened USER GUIDE", "")
                handleChangePage('userguide')
              }}>
              {"USER GUIDE >"}
            </Button>
            <Button variant="text" style={{ width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                createUserLog(props, "TERMS OF USE", "Opened TERMS OF USE", "")
                handleChangePage('terms')
              }}>
              {"TERMS OF USE >"}
            </Button>
            <Button variant="text" style={{ width: "100%" }} onClick={(e) => { e.preventDefault(); handleChangePage('privacypolicy') }}>
              {"PRIVACY POLICY >"}
            </Button>
          </ThemeProvider >
        </div>

      )
    } else if (page === 'quickguide') {
      return <QuickStartGuide />
    } else if (page === 'faq') {
      return <Faqs />
    } else if (page === 'terms') {
      return <Terms />
    } else if (page === 'privacypolicy') {
      return <PrivacyPolicy />
    } else if (page === 'userguide') {
      return <UsersGuide />
    } else if (page === 'testimonials') {
      return <Testimonials />
    }
  }

  return (
    <div
      style={{ marginTop: -100 }}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <div className={classes.container} style={{ width: `${size.width <= 414 ? size.width - 30 : 600}px` }}>
          <ThemeProvider theme={defaultMaterialTheme}>

            {(title.startsWith("Frequently")) && size.width <= 414 ?
              <>
                <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                  Frequently
                        </div>
                <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                  Asked
                        </div>
              </>
              :
              (title.startsWith("Quick")) && size.width <= 414 ?
                <>
                  <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                    Quick Start
                        </div>
                  <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                    Guide
                        </div>
                </>
                :
                <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                  {title}
                </div>
            }
            <Tooltip
              id="tooltip-top"
              title={"Home"}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              {(title.startsWith("Frequently") || title.startsWith("Quick")) ?
                <Button
                  style={{
                    margin: `${size.width <= 414 ? -79 : -45}px ${size.width <= 414 ? 0 : -20}px 0 0 `
                  }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img style={{ width: '50px' }} src={circlelogo} alt="billyounow.com" />
                </Button>
                :
                <Button
                  style={{
                    margin: `-45px ${size.width <= 414 ? 0 : -20}px 0 0 `
                  }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img style={{ width: '50px' }} src={circlelogo} alt="billyounow.com" />
                </Button>
              }
            </Tooltip>
          </ThemeProvider>
        </div>
      </DialogTitle>

      <div className={classes.container}>
        <ThemeProvider theme={defaultBtnMaterialTheme}>
          <GridContainer direction='column' justify='center' alignItems='center'>
            {handleChangePageContent()}
            {page === 'about' ?
              <DialogActions className={classes.modalFooter}>
                <ThemeProvider theme={defaultBtnMaterialTheme}>
                  <Button
                    style={{
                      width: `${size.width <= 414 ? size.width - 200 : 320}px`
                    }}
                    color='primary' variant='contained'
                    onClick={(e) => {
                      e.preventDefault()
                      props.history.push('/app/dashboard')
                    }}
                  >
                    Back
                  </Button>
                </ThemeProvider>
              </DialogActions>
              :
              <DialogActions className={classes.modalFooter}>
                <ThemeProvider theme={defaultBtnMaterialTheme}>
                  <Button
                    style={{
                      width: `${size.width <= 414 ? size.width - 200 : 320}px`
                    }}
                    color='primary' variant='contained' onClick={(e) => { e.preventDefault(); handleChangePage('about') }}>Back</Button>
                </ThemeProvider>
              </DialogActions>
            }
          </GridContainer>
        </ThemeProvider>
      </div>

    </div>

  )
}

export default withStyles(combinedStyles)(withAuthentication(About))
