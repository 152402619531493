
import { createMuiTheme } from "@material-ui/core";

const defaultBtnMaterialTheme = createMuiTheme({
  palette: {
    // primary: red, //C62626
    primary: {
      main: "#C62626"
    },
    // secondary: { main: "#000000" },
    // grey: { main: "#22BF19" }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
      label: {
        fontWeight: 700,
        fontSize: "20px",
        // padding: "-5px -5px -5px -5px",
        // fontHeight: "1px",
        margin: "-10px -2px -10px -2px",
      }
    },
    MuiFormLabel: {
      root: {
        // "&$focused": {
        color: "#999",
        fontSize: "22px",
        backgroundColor: "#fff",
        margin: "-2px 0 0 -5px",
        padding: "0 10px 0 10px"
        // }
      }
    }
  }
});

export default defaultBtnMaterialTheme