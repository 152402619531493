
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";

import Waiting from "utils/Waiting";
// import { Formik, Form } from "formik";
// import { useMediaQuery } from 'react-responsive';

import Tooltip from "@material-ui/core/Tooltip";
// import Typography from "@material-ui/core/Typography";

// import { gql } from "apollo-boost";
import circlelogo from 'assets/img/byn-logo-circle.png';

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import useWindowSize from 'utils/useWindowSize';

import "utils/react-datetime/css/react-datetime.css";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";

import { withFirebase } from "utils/Firebase";

import { ThemeProvider } from "@material-ui/styles";

import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"

import MessageDialog from 'utils/MessageDialog';
import Transition from 'utils/Transition';

import { CREATE_DELETE_ACCOUNT_REQUEST, CANCEL_SERVICE_REQUEST } from 'utils/Graphql/mutations'
import ConfirmDataDelete from "./ConfirmDataDelete";
import { getDaysInMonth } from "date-fns";
import { createUserLog } from "utils/CreateUserLog";

const style = {
};


function Delete(props) {
  const { classes, client, open, onClose, memberData, reFetch } = props;

  const size = useWindowSize()
  const [hasDeleteRequest, setHasDeleteRequest] = useState(false);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false)
  const [deleteDaysRemaining, setDeleteDaysRemaining] = useState(7)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [dateDeletion, setDateDeletion] = useState(0)
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block"
      document.getElementById("mainScreen").style.opacity = 0.3
    } else {
      if (document && document.getElementById("waiting") && document.getElementById("mainScreen")) {
        document.getElementById("waiting").style.display = "none"
        document.getElementById("mainScreen").style.opacity = 1
      }
    }
  }, [isOpenWaiting])

  useEffect(() => {
    console.log(memberData)
    const hasDeleteReq = handleCheckDeleteRequest(memberData.serviceRequest)
    if (hasDeleteReq) setHasDeleteRequest(true)
    else setHasDeleteRequest(false)
    // setHasDownloadRequest(handleCheckDownloadRequest(memberData.serviceRequest))
  }, [memberData.serviceRequest])

  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px")
  // const [closeRightMargin, setCloseRightMargin] = useState("0px")

  const handleCloseMessageDialog = () => {
    reFetch()
    props.history.push('/account');
    // setOpenConfirmDelete(false)
    // setOpenMessageDialog(false)
    onClose()
    // props.history.push('/app/account');
  }

  const ndays = (request, days) => {
    let n = days
    if (request) {
      let reqOriginalDate = new Date(request.date)
      let reqDate = new Date(request.date)
      reqDate.setDate(reqDate.getDate() + n);
      console.log(reqOriginalDate)
      console.log(reqDate)
      let d = Math.abs(reqDate.getDate() - reqOriginalDate.getDate())
      console.log(d)
      return d;
    }
    else {
      return 0
    }
  }
  const handleCheckDeleteRequest = (serviceRequest) => {
    console.log(serviceRequest)
    if (serviceRequest) {
      const sr = serviceRequest.filter((srq, ix) => {
        console.log(srq)
        if (srq.requestType === 'DELETE' && srq.status === 'NEW') {
          setDeleteDaysRemaining(ndays(srq, 7));
          return srq
        }
      })
      console.log(sr)
      if (sr.length > 0) return true
      else return false
    }
    else return false
  }

  const handleCancelRequest = async (requestType) => {
    let serviceRequest = await getRequest(memberData.serviceRequest, requestType)
    console.log(serviceRequest)
    if (serviceRequest) {
      const { id } = serviceRequest
      console.log(id)
      cancelRequest(id).then((response) => {
        createUserLog(props, "DATA DELETE", "Cancelled Data Delete request", "")
        console.log(response)
        //LOADING
        reFetch()
        onClose()
      })
    }
    else {
      console.log(`NO ${requestType} REQUEST`)
    }
  }

  const cancelRequest = async (serviceId) => {
    const { id } = memberData;
    console.log(id)
    try {
      let data = await client.mutate({
        mutation: CANCEL_SERVICE_REQUEST,
        variables: {
          serviceId: serviceId,
          dateCancelled: new Date(),
          // memberId: id
        }
      })
      return data;
    }
    catch (error) {
      console.log(error)
    }
  }

  const getRequest = async (serviceRequest, requestType) => {
    console.log("start get request")
    console.log(requestType)
    if (serviceRequest) {
      var request
      const sr = serviceRequest.filter((srq, ix) => {
        console.log(srq)
        if (srq.requestType === requestType && srq.status === 'NEW') return srq
      })
      console.log(sr)
      if (sr.length > 0) {
        request = sr[0]
        console.log(request)
        return request
      }
      else return null
    }
    else return null
  }

  const dataDelete = async () => {
    const { id } = memberData;
    const date = new Date().toISOString();
    let dateDeletion = await getDateDeletion()
    //TEST
    // let dateDeletion = new Date()
    let data = await client.mutate({
      mutation: CREATE_DELETE_ACCOUNT_REQUEST,
      variables: {
        date,
        memberId: id,
        notes: 'TEST',
        dateDeletion: dateDeletion
      }
    })
    return data;
  }

  const getDateDeletion = async () => {
    //get month
    //get number of days
    let today = new Date()
    console.log(today)
    let dayToday = today.getDay() //0 for sunday, 1 for monday, and so on
    //if dayToday is less than 3 days away from the upcoming sunday, dateDeletion must be set to next sunday
    let daysRemaining = Math.abs(7 - dayToday)
    let daysThisMonth = getDaysInMonth(today)
    var dateDeletion
    console.log(daysRemaining)
    console.log(daysThisMonth)
    if (daysRemaining >= 3 && daysRemaining != 7) {
      //get date this sunday
      dateDeletion = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysRemaining)
      console.log(dateDeletion)
      //assign this sunday 
    } else {
      dateDeletion = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysRemaining + 7)
      console.log(dateDeletion)
      //get date next sunday
      //assign next sunday
    }
    return dateDeletion.toISOString()
  }

  const handleDataDelete = async () => {
    dataDelete().then((response) => {
      createUserLog(props, "DATA DELETE", "Created Data Delete request", "")
      console.log(response)
      if (response.data) {
        let data = response.data.createDeleteRequest
        if (data.dateDeletion) {
          var dateDeletion
          let date = new Date(data.dateDeletion)
          console.log(dateDeletion)
          dateDeletion = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
          console.log(dateDeletion)
          setDateDeletion(dateDeletion)
        }
      }
      // setOpenConfirmDelete(false)
      // setSuccessMsg('Data delete request success. You have n day(s) remaining before your account will be fully deleted.')
      // setOpenSuccess(true)
      setOpenMessageDialog(true)
    })
    // let dateDeletion = await getDateDeletion()
    // console.log(dateDeletion)
  }

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog
        }}
        open={open}
        onClose={onClose}
        TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Data Delete
                </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div id="waiting" style={{ marginBottom: '70px', marginTop: 20 }}>
            <Waiting
              Transition={Transition}
              isOpenWaiting={isOpenWaiting}
            ></Waiting>
          </div>
          {/* {hasDeleteRequest ?
              <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                <Typography>
                  You have a pending delete request. You can still delete your delete request.
              </Typography>
              </div>
              : ''} */}
          <div id="mainScreen" style={{ marginTop: '0px' }}>
            <div
              className={classes.container}
              style={{ width: `${size.width <= 414 ? size.width - 30 : 370}px` }}
            >
              {/* <Formik
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, { setSubmitting }) => {
                  // setSubmitting(true)

                }}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  setSubmitting,
                  touched,
                  errors
                }) => (
                    <Form mode="themed" > */}
              <div
                className={classes.container}
                style={{
                  overflow: "auto",
                  width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                  height: `${size.height - 195}px`
                }}
              >
                <div className={classes.container}>
                  <div style={{ margin: '80px 0 0px 0', fontSize: '26px', fontWeight: '400', textAlign: 'center' }}>
                    {hasDeleteRequest ? 'You have a pending delete request.' : 'Request Data'}
                  </div>
                  <div style={{ margin: '10px 0 60px 0', fontSize: '26px', fontWeight: '400', textAlign: 'center' }}>
                    {hasDeleteRequest ? 'You can still cancel your delete request.' : 'Delete'}
                  </div>

                </div>
              </div>
              <DialogActions className={classes.modalFooter}>
                <ThemeProvider theme={defaultBtnMaterialTheme}>
                  {hasDeleteRequest ?
                    <>
                      <Button
                        style={{
                          width: "60%"
                        }}
                        variant="contained"
                        color="primary"
                        size="md"
                        // disabled={isSubmitting}
                        onClick={hasDeleteRequest ? () => handleCancelRequest('DELETE') : () => setOpenConfirmDelete(true)}
                      >
                        Cancel
                                    </Button>
                      <Button
                        style={{
                          width: "40%"
                        }}
                        variant="outlined"
                        color="primary"
                        size="md"
                        // disabled={isSubmitting}
                        // href="/app/data"
                        // type="submit"
                        onClick={onClose}
                      >
                        Back
                                </Button>
                    </>
                    :
                    <>
                      <Button
                        style={{
                          width: "60%"
                        }}
                        variant="contained"
                        color="primary"
                        size="md"
                        // disabled={isSubmitting}
                        onClick={hasDeleteRequest ? () => handleCancelRequest('DELETE') :
                          () => {
                            setOpenConfirmDelete(true)
                          }}
                      >
                        Go
                                    </Button>
                      <Button
                        style={{
                          width: "40%"
                        }}
                        variant="outlined"
                        color="primary"
                        size="md"
                        // disabled={isSubmitting}
                        // href="/app/data"
                        // type="submit"
                        onClick={onClose}
                      >
                        Back
                                </Button>
                    </>
                  }


                </ThemeProvider>
              </DialogActions>
              {/* </Form>
                  )}
              </Formik> */}
            </div>
          </div>
        </ThemeProvider>

      </Dialog>

      <MessageDialog
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={`Data delete request received. Data delete email will be sent. Your data is scheduled for deletion on ${dateDeletion}. You may cancel the data delete request before that date.`}
        messageOnMessageDialog2={``}
        messageOnMessageDialog3={""}
        messageOnMessageDialog4={""}
        messageOnMessageDialog5={""}
        messageOnMessageDialog6={""}
        messageDialogTitle={"Confirmation"}
        messageLabelBtn={"DONE"}
      // push={"/app/data"}

      />
      <ConfirmDataDelete
        classes={classes}
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        submitFn={handleDataDelete}
        setOpenConfirmDelete={setOpenConfirmDelete}
      />
    </>
  )
}

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(Delete)))
    )
  )
);