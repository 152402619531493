
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import {
  primaryColor,
  grayColor
} from 'assets/jss/material-kit-pro-react.js';

import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import circlelogo from "assets/img/byn-logo-circle.png";

import Transition from "utils/Transition";
import useWindowSize from 'utils/useWindowSize';


function RegSuccessDialog(props) {
  const { classes } = props
  const size = useWindowSize()

  // if (!props.open)
  //   return null;



  return (
    <>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={true}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Registration
                  </div>

              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                  }}
                  className={classes.modalCloseButtonBYN}
                  // href="/auth/dashboard"
                  onClick={(e) => {
                    e.preventDefault()
                    props.history.push('/auth/dashboard')
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="notarynet.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,

            }}
          >

            <div
              className={classes.container}
              style={{

                width: `${size.width <= 320 ? size.width - 60 :
                  size.width <= 360 ? size.width - 60 :
                    size.width <= 375 ? size.width - 60 :
                      size.width <= 414 ? size.width - 60 : 400
                  }px`,
                overflow: "auto",
                height: `${size.height - 143}px`,
                // height: `${size.width <= 320 ? size.height - 180 :
                //   size.width <= 360 ? size.height - 180 :
                //     size.width <= 365 ? size.height - 180 :
                //       size.width <= 375 ? size.height - 180 :
                //         size.width <= 414 ? size.height - 180 :
                //           size.height - 180
                //   }px`,
              }}
            >
              <div style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              >


                <Typography style={{
                  marginTop: `${size.width <= 320 ? 40 :
                    size.width <= 360 ? 40 :
                      size.width <= 365 ? 60 :
                        size.width <= 375 ? 60 :
                          60
                    }px`,
                  fontSize: `${size.width <= 320 ? 20 :
                    size.width <= 360 ? 24 :
                      size.width <= 365 ? 24 :
                        size.width <= 375 ? 24 :
                          24
                    }px`,
                  color: grayColor[1], textAlign: 'center'
                }}
                >
                  Registration successful.
                        </Typography>
              </div>


            </div>

            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  href="/auth/signin"
                  type="submit"
                // onClick={(e) => {
                //   e.preventDefault()
                //   props.history.push('/auth/signin')
                // }}
                >
                  Log-in
                      </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="sm"
                  // href="/auth/signupweb"
                  onClick={(e) => {
                    e.preventDefault()
                    props.history.push('/auth/signupweb')
                  }}
                >
                  Back
                      </Button>

              </ThemeProvider>
            </DialogActions>

          </div>
        </ThemeProvider>
      </Dialog>


    </>

  );
}

export default withStyles(styles)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(withStyles(modalStyle)(RegSuccessDialog))
  )
);