var React = require('react'),
	createClass = require('create-react-class'),
	moment = require('moment')
	;

var DateTimePickerDays = createClass({
	render: function () {
		// var footer = this.renderFooter(),
		var date = this.props.viewDate,
			locale = date.localeData(),
			tableChildren
			;

		tableChildren = [
			React.createElement('thead', { key: 'th' }, [
				React.createElement('tr', { key: 'h' }, [
					this.renderTimer()
				]),
				React.createElement('tr', { key: 'h' }, [
					// React.createElement('th', { key: 'p', className: 'rdtPrev', onClick: this.props.subtractTime(1, 'months') }, React.createElement('span', {}, '◀︎')),
					// React.createElement('td', { key: 's', className: 'rdtSwitchMonthYesr', onClick: this.props.showView('months'), colSpan: 5, 'data-value': this.props.viewDate.month() }, locale.months(date) + ' ' + date.year()),
					React.createElement('td', { key: 's', className: 'rdtSwitchMonthYear', colSpan: 7, 'data-value': this.props.viewDate.month() }, locale.months(date) + ' ' + date.year()),
					// React.createElement('th', { key: 'n', className: 'rdtNext', onClick: this.props.addTime(1, 'months') }, React.createElement('span', {}, '▶︎'))
				]),

				React.createElement('tr', { key: 'd' }, this.getDaysOfWeek(locale).map(function (day, index) { return React.createElement('th', { key: day + index, className: 'dow' }, day); }))
			]),
			React.createElement('tbody', { key: 'tb' }, this.renderDays())
		];

		return React.createElement('div', { className: 'rdtDays' },
			React.createElement('table', {}, tableChildren)
		);
	},

	/**
	 * Get a list of the days of the week
	 * depending on the current locale
	 * @return {array} A list with the shortname of the days
	 */
	getDaysOfWeek: function (locale) {
		var days = locale._weekdaysMin,
			first = locale.firstDayOfWeek(),
			dow = [],
			i = 0
			;

		days.forEach(function (day) {
			dow[(7 + (i++) - first) % 7] = day;
		});

		return dow;
	},

	renderDays: function () {
		var date = this.props.viewDate,
			selected = this.props.selectedDate && this.props.selectedDate.clone(),
			prevMonth = date.clone().subtract(1, 'months'),
			currentYear = date.year(),
			currentMonth = date.month(),
			weeks = [],
			days = [],
			renderer = this.props.renderDay || this.renderDay,
			isValid = this.props.isValidDate || this.alwaysValidDate,
			classes, isDisabled, dayProps, currentDate
			;

		prevMonth.date(prevMonth.daysInMonth()).startOf('week');
		var lastDay = prevMonth.clone().add(42, 'd');
		let day = 0;
		let activeDay = 0
		while (prevMonth.isBefore(lastDay)) {

			if (selected && prevMonth.isSame(selected, 'day')) {
				activeDay = day
				// console.log('activeDay')
				// console.log(activeDay)
				// console.log('selected')
				// console.log(selected)
				break
			}
			day = day + 1
			prevMonth.add(1, 'd');
		}

		let startDay = 0
		let endDay = 0
		if (0 <= activeDay && activeDay <= 6) {

			if (activeDay === 0) { // unlikely 
				startDay = 1
				endDay = 7
			} else if (activeDay === 6) {
				startDay = 1
				endDay = 14
			} else {
				startDay = 1
				endDay = 7
			}

		} else if (7 <= activeDay && activeDay <= 13) {

			if (activeDay === 7) {
				startDay = 1
				endDay = 14
			} else if (activeDay === 13) {
				startDay = 8
				endDay = 21
			} else {
				startDay = 8
				endDay = 14
			}

		} else if (14 <= activeDay && activeDay <= 20) {

			if (activeDay === 14) {
				startDay = 7
				endDay = 21
			} else if (activeDay === 20) {
				startDay = 15
				endDay = 28
			} else {
				startDay = 15
				endDay = 21
			}

		} else if (21 <= activeDay && activeDay <= 27) {

			if (activeDay === 21) {
				startDay = 14
				endDay = 28
			} else if (activeDay === 27) {
				startDay = 21
				endDay = 35
			} else {
				startDay = 22
				endDay = 28
			}

		} else if (28 <= activeDay && activeDay <= 34) {

			if (activeDay === 28) {
				startDay = 21
				endDay = 35
			} else if (activeDay === 34) {
				startDay = 28
				endDay = 42
			} else {
				startDay = 29
				endDay = 35
			}

		} else {

			if (activeDay === 35) {
				startDay = 28
				endDay = 42
			} if (activeDay === 41) { // unlikely 
				startDay = 35
				endDay = 42
			} else {
				startDay = 35
				endDay = 42
			}
		}
		// console.log('startDay')
		// console.log(startDay)
		// console.log('endDay')
		// console.log(endDay)


		date = this.props.viewDate;
		selected = this.props.selectedDate && this.props.selectedDate.clone();
		prevMonth = date.clone().subtract(1, 'months');
		currentYear = date.year();
		currentMonth = date.month();
		weeks = [];
		days = [];
		renderer = this.props.renderDay || this.renderDay;
		let runDay = 0
		prevMonth.date(prevMonth.daysInMonth()).startOf('week');
		// var lastDay = prevMonth.clone().add(42, 'd');

		while (prevMonth.isBefore(lastDay)) {
			runDay = runDay + 1
			if (startDay <= runDay && runDay <= endDay) {

				classes = 'rdtDay';
				currentDate = prevMonth.clone();

				if ((prevMonth.year() === currentYear && prevMonth.month() < currentMonth) || (prevMonth.year() < currentYear))
					classes += ' rdtOld';
				else if ((prevMonth.year() === currentYear && prevMonth.month() > currentMonth) || (prevMonth.year() > currentYear))
					classes += ' rdtNew';

				if (selected && prevMonth.isSame(selected, 'day'))
					classes += ' rdtActive';

				if (prevMonth.isSame(moment(), 'day'))
					classes += ' rdtToday';

				isDisabled = !isValid(currentDate, selected);
				if (isDisabled)
					classes += ' rdtDisabled';

				dayProps = {
					key: prevMonth.format('M_D'),
					'data-value': prevMonth.date(),
					className: classes
				};

				if (!isDisabled)
					dayProps.onClick = this.updateSelectedDate;

				days.push(renderer(dayProps, currentDate, selected));

				if (days.length === 7) {
					weeks.push(React.createElement('tr', { key: prevMonth.format('M_D') }, days));
					days = [];
				}
			}
			prevMonth.add(1, 'd');

		}

		return weeks;
	},

	updateSelectedDate: function (event) {
		this.props.updateSelectedDate(event, true);
	},

	renderDay: function (props, currentDate) {
		return React.createElement('td', props, currentDate.date());
	},

	renderTimer: function () {
		if (!this.props.timeFormat)
			return '';

		var date = this.props.selectedDate || this.props.viewDate;

		return React.createElement('td', { key: 's', className: 'rdtTimeToggle', onClick: this.props.showView('time'), colSpan: 7 },
			date.format(this.props.timeFormat))
	},

	alwaysValidDate: function () {
		return 1;
	}
});

module.exports = DateTimePickerDays;
