import React from 'react';
import './spinner.css'

const Spinner = () => {
  return (

    <div class="loader" style={{ height: '80px', width: '80px', display: 'block', margin: 'auto', marginBottom: '80px' }}>
    </div>

  );
};

export default Spinner;