import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
// IconButton
// import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';

function formatNumber(number) {
  return Number(parseFloat(number).toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: 2,
  });
}

export default class EllipsisRenderer extends Component {
  constructor(props) {
    super(props);
    // debugger;

    this.invokeUpdateLog = this.invokeUpdateLog.bind(this);
    this.invokeDeleteLog = this.invokeDeleteLog.bind(this);

    this.invokeUpdateClient = this.invokeUpdateClient.bind(this);
    this.invokeUpdateCase = this.invokeUpdateCase.bind(this);
  }

  invokeUpdateLog() {
    debugger;
    this.props.context.componentParent.updateLog(this.props.data);
    //this.props.context.componentParent.toggleModal();
  }

  invokeDeleteLog() {
    debugger;
    this.props.context.componentParent.deleteLog(this.props.data);
  }

  invokeUpdateClient() {
    debugger;
    this.props.context.componentParent.updateClient(this.props.data);
    //this.props.context.componentParent.toggleModal();
  }
  invokeUpdateCase() {
    debugger;
    this.props.context.componentParent.updateCase(this.props.data);
    //this.props.context.componentParent.toggleModal();
  }

  render() {
    debugger;
    if (this.props !== undefined) {
      if (this.props.data !== undefined) {
        if (this.props.data.id !== undefined) {
          return (
            <div  />
          );
        } else {
          debugger;
          if (this.props.data.editDelete !== undefined) {
            if (this.props.data.filePath.endsWith('$.activit0y')) {
              return (
                <div style={{ textAlign: 'right', fontWeight: '100' }}>
                </div>
              );
            } else if (this.props.data.filePath.endsWith('$.expens0e')) {
              return (
                <div style={{ textAlign: 'right', fontWeight: '100' }}>
                </div>
              );
            } else if (this.props.data.filePath.endsWith('$.caseHeader')) {
              return (
                <div style={{ textAlign: 'right', fontWeight: '100' }}>
                </div>
              );
            } else if (this.props.data.filePath.endsWith('$.caseHeader2')) {
              return (
                <div style={{ textAlign: 'center', fontWeight: '100' }}>
                </div>
              );
            } else if (this.props.data.filePath.endsWith('$.headerLegend')) {
              return (
                <div style={{ textAlign: 'right', fontWeight: '100' }}>
                </div>
              );
            } else if (this.props.data.filePath.endsWith('$.WorkAll')) {
              debugger;
              if (
                this.props.context.componentParent.formReference().values
                  .logType === '-1' ||
                this.props.context.componentParent.formReference().values
                  .logType === 'SERVICE_LOG'
              )
                return (
                  <div style={{ textAlign: 'right', fontWeight: '600' }}>
                    {'\u20b1' + formatNumber(this.props.data.editDelete)}
                  </div>
                );
              else
                return (
                  <div style={{ textAlign: 'right', fontWeight: '100' }}>
                  </div>
                );
            } else if (this.props.data.filePath.endsWith('$.ExpenseAll')) {
              debugger;

              if (
                this.props.context.componentParent.formReference().values
                  .logType === '-1' ||
                this.props.context.componentParent.formReference().values
                  .logType === 'EXPENSE_LOG'
              )
                return (
                  <div style={{ textAlign: 'right', fontWeight: '600' }}>
                    {'\u20b1' + formatNumber(this.props.data.editDelete)}
                  </div>
                );
              else
                return (
                  <div style={{ textAlign: 'right', fontWeight: '100' }}>
                  </div>
                );
            } else if (this.props.data.filePath.endsWith('$.TotalAll')) {
              debugger;
              return (
                <div style={{ textAlign: 'right', fontWeight: '600' }}>
                  {'\u20b1' + formatNumber(this.props.data.editDelete)}
                </div>
              );
            } else if (this.props.data.filePath.endsWith('$.Work')) {
              debugger;
              if (
                this.props.context.componentParent.formReference().values
                  .logType === '-1' ||
                this.props.context.componentParent.formReference().values
                  .logType === 'SERVICE_LOG'
              )
                return (
                  <div style={{ textAlign: 'right', fontWeight: '600' }}>
                    {'\u20b1' + formatNumber(this.props.data.editDelete)}
                  </div>
                );
              else
                return (
                  <div style={{ textAlign: 'right', fontWeight: '100' }}>
                  </div>
                );
            } else if (this.props.data.filePath.endsWith('$.Expense')) {
              debugger;
              if (
                this.props.context.componentParent.formReference().values
                  .logType === '-1' ||
                this.props.context.componentParent.formReference().values
                  .logType === 'EXPENSE_LOG'
              )
                return (
                  <div style={{ textAlign: 'right', fontWeight: '600' }}>
                    {'\u20b1' + formatNumber(this.props.data.editDelete)}
                  </div>
                );
              else
                return (
                  <div style={{ textAlign: 'right', fontWeight: '100' }}>
                  </div>
                );
            } else if (this.props.data.filePath.endsWith('$.Total')) {
              debugger;
              return (
                <div style={{ textAlign: 'right', fontWeight: '600' }}>
                  {'\u20b1' + formatNumber(this.props.data.editDelete)}
                </div>
              );
            } else if (
              this.props.data.filePath.endsWith('$.Work (This Case)')
            ) {
              if (
                this.props.context.componentParent.formReference().values
                  .logType === '-1' ||
                this.props.context.componentParent.formReference().values
                  .logType === 'SERVICE_LOG'
              ) {
                debugger;
                return (
                  <div style={{ textAlign: 'right', fontWeight: '500' }}>
                    {'\u20b1' + formatNumber(this.props.data.editDelete)}
                  </div>
                );
              } else
                return (
                  <div style={{ textAlign: 'right', fontWeight: '100' }}>
                    {/* {this.props.data.editDelete} */}
                  </div>
                );
            } else if (
              this.props.data.filePath.endsWith('$.Expense (This Case)')
            ) {
              debugger;
              if (
                this.props.context.componentParent.formReference().values
                  .logType === '-1' ||
                this.props.context.componentParent.formReference().values
                  .logType === 'EXPENSE_LOG'
              )
                return (
                  <div style={{ textAlign: 'right', fontWeight: '500' }}>
                    {'\u20b1' + formatNumber(this.props.data.editDelete)}
                  </div>
                );
              else
                return (
                  <div style={{ textAlign: 'right', fontWeight: '100' }}>
                    {/* {this.props.data.editDelete} */}
                  </div>
                );
            } else if (
              this.props.data.filePath.endsWith('$.Subtotal (This Case)')
            ) {
              debugger;
              return (
                <div style={{ textAlign: 'right', fontWeight: '500' }}>
                  {'\u20b1' + formatNumber(this.props.data.editDelete)}
                </div>
              );
            }
          }
        }
      }
    }
  }
}
