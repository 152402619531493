import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useWindowSize from 'utils/useWindowSize';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Faqs = (props) => {
  
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
 
  const size = useWindowSize()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.container}
    style={{ marginTop: "20px", overflow: "auto", height: `${size.height - (size.width <= 414 ? 160 : 160)}px`, width: `${size.width <= 414 ? size.width - 30 : 570}px` }}
  >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            What is it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            BillYouNow is a convenient, secure mobile activity logger for
            lawyers
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            What are its functions?

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            BillYouNow digitally logs work done and expenses incurred by lawyers for clients using mobile devices with internet access.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>

            What is its output?


          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            BillYouNow summarizes all work and expense logs per case and
            per client for fast, easy and presentable client billing.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            What are its security
            features?

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            BillYouNow secures all data through encryption, cryptographic
            protocol transmission and uses Swiss-based cloud servers for
            added protection.

          </Typography>
        </AccordionDetails>
      </Accordion>




    

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
          How do I use it to bill
            a client?

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          BillYouNow summarizes all billable activities and reimbursable
            expenses for convenient bill preparation. A PDF download of the
            summary is provided that can be attached to an email bill or
            printed and attached to a paper bill.

          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
          How is it priced?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          BillYouNow ensures fair pricing for all users, charging based on
            actual usage, that is, a fixed fee per log.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I pay?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            BillYouNow allows you to top up your account by using your
            credit card, online payment systems like Paypal, digital or e-
            wallet services.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>

            How do I use it to
            manage my practice?

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            BillYouNow's log summary shows how your billings are
            distributed across clients, cases, periods and activities for a real
            time snapshot of your practice.

          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>

            How do I make
            suggestions or
            request new features?

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            BillYouNow actively seeks user feedback. Contact us here.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I get support?
            
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You can drop BillYouNow a note, call or contact us through email or our social media accounts here.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
          Will my data be disclosed?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Under no circumstance will BillYouNow disclose your data to anyone. BillYouNow considers your data to be covered by attorney-client privilege. We will refuse any third-party request or demand for your data and promptly inform you of any such request or demand. 
          
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
          What are its terms of use?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You may view BillYouNow's Terms and Conditions here. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
          What is its privacy policy?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You may view BillYouNow's Privacy Policy here. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
          Does it use cookies to collect personal data?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Does it use cookies to collect personal data?No. BillYouNow does not use cookies to collect your personal data. The only personal data BillYouNow retains are personal data you voluntarily provided by the user upon registration.
          </Typography>
        </AccordionDetails>
      </Accordion>

    </div>
  )
}

export default Faqs
