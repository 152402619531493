import React, { useState, useEffect, useRef } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import Button from 'components/CustomButtons/Button.js';
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@material-ui/core";
import useWindowSize from 'utils/useWindowSize';
// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";
import {
  // createMuiTheme,
  withStyles,
  // createStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
// import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";
import { Formik } from "formik";
import { Form } from "react-formik-ui";
import {
  Switch,
  FormControlLabel,
  // FormControl,
  // FormLabel,
  Button,
  TextField,
} from "@material-ui/core";
// import * as Yup from "yup";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import { primaryColor, grayColor } from "assets/jss/material-kit-pro-react.js";
// import FailDialog from "./failDialog";
import circlelogo from "assets/img/byn-logo-circle.png";
import { UPDATE_CASE_MUTATION } from "utils/Graphql/mutations";
import { createUserLog } from "utils/CreateUserLog";



const _EditCase = {
  values: {
    caseCode: "",
    caseTitle: "",
    status: "",
  },
};
const Switchy = withStyles({
  switchBase: {
    color: primaryColor[0],
    "&$checked": {
      color: primaryColor[0],
    },
    "&$checked + $track": {
      backgroundColor: primaryColor[0],
    },
  },
  checked: {},
  track: {},
})(Switch);

const EditCase = (props) => {
  const { classes } = props;
  const size = useWindowSize()
  const formRef = useRef();
  const {
    client,
    currentCase,
    open,
    onClose,
    handleOpenEditCaseConfirm,
    getActivityLogs,
    getExpenseLogs,
  } = props;
  console.log(currentCase);
  // const [caseCode, setCaseCode] = useState("");
  // const [caseTitle, setCaseTitle] = useState("");
  const [toggled, setToggled] = useState(true);
  const [openFail, setOpenFail] = useState(false);
  const [failMessage] = useState("");
  const [failTitle] = useState("");

  const [openActive, setOpenActive] = useState(false);
  const [openInActive, setOpenInActive] = useState(false);
  const [openStatusNotification, setOpenStatusNotification] = useState(false);
  useEffect(() => {
    console.log(currentCase.status);
    if (currentCase.status === "ACTIVE") setToggled(true);
    else if (currentCase.status === "INACTIVE") setToggled(false);
  }, [currentCase.status]);

  const handleChangeStatus = (event, values, setFieldValue) => {
    const stat = event.target.checked;
    console.log(stat);
    console.log(getActivityLogs(currentCase) + getExpenseLogs(currentCase));
    // if (stat    === false && getActivityLogs(currentCase) + getExpenseLogs(currentCase) > 0) {
    //   handleOpenFail();
    // } else {
    setToggled(stat);
    if (stat === true) {
      setFieldValue("status", "ACTIVE");
      values.status = "ACTIVE";
      const { caseCode, caseTitle, status } = values;
      const { id, member, parentId, ord } = currentCase;
      console.log(currentCase);
      console.log(id, member, client);

      client
        .mutate({
          mutation: UPDATE_CASE_MUTATION,
          variables: {
            memberId: member.id,
            clientId: currentCase.client.id,
            caseId: id,
            caseCode: caseCode.toUpperCase(),
            caseTitle: caseTitle.toUpperCase(),
            parentId,
            ord,
            status,
            actionDate: new Date().toISOString(),
          },
        })
        .then((response) => {
          createUserLog(props, "EDIT CASE", "Edited Case info", "")
          console.log("...handleChangeStatus");
          setOpenActive(true);
        });
    } else {
      setFieldValue("status", "INACTIVE");
      values.status = "INACTIVE";
      // props.setEditValues(values);
      _EditCase.values = { ...values };
      console.log("values");
      console.log(values);
      setOpenStatusNotification(true);
    }
    // }
  };

  // const handleOpenFail = () => {
  //   setFailMessage("This case has active logs. Set inactive failed.");
  //   setFailTitle("Set inactive");
  //   setOpenFail(true);
  // };

  const handleCloseFail = () => {
    setOpenFail(false);
  };

  function clientOnGrid(item) {
    return item.lastName && item.firstName
      ? item.lastName + ", " + item.firstName
      : item.lastName && !item.firstName
        ? item.lastName
        : !item.lastName && item.firstName
          ? item.firstName
          : null;
  }

  return (
    <>
      <Dialog
        classes={{
          root: props.classes.modalRoot,
          paper: props.classes.modalLog,
        }}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Edit Case
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: props.classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -12}px 0 0 `,
                    }}
                    className={props.classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <Formik
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={{
                caseCode: currentCase.caseCode ? currentCase.caseCode : "",
                caseTitle: currentCase.caseTitle ? currentCase.caseTitle : "",
                status: currentCase.status,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                handleOpenEditCaseConfirm(values);
              }}
            >
              {({
                values,
                isSubmitting,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                touched,
                errors,
                dirty,
                handleChange,
                handleSubmit,
                handleReset,
                initialErrors,
                isInitialValid,
                isValid,
              }) => (
                <Form>
                  <div className={classes.container}>
                    <Typography
                      style={{
                        textAlign: "center",
                        margin: "40px 0 0 0",
                        fontSize: "16px",
                      }}
                    >
                      {clientOnGrid(props.currentClient)
                        ? clientOnGrid(props.currentClient)
                        : "----------"}
                    </Typography>
                    <Typography
                      style={{
                        textAlign: "center",
                        margin: "0 0 0 0px",
                        fontSize: "16px",
                      }}
                    >
                      {props.currentClient.company
                        ? "(" + props.currentClient.company + ")"
                        : "----------"}
                    </Typography>
                  </div>

                  <div
                    className={classes.container}
                    style={{
                      width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                      overflow: "auto",
                      height: `${size.height - 206}px`,
                    }}
                  >
                    <TextField
                      id="caseCode"
                      style={{ margin: "40px 0 20px 0" }}
                      variant="outlined"
                      label="Case Code"
                      value={values.caseCode}
                      onChange={(e) => {
                        values.caseCode = e.target.value.toUpperCase();
                        setFieldValue("caseCode", e.target.value.toUpperCase());
                      }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.caseCode.length === 0 && values.caseTitle.length === 0}
                    />
                    <TextField
                      id="caseTitle"
                      style={{ marginBottom: "20px" }}
                      variant="outlined"
                      label="Case Title"
                      value={values.caseTitle}
                      onChange={(e) => {
                        values.caseTitle = e.target.value.toUpperCase();
                        setFieldValue(
                          "caseTitle",
                          e.target.value.toUpperCase()
                        );
                      }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.caseCode.length === 0 && values.caseTitle.length === 0}
                    />
                    <Typography
                      style={{
                        textAlign: "center", fontSize: '16px', color: primaryColor[0],
                        display: values.caseCode === "" && values.caseTitle === "" ? 'block' : 'none'
                      }}>
                      <em>* Required</em>
                    </Typography>

                    <Typography
                      style={{
                        fontSize: "18px",
                        margin: "20px 0 -5px 0",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      Status
                    </Typography>
                    <div style={{ textAlign: "center" }}>
                      <FormControlLabel
                        control={
                          <Switchy
                            checked={toggled}
                            onChange={(e) =>
                              handleChangeStatus(e, values, setFieldValue)
                            }
                            disableRipple
                            color={primaryColor[0]}
                            name="status"
                          />
                        }
                        label={toggled === true ? "ACTIVE" : "INACTIVE"}
                      />
                    </div>
                  </div>
                  <DialogActions className={props.classes.modalFooter}>
                    <ThemeProvider theme={defaultBtnMaterialTheme}>
                      <Button
                        style={{ width: "60%" }}
                        variant="contained"
                        disableElevation
                        color="primary"
                        type="submit"
                      >
                        Go
                      </Button>
                      <Button
                        style={{ width: "40%" }}
                        disableElevation
                        color="primary"
                        variant="outlined"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    </ThemeProvider>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </div>
        </ThemeProvider>
      </Dialog>

      {/* <FailDialog
        message={failMessage}
        title={failTitle}
        open={openFail}
        onClose={handleCloseFail}
      /> */}

      <Dialog
        classes={{
          root: props.classes.modalRoot,
          paper: props.classes.modalLog,
        }}
        open={openActive}
        // TransitionComponent={props.Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: props.classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -12}px 0 0 `,
                    }}
                    className={props.classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 -10px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Case status
              </Typography>
              <Typography
                style={{
                  margin: "0px 0 40px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                set to active.
              </Typography>
            </div>
            <DialogActions className={props.classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenActive(false);
                    props.reFetchCases();
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: props.classes.modalRoot,
          paper: props.classes.modalLog,
        }}
        open={openInActive}
        // TransitionComponent={props.Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: props.classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -12}px 0 0 `,
                    }}
                    className={props.classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 -10px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Case status
              </Typography>
              <Typography
                style={{
                  margin: "0px 0 40px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                set to inactive.
              </Typography>
            </div>
            <DialogActions className={props.classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenInActive(false);
                    setOpenStatusNotification(false);
                    props.reFetchCases();
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: props.classes.modalRoot,
          paper: props.classes.modalLog,
        }}
        open={openStatusNotification}
        // TransitionComponent={props.Transition}
        keepMounted
        // onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Verify
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: props.classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -12}px 0 0 `,
                    }}
                    className={props.classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Logging and viewing
              </Typography>
              <Typography
                style={{
                  margin: "0 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                functions disabled for
              </Typography>
              <Typography
                style={{
                  margin: "0 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                case on inactive
              </Typography>
              <Typography
                style={{
                  margin: "0 0 0px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                status.
              </Typography>

              <Typography
                style={{
                  margin: "30px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Verify setting case
              </Typography>
              <Typography
                style={{
                  margin: "0 0 40px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                status to inactive.
              </Typography>
            </div>
            <DialogActions className={props.classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    console.log(_EditCase.values);
                    const { caseCode, caseTitle, status } = _EditCase.values;
                    const { id, member, parentId, ord } = currentCase;
                    console.log(currentCase);
                    console.log(id, member, client);

                    client
                      .mutate({
                        mutation: UPDATE_CASE_MUTATION,
                        variables: {
                          memberId: member.id,
                          clientId: currentCase.client.id,
                          caseId: id,
                          caseCode: caseCode.toUpperCase(),
                          caseTitle: caseTitle.toUpperCase(),
                          parentId,
                          ord,
                          status,
                          actionDate: new Date().toISOString(),
                        },
                      })

                      .then((response) => {
                        console.log("...handleChangeStatus");
                        setOpenStatusNotification(false);
                        setOpenInActive(true);
                      });
                  }}
                >
                  Verify
                </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    console.log(_EditCase.values);
                    const { status } = _EditCase.values;
                    debugger;
                    if (status === "ACTIVE") {
                      formRef.current.values.status = "INACTIVE";
                      currentCase.status = "INACTIVE";
                      setToggled(false);
                    } else {
                      formRef.current.values.status = "ACTIVE";
                      currentCase.status = "ACTIVE";
                      setToggled(true);
                    }
                    setOpenStatusNotification(false);
                  }}
                >
                  Cancel
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>
    </>
  );
};

export default EditCase;
