

export default class DateTimeLog {
  constructor(duration) {
    console.log(duration)
    if (!duration || duration === '') {
      duration = 0;
      this.duration = 0;
    } else {
      this.duration = duration;
    }
  }

  isDateLesserOrGreater = (date, cDate) => {
    let a = { date: new Date(), lesserOrGreater: '', dateString: '' }
    let day = date.getDate()
    let year = date.getFullYear()
    let month = date.getMonth()
    let currentDateMax = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), 23, 59, 59, 999);
    let currentDateMin = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), 0, 0, 0, 0);
    let monthDayYear = new Date(year, month, day);
    console.log("Date: " + month + " " + day + ", " + year)
    if ((date > currentDateMax) || (date < currentDateMin)) {
      console.log("Date: " + monthDayYear.toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' }))
      a.dateString = monthDayYear.toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' });
      a.date = monthDayYear;
    }
    if (date > currentDateMax) {
      a.lesserOrGreater = 'greater'
    }
    else if (date < currentDateMin) {
      a.lesserOrGreater = 'lesser'
    }
    return a;
  }

  getDurationHours = (duration) => {
    let hours = this.duration
    console.log(hours);
    try {
      if (hours && !duration) {
        return parseFloat(Number(hours)) - this.formatNumber_(hours - parseInt(Number(hours)))
      }
      else if (duration && !hours) {
        return parseFloat(Number(duration)) - this.formatNumber_(duration - parseInt(Number(duration)))
      }
      else {
        return parseFloat(Number(hours)) - this.formatNumber_(hours - parseInt(Number(hours)))
      }
    }
    catch (e) {
      throw e;
    }
  }

  getDurationMinutes = (duration) => {
    let dr = 0;
    try {
      if (!duration && !this.duration) {
        return 0;
      } else {
        if (this.duration && !duration) {
          dr = this.formatNumber_(this.duration - parseInt(Number(this.duration))) * 60
        }
        else if (duration && !this.duration) {
          dr = this.formatNumber_(duration - parseInt(Number(duration))) * 60
        }
        else {
          dr = this.formatNumber_(this.duration - parseInt(this.Number(duration))) * 60
        }
      }
      console.log(dr)
      return dr;
    }
    catch (e) {
      throw e;
    }
  }

  formatNumber_ = (str) => {
    var n = Number.parseFloat(str);
    if (!str || isNaN(n) || n < 0) return 0;
    return n.toFixed(2);
  }

  getStartTimeFromEndTime = (endTime) => {
    try {
      console.log(endTime)
      if (typeof endTime === "object") {
        if (Object.prototype.toString.call(endTime) !== '[object Date]') {
          endTime = endTime.toDate()
        }
      } else {
        //not object
        console.log('')
      }
      let startHours = endTime.getHours() - this.getDurationHours()
      let startMinutes = endTime.getMinutes() - this.getDurationMinutes()
      let startTime = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate())
      startTime.setHours(startHours, startMinutes, endTime.getSeconds(), endTime.getMilliseconds())
      console.log(startTime)
      return startTime;
    }
    catch (e) {
      console.log(e)
      throw e;
    }
  }

  getEndTimeFromStartTime = (startTime) => {
    try {
      console.log(startTime)
      if (typeof startTime === "object") {
        if (Object.prototype.toString.call(startTime) !== '[object Date]') {
          startTime = startTime.toDate()
        }
      } else {
        //no object
        console.log('')
      }
      console.log(this.getDurationHours())
      console.log(this.getDurationMinutes())

      let endHours = startTime.getHours() + this.getDurationHours()
      let endMinutes = startTime.getMinutes() + this.getDurationMinutes()
      let endTime = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate())
      endTime.setHours(endHours, endMinutes, startTime.getSeconds(), startTime.getMilliseconds())
      console.log(endTime)
      return endTime;

    }
    catch (error) {
      console.log(error)
    }
  }

  getSynchedStartYearMonthDate = (logDateTime, startDateTime) => {
    try {
      if (logDateTime && startDateTime) {
        if (Object.prototype.toString.call(startDateTime) !== '[object Date]') {
          startDateTime = startDateTime.toDate()
        }
        let startTime = new Date(logDateTime.getFullYear(), logDateTime.getMonth(), logDateTime.getDate(), startDateTime.getHours(), startDateTime.getMinutes(), startDateTime.getSeconds(), startDateTime.getMilliseconds());
        return startTime;
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  getSynchedEndYearMonthDate = (logDateTime, endDateTime) => {
    try {
      if (logDateTime && endDateTime) {
        if (Object.prototype.toString.call(endDateTime) !== '[object Date]') {
          endDateTime = endDateTime.toDate()
        }
        let endTime = new Date(logDateTime.getFullYear(), logDateTime.getMonth(), logDateTime.getDate(), endDateTime.getHours(), endDateTime.getMinutes(), endDateTime.getSeconds(), endDateTime.getMilliseconds());
        return endTime;
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  validateEndDateHours = (logDate, endTime, hours) => {
    try {
      if (Object.prototype.toString.call(logDate) !== '[object Date]') {
        logDate = logDate.toDate()
      }
      if (Object.prototype.toString.call(endTime) !== '[object Date]') {
        endTime = endTime.toDate()
      }

      // logDate = logDate.setHours(23,59,59,999);
      if (endTime > logDate) {
        console.log('bawal')
        return false;
      } else {
        console.log('pwede')
        return true;
      }
    }
    catch (e) {
      console.log(e)
    }
  }

}