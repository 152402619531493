import React, { useState, useEffect } from "react";
import circlelogo from 'assets/img/byn-logo-circle.png';
import Waiting from "utils/Waiting";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import useWindowSize from 'utils/useWindowSize';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  ThemeProvider,
} from "@material-ui/core";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react.js";

import defaultMaterialTheme from "utils/Themes/creditCardTheme.js";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme.js";
import Transition from "utils/Transition";
import { MEMBER_CHECK } from "utils/Graphql/queries";
import {
  CREATE_MEMBER_MUTATION,
  INVITE_MEMBER_MUTATION,
} from "utils/Graphql/mutations";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import expYearList from "./helpers/expirationYearList";

import { removeComma } from "utils/RemoveComma";
import { formatMoney } from "utils/FormatMoney";
import { formatNumber_ } from "utils/FormatNumber_";
import { checkMoney } from "utils/CheckMoney";
import { checkNumber } from 'utils/CheckNumber';

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import FormHelperText from "@material-ui/core/FormHelperText";

import paymongo from "./services/paymongo";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";
import { withFirebase } from "utils/Firebase";

import IconButton from "@material-ui/core/IconButton";
import { ValueService } from "ag-grid-community";

const initialValues = {
  email: "",
};


const Checkout = (props) => {
  const { classes, client } = props;

  const [errors, setErrors] = useState(null)
  const [amount, setAmount] = useState("")
  const [payButtonLabel, setPayButtonLabel] = useState("PAY")

  const [paymentTransitionDelay, setPaymentTransitiondDelay] = useState(false);
  const [orderCompleteData, setOrderCompleteData] = useState(null);

  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  useEffect(() => {
    if (isOpenWaiting) {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "block";
        document.getElementById("mainScreen").style.opacity = 0.3;
      }
    } else {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);
  const size = useWindowSize()

  const handlePayment = (event, values, setSubmitting) => {
    debugger
    event.preventDefault();

    let amount = parseInt(removeComma(values.amount))
    let number = values.number.split("  ")[0] +
      values.number.split("  ")[1] +
      values.number.split("  ")[2] +
      values.number.split("  ")[3]


    const {
      expDate,
      cvc,
      address1,
      address2,
      city,
      province,
      zipCode,
      fullName,
      email,
      phone
    } = values;
    debugger

    const paymongoTokenData = {
      data: {
        attributes: {
          number,
          exp_month: expDate.split(" / ")[0],
          exp_year: expDate.split(" / ")[1],
          cvc,
          billing: {
            fullName,
            email,
            phone,
            address: {
              line1: address1 + " " + address2,
              city,
              state: province,
              country: "Philippines",
              postal_code: zipCode
            }
          }
        }
      }
    };

    const amount_ = parseInt(
      amount
        .toFixed(2)
        .toString()
        .replace(".", "")
    );

    const paymongoPaymentData = (tokenId, tokenType) => {
      return {
        data: {
          attributes: {
            amount_,
            currency: "PHP",
            source: {
              id: tokenId,
              type: tokenType
            }
          }
        }
      };
    };

    setPaymentTransitiondDelay(true);
    setErrors(null);

    paymongo
      .createToken(paymongoTokenData)
      .then(result => {
        const tokenId = result.data.id;
        const tokenType = result.data.type;

        paymongo
          .createPayment(paymongoPaymentData(tokenId, tokenType))
          .then(data => {
            setOrderCompleteData(data);
            setSubmitting(false)
          })
          .catch(error => {
            const code = error.code;
            const message = error.message;
            console.log(code, message);
            setSubmitting(false)
          });
      })
      .catch(error => {
        const code = error.code;
        const message = error.message;
        console.log(code, message);
        setErrors(message);
        setSubmitting(false)
      });
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        // TransitionComponent={Transition}
        open={true}
        // onClose={handleCloseDialog}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Pay
                  </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="notarynet.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>

          <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
            <Waiting Transition={Transition} isOpenWaiting={isOpenWaiting} />
          </div>
          <div id="mainScreen" style={{ marginTop: "-70px" }}>

            <div
              className={classes.container}
              style={{

                width: `${size.width <= 414 ? size.width - 30 : 370}px`,
              }}
            >
              <Formik
                initialValues={{
                  amount: "1,000.00",
                  nnn1: "_ _ _ _",
                  nnn2: "_ _ _ _ ",
                  nnn3: "_ _ _ _",
                  nnn4: "_ _ _ _",
                  number: "4343  4343  4343  4345", prevNumber: "",
                  expDate: "12 / 23", prevExpDate: "",
                  cv: "333",
                  fullName: "DENNY ROXAS",
                  email: "ff@ggg.com",
                  address1: "345 DINE",
                  address2: "DOON, PAROON",
                  phone: "09441231234",
                  city: "TAGUIG",
                  province: "METRO MANILA",
                  zipCode: "1632"
                }}
                // initialErrors={initialValues}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email format")
                    .required("Required"),
                })}
                onSubmit={(e, values, { setSubmitting, resetForm }) => {
                  debugger
                  setSubmitting(true);
                  setIsOpenWaiting(true)

                }}
              >
                {({
                  values,
                  isSubmitting,
                  setSubmitting,
                  isValid,
                  handleChange,
                  handleSubmit,
                  handleReset,
                  setFieldValue
                }) => (
                  <Form>
                    <div
                      className={classes.container}
                      style={{

                        width: `${size.width <= 414 ? size.width - 40 : 360}px`,
                        overflow: "auto",
                        height: `${size.height - 145
                          }px`,
                      }}
                    >

                      {errors && (
                        <Alert severity="error" className={classes.error}>
                          {errors}
                        </Alert>
                      )}

                      <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        className={classes.formTitle}
                        style={{ marginTop: 20 }}
                      >
                        <CreditCardIcon className={classes.formTitleIcon} style={{ color: primaryColor[0] }} />
                        <Typography style={{ marginLeft: 10, fontSize: 18, color: grayColor[1] }}>
                          Amount to be paid
                           </Typography>
                      </Grid>
                      <Grid container spacing={1}>

                        <Grid item xs={12}>
                          <TextField
                            style={{ marginTop: 10, marginBottom: 5 }}
                            id="amount"
                            name="amount"
                            fullWidth
                            label=""
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,

                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            placeholder={"0.00"}
                            onChange={(e) => {
                              debugger
                              if (checkMoney(e.target.value)) {
                                values.amount = e.target.value;
                                setFieldValue(
                                  "amount",
                                  e.target.value
                                );

                                setAmount(values.amount)
                                setPayButtonLabel("Pay  " + formatMoney(
                                  formatNumber_(values.amount)
                                ))

                              }
                            }}

                            required={(formatNumber_(values.amount) === "0.00" || formatNumber_(values.amount) === 0)}

                            onFocus={(e) => {
                              values.amount =
                                values.amount.length > 0
                                  ? removeComma(values.amount)
                                  : "";
                              setFieldValue("amount", values.amount);

                            }}
                            onBlur={(e) => {
                              values.amount =
                                values.amount.length > 0
                                  ? formatMoney(
                                    formatNumber_(values.amount)
                                  )
                                  : "";
                              setFieldValue("amount", values.amount);
                              setPayButtonLabel("Pay  " + values.amount)
                            }}
                            value={values.amount}

                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        className={classes.formTitle}
                      >
                        <CreditCardIcon className={classes.formTitleIcon} style={{ color: primaryColor[0] }} />
                        <Typography style={{ marginLeft: 10, fontSize: 18, color: grayColor[1] }}>
                          Card
                          </Typography>
                      </Grid>
                      <Grid container spacing={1}>

                        <Grid item xs={12}>
                          <TextField
                            style={{ marginTop: 20, marginBottom: 5 }}
                            id="number"
                            name="number"
                            fullWidth
                            label="Number"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,

                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            placeholder={values.nnn1 + "   " + values.nnn2 + "   " + values.nnn3 + "   " + values.nnn4}
                            onChange={e => {
                              let nnnn = e.target.value
                              let len = e.target.value.length

                              if (len === 0) {
                                values.number = ""
                                values.prevNumber = ""
                                setFieldValue("number", "")
                              }
                              else if (len === 1 && checkNumber(nnnn)) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 2 && checkNumber(nnnn)) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 3 && checkNumber(nnnn)) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 4 && checkNumber(nnnn)) {
                                values.number = nnnn + "  "
                                values.prevNumber = nnnn + "  "
                                setFieldValue("number", nnnn + "  ")
                              }


                              else if (len === 5) {
                                values.number = nnnn.substring(0, 4) + "  " + nnnn[4]
                                values.prevNumber = nnnn.substring(0, 4) + "  " + nnnn[4]
                                setFieldValue("number", nnnn.substring(0, 4) + "  " + nnnn[4])
                              }
                              else if (len === 6) {
                                values.number = nnnn.substring(0, 4)
                                values.prevNumber = nnnn.substring(0, 4)
                                setFieldValue("number", nnnn.substring(0, 4))
                              }


                              else if (len === 7 && checkNumber(nnnn.substring(6, 7))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 8 && checkNumber(nnnn.substring(6, 8))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 9 && checkNumber(nnnn.substring(6, 9))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 10 && checkNumber(nnnn.substring(6, 10))) {
                                values.number = nnnn + "  "
                                values.prevNumber = nnnn + "  "
                                setFieldValue("number", nnnn + "  ")
                              }


                              else if (len === 11) {
                                values.number = nnnn.substring(0, 10) + "  " + nnnn[10]
                                values.prevNumber = nnnn.substring(0, 10) + "  " + nnnn[10]
                                setFieldValue("number", nnnn.substring(0, 10) + "  " + nnnn[10])
                              }
                              else if (len === 12) {
                                values.number = nnnn.substring(0, 10)
                                values.prevNumber = nnnn.substring(0, 10)
                                setFieldValue("number", nnnn.substring(0, 10))
                              }


                              else if (len === 13 && checkNumber(nnnn.substring(12, 13))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 14 && checkNumber(nnnn.substring(12, 14))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 15 && checkNumber(nnnn.substring(12, 15))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 16 && checkNumber(nnnn.substring(12, 16))) {
                                values.number = nnnn + "  "
                                values.prevNumber = nnnn + "  "
                                setFieldValue("number", nnnn + "  ")
                              }


                              else if (len === 17) {
                                values.number = nnnn.substring(0, 16) + "  " + nnnn[16]
                                values.prevNumber = nnnn.substring(0, 16) + "  " + nnnn[16]
                                setFieldValue("number", nnnn.substring(0, 16) + "  " + nnnn[16])
                              }
                              else if (len === 18) {
                                values.number = nnnn.substring(0, 16)
                                values.prevNumber = nnnn.substring(0, 16)
                                setFieldValue("number", nnnn.substring(0, 16))
                              }


                              else if (len === 19 && checkNumber(nnnn.substring(18, 19))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 20 && checkNumber(nnnn.substring(18, 20))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 21 && checkNumber(nnnn.substring(18, 21))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }
                              else if (len === 22 && checkNumber(nnnn.substring(18, 22))) {
                                values.number = nnnn
                                values.prevNumber = nnnn
                                setFieldValue("number", nnnn)
                              }



                            }}
                            value={values.number}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          {/* <FormControl className={classes.formControl} fullWidth> */}
                          <TextField
                            style={{ marginBottom: 5 }}
                            label="Exp Date"
                            variant="outlined"
                            onChange={(e) => {
                              console.log('********************')
                              console.log('onChange', e.target.value)
                              console.log('len', e.target.value.length)
                              let mmyy = e.target.value
                              let len = e.target.value.length
                              if (len === 0) {
                                values.prevExpDate = mmyy
                                setFieldValue("expDate", mmyy)
                              }
                              else if (len === 1) {
                                if ("01".includes(mmyy[0])) {
                                  values.prevExpDate = mmyy
                                  values.expDate = mmyy
                                  setFieldValue("expDate", mmyy)
                                } else {
                                  values.expDate = values.prevExpDate
                                  setFieldValue("expDate", values.prevExpDate)
                                }
                              }
                              else if (len === 2) {
                                if ("0".includes(mmyy[0]) && "123456789".includes(mmyy[1]) || "1".includes(mmyy[0]) && "012".includes(mmyy[1])) {
                                  values.prevExpDate = mmyy + " / "
                                  values.expDate = mmyy + " / "
                                  setFieldValue("expDate", mmyy + " / ")
                                } else {
                                  values.expDate = values.prevExpDate
                                  setFieldValue("expDate", values.prevExpDate)
                                }
                              }
                              else if (len === 4) {
                                let c0 = "0123456789".includes(mmyy[0])
                                let c1 = "0123456789".includes(mmyy[1])
                                let c3 = "/".includes(mmyy[3])
                                if (c0 && c1 && c3) {
                                  values.expDate = mmyy[0]
                                  setFieldValue("expDate", mmyy[0])
                                  values.prevExpDate = mmyy[0]
                                }
                                else if (c0 && c1 && !c3) {
                                  values.expDate = mmyy[0] + mmyy[1] + " / "
                                  setFieldValue("expDate", mmyy[0] + mmyy[1] + " / ")
                                  values.prevExpDate = mmyy[0] + mmyy[1] + " / "
                                }
                                else if (c0 && !c1) {
                                  values.expDate = mmyy[0]
                                  setFieldValue("expDate", mmyy[0])
                                  values.prevExpDate = mmyy[0]

                                } else {
                                  values.expDate = values.prevExpDate
                                  setFieldValue("expDate", values.prevExpDate)
                                }
                              }
                              else if (len === 5) {
                                if (values.prevExpDate.length === 6) {
                                  values.expDate = mmyy[0] + mmyy[1] + " / "
                                  setFieldValue("expDate", mmyy[0] + mmyy[1] + " / ")
                                  values.prevExpDate = mmyy[0] + mmyy[1] + " / "
                                }
                                else {
                                  values.expDate = values.prevExpDate
                                  setFieldValue("expDate", values.prevExpDate)
                                }
                              }
                              else if (6 <= len && len <= 7) {
                                console.log("[ / ]", "[" + mmyy.substring(2, 5) + "]")
                                let c0 = "01".includes(mmyy[0])
                                let c1 = ("0".includes(mmyy[0]) && "123456789".includes(mmyy[1]) || "1".includes(mmyy[0]) && "012".includes(mmyy[1]))
                                let c345 = " / " === mmyy.substring(2, 5)
                                if (c0 && c1 && c345 && "0123456789".includes(mmyy[len - 1])) {
                                  values.prevExpDate = mmyy
                                  values.expDate = mmyy
                                  setFieldValue("expDate", mmyy)
                                } else {
                                  values.expDate = values.prevExpDate
                                  setFieldValue("expDate", values.prevExpDate)
                                }
                              }
                              else {
                                //do nothing
                              }

                              console.log("expDate", values.expDate)
                              console.log("prevExpDate", values.prevExpDate)
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            placeholder={"MM / YY"}

                            id="exp_date"
                            name="exp_date"
                            value={values.expDate}
                            fullWidth
                          >

                          </TextField>

                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            style={{ marginBottom: 10 }}
                            id="cv"
                            name="cv"
                            value={values.cv}
                            fullWidth
                            label="C V C"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            placeholder={"_ _ _"}
                            onChange={(e) => {
                              if (checkNumber(e.target.value) && e.target.value.length <= 3) {
                                values.cv = e.target.value
                                setFieldValue("cv", e.target.value);

                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        className={classes.formTitle}
                      >
                        <AccountBoxIcon className={classes.formTitleIcon} style={{ color: primaryColor[0] }} />
                        <Typography style={{ marginLeft: 10, fontSize: 18, color: grayColor[1] }}>
                          Billing Details
                           </Typography>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            style={{ marginTop: 20, marginBottom: 5 }}
                            id="fullName"
                            name="fullName"
                            value={values.fullName}
                            onChange={(e) => {
                              values.name = e.target.value.toUpperCase()
                              setFieldValue("fullName", e.target.value.toUpperCase())
                            }}
                            fullWidth
                            label="Full Name"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="email"
                            name="email"
                            type="email"
                            value={values.email}
                            fullWidth
                            label="Email"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            onChange={(e) => {
                              values.email = e.target.value
                              setFieldValue("email", e.target.value)
                            }}
                            style={{ marginBottom: 5 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="phone"
                            value={values.phone}
                            name="phone"
                            fullWidth
                            label="Phone No."
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            onChange={(e) => {
                              if (checkNumber(e.target.value) && e.target.value.length <= 12) {
                                values.phone = e.target.value
                                setFieldValue("phone", e.target.value);
                              }
                            }}
                            style={{ marginBottom: 5 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="address1"
                            name="line1"
                            value={values.address1}
                            fullWidth
                            label="Address 1"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            onChange={(e) => {
                              values.address1 = e.target.value.toUpperCase()
                              setFieldValue("address1", e.target.value.toUpperCase())
                            }}
                            style={{ marginBottom: 5 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="address2"
                            name="line2"
                            value={values.address2}
                            fullWidth
                            label="Address 2"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            onChange={(e) => {
                              values.address2 = e.target.value.toUpperCase()
                              setFieldValue("address2", e.target.value.toUpperCase())
                            }}
                            style={{ marginBottom: 5 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="city"
                            name="city"
                            value={values.city}
                            fullWidth
                            label="City"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            onChange={(e) => {
                              values.city = e.target.value.toUpperCase()
                              setFieldValue("city", e.target.value.toUpperCase())
                            }}
                            style={{ marginBottom: 5 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="province"
                            name="province"
                            value={values.province}
                            fullWidth
                            label="Province"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            onChange={(e) => {
                              values.province = e.target.value.toUpperCase()
                              setFieldValue("province", e.target.value.toUpperCase())
                            }}
                            style={{ marginBottom: 5 }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="zip_code"
                            name="zip_code"
                            value={values.zipCode}
                            fullWidth
                            label="Zip Code"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: 40,
                                padding: '2px 14px 2px 14px',
                              },
                            }}
                            onChange={(e) => {
                              if (checkNumber(e.target.value) && e.target.value.length <= 4) {
                                values.zipCode = e.target.value
                                setFieldValue("zipCode", e.target.value)
                              }
                            }}
                            style={{ marginBottom: 20 }}
                            placeholder={"_ _ _ _"}
                          />
                        </Grid>
                      </Grid>

                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{ width: "60%" }}
                          variant="contained"
                          color="primary"
                          disabled={!isValid || isSubmitting}
                          onClick={(e) => {
                            setSubmitting(true)
                            handlePayment(e, values, setSubmitting)
                          }}
                        >
                          {payButtonLabel}
                        </Button>

                        <Button
                          style={{ width: "40%" }}
                          color="primary"
                          variant="outlined"
                          onClick={e => {
                            e.preventDefault()
                            props.history.push("/app/dashboard")
                          }}
                        >
                          Cancel
                              </Button>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </Dialog >




    </>
  );
};


export default withStyles(javascriptStyles)(
  withStyles(basicsStyle)(
    withStyles(modalStyle)(withFirebase(withAuthentication(Checkout)))
  )
);
