import React from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import { ThemeProvider, FormControl } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"


const Search = (props) => {
  const { handleSearchFilter } = props;
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          onChange={handleSearchFilter}
          startAdornment={<InputAdornment><SearchIcon /></InputAdornment>}
          labelWidth={60}
          fullWidth
        />
      </FormControl>
    </ThemeProvider>

  )
}

export default Search;