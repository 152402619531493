import React, { useState, useEffect } from "react";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import useWindowSize from 'utils/useWindowSize';
import GridContainer from "components/Grid/GridContainer";

// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem.js";
import {
  Dialog, DialogTitle, withStyles,
  // DialogContent,
  ThemeProvider,
  // Typography, 
  Tooltip, Button, DialogActions
} from '@material-ui/core';
import {
  primaryColor,
} from 'assets/jss/material-kit-pro-react.js';
// import Box from '@material-ui/core/Box';
import Profile from './Profile';
// import { useMediaQuery } from 'react-responsive';
import circlelogo from 'assets/img/byn-logo-circle.png';
import aboutPageMaterialTheme from "utils/Themes/aboutPageMaterialTheme"
import Password from './Password'
import Data from './Data'
import Billing from './Billing'
import { MEMBER_CHECKV2 } from 'utils/Graphql/queries';
import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";
import { withFirebase } from "utils/Firebase";
/*
  https://github.com/mui-org/material-ui/issues/11517#issuecomment-407509327
 */
function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === "function") {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
    width: "100%",
    textAlign: "left",
  },
});

const combinedStyles = combineStyles(
  styles,
  modalStyle,
  javascriptStyles,
  basicsStyle
);

const Account = (props) => {
  const { classes, client, authUser } = props;
  console.log(classes);

  const size = useWindowSize()
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px");
  // const [closeRightMargin, setCloseRightMargin] = useState("0px");

  useEffect(() => {
    handleFetchMemberData();


  }, []);

  const [openAbout, setOpenAbout] = useState(true);
  const [openProfile, setOpenProfile] = useState(false);
  const [openData, setOpenData] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  // const [value, setValue] = useState(0);
  const [page, setPage] = useState("account");
  const [title, setTitle] = useState("Account");

  const [memberData, setMemberData] = useState({});

  const fetchMemberData = async (auth = null) => {
    try {
      let data = await client.query({
        query: MEMBER_CHECKV2,
        variables: {
          email: !auth ? authUser.email : auth.email,
        },
        fetchPolicy: "no-cache",
      });
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleFetchMemberData = (auth = null) => {
    fetchMemberData(auth).then((response) => {
      console.log(response);
      if (response) {
        if (response.data) {
          let mem = response.data.memberCheck;
          if (mem) {
            setMemberData(mem);
          }
        }
      }
    });
  };

  const handleCloseAbout = () => {
    setOpenAbout(false);
  };

  const handleChangePage = (page) => {
    setPage(page);
    if (page === "account") {
      setTitle("Account");
    } else if (page === "profile") {
      setTitle("Profile");
    } else if (page === "password") {
      setTitle("Password");
    } else if (page === "billing") {
      setTitle("Billing");
    } else if (page === "data") {
      setTitle("Data");
    }
  };

  const handleChangePageContent = () => {
    if (page === "account") {
      return (
        <div
          className={classes.container}
          style={{
            width: `${size.width <= 414 ? size.width - 60 : 340}px`,
            overflow: "auto",
            height: `${size.height - 195}px`,
          }}
        >
          <ThemeProvider theme={aboutPageMaterialTheme}>
            <Button
              variant="text"
              style={{ width: "100%" }}
              onClick={() => setOpenProfile(true)}
            >
              {"PROFILE >"}
            </Button>
            <Button
              variant="text"
              style={{ width: "100%" }}
              onClick={() => setOpenPassword(true)}
            >
              {"PASSWORD >"}
            </Button>
            {/* <Button
              variant="text"
              style={{ width: "100%" }}
              onClick={() => setOpenBilling(true)}
            >
              {"BILLING >"}
            </Button> */}
            <Button
              variant="text"
              style={{ width: "100%" }}
              onClick={() => setOpenData(true)}
            >
              {"DATA >"}
            </Button>
          </ThemeProvider>
        </div>
      );
    }
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  return (
    <div
      style={{
        margin: `-100px 0 0 0`
      }}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <div
          className={classes.container}
          style={{
            width: `${size.width <= 414 ? size.width - 30 : 370}px`,
          }}
        >
          <ThemeProvider theme={defaultMaterialTheme}>
            <div
              style={{
                margin: `10px 0 0 0`,
                color: primaryColor[0],
                fontSize: "30px",
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              {title}
            </div>

            <ThemeProvider theme={defaultBtnMaterialTheme}>
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-42px ${size.width <= 414 ? -10 : -12}px 0 0`,
                  }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </ThemeProvider>
        </div>
      </DialogTitle>
      <div className={classes.container}>
        <ThemeProvider theme={defaultBtnMaterialTheme}>
          <GridContainer direction='column' justify='center' alignItems='center'>
            <div
              id="mainAbout"
              style={{
                // justifyContent: 'center',
                margin: "50px 0px 0px 0px",
              }}
            >
              {handleChangePageContent()}
              {page === "account" ? (
                <DialogActions className={classes.modalFooter}>
                  <ThemeProvider theme={defaultBtnMaterialTheme}>
                    <Button
                      style={{ width: '100%' }}
                      color="primary"
                      variant="contained"
                      onClick={e => {
                        e.preventDefault()
                        props.history.push("/app/dashboard")
                      }}
                    >
                      Back
                    </Button>
                  </ThemeProvider>
                </DialogActions>
              ) : (
                <DialogActions>
                  <ThemeProvider theme={defaultBtnMaterialTheme}>
                    <Button
                      style={{ width: `${size.width <= 414 ? size.width - 60 : 320}px` }}
                      color="primary"
                      variant="contained"
                      onClick={() => handleChangePage("account")}
                    >
                      Back
                    </Button>
                  </ThemeProvider>
                </DialogActions>
              )}
            </div>
          </GridContainer>
        </ThemeProvider>
      </div>

      <Profile
        {...props}
        open={openProfile}
        onClose={() => setOpenProfile(false)}
        memberData={memberData}
        reFetch={handleFetchMemberData}
      />
      <Billing
        {...props}
        open={openBilling}
        onClose={() => setOpenBilling(false)}
        memberData={memberData}
        reFetch={handleFetchMemberData}
      />
      <Data
        {...props}
        open={openData}
        onClose={() => setOpenData(false)}
        memberData={memberData}
        reFetch={handleFetchMemberData}
      />
      <Password
        {...props}
        open={openPassword}
        onClose={() => setOpenPassword(false)}
        memberData={memberData}
        reFetch={handleFetchMemberData}
      />
    </div >
  );
};

export default withStyles(combinedStyles)(
  withFirebase(withAuthentication(Account))
);
