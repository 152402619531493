import React, { Suspense } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { createBrowserHistory } from "history";

import App from "../../App";
import Spinner from "components/Spinner/Spinner";
import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

import SignUpPage from "../SignUp";
import SignUpWeb from "../SignUpWeb";
import UserName from "screens/Account/UserName";
import Email from "screens/Account/Email";
import PhoneNumber from "screens/Account/PhoneNumber";
import Password from "screens/Account/Password";
import Data from "screens/Account/Data";
import Download from "screens/Account/Download";
import Delete from "screens/Account/Delete";
import Billing from "screens/Account/Billing";
import LogService from "screens/LogMyWork/LogService";
import LogExpense from "screens/LogMyWork/LogExpense";
import Logs from "screens/Logs";
import Contact from "screens/Contact";

import AppDrawerLayout from "../../layouts/AppDrawer.js";
import SignInDrawerLayout from "../../layouts/SignInDrawer.js";

import RegSuccessDialog from "../SignUpWeb/success";


import { withAuthentication } from "utils/Session";

var hist = createBrowserHistory();

const Main = (props) => {
  const { client } = props
  // console.log('JSON.parse(localStorage.getItem("authUser"))', JSON.parse(localStorage.getItem("authUser")))
  return (

    <Router history={hist} component={App}>
      {JSON.parse(localStorage.getItem("authUser")) === null ? (

        <Switch>
          {/* <Route
            exact
            path="/auth/signupweb"
            render={(props) => <SignUpWeb client={client} {...props} />}
          /> */}
          <Route
            exact
            path="/auth/signup"
            render={(props) => <SignUpPage client={client} {...props} />}
          />
          <Route
            exact
            path="/auth/contact"
            render={(props) => <Contact client={client} {...props} />}
          />
          <Route
            exact
            path="/auth/regsuccess"
            render={(props) => <RegSuccessDialog client={client} {...props} />}
          />
          <Route
            path="/auth"
            render={(props) => (
              <SignInDrawerLayout client={client} {...props} />
            )}
          />

          <Redirect from="/" to="/auth/signin" />
          <Redirect from="/landing" to="/public/byn-landing/index.html" />
        </Switch>
      ) : (

        <Switch>

          <Route
            // exact
            path="/app/username"
            render={(props) => <UserName client={client} {...props} />}
          />
          <Route
            // exact
            path="/app/email"
            render={(props) => <Email client={client} {...props} />}
          />
          <Route
            // exact
            path="/app/phonenumber"
            render={(props) => <PhoneNumber client={client} {...props} />}
          />

          <Route
            exact
            path="/app/password"
            render={(props) => <Password client={client} {...props} />}
          />
          <Route
            exact
            path="/app/data"
            render={(props) => <Data client={client} {...props} />}
          />
          <Route
            exact
            path="/app/download"
            render={(props) => <Download client={client} {...props} />}
          />
          <Route
            exact
            path="/app/delete"
            render={(props) => <Delete client={client} {...props} />}
          />
          <Route
            exact
            path="/app/billing"
            render={(props) => <Billing client={client} {...props} />}
          />
          <Route
            exact
            path="/app/logservices"
            render={(props) => <LogService client={client} {...props} />}
          />
          <Route
            exact
            path="/app/logexpenses"
            render={(props) => <LogExpense client={client} {...props} />}
          />
          <Route
            exact
            path="/app/logs"
            render={(props) => <Logs client={client} {...props} />}
          />

          <Route
            path="/app"
            render={(props) => (
              <AppDrawerLayout client={client} {...props} />
            )}
          />
          <Redirect from="/" to="/app/dashboard" />
        </Switch>

      )}
    </Router>

  );
};

export default withAuthentication(Main);
