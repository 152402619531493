import React, { useState } from "react";
// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";
import Paper from "@material-ui/core/Paper";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { CLIENT_FILTER } from "utils/Graphql/queries";
import { debounce, ThemeProvider, Button, Tooltip } from "@material-ui/core";
import Search from "./Search";
// import ConfirmDialog from "./ConfirmDialog";
import EditClient from "./EditClient";
import useWindowSize from 'utils/useWindowSize';

import {
  UPDATE_CLIENT_MUTATION,
  DELETE_CLIENT_MUTATION,
  CREATE_CLIENT_MUTATION,
} from "utils/Graphql/mutations";
import { CLIENTS_BY_MEMBER_QUERY } from "utils/Graphql/queries";
// import FailDialog from "./failDialog";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
// import errorMaterialTheme from "utils/Themes/errorMaterialTheme";
import DialogActions from "@material-ui/core/DialogActions";
import circlelogo from "assets/img/byn-logo-circle.png";
// import { useMediaQuery } from "react-responsive";
import { primaryColor, grayColor } from "assets/jss/material-kit-pro-react.js";
import { Formik, Form } from "formik";
// import FormHelperText from "@material-ui/core/FormHelperText";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import Transition from "utils/Transition";
import TextField from "@material-ui/core/TextField";
import { createUserLog } from "utils/CreateUserLog";




var _ClientsPortfolio = {
  lastName: "",
  firstName: "",
  company: "",
  status: "",
  line: [],
  cl: {},
};

const style = {
  grid: {
    width: "80%",
  },
  step1: {
    display: "grid",
    gridTemplateColumns: "1fr ",
    gridTemplateRows: "auto auto",
  },
  step1Client: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    marginTop: "20px",
    marginBottom: "40px",
  },
  // step1ClientCompany
  step1ClientCompany: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    marginTop: "40px",
    marginBottom: "20px",
  },
};

const noData = "You have no clients. Add a client!";
const ClientsPortfolio = (props) => {
  const {
    member,
    memberClients,
    classes,
    client,
    authUser,
    reFetchClientsByEmail,
    reFetchCases,
    setPage,
    setClientData,
    setClientCases,
    actionButtons,
    currentClient,
    setCurrentClient,
    hasDeleteRequest,
  } = props;

  const size = useWindowSize()

  const [openClient, setOpenClient] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openEditNotification, setOpenEditNotification] = useState(false);
  const [openDeleteNotification, setOpenDeleteNotification] = useState(false);
  // const [openStatusNotification, setOpenStatusNotification] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const [openEditSuccess, setOpenEditSuccess] = useState(false);
  const [openNoChange, setOpenNoChange] = useState(false);
  const [line1, setLine1] = useState("");
  // const [line2, setLine2] = useState("");
  const [line3, setLine3] = useState("");

  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px");
  // const [closeRightMargin, setCloseRightMargin] = useState("0px");

  const [clients, setClients] = useState(memberClients);
  const [openEditClient, setOpenEditClient] = useState(false);
  // const [openDeleteClient, setOpenDeleteClient] = useState(false);
  const [openAddClient, setOpenAddClient] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("Are you sure?");
  const [dialogTitle, setDialogTitle] = useState("Confirmation");
  const [openFail, setOpenFail] = useState(false);
  const [failMessage, setFailMessage] = useState("");
  const [failTitle, setFailTitle] = useState("");
  const [action, setAction] = useState("");
  const [values, setValues] = useState({});
  const [editValues, setEditValues] = useState({});
  // const [filter, setFilter] = useState("");
  // const [loading, setLoading] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState(noData);

  const getActiveCases = (client) => {
    // return client.cases.length;
    const activeCases = client.cases.filter((cl, index) => {
      if (cl.status === "ACTIVE") return cl;
    });
    return activeCases;
  };

  const getTotalCases = (client) => {
    const totalCases = client.cases.filter((cl, index) => {
      if (cl.status !== "UPDATED") {
        return cl;
      }
    });
    return totalCases;
  };

  const searchClients = async (filter) => {
    const { id } = member;
    console.log(id);
    try {
      const response = await client.query({
        query: CLIENT_FILTER,
        variables: {
          memberId: id,
          filter,
        },
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = debounce((filter) => {
    console.log(filter);
    searchClients(filter)
      .then((response) => {
        createUserLog({ authUser, client }, "CLIENT", "Search", filter)
        console.log(response);
        if (response.data) {
          const filteredClient = response.data.clientFilter;
          if (memberClients.length > 0) {
            if (filteredClient.length === 0)
              setNoDataMessage("No search results");
          } else {
            setNoDataMessage(noData);
          }
          setClients(filteredClient);
        } else {
          console.log("haha backend or network error");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  }, 250);

  const handleCloseNotification = () => {
    debugger;
    setOpenNotification(false);
  };

  const handleSearchFilter = (e) => {
    // console.log(e.target.value)
    onSearch(e.target.value);
  };

  const handleOpenFail = () => {
    setFailMessage("This client has enrolled case(s).");
    setFailTitle("Delete Client");
    setOpenFail(true);
  };

  const handleCloseFail = () => {
    setOpenFail(false);
  };

  const handleOpenPreDeleteClientConfirm = (client) => {
    debugger;
    setDialogTitle("Delete Client");
    setAction("predelete");
    setCurrentClient(client);
    setDialogMessage("Are you sure you want to delete?");
    setOpenDeleteNotification(true);
  };

  const handleOpenDeleteClientConfirm = (client) => {
    debugger;
    // setAction("delete");
    // setDialogTitle("Confirmation...");
    // setCurrentClient(client);
    setOpenDeleteNotification(true);
  };
  const handleCloseConfirmEdit = () => {
    setOpenConfirm(false);
  };
  const handleCloseEditClient = () => {
    setOpenEditClient(false);
  };

  const handleSubmit = () => {
    if (action === "edit") {
      handleSubmitEditClient();
    } else if (action === "predelete") {
      // handleOpenDeleteNotification(_ClientsPortfolio.cl);
      setOpenDeleteNotification(true);
    } else if (action === "delete") {
      handleDeleteClient();
    } else if (action === "add") {
      handleAddSubmitClient();
    }
  };

  // const handleOpenEditClientConfirm = (values) => {
  //   handleSubmitEditClient(values);
  // };

  const handleSubmitEditClient = async (values) => {
    setOpenEditClient(false);
    setEditValues(values);
    updateClient(values)
      .then((response) => {
        createUserLog({ authUser, client }, "CLIENT", "Edited client data", "")
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        setOpenNoChange(true);
      })
      .finally(() => {
        // reFetchClientsByEmail();
      });
  };

  const handleDeleteClient = async () => {
    console.log("delete test");
    setOpenConfirm(false);
    deleteClient()
      .then((response) => {
        createUserLog({ authUser, client }, "CLIENT", "Deleted a client", "")
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        reFetchClientsByEmail();
      });
  };

  const handleAddSubmitClient = async (setFieldValue) => {
    console.log(values);
    setOpenConfirm(false);
    setOpenAddClient(false);
    callCreateClient(values, setFieldValue)
      .then((response) => {
        createUserLog({ authUser, client }, "CLIENT", "Added a client", "")
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        reFetchClientsByEmail();
      });
  };

  const handleOpenAddClient = async () => {
    setOpenClient(true);
  };

  const handleOpenCases = (client) => {
    setCurrentClient(client);
    reFetchCases(client);
  };

  const callCreateClient = async (values, setFieldValue) => {
    return new Promise((resolve, reject) => {
      const { lastName, firstName, company } = values;
      const { id } = member;

      debugger;
      client
        .query({
          query: CLIENTS_BY_MEMBER_QUERY,
          variables: {
            memberId: id,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { clientsByMember } = response.data;
          console.log("clientsByMember");
          console.log(clientsByMember);

          debugger;
          let client_ = [];
          clientsByMember
            .filter(
              (s) =>
                (s.status === "ACTIVE" || s.status === "INACTIVE") &&
                s.lastName === lastName &&
                s.firstName === firstName &&
                s.company === company
            )
            .map((s) => {
              client_.push({
                lastName: s.lastName,
                firstName: s.firsName,
                company: s.company,
              });
            });

          if (client_.length === 1) {
            let s = "";
            if (lastName && firstName && company) {
              s = s + lastName + ", " + firstName + " (" + company + ")";
            } else if (lastName && firstName && !company) {
              s = s + lastName + ", " + firstName
            } else if (!lastName && firstName && company) {
              s = s + firstName + "(" + company + ")"
            } else if (!lastName && firstName && !company) {
              s =
                s + firstName;
            } else if (lastName && !firstName && company) {
              s = s + lastName + " (" + company + ")"
            } else if (lastName && !firstName && !company) {
              s =
                s + lastName
            } else if (!lastName && !firstName && company) {
              s =
                s +
                "(" +
                company +
                ")";
            } else if (!lastName && !firstName && !company) {
              s = s + "";
            }
            setLine1(s);
            reject(new Error(s));
          } else {
            let clients = [];
            clientsByMember
              .filter(
                (s) =>
                  (s.status === "ACTIVE" || s.status === "INACTIVE") &&
                  ((s.lastName &&
                    lastName &&
                    s.lastName.length > 1 &&
                    lastName.length > 1 &&
                    (s.lastName === lastName)) ||
                    (s.company &&
                      company &&
                      s.company.length > 1 &&
                      company.length > 1 &&
                      (s.company === company))
                  )
              )
              .map((s) => {
                clients.push({
                  lastName: s.lastName,
                  firstName: s.firstName,
                  company: s.company,
                });
              });

            if (clients.length > 0) {
              let s = "";
              let m = "";
              if (lastName && firstName) {
                s = firstName + " " + lastName;
              } else if (lastName && !firstName) {
                s = lastName;
              } else if (!lastName && firstName) {
                s = firstName;
              }
              if (company) {
                m = s + " (" + company + ")";
              } else {
                m = s
              }
              setLine3(m);
              let ts = "";
              _ClientsPortfolio.line = [];
              for (let i = 0; i < clients.length; i++) {
                let s1 = "";
                if (clients[i].lastName && clients[i].firstName) {
                  s1 = clients[i].firstName + " " + clients[i].lastName;
                } else if (clients[i].lastName && !clients[i].firstName) {
                  s1 = clients[i].lastName;
                } else if (!clients[i].lastName && clients[i].firstName) {
                  s1 = clients[i].firstName;
                }
                if (clients[i].company) {
                  ts = s1 + " (" + clients[i].company + ")";
                } else {
                  ts = s1
                }

                if (
                  _ClientsPortfolio.line.filter((ss) => ss === ts).length === 0
                )
                  _ClientsPortfolio.line.push(ts);
              }
              setLine1(ts);
              setOpenNotification(true);
            } else {
              client
                .mutate({
                  mutation: CREATE_CLIENT_MUTATION,
                  variables: {
                    id,
                    lastName: lastName.toUpperCase(),
                    firstName: firstName.toUpperCase(),
                    company: company.toUpperCase(),
                    hourlyRate: 0.0,
                    memberId: id,
                  },
                })
                .then((response) => {
                  setOpenClient(false);
                  setOpenSuccess(true);
                });
              debugger;
            }
          }
        });
    });
  };

  const createClient = async () => {
    const { id } = member;
    debugger;
    client
      .mutate({
        mutation: CREATE_CLIENT_MUTATION,
        variables: {
          id,
          lastName: _ClientsPortfolio.lastName,
          firstName: _ClientsPortfolio.firstName,
          company: _ClientsPortfolio.company,
          hourlyRate: 0.0,
          memberId: id,
        },
      })
      .then((response) => {
        debugger;
        createUserLog(props, "CREATE NEW CLIENT", "Created a new Client", "")
        console.log(response);
        setOpenSuccess(true);
      });
  };

  const updateClient = async (values) => {
    return new Promise((resolve, reject) => {
      const { lastName, firstName, company, status } = values;
      const { member, id, parentId, ord } = currentClient;

      debugger;
      client
        .query({
          query: CLIENTS_BY_MEMBER_QUERY,
          variables: {
            memberId: member.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { clientsByMember } = response.data;
          console.log("clientsByMember");
          console.log(clientsByMember);

          debugger;
          let client_ = [];
          clientsByMember
            .filter(
              (s) =>
                s.id === currentClient.id &&
                s.lastName === lastName &&
                s.firstName === firstName &&
                s.company === company
            )
            .map((s) => {
              client_.push({
                id: s.id,
                lastName: s.lastName,
                firstName: s.firsName,
                company: s.company,
              });
            });

          if (client_.length === 1) {
            let s = "";
            if (lastName && firstName && company) {
              s = s + lastName + ", " + firstName + " (" + company + ")";
            } else if (lastName && firstName && !company) {
              s = s + lastName + ", " + firstName
            } else if (!lastName && firstName && company) {
              s = s + " (" + company + ")";
            } else if (!lastName && firstName && !company) {
              s =
                s + firstName
            } else if (lastName && !firstName && company) {
              s = s + lastName + " (" + company + ")"
            } else if (lastName && !firstName && !company) {
              s =
                s + lastName
            } else if (!lastName && !firstName && company) {
              s =
                s +

                "(" +
                company +
                ")";
            } else if (!lastName && !firstName && !company) {
              s = s + "";
            }
            setLine1(s);
            reject(new Error(s));
          } else {
            let clients = [];
            clientsByMember
              .filter(
                (s) =>
                  (s.status === "ACTIVE" || s.status === "INACTIVE") &&
                  ((s.lastName &&
                    lastName &&
                    s.lastName.length > 1 &&
                    lastName.length > 1 &&
                    (s.lastName === lastName)) ||
                    (s.company &&
                      company &&
                      s.company.length > 1 &&
                      company.length > 1 &&
                      (s.company === company))
                  )
              )
              .map((s) => {
                clients.push({
                  id: s.id,
                  lastName: s.lastName,
                  firstName: s.firstName,
                  company: s.company,
                });
              });

            if (clients.length > 0) {

              if (clients.length === 1 && clients[0].id === currentClient.id) {
                client
                  .mutate({
                    mutation: UPDATE_CLIENT_MUTATION,
                    variables: {
                      memberId: member.id,
                      clientId: id,
                      lastName: lastName.toUpperCase(),
                      firstName: firstName.toUpperCase(),
                      company: company.toUpperCase(),
                      parentId,
                      ord,
                      status: status.toUpperCase(),
                      actionDate: new Date().toISOString(),
                    },
                  })
                  .then((response) => {
                    createUserLog(props, "EDIT CLIENT", "Edited client info", "")
                    setOpenEditClient(false);
                    setOpenEditSuccess(true);
                  });
                debugger;
              } else {
                let s = "";
                let m = "";
                if (lastName && firstName) {
                  s = firstName + " " + lastName;
                } else if (lastName && !firstName) {
                  s = lastName;
                } else if (!lastName && firstName) {
                  s = firstName;
                }
                if (company) {
                  m = s + " (" + company + ")";
                } else {
                  m = s
                }
                setLine3(m);
                let ts = "";
                _ClientsPortfolio.line = [];
                for (let i = 0; i < clients.length; i++) {
                  let s1 = "";
                  if (clients[i].lastName && clients[i].firstName) {
                    s1 = clients[i].firstName + " " + clients[i].lastName;
                  } else if (clients[i].lastName && !clients[i].firstName) {
                    s1 = clients[i].lastName;
                  } else if (!clients[i].lastName && clients[i].firstName) {
                    s1 = clients[i].firstName;
                  }
                  if (clients[i].company) {
                    ts = s1 + " (" + clients[i].company + ")";
                  } else {
                    ts = s1
                  }

                  if (
                    _ClientsPortfolio.line.filter((ss) => ss === ts).length === 0
                  )
                    _ClientsPortfolio.line.push(ts);
                }
                setLine1(ts);
                setEditValues(values);
                setOpenEditNotification(true);
              }
            } else {
              client
                .mutate({
                  mutation: UPDATE_CLIENT_MUTATION,
                  variables: {
                    memberId: member.id,
                    clientId: id,
                    lastName: lastName.toUpperCase(),
                    firstName: firstName.toUpperCase(),
                    company: company.toUpperCase(),
                    parentId,
                    ord,
                    status: status.toUpperCase(),
                    actionDate: new Date().toISOString(),
                  },
                })
                .then((response) => {
                  setOpenEditClient(false);
                  setOpenEditSuccess(true);
                });
              debugger;
            }
          }
        });
    });
  };

  const directUpdateClient = async () => {
    console.log("directUpdateClient");
    console.log("editValues");
    console.log(editValues);
    // console.log(_ClientsPortfolio);
    // const { lastName, firstName, company, status } = _ClientsPortfolio;
    const { lastName, firstName, company, status } = editValues;
    const { member, id, parentId, ord } = currentClient;
    console.log(id);
    console.log(status);
    client
      .mutate({
        mutation: UPDATE_CLIENT_MUTATION,
        variables: {
          memberId: member.id,
          clientId: id,
          lastName: lastName ? lastName.toUpperCase() : lastName,
          firstName: firstName ? firstName.toUpperCase() : firstName,
          company: company ? company.toUpperCase() : company,
          parentId,
          ord,
          status: status ? status.toUpperCase() : status,
          actionDate: new Date().toISOString(),
        },
      })
      .then((response) => {
        createUserLog(props, "EDIT CLIENT", "Edited Client info", "")
        console.log("...directUpdateClient");
        return response;
      })
      .catch((error) => {
        console.log("...directUpdateClient");
        throw error;
      });
  };

  const deleteClient = async () => {
    const { member, id } = currentClient;
    console.log(currentClient);
    try {
      const response = await client.mutate({
        mutation: DELETE_CLIENT_MUTATION,
        variables: {
          clientId: id,
        },
      });
      createUserLog(props, "DELETE CLIENT", "Deleted a Client", "")
      return response;
    } catch (error) {
      throw error;
    }
  };

  const spreadClient = () => {
    console.log(clients);
    const activeClients = clients.filter((cl) => {
      if (cl) {
        if (cl.status === "ACTIVE" || cl.status === "INACTIVE") return cl;
      }
    });

    function clientOnGrid(item) {
      return item.lastName && item.firstName
        ? item.lastName + ", " + item.firstName
        : item.lastName && !item.firstName
          ? item.lastName
          : !item.lastName && item.firstName
            ? item.firstName
            : null;
    }

    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={true}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0px`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Clients
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -12}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <div style={{ margin: "40px 0 0 0" }}> </div>

        <div
          className={classes.container}
          style={{
            width: `${size.width <= 414 ? size.width - 30 : 370}px`,
          }}
        >
          {hasDeleteRequest ? (
            <Typography style={{ margin: "30px 30px 30px 30px" }}>
              You have a pending delete request. Cancel the delete request to
              add, edit, and delete clients and cases.
            </Typography>
          ) : null}

          <div
            style={{
              overflow: "auto",
              height: `${size.height - 230}px`,
            }}
          >
            {activeClients && activeClients.length > 0 ? (
              activeClients
                .sort(function (a, b) {
                  let namea = a.lastName + a.firstName + a.company;
                  let nameb = b.lastName + b.firstName + b.company;
                  return namea.localeCompare(nameb);
                })
                .map((cl, index) => {
                  console.log(cl);
                  return (
                    <Paper
                      className={classes.container}
                      style={{
                        padding: "10px 0 10px 0",
                        margin: "0 0 15px 0px",
                        width: `${size.width <= 414 ? size.width - 60 : 320}px`,
                      }}
                    >
                      <Typography style={{ textAlign: "center" }}>
                        {clientOnGrid(cl)}
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          margin: "0 0 15px 0",
                        }}
                      >
                        {cl && cl.company && cl.company.length > 0
                          ? "(" + cl.company + ")"
                          : null}
                      </Typography>
                      <Typography style={{ textAlign: "center" }}>
                        Status: {cl.status}
                      </Typography>
                      <Typography style={{ textAlign: "center" }}>
                        Cases: {getActiveCases(cl).length}
                      </Typography>

                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "10px 0 0 0",
                          }}
                        >
                          <Button
                            style={{ margin: "0 10px 0 0", width: "30px" }}
                            variant="contained"
                            color="primary"
                            round
                            disableElevation
                            onClick={() => {
                              handleOpenCases(cl);
                            }}
                          >
                            View
                          </Button>
                          <Button
                            style={{ margin: "0 10px 0 0", width: "30px" }}
                            variant="outlined"
                            color="primary"
                            round
                            disableElevation
                            onClick={() => {
                              setCurrentClient(cl);
                              setOpenEditClient(true);
                            }}
                            disabled={hasDeleteRequest}
                          >
                            Edit
                          </Button>
                          <Button
                            style={{
                              width: `${size.width <= 320 ? 100 : 100}px`,
                            }}
                            variant="contained"
                            variant="outlined"
                            color="primary"
                            size="small"
                            round
                            disableElevation
                            disabled={hasDeleteRequest}
                            onClick={() => {
                              // if (getActiveCases(cl).length > 0)
                              //   handleOpenFail();
                              // else {
                              _ClientsPortfolio.cl = cl;
                              setCurrentClient(cl);
                              handleOpenDeleteClientConfirm(cl);
                              // }
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </ThemeProvider>
                    </Paper>
                  );
                })
            ) : (
              <Typography style={{ fontSize: "26px", textAlign: "center" }}>
                {noDataMessage}
              </Typography>
            )}
          </div>

          <DialogActions className={classes.modalFooterCenter}>
            <Search
              handleSearchFilter={handleSearchFilter}
              placeHolder="Name"
            />
          </DialogActions>
          <DialogActions className={classes.modalFooter}>
            <ThemeProvider theme={defaultBtnMaterialTheme}>
              <Button
                // style={{ width: "190px", margin: "0 0 0 0" }}
                style={{ width: "67%" }}
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleOpenAddClient}
                disabled={hasDeleteRequest}
              >
                Add Client
            </Button>
              <Button
                // style={{ margin: "0 0 0 10px" }}
                style={{ width: "33%" }}
                variant="outlined"
                color="primary"
                size="medium"
                onClick={e => {
                  e.preventDefault()
                  props.history.push("/app/dashboard")
                }}
                disabled={hasDeleteRequest}
              >
                Back
            </Button>
            </ThemeProvider>
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      {spreadClient()}

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        // TransitionComponent={props.Transition}
        keepMounted
        open={openClient}
        onClose={props.handleCloseClient}
        scroll={props.scroll}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Add Client
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <Formik
              validateOnChange={true}
              validateOnBlur={true}
              enableReinitialize={true}
              initialValues={{
                firstName: "",
                lastName: "",
                company: "",
              }}
              validate={(values) => {
                // debugger
                console.log("validate...");
                const errors = {};

                if (!values.firstName && !values.lastName && !values.company) {
                  errors.company = "Please fill at least one field.";
                }
                console.log("errors");
                console.log(errors);
                console.log("...validate");
                return errors;
              }}
              onSubmit={(
                values,
                { isSubmitting, setSubmitting, setFieldValue }
              ) => {
                debugger;
                isSubmitting = true;
                // handleSubmitClient(values, isSubmitting)
                callCreateClient(values, setFieldValue)
                  .then((response) => {
                    console.log(response);
                    const { createClient } = response.data;
                    debugger;
                    setOpenClient(false);
                    setOpenSuccess(true);
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    setOpenDuplicate(true);
                  });
              }}
              render={({
                values,
                isSubmitting,
                setSubmitting,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => (
                <Form mode="themed">
                  <div
                    className={classes.container}
                    style={{
                      width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                      overflow: "auto",
                      height: `${size.height - 135}px`,
                    }}
                  >
                    <TextField
                      style={{
                        margin: "60px 0 0 0",
                        textTranform: "uppercase",
                      }}
                      fullWidth
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                      onChange={(event) => {
                        values.firstName = event.target.value.toUpperCase();
                        setFieldValue(
                          "firstName",
                          event.target.value.toUpperCase()
                        );
                        _ClientsPortfolio.firstName = event.target.value.toUpperCase();
                      }}
                      value={values.firstName}
                    />

                    <TextField
                      style={{
                        margin: "20px 0 0 0",
                        textTranform: "uppercase",
                      }}
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                      onChange={(event) => {
                        values.lastName = event.target.value.toUpperCase();
                        setFieldValue(
                          "lastName",
                          event.target.value.toUpperCase()
                        );
                        _ClientsPortfolio.lastName = event.target.value.toUpperCase();
                      }}
                      value={values.lastName}
                    />
                    <TextField
                      style={{
                        margin: "20px 0 20px 0",
                        textTranform: "uppercase",
                      }}
                      fullWidth
                      name="company"
                      label="Company"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                      onChange={(event) => {
                        values.company = event.target.value.toUpperCase();
                        setFieldValue(
                          "company",
                          event.target.value.toUpperCase()
                        );
                        _ClientsPortfolio.company = event.target.value.toUpperCase();
                      }}
                      value={values.company}
                    />
                    <Typography
                      style={{
                        textAlign: "center", fontSize: '16px', color: primaryColor[0],
                        display: values.firstName === "" && values.lastName === "" && values.company === "" ? 'block' : 'none'
                      }}>
                      <em>* Required</em>
                    </Typography>

                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: grayColor[1],
                        fontStyle: "italic",
                      }}
                    >
                      Fill in at least one field.
                    </Typography>
                  </div>

                  <DialogActions className={classes.modalFooter}>
                    <ThemeProvider theme={defaultBtnMaterialTheme}>
                      <Button
                        style={{ width: "60%" }}
                        disabled={
                          isSubmitting ||
                          (!isSubmitting &&
                            ((values.lastName === "" || values.lastName.length < 2) &&
                              values.firstName === "" &&
                              (values.company === "" || values.company.length < 2)))
                        }
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="md"
                      >
                        Save
                      </Button>
                      <Button
                        style={{ width: "40%" }}
                        variant="outlined"
                        color="primary"
                        size="md"
                        onClick={() => {
                          setOpenClient(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </ThemeProvider>
                  </DialogActions>
                </Form>
              )}
            ></Formik>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Verify
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "20px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                The following client/s
              </Typography>
              <Typography
                style={{
                  margin: "0 0 20px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                have similar name/s:
              </Typography>

              {_ClientsPortfolio.line.map((s) => (
                <Typography
                  style={{
                    margin: "0 0 0 0",
                    fontSize: "16px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  {s}
                </Typography>
              ))}
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "16px",
                  color: primaryColor[0],
                  textAlign: "center",
                }}
              >
                {line3}
              </Typography>
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Verify client addition.
              </Typography>

            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    createClient();
                    setOpenNotification(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenNotification(false);
                  }}
                >
                  No
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openDuplicate}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Add Client
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 10px 0",
                  fontSize: "22px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Client already exists, cannot add same client
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenDuplicate(false);
                  }}
                >
                  Go
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openNoChange}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Notification
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 0px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                No changes have been
              </Typography>

              <Typography
                style={{
                  margin: "0px 0 0px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                made to the client.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenNoChange(false);
                    reFetchClientsByEmail();
                  }}
                >
                  Back
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openSuccess}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 10px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Client added.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenSuccess(false);
                    reFetchClientsByEmail();
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openEditNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Verify
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 136}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                The following client/s
              </Typography>
              <Typography
                style={{
                  margin: "0 0 20px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                have similar name/s:
              </Typography>

              {_ClientsPortfolio.line.map((s) => (
                <Typography
                  style={{
                    margin: "0 0 0 0",
                    fontSize: "16px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  {s}
                </Typography>
              ))}
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "16px",
                  color: primaryColor[0],
                  textAlign: "center",
                }}
              >
                {line3}
              </Typography>
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Verify client edit.
              </Typography>

            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    directUpdateClient().then((response) => {
                      // reFetchClientsByEmail()
                      setOpenEditNotification(false);
                      setOpenEditSuccess(true);
                    });
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenEditNotification(false);
                  }}
                >
                  No
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openEditSuccess}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 0 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Client edited.
              </Typography>
            </div>

            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenEditSuccess(false);
                    reFetchClientsByEmail();
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modaLog,
        }}
        open={openDeleteNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Delete Client
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <Formik
              initialValues={{
                delete: "",
              }}
              // validationSchema={}
              onSubmit={async (values, { setSubmitting }) => { }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div
                    className={classes.container}
                    style={{
                      width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                      overflow: "auto",
                      height: `${size.height - 135}px`,
                    }}
                  >
                    <Typography
                      style={{
                        margin: "40px 0 -10px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      Deleted client data
                    </Typography>
                    <Typography
                      style={{
                        margin: "0 0 -10px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      cannot be recovered,
                    </Typography>
                    <Typography
                      style={{
                        margin: "0 0 0px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      including cases and logs.
                    </Typography>

                    <Typography
                      style={{
                        margin: "30px 0 -10px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      Please confirm request
                    </Typography>
                    <Typography
                      style={{
                        margin: "0 0 20px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      by typing "DELETE"
                    </Typography>

                    <div
                      className={classes.container}
                      style={{
                        width: `${size.width <= 414 ? size.width - 100 : 300
                          }px`,
                      }}
                    >
                      <TextField
                        style={{ textAlign: "center" }}
                        className={classes.container}
                        variant="outlined"
                        onChange={(e) => {
                          setFieldValue("delete", e.target.value);
                        }}
                        value={values.delete}
                        required={values.delete.length === 0}
                      />
                    </div>
                  </div>
                  <DialogActions className={classes.modalFooter}>
                    <ThemeProvider theme={defaultBtnMaterialTheme}>
                      <Button
                        disabled={values.delete !== "DELETE"}
                        style={{ width: "60%" }}
                        variant="contained"
                        color="primary"
                        size="md"
                        onClick={() => {
                          const { member, id, parentId, ord } = currentClient;
                          // const { member } = currentClient;
                          debugger;
                          client
                            .mutate({
                              mutation: UPDATE_CLIENT_MUTATION,
                              variables: {
                                memberId: member.id,
                                clientId: id,
                                status: "XXXXXX",
                                ord,
                                parentId,
                                actionDate: new Date().toISOString(),
                              },
                            })
                            .then((response) => {
                              console.log("...directUpdateClient");
                              reFetchClientsByEmail();
                            })
                            .catch((error) => {
                              console.log("...directUpdateClient");
                            });
                          setOpenDeleteNotification(false);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        style={{ width: "40%" }}
                        variant="outlined"
                        color="primary"
                        size="md"
                        onClick={() => {
                          setOpenDeleteNotification(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </ThemeProvider>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </div>
        </ThemeProvider>
      </Dialog>

      <EditClient
        history={props.history}
        client={client}
        classes={classes}
        Tramsition={Transition}
        open={openEditClient}
        currentClient={currentClient}
        handleSubmitEditClient={handleSubmitEditClient}
        onClose={handleCloseEditClient}
        getActiveCases={getActiveCases}
        directUpdnateClient={directUpdateClient}
        reFetchClientsByEmail={reFetchClientsByEmail}
        setOpenEditClient={setOpenEditClient}
      />

      {/* <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirmEdit}
        message={dialogMessage}
        title={dialogTitle}
        submit={handleSubmit}
      /> */}
      {/* <FailDialog
        message={failMessage}
        title={failTitle}
        open={openFail}
        onClose={handleCloseFail}
      /> */}
    </>
  );
};

export default ClientsPortfolio;
