import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import useWindowSize from 'utils/useWindowSize';

import Waiting from "utils/Waiting";
import { Formik, Form } from "formik";
// import { useMediaQuery } from "react-responsive";

import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
import EmailIcon from "@material-ui/icons/Email";
// import Face from "@material-ui/icons/Face";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// import { gql } from "apollo-boost";
import circlelogo from "assets/img/byn-logo-circle.png";

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import "utils/react-datetime/css/react-datetime.css";
import { MEMBER_CHECKV2 } from 'utils/Graphql/queries';

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";

import { withFirebase } from "utils/Firebase";

import { ThemeProvider } from "@material-ui/styles";

import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import sameTheme from "utils/Themes/viewLogMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import Transition from "utils/Transition";

import Email from "./Email";
import UserName from "./UserName";
import PhoneNumber from "./PhoneNumber";

const style = {
  step3Notes480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
};

const initialValues = {
  userName: "",
  email: "",
  phoneNumber: "",
};

function Profile(props) {
  const {
    classes,
    client,
    authUser,
    open,
    onClose,
    memberData,
    setMemberData,
    reFetch,
  } = props;
  const [openEmail, setOpenEmail] = useState(false);
  const [openUserName, setOpenUserName] = useState(false);
  const [openPhoneNumber, setOpenPhoneNumber] = useState(false);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  const [member, setMember] = useState(memberData);
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block";
      document.getElementById("mainScreen").style.opacity = 0.3;
    } else {
      if (
        document &&
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);

  // useEffect(() => {
  //   handleFetchMemberData()
  // }, [])

  const [email, setEmail] = useState("");
  const size = useWindowSize()
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px");
  // const [closeRightMargin, setCloseRightMargin] = useState("0px");
  const [hasDeleteRequest, setHasDeleteRequest] = useState(false);
  const [deleteDaysRemaining, setDeleteDaysRemaining] = useState(7)

  useEffect(() => {
    console.log(memberData);
    if (!memberData) {
      client.query({
        query: MEMBER_CHECKV2,
        variables: {
          email: authUser.email,
        },
        fetchPolicy: "no-cache",
      })
        .then(response => {
          const { memberCheck } = response.data
          console.log('memberCheck');
          console.log(memberCheck);
          setMember(memberCheck);


          initialValues.email = memberCheck.email;
          initialValues.phoneNumber = memberCheck.phoneNo;
          initialValues.userName = memberCheck.userName;

          const hasDeleteReq = handleCheckDeleteRequest(memberData.serviceRequest)
          if (hasDeleteReq) setHasDeleteRequest(true)
          else setHasDeleteRequest(false)

        })
    } else {
      setMember(memberData);
      initialValues.email = memberData.email;
      initialValues.phoneNumber = memberData.phoneNo;
      initialValues.userName = memberData.userName;

      const hasDeleteReq = handleCheckDeleteRequest(memberData.serviceRequest)
      if (hasDeleteReq) setHasDeleteRequest(true)
      else setHasDeleteRequest(false)
    }
  }, [memberData]);
  // const [memberData, setMemberData] = useState({});

  const ndays = (request, days) => {
    let n = days
    if (request) {
      let reqOriginalDate = new Date(request.date)
      let reqDate = new Date(request.date)
      reqDate.setDate(reqDate.getDate() + n);
      console.log(reqOriginalDate)
      console.log(reqDate)
      let d = Math.abs(reqDate.getDate() - reqOriginalDate.getDate())
      console.log(d)
      return d;
    }
    else {
      return 0
    }
  }

  const handleCheckDeleteRequest = (serviceRequest) => {
    console.log(serviceRequest)
    if (serviceRequest) {
      const sr = serviceRequest.filter((srq, ix) => {
        console.log(srq)
        if (srq.requestType === 'DELETE' && srq.status === 'NEW') {
          setDeleteDaysRemaining(ndays(srq, 7));
          return srq
        }
      })
      console.log(sr)
      if (sr.length > 0) return true
      else return false
    }
    else return false
  }
  // const fetchMemberData = async () => {
  //   try {
  //     let data = await client.query({
  //       query: MEMBER_CHECK,
  //       variables: {
  //         email: authUser.email
  //       },
  //       fetchPolicy: 'no-cache'
  //     })
  //     console.log(data)
  //     return data;
  //   }
  //   catch (e) {
  //     console.log(e)
  //   }
  // }

  // const handleFetchMemberData = () => {
  //   setIsOpenWaiting(true)
  //   fetchMemberData().then((response) => {
  //     console.log(response)
  //     if (response) {
  //       if (response.data) {
  //         let mem = response.data.memberCheck
  //         if (mem) {
  //           setMemberData(mem)
  //           initialValues.userName = ''
  //           initialValues.email = mem.email
  //           initialValues.phoneNumber = mem.phoneNo
  //         }
  //       }
  //     }
  //   })
  //   .finally(()=>{
  //     setIsOpenWaiting(false)
  //   })
  // }
  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={open}
        onClose={onClose}
        // TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Profile
              </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
            <Waiting
              Transition={Transition}
              isOpenWaiting={isOpenWaiting}
            ></Waiting>
          </div>
          {hasDeleteRequest ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              <Typography style={{ margin: '30px' }}>
                You have a pending delete request. Cancel the delete request to
                log an expense.
              </Typography>
            </div>
          ) : (
            ""
          )}
          <div id="mainScreen" style={{ marginTop: "70px" }}>
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 30 : 370}px`,
              }}
            >
              <Formik
                initialValues={initialValues}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, { setSubmitting }) => {
                  // setSubmitting(true)
                }}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  setSubmitting,
                  touched,
                  errors,
                }) => (
                  <Form mode="themed">
                    <div
                      className={classes.container}
                      style={{
                        overflow: "auto",
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        height: `${size.height - 195}px`,
                      }}
                    >
                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          style={{ margin: "10px 0px 0px 0" }}
                          fullWidth
                          name="userName"
                          label="User Name"
                          variant="outlined"
                          disabled={hasDeleteRequest}
                          onChange={(event) => {
                            values.userName = event.target.value.toUpperCase();
                            setFieldValue(
                              "userName",
                              event.target.value.toUpperCase()
                            );
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          value={values.userName}
                        />
                      </ThemeProvider>
                      <FormHelperText
                        style={{ color: primaryColor[0], marginLeft: "10px" }}
                      >
                        {errors.userName}
                      </FormHelperText>

                      <DialogActions className={classes.modalFooter}>
                        <ThemeProvider theme={defaultBtnMaterialTheme}>
                          <div style={{ margin: "0px -10px 0 0" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="md"
                              disabled={hasDeleteRequest}
                              // disabled={isSubmitting ||
                              //   (!isSubmitting &&
                              //     (values.justInitialized ||
                              //       Object.keys(errors).length > 0)
                              //   )}
                              type="submit"
                              // href="/app/username"
                              onClick={() => setOpenUserName(true)}
                            >
                              Edit
                            </Button>
                          </div>
                        </ThemeProvider>
                      </DialogActions>

                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          style={{ margin: "10px 0px 0px 0" }}
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                            setEmail(e.target.value);
                          }}
                          name="email"
                          disabled={hasDeleteRequest}
                          value={values.email}
                          type="email"
                          label="Email"
                          // placeholder="Email*"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          step="0"
                          // required
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end" color="red">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                      <FormHelperText
                        style={{ color: primaryColor[0], marginLeft: "10px" }}
                      >
                        {errors.email}
                      </FormHelperText>

                      <DialogActions className={classes.modalFooter}>
                        <ThemeProvider theme={defaultBtnMaterialTheme}>
                          <div style={{ margin: "0px -10px 0 0" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="md"
                              disabled={hasDeleteRequest}
                              // disabled={isSubmitting ||
                              //   (!isSubmitting &&
                              //     (values.justInitialized ||
                              //       Object.keys(errors).length > 0)
                              //   )}
                              type="submit"
                              // href="/app/email"
                              onClick={() => setOpenEmail(true)}
                            >
                              Edit
                            </Button>
                          </div>
                        </ThemeProvider>
                      </DialogActions>

                      <div style={style.step3Notes480}>
                        <ThemeProvider theme={sameTheme}>
                          <TextField
                            style={{ margin: "-10px 0px 0px 0" }}
                            fullWidth
                            name="phoneNumber"
                            label="Phone number"
                            variant="outlined"
                            disabled={hasDeleteRequest}
                            onChange={(event) => {
                              values.phoneNumber = event.target.value.toUpperCase();
                              setFieldValue(
                                "phoneNumber",
                                event.target.value.toUpperCase()
                              );
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            value={values.phoneNumber}
                          />
                        </ThemeProvider>
                        <FormHelperText
                          style={{ color: primaryColor[0], marginLeft: "10px" }}
                        >
                          {errors.phoneNumber}
                        </FormHelperText>
                      </div>

                      <DialogActions className={classes.modalFooter}>
                        <ThemeProvider theme={defaultBtnMaterialTheme}>
                          <div style={{ margin: "0px -10px 0 0" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="md"
                              disabled={hasDeleteRequest}
                              // disabled={isSubmitting ||
                              //   (!isSubmitting &&
                              //     (values.justInitialized ||
                              //       Object.keys(errors).length > 0)
                              //   )}
                              type="submit"
                              // href="/app/phonenumber"
                              onClick={() => setOpenPhoneNumber(true)}
                            >
                              Edit
                            </Button>
                          </div>
                        </ThemeProvider>
                      </DialogActions>
                    </div>

                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        {/* <div
                            style={{
                              // margin: `0 ${size.width <= 414 ? -7 : -14}px 0 0`,
                              width: "100%"
                            }}
                          > */}
                        <Button
                          style={{
                            // margin: `0 ${size.width <= 414 ? -7 : -14}px 0 0`,
                            // margin: `5px 0 0 0`,
                            width: "100%"
                          }}
                          variant="contained"
                          color="primary"
                          size="md"
                          // disabled={isSubmitting}
                          // href="/app/account"
                          // type="submit"
                          onClick={onClose}
                        >
                          BACK
                          </Button>
                        {/* </div> */}
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>

      <Email
        {...props}
        open={openEmail}
        onClose={() => setOpenEmail(false)}
        reFetch={reFetch}
        memberData={memberData}
        setMemberData={setMemberData}
      />
      <UserName
        {...props}
        memberData={props.memberData}
        open={openUserName}
        onClose={() => setOpenUserName(false)}
        reFetch={reFetch}
      />
      <PhoneNumber
        {...props}
        memberData={member}
        open={openPhoneNumber}
        onClose={() => setOpenPhoneNumber(false)}
        reFetch={reFetch}
      />
    </>
  );
}

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(Profile)))
    )
  )
);
