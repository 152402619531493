import React, { useState } from 'react'
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import useWindowSize from 'utils/useWindowSize';
// import GridContainer from 'components/Grid/GridContainer';
// import GridItem from "components/Grid/GridItem.js";
import {
  Dialog, DialogTitle, withStyles,
  // DialogContent, 
  ThemeProvider,
  Tooltip, Button, DialogActions
} from '@material-ui/core';
import {
  primaryColor,
} from 'assets/jss/material-kit-pro-react.js';
// import { useMediaQuery } from 'react-responsive';
import circlelogo from 'assets/img/byn-logo-circle.png';
import aboutPageMaterialTheme from "utils/Themes/aboutPageMaterialTheme"
/*
  https://github.com/mui-org/material-ui/issues/11517#issuecomment-407509327
 */
function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

const styles = theme => ({
  root: {
    maxWidth: '100%',
    width: '100%',
    textAlign: 'left'
  },
})

const combinedStyles = combineStyles(styles, modalStyle, javascriptStyles, basicsStyle)

const Billing = (props) => {
  const { classes, open, onClose } = props
  console.log(classes)

  const size = useWindowSize()
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px")
  // const [closeRightMargin, setCloseRightMargin] = useState("0px")

  const [setOpenAbout] = useState(true)
  // const [value, setValue] = useState(0);
  const [page] = useState('billing')
  const [title] = useState('Billing')

  const handleCloseAbout = () => {
    setOpenAbout(false)
  }

  const handleChangePageContent = () => {
    if (page === 'billing') {
      return (
        <div
          className={classes.container}
          style={{
            width: `${size.width <= 414 ? size.width - 40 : 360}px`,
            overflow: "auto",
            height: `${size.height - 165}px`
          }}
        >
          <ThemeProvider theme={aboutPageMaterialTheme}>
            <div className={classes.container}>
              <div style={{ margin: '80px 0 80px 0', fontSize: '26px', fontWeight: '400', textAlign: 'center' }}>
                Under construction.
              </div>
            </div>
          </ThemeProvider>
        </div >
      )
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        // TransitionComponent={props.Transition}
        keepMounted
        fullWidth
        fullScreen
        onClose={handleCloseAbout}
        maxWidth="lg"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                {title}
              </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -13}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            id="mainAbout"
            style={{
              // justifyContent: 'center',
              margin: '20px 0px 0px 0px'
            }}>
            <div className={classes.container} style={{ width: `${size.width <= 414 ? size.width - 30 : 370}px` }}>
              {handleChangePageContent()}
              <DialogActions className={classes.modalFooter}>
                <ThemeProvider theme={defaultBtnMaterialTheme}>
                  <Button
                    style={{
                      width: "100%"
                    }}
                    variant="contained"
                    color="primary"
                    size="md"
                    // disabled={isSubmitting}
                    // href="/app/account"
                    // type="submit"
                    onClick={onClose}
                  >
                    BACK
                              </Button>

                </ThemeProvider>
              </DialogActions>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>
    </div >
  )
}

export default withStyles(combinedStyles)(Billing)
