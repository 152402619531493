import React, { useState, useEffect } from "react";
import circlelogo from "assets/img/byn-logo-circle.png";
import Waiting from "utils/Waiting";
import Email from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import useWindowSize from 'utils/useWindowSize';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  ThemeProvider,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react.js";

import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme.js";
import defaultMatTheme from "utils/Themes/defaultMatTheme.js";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme.js";
import Transition from "utils/Transition";
import { MEMBER_CHECK } from "utils/Graphql/queries";
import {
  CREATE_MEMBER_MUTATION,
  INVITE_MEMBER_MUTATION,
} from "utils/Graphql/mutations";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createUserLog } from "utils/CreateUserLog";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";
import { withFirebase } from "utils/Firebase";



import IconButton from "@material-ui/core/IconButton";
const initialValues = {
  email: "",
};


const Invite = (props) => {
  const { classes, client, authUser } = props;
  const [whoami, setWhoami] = useState("");

  useEffect(() => {
  
    const { client, authUser } = props;
    const { email } = authUser;
    debugger;
    client
      .query({
        query: MEMBER_CHECK,
        variables: {
          email: email,
        },
      })
      .then((response) => {
        const { memberCheck } = response.data;
        console.log(memberCheck.id);
        debugger;
        setWhoami(memberCheck.id);
        console.log("out getMemberCheck");
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        console.log("out getMemberCheck");
      });

  }, []);

  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  useEffect(() => {
    if (isOpenWaiting) {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "block";
        document.getElementById("mainScreen").style.opacity = 0.3;
      }
    } else {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);
  const size = useWindowSize()
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [openInviteSuccess, setOpenInviteSuccess] = useState(false);
  const [openInviteExists, setOpenInviteExists] = useState(false);

  const handleSubmitInvite = async (values, setSubmitting, resetForm) => {
    debugger;
    console.log(values);
    console.log(email);
    if (values.email !== "") {
      console.log("ok");
      client
        .mutate({
          mutation: MEMBER_CHECK,
          variables: {
            email: values.email,
          },
        })
        .then((response) => {
          console.log("response.data.memberCheck");
          console.log(response.data.memberCheck);
          const { memberCheck } = response.data;
          console.log("memberCheck");
          console.log(memberCheck);
          if (memberCheck) {
            setSubmitting(false);
            setOpenInviteExists(true);
          } else {
            createMember(values)
              .then((response) => {
                console.log(response);
                if (response) {
                  const { data } = response;
                  if (data.createMember) {
                    inviteMember(values)
                      .then((response) => {
                        if (response) {
                          const { data } = response;
                          if (data.inviteMember) {
                            createUserLog({ authUser, client }, "INVITE", "Invite sent-out to " + values.email, authUser.email + " has sent an invite to " + values.email)
                            setSubmitting(false);
                            setOpenInviteSuccess(true);
                            setEmail("");
                            values.email = "";
                            setError("");
                            setIsOpenWaiting(false)
                          }
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        setError(error);
                        setSubmitting(false);
                        setIsOpenWaiting(false)
                      });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                setError(error);
                setSubmitting(false);
                setIsOpenWaiting(false)
              });
          }
        });
    }
  };

  const createMember = async (values) => {
    const { email } = values;
    debugger;

    try {
      // if(email != ''){
      let response = await client.mutate({
        mutation: CREATE_MEMBER_MUTATION,
        variables: {
          email: email,
          wasInvitedBy: whoami,
        },
      });
      return response;
      // }
    } catch (error) {
      console.log(error);
      if (error.message.includes("unique constraint would be violated"))
        setError(
          "An account with an E-Mail address to this social account already exists."
        );
      else setError(error);
    }
  };

  const inviteMember = async (values) => {
    const { email } = values;
    try {
      let response = await client.mutate({
        mutation: INVITE_MEMBER_MUTATION,
        variables: {
          email: email,
          displayName: "",
        },
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <>
      <div
        style={{ marginTop: -100 }}

      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Invite
                  </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>

          <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
            <Waiting Transition={Transition} isOpenWaiting={isOpenWaiting} />
          </div>
          <div id="mainScreen" style={{ marginTop: "-70px" }}>

            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 30 : 370}px`,
              }}
            >
              <Formik
                initialValues={initialValues}
                initialErrors={initialValues}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email format")
                    .required("Required"),
                  // phoneNo: Yup.string().required('Required')
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  setIsOpenWaiting(true)
                  handleSubmitInvite(values, setSubmitting, resetForm);
                }}
              >
                {({
                  values,
                  isSubmitting,
                  isValid,
                  handleChange,
                  handleSubmit,
                  handleReset,
                }) => (
                  <Form>
                    <div
                      className={classes.container}
                      style={{
                        width: `${size.width <= 414 ? size.width - 40 : 360}px`,
                        overflow: "auto",
                        height: `${size.height - 145
                          }px`,
                      }}
                    >
                      <ThemeProvider theme={defaultMatTheme}>
                        <TextField
                          style={{ marginTop: "60px" }}
                          autoFocus
                          onChange={handleChange("email")}
                          name="email"
                          label="Email"
                          value={values.email}
                          id="input-with-icon-textfield"
                          type="email"
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          required={values.email.length === 0}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </ThemeProvider>
                      <Typography
                        style={{
                          textAlign: "center", fontSize: '16px', color: primaryColor[0],
                          display: values.email === "" ? 'block' : 'none'
                        }}>
                        <em>* Required</em>
                      </Typography>
                      <FormHelperText
                        style={{
                          color: primaryColor[0],
                          margin: "10px 0 20px 10px",
                          fontSize: "18px",
                        }}
                      >
                        {error && error.message ? error.message : null}
                      </FormHelperText>
                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{ width: "60%" }}
                          variant="contained"
                          type="submit"
                          color="primary"
                          onClick={handleSubmit}
                          disabled={!isValid || isSubmitting}
                        >
                          Send
                              </Button>

                        <Button
                          style={{ width: "40%" }}
                          color="primary"

                          variant="outlined"
                          onClick={e => {
                            e.preventDefault()
                            createUserLog(props, "INVITE", "Cancelled Invite", "clicked CANCEL")
                            props.history.push("/app/dashboard")
                          }}
                        >
                          Cancel
                              </Button>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </div>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openInviteSuccess}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Invite
                  </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 144}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 10px 0",
                  fontSize: "22px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Thank you!
                        </Typography>
              <Typography
                style={{
                  margin: "0 0 0 0",
                  fontSize: "18px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Invitee will receive email invitation.
                        </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setIsOpenWaiting(false)
                    setOpenInviteSuccess(false);
                  }}
                >
                  Close
                      </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openInviteExists}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Invite
                  </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -20 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 145}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 10px 0",
                  fontSize: "18px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                That email address is associated with an existing
                account.
                        </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setIsOpenWaiting(false)
                    setOpenInviteExists(false);
                  }}
                >
                  Close
                      </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>
    </>
  );
};


export default withStyles(javascriptStyles)(
  withStyles(basicsStyle)(
    withStyles(modalStyle)(withFirebase(withAuthentication(Invite)))
  )
);
