
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import * as Yup from "yup";

import Waiting from "utils/Waiting";
import { Formik, Form } from "formik";

import FormHelperText from "@material-ui/core/FormHelperText";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';

import { gql } from "apollo-boost";
import circlelogo from 'assets/img/byn-logo-circle.png';

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import "utils/react-datetime/css/react-datetime.css";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";

import { withFirebase } from "utils/Firebase";

import { ThemeProvider } from "@material-ui/styles";

import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import sameTheme from "utils/Themes/viewLogMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import MessageDialog from 'utils/MessageDialog';
import MessageDialog2 from 'utils/MessageDialog';
import Transition from 'utils/Transition';
import { UPDATE_MEMBER_MUTATION } from 'utils/Graphql/mutations'
import useWindowSize from 'utils/useWindowSize';
import { createUserLog } from "utils/CreateUserLog";

import IconButton from "@material-ui/core/IconButton";

const style = {
  step3Notes480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
};

const initialValues = {
  oldEmail: "",
  newEmail: ""
};

function Email(props) {
  const { authUser, classes, onClose, open, reFetch, memberData, firebase } = props;

  const [hasDeleteRequest] = useState(false);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false)
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block";
      document.getElementById("mainScreen").style.opacity = 0.3;
    } else {
      if (
        document &&
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);

  // const [email, setEmail] = useState('')
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [openMessageDialog2, setOpenMessageDialog2] = useState(false);
  const size = useWindowSize()

  useEffect(() => {
    console.log(memberData);
    initialValues.oldEmail = memberData ? memberData.email : '';
    initialValues.newUserName = ''

  }, [memberData]);
  const handleCloseMessageDialog = () => {
    reFetch()
    setOpenMessageDialog(false)
    onClose()
    // props.history.push('/profile');
  }

  const handleCloseMessageDialog2 = () => {
    // reFetch();
    setOpenMessageDialog2(false);
    // onClose();
    // props.history.push('/profile');
  };
  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog
        }}
        open={open}
        onClose={onClose}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Email
                </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div id="waiting" style={{ marginBottom: '70px', marginTop: 20 }}>
            <Waiting
              Transition={Transition}
              isOpenWaiting={isOpenWaiting}
            ></Waiting>
          </div>
          {hasDeleteRequest ?
            <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
              <Typography>
                You have a pending delete request. Cancel the delete request to log an expense.
              </Typography>
            </div>
            : ''}
          <div id="mainScreen" style={{ marginTop: '-30px' }}>
            <div
              className={classes.container}
              style={{ width: `${size.width <= 414 ? size.width - 30 : 370}px` }}
            >
              <Formik
                initialValues={initialValues}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={
                  Yup.object().shape({
                    newEmail: Yup.string()
                      .email('E-mail is not valid!')
                      .required('E-mail is required!')
                  })
                }
                onSubmit={(values, { setSubmitting }) => {
                  // setSubmitting(true)
                }}
              >
                {({
                  isValid,
                  values,
                  isSubmitting,
                  setFieldValue,
                  setSubmitting,
                  touched,
                  errors
                }) => (
                  <Form mode="themed" >
                    <div
                      className={classes.container}
                      style={{
                        overflow: "auto",
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        height: `${size.height - 165}px`
                      }}
                    >
                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          // onChange={(e) => {
                          //   setFieldValue('email', e.target.value)
                          //   setEmail(e.target.value)
                          // }}
                          style={{ margin: "40px 0px 0px 0" }}
                          name="email"
                          value={values.oldEmail}
                          type="email"
                          label="Old"
                          // placeholder="Email*"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          // step="0"
                          // required
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end" color="red">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {/* <FormHelperText style={{ color: primaryColor[0], marginLeft: "10px" }}>
                            {errors.email}
                          </FormHelperText> */}
                      </ThemeProvider>

                      <ThemeProvider theme={defaultMaterialTheme}>
                        <TextField
                          style={{ margin: "20px 0px 0px 0" }}
                          autoFocus

                          name="email"
                          value={values.newEmail}
                          type="email"
                          label="New"
                          // placeholder="Email*"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          step="0"
                          // required
                          variant="outlined"
                          onChange={(event) => {
                            values.newEmail = event.target.value
                            setFieldValue(
                              "newEmail",
                              event.target.value
                            );
                            console.log('typing new email')
                            console.log(values.newEmail)
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          required={values.newEmail.length === 0}

                          InputProps={{
                            readOnly: false,
                            endAdornment: (
                              <InputAdornment position="end" color="red">
                                <EmailIcon />


                              </InputAdornment>
                            ),
                          }}
                        />
                        <FormHelperText style={{ color: primaryColor[0], marginLeft: "10px" }}>
                          {errors && errors.newEmail && touched.newEmail ? errors.newEmail : null}
                        </FormHelperText>
                        <Typography
                          style={{
                            textAlign: "center", fontSize: '16px', color: primaryColor[0],
                            display: values.newEmail === "" ? 'block' : 'none'
                          }}>
                          <em>* Required</em>
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{
                            width: "60%"
                          }}
                          variant="contained"
                          color="primary"
                          size="md"
                          disabled={!isValid || isSubmitting || values.newEmail === "" || (values.oldEmail === values.newEmail)}
                          onClick={() => {
                            if (values.oldEmail === values.newEmail) {
                              setOpenMessageDialog2(true);
                            }
                            else {
                              console.log('UserName values')
                              console.log(values)
                              setSubmitting(true);
                              setIsOpenWaiting(true)
                              const { client } = props
                              client.query({
                                query: MEMBER_CHECK1,
                                variables: {
                                  email: values.newEmail
                                }
                              })
                                .then(response => {
                                  firebase.doEmailUpdate(values.newEmail)
                                  const { data } = response
                                  const { memberCheck1 } = data
                                  if (memberCheck1.length === 0) {
                                    client.mutate({
                                      mutation: UPDATE_MEMBER_MUTATION,
                                      variables: {
                                        memberId: memberData.id,
                                        email: values.newEmail,
                                      }
                                    })
                                      .then(response => {
                                        createUserLog(props, "EMAIL", "Changed email address", "")
                                        let authUser_ = { ...authUser, email: values.newEmail }
                                        localStorage.setItem('authUser', JSON.stringify(authUser_));
                                        setSubmitting(false);
                                        setIsOpenWaiting(false)
                                        setOpenMessageDialog(true);
                                        reFetch(authUser_)
                                      })
                                  } else {
                                    console.log('user email exists')
                                    setSubmitting(false);
                                    setIsOpenWaiting(false)
                                    setOpenMessageDialog2(true);
                                  }
                                })
                                .catch(e => {
                                  console.log(e)
                                })


                            }
                          }}
                        >
                          Save
                          </Button>

                        <Button
                          style={{
                            width: "40%"
                          }}
                          variant="outlined"
                          color="primary"
                          size="md"
                          // disabled={isSubmitting}
                          // href="/app/profile"
                          onClick={onClose}
                        // type="submit"
                        >
                          Cancel
                              </Button>

                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>

      </Dialog>

      <MessageDialog
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={"Email changed"}
        messageOnMessageDialog2={""}
        messageDialogTitle={"Email Update"}
        messageLabelBtn={"DONE"}
      // push={"/app/profile"}
      />

      <MessageDialog2
        Transition={props.Transition}
        openMessageDialog={openMessageDialog2}
        handleCloseMessageDialog={handleCloseMessageDialog2}
        setOpenMessageDialog={setOpenMessageDialog2}
        messageOnMessageDialog={"That email address is associated"}
        messageOnMessageDialog2={" with an existing account."}
        messageDialogTitle={"Email"}
        messageLabelBtn={"back"}
        variant={"outlined"}
      // push={"/app/profile"}
      />

    </>
  )
}


export const MEMBER_CHECK1 = gql`
  query memberCheck1($email: String!) {
    memberCheck1(email: $email) {
      id
      email
      userName
      displayName
      hasConfirmedAgreement
      hasRespondedToInvite
      hasInitiallyLoggedIn
      phoneNo
      isLoggedIn
    }
  }
`;

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(Email)))
    )
  )
);