import React from 'react'
import {
  Dialog, DialogTitle,
  // DialogContent, DialogContentText,
  TextField, DialogActions, Button, ThemeProvider, Typography,
  // FormControl,
  Tooltip
} from '@material-ui/core'
import { Formik, Form } from 'formik';
import {
  primaryColor
} from 'assets/jss/material-kit-pro-react.js';
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme.js"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme.js"
import circlelogo from "assets/img/byn-logo-circle.png";
import useWindowSize from 'utils/useWindowSize';



const ConfirmDataDelete = (props) => {
  const { classes, open, submitFn, setOpen } = props;
  const size = useWindowSize()

  const validate = (values) => {
    const errors = {};
    console.log(values)
    if (values.deleteText !== 'DELETE') {
      errors.deleteText = "Input text did not match 'DELETE'"
    }
    console.log(errors)

    return errors
  }

  const handleCloseConfirm = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={open}
        TransitionComponent={props.Transition}
        keepMounted
        onClose={handleCloseConfirm}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
                  </div>
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0` }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div id="waiting" style={{ marginBottom: '70px', marginTop: 20 }} />
          <div
          // className={classes.container}
          // style={{
          //   width: `${size.width <= 414 ? size.width - 30 : 400}px`,
          // }}
          >
            <div
              className={classes.container}
              style={{
                // marginLeft: "-10px",
                width: `${size.width <= 414 ? size.width - 30 : 370
                  }px`,
                // overflow: "auto",
                // height: `${size.height - 150}px`,
              }}
            >
              <Formik
                validate={validate}
                onSubmit={values => {
                  console.log(values)
                  submitFn()
                  values.deleteText = ''
                }}
                initialValues={{ deleteText: '' }}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  values,
                  isSubmitting,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                  touched,
                  errors,
                  dirty,
                  handleChange,
                  handleSubmit,
                  handleReset
                }) => (
                  <Form mode="themed" >
                    <div
                      className={classes.container}
                      style={{
                        overflow: "auto",
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        height: `${size.height - 195}px`,
                        marginTop: '0px'
                      }}
                    >
                      <div className={classes.container}>
                        <div style={{ margin: '30px 0 0px 0', fontSize: '26px', fontWeight: '400', textAlign: 'center' }}>
                          {/* {hasDeleteRequest ? 'You have a pending delete request.' : 'Request Data'} */}
                          Are you sure you want to delete your account? Deleted data cannot be recovered.
                        </div>
                        <div style={{ margin: '10px 0 20px 0', fontSize: '26px', fontWeight: '400', textAlign: 'center' }}>
                          Type 'DELETE' to confirm.
                        </div>
                        <TextField
                          variant="outlined"
                          fullWidth
                          name='deleteText'
                          id='deleteText'
                          value={values.deleteText}
                          required
                          onChange={e => {
                            setFieldValue('deleteText', e.target.value)
                          }}
                        />
                        {errors.deleteText ? <Typography color='primary'>{errors.deleteText}</Typography> : ''}
                      </div>
                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{
                            width: "60%"
                          }}
                          variant='contained' color='primary' size="md"
                          onClick={handleSubmit}
                        >Delete</Button>
                        <Button
                          style={{
                            width: "40%"
                          }}
                          variant='outlined' color='primary' size="md"
                          onClick={handleCloseConfirm}
                        >Cancel</Button>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </Dialog >
    </>
  )
}

export default ConfirmDataDelete
