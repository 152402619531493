import React, { useState, useEffect, useRef } from "react";

import { gql } from "apollo-boost";
import circlelogo from "assets/img/byn-logo-circle.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import "ag-grid-community";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import MDButton from "@material-ui/core/Button";
import SystemUpdateAlt from "@material-ui/icons/Archive";
import useWindowSize from 'utils/useWindowSize';
import { createUserLog } from "utils/CreateUserLog";

import {
  primaryColor,
} from "assets/jss/material-kit-pro-react.js";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";

import EllipsisRenderer from "./EllipsisRenderer";
import EllipsisRendererPrint from "./EllipsisRendererPrint";
import ServiceUpdate from "./ServiceUpdate";
import ServiceDelete from "./LogDelete";
import ExpenseUpdate from "./ExpenseUpdate";
import MessageDialog from "utils/MessageDialog";
import MessageDialog2 from "utils/MessageDialog";
import MessageDialog3 from "utils/MessageDialog";
import MessageDialog4 from "utils/MessageDialog";

import DialogActions from "@material-ui/core/DialogActions";
import Waiting from "utils/Waiting";
import { activitiesSource, L2Source } from "screens/LogMyWork/LogService.js";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextFieldComponent from "utils/TextFieldComponent.js";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import { withFirebase } from "utils/Firebase";
import "./styles.css";
import Tooltip from "@material-ui/core/Tooltip";
import ViewLogModal from "./ViewLogModal";
import { useMediaQuery } from "react-responsive";
import { normalizeMinutes } from "./ViewLogModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import CustomTooltip from "./customTooltip.js";

import { formatMoney } from "utils/FormatMoney";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import moreLessBtnMaterialTheme from "utils/Themes/moreLessBtnMaterialTheme";
import dateMaterialTheme from "utils/Themes/dateMaterialTheme";
import { formatNumber_ } from "utils/FormatNumber_";
import Transition from "utils/Transition";
import { MEMBER_CHECKV2 } from "utils/Graphql/queries"


export const clone = (obj) => {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null === obj || "object" != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const initialValues_case = {
  caseCode: "",
  caseTitle: "",
};

export const initialValues_client = {
  firstName: "",
  lastName: "",
  company: "",
};

const style = {
  grid: {
    width: "60%",
  },
  mainWindow: {
    display: "grid",
    gridTemplateColumns: ".10fr .80fr .10fr",
    gridTemplateRows: "auto auto",
  },
  step1: {
    display: "grid",
    gridTemplateColumns: ".14fr .01fr .14fr .01fr 0.015fr .135fr",
    gridTemplateRows: "auto auto",
  },
  step1360: {
    display: "grid",
    gridTemplateColumns: "300px",
    gridTemplateRows: "auto auto",
    margin: "5px 0 0 20px",
  },
  step1375: {
    display: "grid",
    gridTemplateColumns: "315px",
    gridTemplateRows: "auto auto",
    margin: "5px 0 0 20px",
  },
  step1414: {
    display: "grid",
    gridTemplateColumns: "355px",
    gridTemplateRows: "auto auto",
    margin: "5px 0 0 10px",
  },
  step1667: {
    display: "grid",
    gridTemplateColumns: "603px",
    gridTemplateRows: "auto auto",
    margin: "5px 0 0 20px",
  },
  step1CustomDates: {
    display: "grid",
    gridTemplateColumns: "20px 149px 2px 149px",
    gridTemplateRows: "auto auto",
    marginTop: "-30px",
    marginBottom: "10px",
  },
  step11224: {
    display: "grid",
    gridTemplateColumns: ".15fr .01fr .24fr .01fr 0.015fr .135fr",
    gridTemplateRows: "auto auto",
  },
  step1Headerxx: {
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    marginBottom: "5px",
  },
  step1Headerx: {
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    marginBottom: "5px",
  },
  step1HeaderCategoryx: {
    display: "grid",
    gridTemplateColumns: ".4fr",
    gridTemplateRows: "auto auto",
    marginBottom: "5px",
  },
  step1CustomDatesx: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    margin: '-10px 0 0 0'
  },
  step1Header: {
    display: "grid",
    gridTemplateColumns: ".19fr .01fr .38fr .01fr .135fr",
    gridTemplateRows: "auto auto",
  },
  step1Header896: {
    display: "grid",
    gridTemplateColumns: ".18fr .01fr .29fr .01fr .135fr",
    gridTemplateRows: "auto auto",
  },
  step1Header667: {
    display: "grid",
    gridTemplateColumns: ".15fr .01fr .24fr .01fr .135fr",
    gridTemplateRows: "auto auto",
  },
  step1Period: {
    display: "grid",
    gridTemplateColumns: ".15fr",
    gridTemplateRows: "auto auto",
    height: "40px",
    // width: '300px',
    fontSize: "14px",
  },
  stepCustom: {
    marginTop: "-30px",
    display: "grid",
    gridTemplateColumns: ".9",
    gridTemplateRows: "auto auto",
    height: "25px",
    // width: '300px',
    fontSize: "12px",
    width: "100%",
  },
  stepCustom1: {
    marginTop: "-35px",
    display: "grid",
    gridTemplateColumns: ".9",
    gridTemplateRows: "auto auto",
    height: "25px",
    // width: '300px',
    fontSize: "12px",
    width: "100%",
  },
  stepCustom2: {
    marginTop: "-20px",
    display: "grid",
    gridTemplateColumns: ".9",
    gridTemplateRows: "auto auto",
    height: "25px",
    // width: '300px',
    fontSize: "12px",
    width: "100%",
  },
  step1Period1224: {
    display: "grid",
    gridTemplateColumns: ".35fr",
    gridTemplateRows: "auto auto",
    height: "30px",
    // width: '300px',
    fontSize: "14px",
  },
  step3DateError: {
    display: "grid",
    gridTemplateColumns: ".9fr",
    color: "primary",
    gridTemplateRows: "auto auto",
  },
  step1Client: {
    display: "grid",
    gridTemplateColumns: "40px 1fr 100px",
    gridTemplateRows: "auto auto",
  },
  step2: {
    display: "grid",
    gridTemplateColumns: ".20fr",
    gridTemplateRows: "auto auto",
  },
  step2Filter: {
    display: "grid",
    gridTemplateColumns: ".14fr",
    gridTemplateRows: "auto auto",
  },
  step2billing: {
    display: "grid",
    gridTemplateColumns: "30px .3fr 1px",
    gridTemplateRows: "auto auto",
  },
  step3: {
    display: "grid",
    gridTemplateColumns: "30px 1fr 10px",
    gridTemplateRows: "auto auto",
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

function formatNumber(number) {
  return Number(parseFloat(number).toFixed(2)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
}

const useForceUpdate = () => useState()[1];

function Logs(props) {
  let notificationMessage = "";
  let refreshGridNotification = false;

  let paramStart_ = null;
  let paramEnd_ = null;

  const [start_, setStart_] = useState(null);
  const [end_, setEnd_] = useState(null);
  const [doQuery, setDoQuery] = useState(false);
  const [qryStart_, setQryStart_] = useState(null);
  const [qryEnd_, setQryEnd_] = useState(null);

  const [dashboardOnClose, setDashboardOnClose] = useState(false);
  const [startDate__, setStartDate__] = useState(null);
  const [endDate__, setEndDate__] = useState(null);
  // const [pdfNotif, setPdfNotif] = useState(false);

  const size = useWindowSize()

  const checkTriggerQuery = (values) => {
    console.log("start ------------ checkTriggerQuery");
    console.log("values.startDate");
    console.log(values.startDate);
    console.log("values.endDate");
    console.log(values.endDate);
    console.log("start_");
    console.log(start_);
    console.log("end_");
    console.log(end_);
    console.log("qryStart_");
    console.log(qryStart_);
    console.log("qryEnd_");
    console.log(qryEnd_);
    console.log("init -------------- checkTriggerQuery");
    let start_hasChanged = false;
    let end_hasChanged = false;
    debugger;
    if (values.startDate !== null || values.endDate !== null) {
      console.log("values.startDate !== null || values.endDate !== null");
      console.log(values.startDate);
      console.log(values.endDate);

      if (
        qryStart_ === null ||
        (qryStart_ !== null && (values.startDate !== null && values.startDate < qryStart_))
      ) {
        console.log(
          " qryStart_ === null || (qryStart_ !== null && (values.startDate !== null && values.startDate < qryStart_))"
        );
        console.log(
          qryStart_ === null ||
          (qryStart_ !== null && (values.startDate !== null && values.startDate < qryStart_))
        );
        console.log("values.startDate < qryStart_");
        console.log(values.startDate < qryStart_);
        // setQryStart_(paramStart_);

        start_hasChanged = true;
      }

      if (
        qryEnd_ === null ||
        (qryEnd_ !== null && (values.endDate !== null && values.endDate.toISOString().split("T")[0] > qryEnd_.toISOString().split("T")[0]))
      ) {
        console.log(
          " qryEnd_ === null || (qryEnd_ !== null && (values.endDate !== null && values.endDate.toISOString().split(\"T\")[0] > qryEnd_.toISOString().split(\"T\")[0]))"
        );
        console.log(
          qryEnd_ === null ||
          (qryEnd_ !== null && (values.endDate !== null && values.endDate.toISOString().split("T")[0] > qryEnd_.toISOString().split("T")[0]))
        );
        console.log("paramEnd_ > qryEnd_");
        console.log(paramEnd_ > qryEnd_);
        // setQryEnd_(paramEnd_);

        end_hasChanged = true;
      }

      setStart_(values.startDate);
      setEnd_(values.endDate);

    } else {
      console.log("values.startDate || values.endDate");
      console.log(values.startDate ? values.startDate.toISOString() : "");
      console.log(values.endDate ? values.endDate.toISOString() : "");
    }

    console.log('start_hasChanged')
    console.log(start_hasChanged)
    console.log('end_hasChanged')
    console.log(end_hasChanged)
    console.log("(start_hasChanged || end_hasChanged)");
    console.log(start_hasChanged || end_hasChanged);
    setDoQuery(start_hasChanged || end_hasChanged);
    // }

    console.log("end ------------ checkTriggerQuery");
    return "";
  };

  const { classes } = props;
  const formRef = useRef();
  const [
    // openConfirmUpdateExpenseLog,
    setOpenConfirmUpdateExpenseLog,
  ] = useState(false);
  const [
    // openConfirmUpdateServiceLog,
    setOpenConfirmUpdateServiceLog,
  ] = useState(false);

  const [openServiceLog, setOpenServiceLog] = useState(true);
  const [openLog, setOpenLog] = useState(false);
  const [openLogHasBeenOpened, setOpenLogHasBeenOpened] = useState(false);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [openMessageSystemDialog, setOpenMessageSystemDialog] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [openMessageDialogPDF, setOpenMessageDialogPDF] = useState(false);
  const [openMessageDialogPDFPhone, setOpenMessageDialogPDFPhone] = useState(false);
  const [openMessageDialogPDFDesktop, setOpenMessageDialogPDFDesktop] = useState(false);
  const [messageOnMessageDialog, setMessageOnMessageDialog] = useState("");
  const [details, setDetails] = useState(null);
  useEffect(() => {
    fetch(
      `https://geolocation-db.com/json/${process.env.GEO_LOCATION_KEY}`
    )
      .then(response => response.json())
      .then(data => {
        setDetails(data)
        createUserLog(props, "VIEW_LOGS", "Opened VIEW LOG", `${data.city}, ${data.state}, ${data.country_name} (${data.country_code}) ${data.IPv4}`)
      })
      .catch(error => {
        createUserLog(props, "VIEW_LOGS", "Opened VIEW LOG", "")
        console.log('VIEW LOG error', error)
      })


    debugger
    if (size.width > 414) {
      setPdfSimpleLayout(false);
    }

  

  }, []);

  const [calledFrom, setCalledFrom] = useState("");
  const [isOpenViewUpdate, setIsOpenViewUpdate] = useState(false);
  const [isOpenViewLog, setIsOpenViewLog] = useState(false);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);

  useEffect(() => {

    if (isOpenWaiting && document) {
      if (document.getElementById("waiting"))
        document.getElementById("waiting").style.display = "block";
      if (document.getElementById("mainScreen"))
        document.getElementById("mainScreen").style.opacity = 0.1;
      if (document.getElementById("mainScreenLog"))
        document.getElementById("mainScreenLog").style.opacity = 0.1;
    } else {
      if (document.getElementById("waiting"))
        document.getElementById("waiting").style.display = "none";
      if (document.getElementById("mainScreen"))
        document.getElementById("mainScreen").style.opacity = 1;
      if (document.getElementById("mainScreenLog"))
        document.getElementById("mainScreenLog").style.opacity = 1;
    }
  }, [isOpenWaiting]);

  const [printingDocument, setPrintingDocument] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 897px)",
  });

  const is896 = useMediaQuery({
    query: "(max-width: 896px)",
  });

  const is667 = useMediaQuery({
    query: "(max-width: 667px)",
  });

  const is414 = useMediaQuery({
    query: "(max-width: 414px)",
  });

  const is480 = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const is375 = useMediaQuery({
    query: "(max-width: 375px)",
  });

  const is360 = useMediaQuery({
    query: "(max-width: 360px)",
  });

  const is320 = useMediaQuery({
    query: "(max-width: 320px)",
  });

  const [hasDeleteRequest, setHasDeleteRequest] = useState(false);

  const [contextParent, setContextParent] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowDataClone, setSelectedRowDataClone] = useState({});

  const checkMemberData = async () => {
    const { client } = props;
    const { authUser } = props;
    const { email } = authUser;
    try {
      let data = await client.query({
        query: MEMBER_CHECKV2,
        variables: {
          email
        }
      })
      console.log(data)
      return data
    }
    catch (error) {
      console.log(error)
    }

  }

  const handleCheckDeleteRequest = (serviceRequest) => {
    console.log(serviceRequest)
    if (serviceRequest) {
      const sr = serviceRequest.filter((srq, ix) => {
        console.log(srq)
        if (srq.requestType === 'DELETE' && srq.status === 'NEW') {
          // setDeleteDaysRemaining(ndays(srq, 7));
          return srq
        }
      })
      console.log(sr)
      if (sr.length > 0) return true
      else return false
    }
    else return false
  }

  const checkDeleteRequest = () => {
    checkMemberData()
      .then(response => {
        console.log(response)
        let data = response.data.memberCheck
        if (data) {
          if (data.serviceRequest) {
            const hasDeleteReq = handleCheckDeleteRequest(data.serviceRequest)
            if (hasDeleteReq) setHasDeleteRequest(true)
            else setHasDeleteRequest(false)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    console.log("in start useEffect []");
    setIsOpenWaiting(true);
    setServiceActivities();
    fetchClientsforSelect();
    console.log("in end useEffect []");
  }, []);

  const [domLayout, setDomLayout] = useState("autoHeight");
  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    console.log("in start useEffect [rowData]");
    console.log("in end useEffect [rowData]");
  }, [rowData]);

  const [rowDataPrint, setRowDataPrint] = useState([]);
  useEffect(() => {
    console.log("in start useEffect [rowDataPrint]");
    if (rowDataPrint.length > 0) {
      if (document.getElementById("GridPrint"))
        document.getElementById("GridPrint").style.display = 'block';
      if (document.getElementById("myGridPrint"))
        document.getElementById("myGridPrint").style.display = 'block';
    } else {
      if (document.getElementById("GridPrint"))
        document.getElementById("GridPrint").style.display = 'none';
      if (document.getElementById("myGridPrint"))
        document.getElementById("myGridPrint").style.display = 'none';
    }

    console.log("in end useEffect [rowDataPrint]");
  }, [rowDataPrint]);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState("");
  const [startDate_, setStartDate_] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0
    )
  );
  const [endDate_, setEndDate_] = useState(new Date());
  const [refetchLogs, setRefetchLogs] = useState(0);

  useEffect(() => {
    console.log("in start useEffect [startDate_, endDate_]");
    setRefetchLogs(1);
    console.log("in end useEffect [startDate_, endDate_]");
  }, [startDate_, endDate_]);

  const [currentClient, setCurrentClient] = useState("-1");
  const [currentCase, setCurrentCase] = useState("-1");

  const [logType, setLogType] = useState("");
  const [billingType, setBillingType] = useState("-1");
  const [refreshTemp, setRefreshTemp] = useState(0);
  const [pdfSimpleLayout, setPdfSimpleLayout] = useState(true);
  useEffect(() => {
    console.log(
      "in start useEffect [currentClient, currentCase, billingType, pdfSimpleLayout, refreshTemp]"
    );
    if (start_ && end_) reArrange(setRowData, null);
    console.log(
      "in end useEfect [currentClient, currentCase, billingType,  pdfSimpleLayout , refreshTemp]"
    );
  }, [billingType, pdfSimpleLayout, refreshTemp]);

  const [refreshTempService, setRefreshTempService] = useState([]);
  useEffect(() => {
    console.log("in start useEffect [refreshTempService]");
    setRowData(refreshTempService);
    console.log("in end useEfect [refreshTempService]");
  }, [refreshTempService]);

  const [refreshTempExpense, setRefreshTempExpense] = useState(0);
  useEffect(() => {
    console.log("in start useEffect [refreshTempExpense]");
    setRowData(refreshTempExpense);
    console.log("in end useEfect [refreshTempExpense]");
  }, [refreshTempExpense]);

  const [rowNode, setRowNode] = useState({});
  const [clientsByEmailCaseDate, setClientsByEmailCaseDate] = useState([]);
  const [clientsByEmail, setClientsByEmail] = useState([]);
  const [openClient, setOpenClient] = useState(false);
  const [openCase, setOpenCase] = useState(false);

  const [initialValues_clientx, setInitialValues_clientx] = useState([]);
  const [initialValues_case, setInitialValues_case] = useState([]);
  const [initialValues_service, setInitialValues_service] = useState([]);
  const [initialValues_expense, setInitialValues_expense] = useState([]);
  const [billingTypeExpense, setBillingTypeExpense] = useState("");
  const [billingTypeService, setBillingTypeService] = useState("");

  const forceUpdate = useForceUpdate();
  const [activity1Options, setActivity1Options] = useState([]);
  const [scroll, setScroll] = useState("body");
  const [delete_id, setDelete_id] = useState("");
  const [openUpdateExpenseLog, setOpenUpdateExpenseLog] = useState(false);
  const [openUpdateServiceLog, setOpenUpdateServiceLog] = useState(false);
  const [openDeleteExpenseLog, setOpenDeleteExpenseLog] = useState(false);
  const [openDeleteDoneLog, setOpenDeleteDoneLog] = useState(false);

  let delete_serviceLogId = "";
  let delete_expenseLogId = "";

  const [memberId, setMemberId] = useState("");
  const [clientId, setClientId] = useState("");
  const [caseId, setCaseId] = useState("");
  const [workLogId, setWorkLogId] = useState("");
  const [company, setCompany] = useState("");
  const [paramClient, setParamClient] = useState({});
  const [parentId, setParentId] = useState("");
  const [ord, setOrd] = useState("");
  const [status, setStatus] = useState("");

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    tooltipComponent: "customTooltip",
  };

  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month_ = (mm) => {
    return MONTHS[mm];
  };

  const date_ = (dd) => {
    return dd <= 9 ? "0" + dd : dd;
  };

  const columnDefs = [
    {
      field: "filePath",
      hide: true,
      sort: "asc",
      width: 900,
    },
    {
      field: "label",
      headerName: "Sections and Labels",
      hide: false,
      width: is320
        ? 130
        : is360
          ? 130
          : is375
            ? 130
            : is414
              ? 140
              : is480
                ? 180
                : is667
                  ? 180
                  : is896
                    ? pdfSimpleLayout
                      ? 300
                      : 180
                    : isDesktopOrLaptop
                      ? pdfSimpleLayout
                        ? 300
                        : 180
                      : 0,
      colSpan: function (params) {
        var path = params.data.filePath;
        if (
          path.endsWith("$.client") ||
          path.endsWith("$.headerLegend") ||
          path.endsWith("$.ClientAll") ||
          path.endsWith("$.Period") ||
          path.endsWith("$.PeriodClient") ||
          path.endsWith("$.case") ||
          path.endsWith("$.datebreak") ||
          path.endsWith("$.casebreak")
        ) {
          return pdfSimpleLayout ? 4 : 9;
        } else if (
          path.endsWith("$.TotalAll") ||
          path.endsWith("$.WorkAll") ||
          path.endsWith("$.ExpenseAll") ||
          path.endsWith("$.Total") ||
          path.endsWith("$.Subtotal (This Case)") ||
          path.endsWith("$.Work (This Case)") ||
          path.endsWith("$.Expense (This Case)") ||
          path.endsWith("$.Expense") ||
          path.endsWith("$.Work")
        ) {
          return pdfSimpleLayout ? 3 : 8;
        } else {
          return 1;
        }
      },
      valueFormatter: function (params) {
        var path = params.data.filePath;

        if (path.endsWith("$.ClientAll")) {
          return path.split("$.")[1];
        } else if (path.endsWith("$.headerLegend")) {
          return ""; //Legend: (1) - Date     (2) - Bill Type       (3) - Rate       (4) - Duration in hrs       (5) - Start Time       (6) - End Time       (7) - Amount'
        } else if (path.endsWith("$.PeriodClient")) {
          return path.split("$.")[3]; //.split('Period :')[1]
        } else if (path.endsWith("$.Period")) {
          return path.split("$.")[1]; //.split('Period :')[1]
        } else if (path.endsWith("$.WorkAll")) {
          return path.split("$.")[1];
        } else if (path.endsWith("$.ExpenseAll")) {
          return path.split("$.")[1];
        } else if (path.endsWith("$.TotalAll")) {
          return path.split("$.")[1];
        } else if (path.endsWith("$.client")) {
          return path.split(".$.")[1].split("$")[0];
        } else if (path.endsWith("$.toDate")) {
          let d = new Date(path.split("$.")[1]);
          return (
            "To: " +
            date_(d.getDate()) +
            " " +
            month_(d.getMonth()) +
            " " +
            d.getFullYear()
          );
        } else if (path.endsWith("$.case")) {
          return path.split("$.")[3].split("$")[0];
        } else if (path.endsWith("$.datebreak")) {
          return "---";
        } else if (path.endsWith("$.casebreak")) {
          return "---";
        } else if (path.endsWith("$.Work")) {
          return path.split("$.")[3];
        } else if (path.endsWith("$.Expense")) {
          return path.split("$.")[3];
        } else if (path.endsWith("$.Total")) {
          return path.split("$.")[3];
        } else if (path.endsWith("$.Work (This Case)")) {
          return path.split("$.")[5];
        } else if (path.endsWith("$.Expense (This Case)")) {
          return path.split("$.")[5];
        } else if (path.endsWith("$.Subtotal (This Case)")) {
          return path.split("$.")[5];
        } else if (path.endsWith("$.caseHeader")) {
          return "";
        } else if (path.endsWith("$.caseHeader2")) {
          return "";
        } else if (path.endsWith("$.activit0y")) {
          return "";
        } else if (path.endsWith("$.activity2")) {
          return path.split("$.")[7];
        } else if (path.endsWith("$.expens0e")) {
          return "";
        } else if (path.endsWith("$.expense2")) {
          return path.split("$.")[7];
        }
        return path;
      },
      cellStyle: function (params) {
        var path = params.data.filePath;
        if (path.endsWith("$.ClientAll")) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "16px",
            "font-weight": "800",
            color: "white",
            "background-color": "#757575",
          };
        } else if (path.endsWith("$.client")) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "16px",
            "font-weight": "800",
            color: "white",
            "background-color": "#757575",
          };
        } else if (
          path.endsWith("$.Period") ||
          path.endsWith("$.PeriodClient")
        ) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "14px",
            "font-weight": "600",
            color: "white",
            "background-color": "#757575",
          };
        } else if (path.endsWith("$.case")) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            color: "black",
            "font-size": "14px",
            "font-weight": "700",
            "background-color": "#cfcfcf",
          };
        } else if (path.endsWith("$.caseHeader2")) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            color: "black",
            "font-size": "14px",
            "font-weight": "500",
            "background-color": "#fff",
          };
        } else if (path.endsWith("$.datebreak")) {
          return {
            "text-align": "center",
          };
        } else if (path.endsWith("$.casebreak")) {
          return {
            "text-align": "center",
          };
        } else if (path.endsWith("$.TotalAll")) {
          return {
            "font-family": "Helvetica",
            "text-align": "left",
            "font-size": "13px",
            "font-weight": "900",
          }; // params.data.date
        } else if (path.endsWith("$.ExpenseAll")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "EXPENSE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "13px",
              "font-weight": "900",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.WorkAll")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "SERVICE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "13px",
              "font-weight": "900",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.Total")) {
          return {
            "font-family": "Helvetica",
            "text-align": "left",
            "font-size": "13px",
            "font-weight": "800",
          }; // params.data.date
        } else if (path.endsWith("$.Expense")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "EXPENSE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "13px",
              "font-weight": "800",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.Work")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "SERVICE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "13px",
              "font-weight": "800",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.Subtotal (This Case)")) {
          return {
            "font-family": "Helvetica",
            "text-align": "left",
            "font-weight": "600",
          };
        } else if (path.endsWith("$.Work (This Case)")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "SERVICE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "600",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.Expense (This Case)")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "EXPENSE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "600",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else {
          let b = {};
          if (printingDocument) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          } else if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "8px",
            };
          } else if (is360) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "9px",
            };
          } else if (is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "10px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          }
          return b;
        }
      },
    },
    {
      field: "id",
      hide: true,
    },
    {
      hide: true,
      headerName: "",
      field: "date",
      width: 0,
    },
    {
      hide: false,
      headerName: "Log Date",
      field: "datePrime",
      width: is320
        ? 95
        : is360
          ? 85
          : is375
            ? 85
            : is414
              ? 75
              : is480
                ? 75
                : is667
                  ? 75
                  : is896
                    ? pdfSimpleLayout
                      ? 150
                      : 80
                    : isDesktopOrLaptop
                      ? pdfSimpleLayout
                        ? 150
                        : 80
                      : 0,
      //tooltipComponentParams: { column: "date" },
      tooltip: function (params) {
        if (params.data.datePrime) {
          let dt = params.data.date;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Date"))
          ) {
            return "Date";
          }
        }
      },
      cellStyle: function (params) {
        if (params.data.date) {
          let dt = params.data.date;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Date"))
          ) {
            if (is320) {
              return {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "9px",
              };
            } else if (is360 || is375) {
              return {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "9px",
              };
            } else if (is480 || is414) {
              return {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "11px",
              };
            } else if (is667) {
              return {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else if (is896) {
              return {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else {
              return {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "13px",
              };
            }
          } else {
            if (printingDocument) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "12px",
              };
            } else if (is320) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "9px",
              };
            } else if (is360 || is375) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "10px",
              };
            } else if (is480 || is414) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "11px",
              };
            } else if (is667) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "12px",
              };
            } else if (is896) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "12px",
              };
            } else {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "12px",
              };
            }
          }
        }
      },
      valueFormatter: function (params) {
        let dt = params.data.date;
        if (dt) {
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Date"))
          ) {
            return dt;
          }

          if (is320) {
            return dt.toString().substring(4, 11);
          } else if (is360) {
            return dt.toString().substring(4, 11);
          } else if (is375) {
            return dt.toString().substring(4, 11);
          } else if (is375) {
            return dt.toString().substring(4, 11);
          } else if (is414) {
            return dt.toString().substring(4, 11);
          } else if (is480) {
            return dt.toString().substring(4, 11);
          } else if (is896) {
            return dt.toString().substring(4, 11);
          } else {
            return pdfSimpleLayout
              ? dt.toString().substring(4, 15)
              : dt.toString().substring(4, 11);
          }
        }
      },
    },
    {
      headerName: "Bill Type (desc)",
      hide: true,
      field: "billingTypeDesc",
      width: 200,
      cellStyle: { "text-align": "left" },
      headerTooltip: "Bill Type",
      tooltipField: "billingTypeDesc",
    },
    {
      headerName: "Bill Type",
      hide: pdfSimpleLayout ? true : false,
      field: "billingType",
      width: 76,
      cellStyle: { "text-align": "left" },
      headerTooltip: "Bill Type",
      // tooltipField: 'billingType',
      //tooltipComponentParams: { column: "billingType" },
      tooltip: function (params) {
        if (params.data.billingType) {
          let dt = params.data.billingType;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Bill"))
          ) {
            return "Bill Type";
          }
        }
      },
      valueFormatter: function (params) {
        const { billingType } = params.data;
        let dt = params.data.billingType;
        if (
          typeof dt === "string" &&
          ((dt.includes("(") && dt.includes(")")) || dt.includes("Bill"))
        ) {
          return dt;
        } else
          return (
            billingType.substring(0, 1) + billingType.substring(1).toLowerCase()
          );
      },
      cellStyle: function (params) {
        if (params.data.billingType) {
          let dt = params.data.billingType;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Bill"))
          ) {
            return {
              "text-align": "center",
              "font-family": "Helvetica",
              "font-size": "13px",
            };
          } else
            return {
              "text-align": "left",
              "font-family": "Helvetica",
              "font-size": "11px",
            };
        }
      },
    },
    {
      headerName: "Hourly Rate",
      width: 90,
      field: "hourlyRate",
      hide: pdfSimpleLayout ? true : false,
      cellStyle: function (params) {
        if (params.data.hourlyRate) {
          let dt = params.data.hourlyRate;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Rate"))
          ) {
            return {
              "text-align": "center",
              "font-family": "Helvetica",
              "font-size": "13px",
            };
          } else
            return billingType === "HOURLY"
              ? { "text-align": "right" }
              : { "text-align": "center" };
        }
      },
      //tooltipComponentParams: { column: "hourlyRate" },
      tooltip: function (params) {
        if (params.data.hourlyRate) {
          let dt = params.data.hourlyRate;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Rate"))
          ) {
            return "Rate";
          }
        }
      },
      valueFormatter: function (params) {
        const { hourlyRate, billingType } = params.data;
        if (
          (typeof hourlyRate === "string" &&
            (hourlyRate.includes("(") && hourlyRate.includes(")"))) ||
          hourlyRate.includes("Rate")
        )
          return hourlyRate;
        else
          return billingType === "HOURLY"
            ? "\u20b1" + formatNumber(hourlyRate)
            : "---";
      },
    },
    {
      headerName: "Duration (hrs)",
      hide: pdfSimpleLayout ? true : false,
      field: "hours",
      width: 60,
      headerTooltip: "hours",
      cellStyle: function (params) {
        if (params.data.hours) {
          let dt = params.data.hours;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Hours"))
          ) {
            return {
              "text-align": "center",
              "font-family": "Helvetica",
              "font-size": "13px",
            };
          } else
            return billingType === "HOURLY"
              ? { "text-align": "right" }
              : { "text-align": "center" };
        }
      },
      //tooltipComponentParams: { column: "hours" },
      tooltip: function (params) {
        if (params.data.hours) {
          let dt = params.data.hours;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Hours"))
          ) {
            return "Duration in hrs";
          }
        }
      },
      valueFormatter: function (params) {
        // const { value } = params
        const { hours, billingType, logType } = params.data;
        if (
          typeof hours === "string" &&
          ((hours.includes("(") && hours.includes(")")) ||
            hours.includes("Hours"))
        ) {
          return hours;
        }
        if (logType === "SERVICE_LOG") {
          if (billingType === "FIXED")
            return "---"
          else
            formatNumber(hours)
        } else {
          if (billingType !== "HOURLY")
            return "---"
          else
            formatNumber(hours)
        }
      },
    },

    {
      hide: pdfSimpleLayout ? true : false,
      headerName: "Start Time",
      field: "startTime",
      width: is320
        ? 95
        : is360
          ? 85
          : is375
            ? 85
            : is414
              ? 75
              : is480
                ? 75
                : is667
                  ? 75
                  : is896
                    ? 75
                    : isDesktopOrLaptop
                      ? 75
                      : 0,
      //tooltipComponentParams: { column: "startTime" },
      tooltip: function (params) {
        const { startTime, billingType } = params.data;
        if (
          typeof startTime === "string" &&
          ((startTime.includes("(") && startTime.includes(")")) ||
            startTime.includes("Start"))
        )
          return startTime;
      },
      cellStyle: function (params) {
        let b = {};
        const { startTime, billingType, logType } = params.data;
        if (
          typeof startTime === "string" &&
          ((startTime.includes("(") && startTime.includes(")")) ||
            startTime.includes("Start"))
        ) {
          b = {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "13px",
          };
        } else if (
          typeof startTime === "string" &&
          !(
            (startTime.includes("(") && startTime.includes(")")) ||
            startTime.includes("Start")
          )
        ) {
          if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "9px",
            };
          } else if (is360 || is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "10px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          }
        } else {
          if (
            billingType === "HOURLY" ||
            (billingType === "NO CHARGE" && logType === "SERVICE_LOG")
          ) {
            if (startTime) {
              if (startTime.toString().includes("1970")) {
                if (is320) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "9px",
                  };
                } else if (is360 || is375) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "10px",
                  };
                } else if (is480 || is414) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "11px",
                  };
                } else if (is667) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                } else if (is896) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                } else {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                }
              } else {
                if (is320) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "9px",
                  };
                } else if (is360 || is375) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "10px",
                  };
                } else if (is480 || is414) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "11px",
                  };
                } else if (is667) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                } else if (is896) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                } else {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                }
              }
            }
          } else {
            if (is320) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "9px",
              };
            } else if (is360 || is375) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "10px",
              };
            } else if (is480 || is414) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "11px",
              };
            } else if (is667) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else if (is896) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            }
          }
        }
        return b;
      },
      valueFormatter: function (params) {
        const { startTime, billingType, logType } = params.data;
        if (typeof startTime === "string") {
          if (
            (startTime.includes("(") && startTime.includes(")")) ||
            startTime.includes("Start")
          )
            return startTime;
          else return "---";
        } else {
          if (
            billingType === "HOURLY" ||
            (billingType === "NO CHARGE" && logType === "SERVICE_LOG")
          ) {
            if (startTime) {
              if (startTime.toString().includes("1970")) return "---";
              else {
                let t = startTime.toLocaleTimeString();
                return (
                  t.split(" ")[0].split(":")[0] +
                  ":" +
                  t.split(" ")[0].split(":")[1] +
                  t.split(" ")[1]
                );
              }
            }
          } else {
            return "---";
          }
        }
      }
    },

    {
      hide: pdfSimpleLayout ? true : false,
      headerName: "End Time",
      field: "endTime",
      width: is320
        ? 95
        : is360
          ? 85
          : is375
            ? 85
            : is414
              ? 75
              : is480
                ? 75
                : is667
                  ? 75
                  : is896
                    ? 75
                    : isDesktopOrLaptop
                      ? 75
                      : 0,
      //tooltipComponentParams: { column: "endTime" },
      tooltip: function (params) {
        const { endTime, billingType } = params.data;
        if (
          typeof endTime === "string" &&
          ((endTime.includes("(") && endTime.includes(")")) ||
            endTime.includes("End"))
        ) {
          return endTime;
        }
      },
      cellStyle: function (params) {
        let b = {};
        const { endTime, billingType, logType } = params.data;
        if (
          typeof endTime === "string" &&
          ((endTime.includes("(") && endTime.includes(")")) ||
            endTime.includes("End"))
        ) {
          b = {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "13px",
          };
        } else if (
          typeof endTime === "string" &&
          !(
            (endTime.includes("(") && endTime.includes(")")) ||
            endTime.includes("Start")
          )
        ) {
          if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "9px",
            };
          } else if (is360 || is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "10px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          }
        } else {
          if (
            billingType === "HOURLY" ||
            (billingType === "NO CHARGE" && logType === "SERVICE_LOG")
          ) {
            if (endTime) {
              if (endTime.toString().includes("1970")) {
                if (is320) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "9px",
                  };
                } else if (is360 || is375) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "10px",
                  };
                } else if (is480 || is414) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "11px",
                  };
                } else if (is667) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                } else if (is896) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                } else {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                }
              } else {
                if (is320) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "9px",
                  };
                } else if (is360 || is375) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "10px",
                  };
                } else if (is480 || is414) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "11px",
                  };
                } else if (is667) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                } else if (is896) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                } else {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                }
              }
            }
          } else {
            if (is320) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "9px",
              };
            } else if (is360 || is375) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "10px",
              };
            } else if (is480 || is414) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "11px",
              };
            } else if (is667) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else if (is896) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            }
          }
        }
        return b;
      },
      valueFormatter: function (params) {
        const { endTime, billingType, logType } = params.data;
        if (typeof endTime === "string") {
          if (
            (endTime.includes("(") && endTime.includes(")")) ||
            endTime.includes("End")
          )
            return endTime;
          else return "---";
        } else {
          if (
            billingType === "HOURLY" ||
            (billingType === "NO CHARGE" && logType === "SERVICE_LOG")
          ) {
            if (endTime) {
              if (endTime.toString().includes("1970")) return "---";
              else {
                let t = endTime.toLocaleTimeString();
                return (
                  t.split(" ")[0].split(":")[0] +
                  ":" +
                  t.split(" ")[0].split(":")[1] +
                  t.split(" ")[1]
                );
              }
            }
          } else {
            return "---";
          }
        }
      },
    },
    {
      headerName: "Notes",
      hide: true,
      field: "notes",
      width: 280,
      minWidth: 280,
      cellStyle: { "font-family": "Helvetica", "text-align": "left" },
      headerTooltip: "Notes",
      tooltipField: "notes",
    },
    {
      headerName: "Amount",
      field: "amount",
      width: is320
        ? 120
        : is360
          ? 105
          : is375
            ? 105
            : is414
              ? 105
              : is480
                ? 105
                : is667
                  ? 115
                  : is896
                    ? pdfSimpleLayout
                      ? 200
                      : 115
                    : isDesktopOrLaptop
                      ? pdfSimpleLayout
                        ? 200
                        : 115
                      : 0,
      headerTooltip: "Amount",
      //tooltipComponentParams: { column: "amount" },
      tooltip: function (params) {
        if (params.data.amount) {
          let dt = params.data.amount;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Amount"))
          ) {
            return dt;
          }
        }
      },
      valueFormatter: function (params) {
        const { amount, filePath } = params.data;
        if (
          typeof amount === "string" &&
          ((amount.includes("(") && amount.includes(")")) ||
            amount.includes("Amount"))
        )
          return amount;
        else
          return (filePath.endsWith("$.activity2") ||
            filePath.endsWith("$.expense2")) ?
            amount ? "\u20b1" + formatNumber(amount)
              : "---" : null
      },
      cellStyle: function (params) {
        let b = {};
        let dt = params.data.amount;
        if (
          typeof dt === "string" &&
          ((dt.includes("(") && dt.includes(")")) || dt.includes("Amount"))
        ) {
          if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "9px",
            };
          } else if (is360 || is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "9px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "13px",
            };
          }
        } else {
          if (printingDocument) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          } else if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "9px",
            };
          } else if (is360 || is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "9px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "12px",
            };
          }
        }
        return b;
      },
    },
    {
      headerName: "Totals",
      tooltip: function (params) {
        if (!formatNumber(params.data.editDelete) !== "NaN")
          return "Edit/Delete";
      },
      field: "editDelete",
      hide: false,
      width: is320
        ? 150
        : is360
          ? 140
          : is375
            ? 140
            : is414
              ? 120
              : is480
                ? 120
                : is667
                  ? 120
                  : is896
                    ? pdfSimpleLayout
                      ? 200
                      : 105
                    : isDesktopOrLaptop
                      ? pdfSimpleLayout
                        ? 200
                        : 105
                      : 200,

      cellRenderer: "ellipsisRenderer",
    },
  ];

  /////////////////////////////////////////////////////////////
  const columnDefsPrint = [
    {
      field: "filePath",
      hide: true,
      sort: "asc",
      width: 900,
    },
    {
      field: "label",
      headerName: "Sections and Labels",
      hide: false,
      width: printingDocument
        ? pdfSimpleLayout
          ? 300
          : 180

        : 0,
      colSpan: function (params) {
        var path = params.data.filePath;
        if (
          path.endsWith("$.client") ||
          path.endsWith("$.headerLegend") ||
          path.endsWith("$.ClientAll") ||
          path.endsWith("$.Period") ||
          path.endsWith("$.PeriodClient") ||
          path.endsWith("$.case") ||
          path.endsWith("$.datebreak") ||
          path.endsWith("$.casebreak")
        ) {
          return pdfSimpleLayout ? 4 : 9;
        } else if (
          path.endsWith("$.TotalAll") ||
          path.endsWith("$.WorkAll") ||
          path.endsWith("$.ExpenseAll") ||
          path.endsWith("$.Total") ||
          path.endsWith("$.Subtotal (This Case)") ||
          path.endsWith("$.Work (This Case)") ||
          path.endsWith("$.Expense (This Case)") ||
          path.endsWith("$.Expense") ||
          path.endsWith("$.Work")
        ) {
          return pdfSimpleLayout ? 3 : 8;
        } else {
          return 1;
        }
      },
      valueFormatter: function (params) {
        var path = params.data.filePath;

        if (path.endsWith("$.ClientAll")) {
          return path.split("$.")[1];
        } else if (path.endsWith("$.headerLegend")) {
          return ""; //Legend: (1) - Date     (2) - Bill Type       (3) - Rate       (4) - Duration in hrs       (5) - Start Time       (6) - End Time       (7) - Amount'
        } else if (path.endsWith("$.PeriodClient")) {
          return path.split("$.")[3]; //.split('Period :')[1]
        } else if (path.endsWith("$.Period")) {
          return path.split("$.")[1]; //.split('Period :')[1]
        } else if (path.endsWith("$.WorkAll")) {
          return path.split("$.")[1];
        } else if (path.endsWith("$.ExpenseAll")) {
          return path.split("$.")[1];
        } else if (path.endsWith("$.TotalAll")) {
          return path.split("$.")[1];
        } else if (path.endsWith("$.client")) {
          return path.split(".$.")[1].split("$")[0];
        } else if (path.endsWith("$.toDate")) {
          let d = new Date(path.split("$.")[1]);
          return (
            "To: " +
            date_(d.getDate()) +
            " " +
            month_(d.getMonth()) +
            " " +
            d.getFullYear()
          );
        } else if (path.endsWith("$.case")) {
          return path.split("$.")[3].split("$")[0];
        } else if (path.endsWith("$.datebreak")) {
          return "---";
        } else if (path.endsWith("$.casebreak")) {
          return "---";
        } else if (path.endsWith("$.Work")) {
          return path.split("$.")[3];
        } else if (path.endsWith("$.Expense")) {
          return path.split("$.")[3];
        } else if (path.endsWith("$.Total")) {
          return path.split("$.")[3];
        } else if (path.endsWith("$.Work (This Case)")) {
          return path.split("$.")[5];
        } else if (path.endsWith("$.Expense (This Case)")) {
          return path.split("$.")[5];
        } else if (path.endsWith("$.Subtotal (This Case)")) {
          return path.split("$.")[5];
        } else if (path.endsWith("$.caseHeader")) {
          return "";
        } else if (path.endsWith("$.caseHeader2")) {
          return "";
        } else if (path.endsWith("$.activit0y")) {
          return "";
        } else if (path.endsWith("$.activity2")) {
          return path.split("$.")[7];
        } else if (path.endsWith("$.expens0e")) {
          return "";
        } else if (path.endsWith("$.expense2")) {
          return path.split("$.")[7];
        }
        return path;
      },
      cellStyle: function (params) {
        var path = params.data.filePath;
        if (path.endsWith("$.ClientAll")) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "16px",
            "font-weight": "800",
            color: "white",
            "background-color": "#757575",
          };
        } else if (path.endsWith("$.client")) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "16px",
            "font-weight": "800",
            color: "white",
            "background-color": "#757575",
          };
        } else if (
          path.endsWith("$.Period") ||
          path.endsWith("$.PeriodClient")
        ) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "14px",
            "font-weight": "600",
            color: "white",
            "background-color": "#757575",
          };
        } else if (path.endsWith("$.case")) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            color: "black",
            "font-size": "14px",
            "font-weight": "700",
            "background-color": "#cfcfcf",
          };
        } else if (path.endsWith("$.caseHeader2")) {
          return {
            "font-family": "Helvetica",
            "text-align": "center",
            color: "black",
            "font-size": "14px",
            "font-weight": "500",
            "background-color": "#fff",
          };
        } else if (path.endsWith("$.datebreak")) {
          return {
            "text-align": "center",
          };
        } else if (path.endsWith("$.casebreak")) {
          return {
            "text-align": "center",
          };
        } else if (path.endsWith("$.TotalAll")) {
          return {
            "font-family": "Helvetica",
            "text-align": "left",
            "font-size": "13px",
            "font-weight": "900",
          }; // params.data.date
        } else if (path.endsWith("$.ExpenseAll")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "EXPENSE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "13px",
              "font-weight": "900",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.WorkAll")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "SERVICE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "13px",
              "font-weight": "900",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.Total")) {
          return {
            "font-family": "Helvetica",
            "text-align": "left",
            "font-size": "13px",
            "font-weight": "800",
          }; // params.data.date
        } else if (path.endsWith("$.Expense")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "EXPENSE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "13px",
              "font-weight": "800",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.Work")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "SERVICE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "13px",
              "font-weight": "800",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.Subtotal (This Case)")) {
          return {
            "font-family": "Helvetica",
            "text-align": "left",
            "font-weight": "600",
          };
        } else if (path.endsWith("$.Work (This Case)")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "SERVICE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "600",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else if (path.endsWith("$.Expense (This Case)")) {
          if (
            formRef.current.values.logType === "-1" ||
            formRef.current.values.logType === "EXPENSE_LOG"
          )
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "600",
            };
          else
            return {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-weight": "100",
              color: "white",
            };
        } else {
          let b = {};
          if (printingDocument) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          } else if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "8px",
            };
          } else if (is360) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "9px",
            };
          } else if (is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "10px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "left",
              "font-size": "12px",
            };
          }
          return b;
        }
      },
    },
    {
      field: "id",
      hide: true,
    },
    {
      hide: true,
      headerName: "",
      field: "date",
      width: 0,
    },
    {
      hide: false,
      headerName: "Log Date",
      field: "datePrime",
      width: printingDocument
        ? pdfSimpleLayout
          ? 150
          : 80

        : 0,
      //tooltipComponentParams: { column: "date" },
      tooltip: function (params) {
        if (params.data.datePrime) {
          let dt = params.data.date;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Date"))
          ) {
            return "Date";
          }
        }
      },
      cellStyle: function (params) {
        if (params.data.date) {
          let dt = params.data.date;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Date"))
          ) {

            {
              return {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "13px",
              };
            }
          } else {
            if (printingDocument) {
              return {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "13px",
              };
            } else if (is320) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "9px",
              };
            } else if (is360 || is375) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "10px",
              };
            } else if (is480 || is414) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "11px",
              };
            } else if (is667) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "12px",
              };
            } else if (is896) {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "12px",
              };
            } else {
              return {
                "font-family": "Helvetica",
                "text-align": "right",
                "font-size": "12px",
              };
            }
          }
        }
      },
      valueFormatter: function (params) {
        let dt = params.data.date;
        if (dt) {
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Date"))
          ) {
            return dt;


          } else {
            return pdfSimpleLayout
              ? dt.toString().substring(4, 15)
              : dt.toString().substring(4, 11);
          }
        }
      },
    },
    {
      headerName: "Bill Type (desc)",
      hide: true,
      field: "billingTypeDesc",
      width: 200,
      cellStyle: { "text-align": "left" },
      headerTooltip: "Bill Type",
      tooltipField: "billingTypeDesc",
    },
    {
      headerName: "Bill Type",
      hide: pdfSimpleLayout ? true : false,
      field: "billingType",
      width: 76,
      cellStyle: { "text-align": "left" },
      headerTooltip: "Bill Type",
      // tooltipField: 'billingType',
      //tooltipComponentParams: { column: "billingType" },
      tooltip: function (params) {
        if (params.data.billingType) {
          let dt = params.data.billingType;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Bill"))
          ) {
            return "Bill Type";
          }
        }
      },
      valueFormatter: function (params) {
        const { billingType } = params.data;
        let dt = params.data.billingType;
        if (
          typeof dt === "string" &&
          ((dt.includes("(") && dt.includes(")")) || dt.includes("Bill"))
        ) {
          return dt;
        } else
          return (
            billingType.substring(0, 1) + billingType.substring(1).toLowerCase()
          );
      },
      cellStyle: function (params) {
        if (params.data.billingType) {
          let dt = params.data.billingType;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Bill"))
          ) {
            return {
              "text-align": "center",
              "font-family": "Helvetica",
              "font-size": "13px",
            };
          } else
            return {
              "text-align": "left",
              "font-family": "Helvetica",
              "font-size": "11px",
            };
        }
      },
    },
    {
      headerName: "Hourly Rate",
      width: 90,
      field: "hourlyRate",
      hide: pdfSimpleLayout ? true : false,
      cellStyle: function (params) {
        if (params.data.hourlyRate) {
          let dt = params.data.hourlyRate;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Rate"))
          ) {
            return {
              "text-align": "center",
              "font-family": "Helvetica",
              "font-size": "13px",
            };
          } else
            return billingType === "HOURLY"
              ? { "text-align": "right" }
              : { "text-align": "center" };
        }
      },
      //tooltipComponentParams: { column: "hourlyRate" },
      tooltip: function (params) {
        if (params.data.hourlyRate) {
          let dt = params.data.hourlyRate;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Rate"))
          ) {
            return "Rate";
          }
        }
      },
      valueFormatter: function (params) {
        const { hourlyRate, billingType } = params.data;
        if (
          (typeof hourlyRate === "string" &&
            (hourlyRate.includes("(") && hourlyRate.includes(")"))) ||
          hourlyRate.includes("Rate")
        )
          return hourlyRate;
        else
          return billingType === "HOURLY"
            ? "\u20b1" + formatNumber(hourlyRate)
            : "---";
      },
    },
    {
      headerName: "Duration (hrs)",
      hide: pdfSimpleLayout ? true : false,
      field: "hours",
      width: 60,
      headerTooltip: "hours",
      cellStyle: function (params) {
        if (params.data.hours) {
          let dt = params.data.hours;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Hours"))
          ) {
            return {
              "text-align": "center",
              "font-family": "Helvetica",
              "font-size": "13px",
            };
          } else
            return billingType === "HOURLY"
              ? { "text-align": "right" }
              : { "text-align": "center" };
        }
      },
      //tooltipComponentParams: { column: "hours" },
      tooltip: function (params) {
        if (params.data.hours) {
          let dt = params.data.hours;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Hours"))
          ) {
            return "Duration in hrs";
          }
        }
      },
      valueFormatter: function (params) {
        // const { value } = params
        const { hours, billingType, logType } = params.data;
        if (
          typeof hours === "string" &&
          ((hours.includes("(") && hours.includes(")")) ||
            hours.includes("Hours"))
        ) {
          return hours;
        }
        if (logType === "SERVICE_LOG") {
          if (billingType === "FIXED")
            return "---"
          else
            formatNumber(hours)
        } else {
          if (billingType !== "HOURLY")
            return "---"
          else
            formatNumber(hours)
        }

      },
    },

    {
      hide: pdfSimpleLayout ? true : false,
      headerName: "Start Time",
      field: "startTime",
      width: printingDocument
        ? 75
        : is320
          ? 95
          : is360
            ? 85
            : is375
              ? 85
              : is414
                ? 75
                : is480
                  ? 75
                  : is667
                    ? 75
                    : is896
                      ? 75
                      : isDesktopOrLaptop
                        ? 75
                        : 0,
      //tooltipComponentParams: { column: "startTime" },
      tooltip: function (params) {
        const { startTime, billingType } = params.data;
        if (
          typeof startTime === "string" &&
          ((startTime.includes("(") && startTime.includes(")")) ||
            startTime.includes("Start"))
        )
          return startTime;
      },
      cellStyle: function (params) {
        let b = {};
        const { startTime, billingType, logType } = params.data;
        if (
          typeof startTime === "string" &&
          ((startTime.includes("(") && startTime.includes(")")) ||
            startTime.includes("Start"))
        ) {
          b = {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "13px",
          };
        } else if (
          typeof startTime === "string" &&
          !(
            (startTime.includes("(") && startTime.includes(")")) ||
            startTime.includes("Start")
          )
        ) {
          if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "9px",
            };
          } else if (is360 || is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "10px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          }
        } else {
          if (
            billingType === "HOURLY" ||
            (billingType === "NO CHARGE" && logType === "SERVICE_LOG")
          ) {
            if (startTime) {
              if (startTime.toString().includes("1970")) {
                if (is320) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "9px",
                  };
                } else if (is360 || is375) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "10px",
                  };
                } else if (is480 || is414) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "11px",
                  };
                } else if (is667) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                } else if (is896) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                } else {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                }
              } else {
                if (is320) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "9px",
                  };
                } else if (is360 || is375) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "10px",
                  };
                } else if (is480 || is414) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "11px",
                  };
                } else if (is667) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                } else if (is896) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                } else {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                }
              }
            }
          } else {
            if (is320) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "9px",
              };
            } else if (is360 || is375) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "10px",
              };
            } else if (is480 || is414) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "11px",
              };
            } else if (is667) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else if (is896) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            }
          }
        }
        return b;
      },
      valueFormatter: function (params) {
        const { startTime, billingType, logType } = params.data;
        if (typeof startTime === "string") {
          if (
            (startTime.includes("(") && startTime.includes(")")) ||
            startTime.includes("Start")
          )
            return startTime;
          else return "---";
        } else {
          if (
            billingType === "HOURLY" ||
            (billingType === "NO CHARGE" && logType === "SERVICE_LOG")
          ) {
            if (startTime) {
              if (startTime.toString().includes("1970")) return "---";
              else {
                let t = startTime.toLocaleTimeString();
                return (
                  t.split(" ")[0].split(":")[0] +
                  ":" +
                  t.split(" ")[0].split(":")[1] +
                  t.split(" ")[1]
                );
              }
            }
          } else {
            return "---";
          }
        }
      },
    },

    {
      hide: pdfSimpleLayout ? true : false,
      headerName: "End Time",
      field: "endTime",
      width: printingDocument
        ? 75
        : is320
          ? 95
          : is360
            ? 85
            : is375
              ? 85
              : is414
                ? 75
                : is480
                  ? 75
                  : is667
                    ? 75
                    : is896
                      ? 75
                      : isDesktopOrLaptop
                        ? 75
                        : 0,
      //tooltipComponentParams: { column: "endTime" },
      tooltip: function (params) {
        const { endTime, billingType } = params.data;
        if (
          typeof endTime === "string" &&
          ((endTime.includes("(") && endTime.includes(")")) ||
            endTime.includes("End"))
        ) {
          return endTime;
        }
      },
      cellStyle: function (params) {
        let b = {};
        const { endTime, billingType, logType } = params.data;
        if (
          typeof endTime === "string" &&
          ((endTime.includes("(") && endTime.includes(")")) ||
            endTime.includes("End"))
        ) {
          b = {
            "font-family": "Helvetica",
            "text-align": "center",
            "font-size": "13px",
          };
        } else if (
          typeof endTime === "string" &&
          !(
            (endTime.includes("(") && endTime.includes(")")) ||
            endTime.includes("Start")
          )
        ) {
          if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "9px",
            };
          } else if (is360 || is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "10px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "center",
              "font-size": "12px",
            };
          }
        } else {
          if (
            billingType === "HOURLY" ||
            (billingType === "NO CHARGE" && logType === "SERVICE_LOG")
          ) {
            if (endTime) {
              if (endTime.toString().includes("1970")) {
                if (is320) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "9px",
                  };
                } else if (is360 || is375) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "10px",
                  };
                } else if (is480 || is414) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "11px",
                  };
                } else if (is667) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                } else if (is896) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                } else {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "center",
                    "font-size": "12px",
                  };
                }
              } else {
                if (is320) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "9px",
                  };
                } else if (is360 || is375) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "10px",
                  };
                } else if (is480 || is414) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "11px",
                  };
                } else if (is667) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                } else if (is896) {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                } else {
                  b = {
                    "font-family": "Helvetica",
                    "text-align": "right",
                    "font-size": "12px",
                  };
                }
              }
            }
          } else {
            if (is320) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "9px",
              };
            } else if (is360 || is375) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "10px",
              };
            } else if (is480 || is414) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "11px",
              };
            } else if (is667) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else if (is896) {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            } else {
              b = {
                "font-family": "Helvetica",
                "text-align": "center",
                "font-size": "12px",
              };
            }
          }
        }
        return b;
      },
      valueFormatter: function (params) {
        const { endTime, billingType, logType } = params.data;
        if (typeof endTime === "string") {
          if (
            (endTime.includes("(") && endTime.includes(")")) ||
            endTime.includes("End")
          )
            return endTime;
          else return "---";
        } else {
          if (
            billingType === "HOURLY" ||
            (billingType === "NO CHARGE" && logType === "SERVICE_LOG")
          ) {
            if (endTime) {
              if (endTime.toString().includes("1970")) return "---";
              else {
                let t = endTime.toLocaleTimeString();
                return (
                  t.split(" ")[0].split(":")[0] +
                  ":" +
                  t.split(" ")[0].split(":")[1] +
                  t.split(" ")[1]
                );
              }
            }
          } else {
            return "---";
          }
        }
      },
    },
    {
      headerName: "Notes",
      hide: true,
      field: "notes",
      width: 280,
      minWidth: 280,
      cellStyle: { "font-family": "Helvetica", "text-align": "left" },
      headerTooltip: "Notes",
      tooltipField: "notes",
    },
    {
      headerName: "Amount",
      field: "amount",
      width: printingDocument
        ? pdfSimpleLayout
          ? 200
          : 115

        : 0,
      headerTooltip: "Amount",
      //tooltipComponentParams: { column: "amount" },
      tooltip: function (params) {
        if (params.data.amount) {
          let dt = params.data.amount;
          if (
            typeof dt === "string" &&
            ((dt.includes("(") && dt.includes(")")) || dt.includes("Amount"))
          ) {
            return dt;
          }
        }
      },
      valueFormatter: function (params) {
        const { amount, filePath } = params.data;
        if (
          typeof amount === "string" &&
          ((amount.includes("(") && amount.includes(")")) ||
            amount.includes("Amount"))
        )
          return amount;
        else
          return (filePath.endsWith("$.activity2") ||
            filePath.endsWith("$.expense2")) ?
            amount ? "\u20b1" + formatNumber(amount)
              : "---" : null
      },
      cellStyle: function (params) {
        let b = {};
        let dt = params.data.amount;
        if (
          typeof dt === "string" &&
          ((dt.includes("(") && dt.includes(")")) || dt.includes("Amount"))
        ) {

          {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "13px",
            };
          }
        } else {
          if (printingDocument) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "13px",
            };
          } else if (is320) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "9px",
            };
          } else if (is360 || is375) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "9px",
            };
          } else if (is480 || is414) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "11px",
            };
          } else if (is667) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "12px",
            };
          } else if (is896) {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "12px",
            };
          } else {
            b = {
              "font-family": "Helvetica",
              "text-align": "right",
              "font-size": "12px",
            };
          }
        }
        return b;
      },
    },
    {
      headerName: "Totals",
      tooltip: function (params) {
        if (!formatNumber(params.data.editDelete) !== "NaN")
          return "Edit/Delete";
      },
      field: "editDelete",
      hide: false,
      width: printingDocument
        ? pdfSimpleLayout
          ? 200
          : 105

        : 200,

      cellRenderer: "ellipsisRenderer",
    },
  ];

  const [clientArray, setClientArray] = useState([]);
  const [caseArray, setCaseArray] = useState([]);

  const contextAssignment = {
    componentParent: {
      formReference() {
        return formRef.current;
      },

      updateClient(rowNode) {
        debugger;
        setInitialValues_clientx({
          firstName: rowNode.firstName,
          lastName: rowNode.lastName,
          company: rowNode.company,
        });
        setMemberId(rowNode.memberId);
        setClientId(rowNode.clientId);
        setParentId(rowNode.parentId);
        setOrd(rowNode.ord);
        setStatus(rowNode.status);
        setOpenClient(true);
        setScroll("body");
      },

      updateCase(rowNode) {
        debugger;
        setInitialValues_case({
          caseCode: rowNode.caseCode,
          caseTitle: rowNode.caseTitle,
        });
        setMemberId(rowNode.memberId);
        setClientId(rowNode.clientId);
        setCaseId(rowNode.caseId);
        setParentId(rowNode.parentId);
        setOrd(rowNode.ord);
        setStatus(rowNode.status);
        setCompany(rowNode.company);
        setOpenCase(true);
        setScroll("body");
        setParamClient({
          id: rowNode.clientId,
          firstName: rowNode.firstName,
          lastName: rowNode.lastName,
          company: rowNode.company,
        });
      },

      deleteLog(rowNode) {
        console.log("deleteLog rowNode");
        console.log(rowNode);
        debugger;
        if (rowNode.logType === "EXPENSE_LOG") {
          setScroll("body");
          delete_expenseLogId = rowNode.id;
        } else {
          setScroll("body");
          delete_serviceLogId = rowNode.id;
        }
      },
      updateLog(rowNode) {
        debugger;
        let s = rowNode.startTime === null ? "" : rowNode.startTime;
        let e = rowNode.endTime === null ? "" : rowNode.endTime;
        if (rowNode.logType === "EXPENSE_LOG") {
          setInitialValues_expense({
            amount: formatMoney(formatNumber_(rowNode.amount)),
            expense: rowNode.expenselogType,
            notes: rowNode.notes,
            startTime:
              rowNode.startTime === ""
                ? ""
                : normalizeMinutes(rowNode.startTime),
            endTime:
              rowNode.endTime === "" ? "" : normalizeMinutes(rowNode.endTime),
            elapsed: 0,
            hours: rowNode.hours,
            billingType: rowNode.billingType,
            fixedRate:
              rowNode.billingType === "FIXED"
                ? formatMoney(formatNumber_(rowNode.fixedRate))
                : "",
            hourlyRate:
              rowNode.billingType === "HOURLY"
                ? formatMoney(formatNumber_(rowNode.hourlyRate))
                : formatMoney(formatNumber_(rowNode.hourlyRateExpense)),
            ord: rowNode.ord,
            date: rowNode.date,
            parentId: rowNode.parentId,
            durationHour: '',
            durationMinutes: ''
          });
          setWorkLogId(rowNode.id);
          setMemberId(rowNode.memberId);
          setClientId(rowNode.clientId);
          setCaseId(rowNode.caseId);
          setParentId(rowNode.parentId);
          setOrd(rowNode.ord);
          setStatus(rowNode.status);
          setBillingTypeExpense(rowNode.billingType);
          setOpenUpdateExpenseLog(true);
          setScroll("body");
          setDelete_id(rowNode.id);
          delete_expenseLogId = rowNode.id;
        } else {
          debugger;
          let friend = {
            amount: rowNode.amount,
            activityL1: appendGreaterThanToL1(rowNode.activityLogType),
            activityL2: appendGreaterThanToL2(
              rowNode.activityLogType,
              rowNode.activityLogSubType
            ),
            activityL3: rowNode.activityLogSubSubType,
            activity1Options: activity1Options,
            activity2Options: [],
            activity3Options: [],
            L2: [""],
            L3: [""],
            notes: rowNode.notes,
            startTime:
              rowNode.startTime === ""
                ? ""
                : normalizeMinutes(rowNode.startTime),
            endTime:
              rowNode.endTime === "" ? "" : normalizeMinutes(rowNode.endTime),
            elapsed: 0,
            hours: rowNode.hours,
            billingType: rowNode.billingType,
            fixedRate: rowNode.billingType === "FIXED" ? rowNode.fixedRate : "",
            hourlyRate:
              rowNode.billingType === "HOURLY"
                ? rowNode.hourlyRate
                : rowNode.hourlyRateService,
            ord: rowNode.ord,
            date: rowNode.date,
            parentId: rowNode.parentId,
            durationHour: '',
            durationMinutes: ''
          };
          populateCategories(friend, null, friend.activityL1);
          debugger;
          setInitialValues_service({
            amount: formatMoney(formatNumber_(friend.amount)),
            activityL1: friend.activityL1,
            activityL2: friend.activityL2,
            activityL3: friend.activityL3,
            activity1Options: activity1Options,
            activity2Options: friend.activity2Options,
            activity3Options: friend.activity3Options,
            L2: friend.L2,
            L3: friend.L3,
            notes: friend.notes,
            startTime: friend.startTime,
            endTime: friend.endTime,
            elapsed: friend.elapsed,
            hours: friend.hours,
            billingType: friend.billingType,
            fixedRate:
              friend.fixedRate.length > 0
                ? formatMoney(formatNumber_(friend.fixedRate))
                : "",
            hourlyRate:
              friend.hourlyRate.length > 0
                ? formatMoney(formatNumber_(friend.hourlyRate))
                : "",
            ord: friend.ord,
            date: friend.date,
            parentId: friend.parentId,
            durationHour: '',
            durationMinutes: ''
          });
          console.log("friend.fixedRate");
          console.log(
            friend.fixedRate.length > 0
              ? formatMoney(formatNumber_(friend.fixedRate))
              : ""
          );
          debugger;
          setWorkLogId(rowNode.id);
          setMemberId(rowNode.memberId);
          setClientId(rowNode.clientId);
          setCaseId(rowNode.caseId);
          setParentId(rowNode.parentId);
          setOrd(rowNode.ord);
          setStatus(rowNode.status);
          setBillingTypeService(rowNode.billingType);
          setOpenUpdateServiceLog(true);
          setScroll("body");
          setDelete_id(rowNode.id);
          delete_serviceLogId = rowNode.id;
        }
      },
    },
  };
  const checkBoxTheme = createMuiTheme({
    root: {
      "&$checked": {
        color: "#C62626",
      },
    },
    checked: {},
  });

  function handleCloseServiceLog() {
    setOpenServiceLog(false);
  }

  function handleCloseLog(e) {
    e.preventDefault()
    setOpenLog(false);
  }
  function handleOpenLog() {
    setOpenLog(true);
    setOpenLogHasBeenOpened(true);
  }

  const setServiceActivities = () => {
    let activityOptions = [];
    activitiesSource.map((item) => {
      activityOptions.push({
        value: item.name,
        label: item.name,
      });
    });
    debugger;
    setActivity1Options(activityOptions);
  };

  function fetchClientsforSelect2() {
    console.log("start --------------------fetchClientsforSelect");

    const { client } = props;
    const { authUser } = props;
    debugger;
    const { email } = authUser;
    client.resetStore().then(() => {
      client
        .query({
          query: CLIENTS_ONLY_BY_EMAIL_QUERY,
          variables: {
            email,
          },
        })
        .then((response) => {
          const data = response.data;
          const { clientsByEmail } = data;
          console.log("clientsByEmail");
          console.log(clientsByEmail);

          let clients = clientsByEmail
            .filter((a) => a.status === "ACTIVE")
            .sort(function (a, b) {
              let namea = a.lastName + a.firstName + a.company;
              let nameb = b.lastName + b.firstName + b.company;
              return namea.localeCompare(nameb);
            });
          let clientSize = clientsByEmail.filter((a) => a.status === "ACTIVE")
            .length;

          if (clientSize === 0) {
            setIsOpenWaiting(false);
            refreshGridNotification = true;
            notificationMessage = "NO CLIENT ENROLLED.";
            setMessageOnMessageDialog(notificationMessage);
            setOpenMessageSystemDialog(true);
          } else {
            // if (clientSize > 1) {

            if (clients !== undefined) {
              let nClients = clientsByEmail.filter((a) => a.status === "ACTIVE")
                .length;
              if (nClients === 1) {
                let casesArray = [];

                clientsByEmail
                  .filter((a) => a.status === "ACTIVE")
                  .map((__client, ix) => {
                    let x = __client.cases.filter(
                      (cs) => cs.status === "ACTIVE"
                    ).length;

                    if (x === 0) {
                      formRef.current.values.case = "-1";
                      setCurrentCase("-1");
                      formRef.current.values.caseArray = [
                        {
                          value: "-1",
                          label: "ALL",
                        },
                      ];
                      setCaseArray([
                        {
                          value: "-1",
                          label: "ALL",
                        },
                      ]);
                      formRef.current.values.client = "-1";
                      setCurrentClient("-1");
                      document.getElementById("logTypeFilter").style.display =
                        "none";
                      document.getElementById("logTypeFilter1").style.display =
                        "block";
                      document.getElementById("periodFilter").style.display =
                        "none";
                      document.getElementById("periodFilter1").style.display =
                        "block";
                      setIsOpenWaiting(false);
                      refreshGridNotification = true;
                      notificationMessage =
                        "No case for Client " + getClientLabel(client);
                      setMessageOnMessageDialog(notificationMessage);
                      setOpenMessageDialog(true);
                    } else if (x === 1) {
                      let case_ = [];
                      __client.cases.map((__case, indx) => {
                        if (__case.status === "ACTIVE") {
                          case_ = [
                            {
                              value: __case.id,
                              label:
                                __case.caseCode && __case.caseTitle
                                  ? __case.caseCode + " - " + __case.caseTitle
                                  : __case.caseCode && !__case.caseTitle
                                    ? __case.caseCode
                                    : !__case.caseCode && __case.caseTitle
                                      ? __case.caseTitle
                                      : "",
                            },
                          ];
                          casesArray.push({
                            value: __case.id,
                            label:
                              __case.caseCode && __case.caseTitle
                                ? __case.caseCode + " - " + __case.caseTitle
                                : __case.caseCode && !__case.caseTitle
                                  ? __case.caseCode
                                  : !__case.caseCode && __case.caseTitle
                                    ? __case.caseTitle
                                    : "",
                          });
                        }
                      });
                      formRef.current.values.client = client;
                      setCurrentClient(client);
                      formRef.current.values.caseArray = casesArray;
                      setCaseArray(casesArray);
                      formRef.current.values.case = case_;
                      setCurrentCase(case_);
                      document.getElementById("caseFilter").style.display =
                        "block";
                    } else {
                      casesArray.push({
                        value: "-1",
                        label: "ALL",
                      });
                      __client.cases
                        .sort(function (a, b) {
                          let namea = a.caseCode + a.caseTitle;
                          let nameb = b.caseCode + b.caseTitle;
                          return namea.localeCompare(nameb);
                        })
                        .map((__case, indx) => {
                          if (__case.status === "ACTIVE") {
                            casesArray.push({
                              value: __case.id,
                              label:
                                __case.caseCode && __case.caseTitle
                                  ? __case.caseCode + " - " + __case.caseTitle
                                  : __case.caseCode && !__case.caseTitle
                                    ? __case.caseCode
                                    : !__case.caseCode && __case.caseTitle
                                      ? __case.caseTitle
                                      : "",
                            });
                          }
                        });
                      formRef.current.values.client = client;
                      setCurrentClient(client);
                      formRef.current.values.case = "-1";
                      setCurrentCase("-1");
                      formRef.current.values.caseArray = casesArray;
                      setCaseArray(casesArray);
                      document.getElementById("caseFilter").style.display =
                        "block";
                    }
                  });
              } else {
                //nClients > 1
                let clientsArray = [];
                clientsArray.push({
                  value: "-1",
                  label: "ALL",
                });

                clientsByEmail
                  .filter((a) => a.status === "ACTIVE")
                  .sort(function (a, b) {
                    let namea = a.lastName + a.firstName + a.company;
                    let nameb = b.lastName + b.firstName + b.company;
                    return namea.localeCompare(nameb);
                  })
                  .map((__client, ix) => {
                    if (__client.status === "ACTIVE") {
                      // if (__client.cases.filter(a => (a.status === "ACTIVE")).length > 0) {
                      clientsArray.push({
                        value: __client.id,
                        label: clientOnGrid(__client),
                        // (
                        //   __client.company ?
                        //     __client.company :
                        //     (
                        //       __client.firstName && __client.lastName ?
                        //         __client.firstName + ' ' + __client.lastName :
                        //         (
                        //           !__client.firstName ? __client.lastName : __client.firstName
                        //         )
                        //     )
                        // )
                      });
                    }
                  });
                console.log("clientsArray");
                console.log(clientsArray);
                setClientArray(clientsArray);
              }
            }
            setIsOpenWaiting(false);
            document.getElementById("waiting").style.display = "none";
            document.getElementById("mainScreen").style.opacity = 1;

            console.log("end --------------------fetchClientsforSelect");
          }
          setClientsByEmail(clients);
        });
    });
  }

  function fetchClientsforSelect() {
    const { client } = props;
    const { authUser } = props;
    debugger;
    const { email } = authUser;
    client.resetStore().then(() => {
      checkDeleteRequest()
      client
        .query({
          query: CLIENTS_ONLY_BY_EMAIL_QUERY,
          variables: {
            email,
          },
        })
        .then((response) => {
          const data = response.data;
          const { clientsByEmail } = data;
          setClientsByEmail(
            clientsByEmail
              .filter((a) => a.status === "ACTIVE")
              .sort(function (a, b) {
                let namea = a.lastName + a.firstName + a.company;
                let nameb = b.lastName + b.firstName + b.company;
                return namea.localeCompare(nameb);
              })
          );
          let clientSize = clientsByEmail.filter((a) => a.status === "ACTIVE")
            .length;

          if (clientSize === 0) {
            setIsOpenWaiting(false);
            refreshGridNotification = true;
            notificationMessage = "NO CLIENT ENROLLED.";
            setMessageOnMessageDialog(notificationMessage);
            setDashboardOnClose(true);
            setOpenMessageDialog(true);
          } else if (clientSize === 1) {
            let clientsArray = [];
            let clientLabel = null;
            clientsByEmail
              .filter((a) => a.status === "ACTIVE")
              .map((__client, ix) => {
                clientsArray.push({
                  value: __client.id,
                  label: clientOnGrid(__client),
                });
                clientLabel = clientOnGrid(__client);
              });
            setClientArray(clientsArray);
            formRef.current.values.client = clientsArray[0].value;
            setCurrentClient(clientsArray[0].value);

            let casesArray = [];

            clientsByEmail
              .filter((a) => a.status === "ACTIVE")
              .map((__client, ix) => {
                let hasLogs_ = false;
                if (
                  __client.cases.filter((a) => a.status === "ACTIVE").length > 0
                ) {
                  __client.cases
                    .filter((a) => a.status === "ACTIVE")
                    .map((__case, indx) => {
                      if (hasLogs(__case)) {
                        hasLogs_ = true;
                      }
                    });
                }

                let x = __client.cases.filter((cs) => cs.status === "ACTIVE")
                  .length;

                if (x === 0) {
                  formRef.current.values.case = "-1";
                  setCurrentCase("-1");
                  formRef.current.values.caseArray = [
                    {
                      value: "-1",
                      label: "ALL",
                    },
                  ];
                  setCaseArray([
                    {
                      value: "-1",
                      label: "ALL",
                    },
                  ]);
                  formRef.current.values.client = "-1";
                  setCurrentClient("-1");
                  setIsOpenWaiting(false);
                  refreshGridNotification = true;
                  notificationMessage = "No case for Client " + clientLabel;
                  setMessageOnMessageDialog(notificationMessage);
                  setDashboardOnClose(true);
                  setOpenMessageDialog(true);
                } else if (x === 1) {
                  __client.cases.map((__case, indx) => {
                    if (__case.status === "ACTIVE") {
                      casesArray.push({
                        value: __case.id,
                        label:
                          __case.caseCode && __case.caseTitle
                            ? __case.caseCode + " - " + __case.caseTitle
                            : __case.caseCode && !__case.caseTitle
                              ? __case.caseCode
                              : !__case.caseCode && __case.caseTitle
                                ? __case.caseTitle
                                : "",
                      });
                    }
                  });

                  if (hasLogs_) {
                    formRef.current.values.caseArray = casesArray;
                    setCaseArray(casesArray);
                    formRef.current.values.case = casesArray[0].value;
                    setCurrentCase(casesArray[0].value);
                    document.getElementById("caseFilter").style.display =
                      "block";
                  } else {
                    formRef.current.values.client = "-1";
                    setCurrentClient("-1");
                    setIsOpenWaiting(false);
                    refreshGridNotification = true;
                    notificationMessage = "No logs for Client " + clientLabel;
                    setMessageOnMessageDialog(notificationMessage);
                    setDashboardOnClose(true);
                    setOpenMessageDialog(true);
                  }
                } else {
                  if (hasLogs_) {
                    casesArray.push({
                      value: "-1",
                      label: "ALL",
                    });
                    __client.cases
                      .sort(function (a, b) {
                        let namea = a.caseCode + a.caseTitle;
                        let nameb = b.caseCode + b.caseTitle;
                        return namea.localeCompare(nameb);
                      })
                      .map((__case, indx) => {
                        if (__case.status === "ACTIVE") {
                          casesArray.push({
                            value: __case.id,
                            label:
                              __case.caseCode && __case.caseTitle
                                ? __case.caseCode + " - " + __case.caseTitle
                                : __case.caseCode && !__case.caseTitle
                                  ? __case.caseCode
                                  : !__case.caseCode && __case.caseTitle
                                    ? __case.caseTitle
                                    : "",
                          });
                        }
                      });
                    formRef.current.values.client = client;
                    setCurrentClient(client);
                    formRef.current.values.case = "-1";
                    setCurrentCase("-1");
                    formRef.current.values.caseArray = casesArray;
                    setCaseArray(casesArray);
                    document.getElementById("caseFilter").style.display =
                      "block";
                  } else {
                    formRef.current.values.client = "-1";
                    setCurrentClient("-1");
                    setIsOpenWaiting(false);
                    refreshGridNotification = true;
                    notificationMessage = "No logs for Client " + clientLabel;
                    setMessageOnMessageDialog(notificationMessage);
                    setDashboardOnClose(true);
                    setOpenMessageDialog(true);
                  }
                }
              });
          } else if (clientsByEmail !== undefined && currentClient === "-1") {
            let clientsArray = [];
            clientsArray.push({
              value: "-1",
              label: "ALL",
            });

            clientsByEmail
              .filter((a) => a.status === "ACTIVE")
              .sort(function (a, b) {
                let namea = a.lastName + a.firstName + a.company;
                let nameb = b.lastName + b.firstName + b.company;
                return namea.localeCompare(nameb);
              })
              .map((__client, ix) => {
                if (__client.status === "ACTIVE") {
                  let casesArray = [];
                  casesArray.push({
                    value: "-1",
                    label: "ALL",
                  });
                  clientsArray.push({
                    value: __client.id,
                    label: clientOnGrid(__client),
                  });
                }
              });
            setClientArray(clientsArray);
            document.getElementById("waiting").style.display = "none";
            document.getElementById("mainScreen").style.opacity = 1;
          }
          setIsOpenWaiting(false);
        });
    });

    function hasLogs(__case) {
      let logs = __case.activityLog.filter((a) => a.status === "ACTIVE").length;
      logs =
        logs + __case.expenseLog.filter((a) => a.status === "ACTIVE").length;

      return logs > 0;
    }
  }

  function appendGreaterThanToL1(L1) {
    if (typeof L1 !== "undefined") {
      if (
        L1.includes("ATTEND") ||
        L1.includes("READ") ||
        L1.includes("WRITE") ||
        L1.includes("CALL")
      ) {
        return L1 + " >";
      } else return L1;
    } else return L1;
  }

  function appendGreaterThanToL2(L1, L2) {
    if (typeof L1 !== "undefined" && typeof L2 !== "undefined") {
      if (
        (L1.includes("ATTEND") && L2.includes("HEARING")) ||
        (L1.includes("WRITE") && L2.includes("PLEADING"))
      ) {
        return L2 + " >";
      } else return L2;
    } else return L2;
  }

  function computeTimeForExcel(log, logTime) {
    let ret = "";
    if (!log.billingType.includes("HOURLY")) ret = "   ";
    else {
      if (logTime.toString().includes("1970")) ret = "   ";
      else {
        let t = logTime.toLocaleTimeString();
        ret =
          t.split(" ")[0].split(":")[0] +
          ":" +
          t.split(" ")[0].split(":")[1] +
          t.split(" ")[1];
      }
    }
    return ret;
  }

  function reArrangeDontRefreshCasesList(setRowData, deletedlogData) {
    debugger;
    console.log("---------------------reArrange");

    let sDate = start_.toISOString();
    let eDate = end_.toISOString();

    console.log(start_.toISOString());
    console.log(end_.toISOString());

    console.log(qryStart_.toISOString());
    console.log(qryEnd_.toISOString());

    refreshGrid(
      clientsByEmailCaseDate,
      sDate,
      eDate,
      deletedlogData,
      setRowData
    );

    console.log("end ---------------------reArrange");
  }

  function reArrange(setRowData, deletedlogData) {
    debugger;
    console.log("---------------------reArrange");

    let sDate = start_.toISOString();
    let eDate = end_.toISOString();

    console.log(start_.toISOString());
    console.log(end_.toISOString());

    console.log(qryStart_.toISOString());
    console.log(qryEnd_.toISOString());

    populateClientsAndCases(currentClient, currentCase);
    refreshGrid(
      clientsByEmailCaseDate,
      sDate,
      eDate,
      deletedlogData,
      setRowData
    );

    console.log("end ---------------------reArrange");
  }

  function populateClientsAndCases(client, case_ = null) {
    console.log("start ---------------------populateClientsAndCases");

    console.log("client");
    console.log(client.length);
    console.log(client);

    debugger;
    if (clientsByEmail !== undefined && client && client === "-1") {
      let clientsArray = [];
      clientsArray.push({
        value: "-1",
        label: "ALL",
      });

      let casesArray = [];
      clientsByEmail
        .filter((a) => a.status === "ACTIVE")
        .sort(function (a, b) {
          let namea = a.lastName + a.firstName + a.company;
          let nameb = b.lastName + b.firstName + b.company;
          return namea.localeCompare(nameb);
        })
        .map((__client, ix) => {
          if (__client.status === "ACTIVE") {
            let casesArray = [];
            casesArray.push({
              value: "-1",
              label: "ALL",
            });
            clientsArray.push({
              value: __client.id,
              label: clientOnGrid(__client),
            });
          }
        });
      setClientArray(clientsArray);
      setCaseArray(casesArray);
    } else if (clientsByEmail !== undefined && client !== "-1") {
      let casesArray = [];

      clientsByEmail
        .filter((a) => a.status === "ACTIVE" && a.id === client)
        .map((__client, ix) => {
          let hasLogs_ = false;
          if (__client.cases.filter((a) => a.status === "ACTIVE").length > 0) {
            __client.cases
              .filter((a) => a.status === "ACTIVE")
              .map((__case, indx) => {
                if (hasLogs(__case)) {
                  hasLogs_ = true;
                }
              });
          }

          let x = __client.cases.filter((cs) => cs.status === "ACTIVE").length;

          if (x === 0) {
            formRef.current.values.case = "-1";
            setCurrentCase("-1");
            formRef.current.values.caseArray = [
              {
                value: "-1",
                label: "ALL",
              },
            ];
            setCaseArray([
              {
                value: "-1",
                label: "ALL",
              },
            ]);
            formRef.current.values.client = "-1";
            setCurrentClient("-1");
            document.getElementById("caseFilter").style.display = "none";
            document.getElementById("logTypeFilter").style.display = "block";
            document.getElementById("logTypeFilter1").style.display = "none";
            document.getElementById("periodFilter").style.display = "block";
            document.getElementById("periodFilter1").style.display = "none";
            setIsOpenWaiting(false);
            refreshGridNotification = true;
            notificationMessage =
              "No case for Client " + getClientLabel(client);
            setMessageOnMessageDialog(notificationMessage);
            setOpenMessageDialog(true);
          } else if (x === 1) {
            __client.cases.map((__case, indx) => {
              if (__case.status === "ACTIVE") {
                casesArray.push({
                  value: __case.id,
                  label:
                    __case.caseCode && __case.caseTitle
                      ? __case.caseCode + " - " + __case.caseTitle
                      : __case.caseCode && !__case.caseTitle
                        ? __case.caseCode
                        : !__case.caseCode && __case.caseTitle
                          ? __case.caseTitle
                          : "",
                });
              }
            });

            if (hasLogs_) {
              formRef.current.values.caseArray = casesArray;
              setCaseArray(casesArray);
              formRef.current.values.case = casesArray[0].value;
              setCurrentCase(casesArray[0].value);
              document.getElementById("caseFilter").style.display = "block";
            } else {
              formRef.current.values.client = "-1";
              setCurrentClient("-1");
              document.getElementById("caseFilter").style.display = "none";
              document.getElementById("logTypeFilter").style.display = "block";
              document.getElementById("logTypeFilter1").style.display = "none";
              document.getElementById("periodFilter").style.display = "block";
              document.getElementById("periodFilter1").style.display = "none";
              setIsOpenWaiting(false);
              refreshGridNotification = true;
              notificationMessage =
                "No logs for Client " + getClientLabel(client);
              setMessageOnMessageDialog(notificationMessage);
              setOpenMessageDialog(true);
            }
          } else {
            if (hasLogs_) {
              casesArray.push({
                value: "-1",
                label: "ALL",
              });
              __client.cases
                .sort(function (a, b) {
                  let namea = a.caseCode + a.caseTitle;
                  let nameb = b.caseCode + b.caseTitle;
                  return namea.localeCompare(nameb);
                })
                .map((__case, indx) => {
                  if (__case.status === "ACTIVE") {
                    casesArray.push({
                      value: __case.id,
                      label:
                        __case.caseCode && __case.caseTitle
                          ? __case.caseCode + " - " + __case.caseTitle
                          : __case.caseCode && !__case.caseTitle
                            ? __case.caseCode
                            : !__case.caseCode && __case.caseTitle
                              ? __case.caseTitle
                              : "",
                    });
                  }
                });

              if (case_ !== "-1" && case_) {
                let caseHasLogs = false;
                let caseLabel = "";
                __client.cases
                  .filter((a) => a.id === case_)
                  .map((__case, indx) => {
                    caseHasLogs = hasLogs(__case);
                    caseLabel =
                      __case.caseCode && __case.caseTitle
                        ? __case.caseCode + " - " + __case.caseTitle
                        : __case.caseCode && !__case.caseTitle
                          ? __case.caseCode
                          : !__case.caseCode && __case.caseTitle
                            ? __case.caseTitle
                            : "";
                  });

                if (!caseHasLogs) {
                  setIsOpenWaiting(false);
                  refreshGridNotification = true;
                  notificationMessage = "No logs for Case " + caseLabel;
                  setMessageOnMessageDialog(notificationMessage);
                  setOpenMessageDialog(true);
                  formRef.current.values.case = "-1";
                  setCurrentCase("-1");
                } else {
                  formRef.current.values.case = case_;
                  setCurrentCase(case_);
                }
              } else {
                formRef.current.values.case = "-1";
                setCurrentCase("-1");
              }

              formRef.current.values.client = client;
              setCurrentClient(client);
              formRef.current.values.caseArray = casesArray;
              setCaseArray(casesArray);
              document.getElementById("caseFilter").style.display = "block";
            } else {
              formRef.current.values.client = "-1";
              setCurrentClient("-1");
              document.getElementById("caseFilter").style.display = "none";
              document.getElementById("logTypeFilter").style.display = "block";
              document.getElementById("logTypeFilter1").style.display = "none";
              document.getElementById("periodFilter").style.display = "block";
              document.getElementById("periodFilter1").style.display = "none";
              setIsOpenWaiting(false);
              refreshGridNotification = true;
              notificationMessage =
                "No logs for Client " + getClientLabel(client);
              setMessageOnMessageDialog(notificationMessage);
              setOpenMessageDialog(true);
            }
          }
        });

      function hasLogs(__case) {
        console.log("start -------------------hasLogs");
        let logs = __case.activityLog.filter((a) => a.status === "ACTIVE")
          .length;
        logs =
          logs + __case.expenseLog.filter((a) => a.status === "ACTIVE").length;

        console.log("__case");
        console.log(__case);
        console.log("logs");
        console.log(logs);
        console.log("end -------------------hasLogs");
        return logs > 0;
      }
    }

    console.log("end ---------------------populateClientsAndCases");
  }

  function clientOnGrid(item) {
    return item ? item.lastName && item.firstName && item.company
      ? item.lastName + ", " + item.firstName + " (" + item.company + ")"
      : item.lastName && item.firstName && !item.company
        ? item.lastName + ", " + item.firstName
        : item.lastName && !item.firstName && item.company
          ? item.lastName + " (" + item.company + ")"
          : item.lastName && !item.firstName && !item.company
            ? item.lastName
            : !item.lastName && item.firstName && item.company
              ? item.firstName + " (" + item.company + ")"
              : !item.lastName && !item.firstName && item.company
                ? item.company
                : !item.lastName && item.firstName && !item.company
                  ? item.firstName
                  : ""
      : ""
  }

  function caseOnGrid(__case) {
    return __case.caseCode && __case.caseTitle
      ? __case.caseCode + " - " + __case.caseTitle
      : __case.caseCode && !__case.caseTitle
        ? __case.caseCode
        : !__case.caseCode && __case.caseTitle
          ? __case.caseTitle
          : "";
  }

  function refreshGrid(clientData, sDate, eDate, deletedlogData, setRowData) {
    console.log("start ---------------------refreshGrid");

    let temp = [];
    let displayLegend = false;
    let headerActivity = false;
    let headerExpense = false;
    let clientServiceAll = null;
    let clientExpenseAll = null;
    let clientTotalAll = null;
    let clientService = null;
    let clientExpense = null;
    let clientTotal = null;
    let caseService = null;
    let caseExpense = null;
    let caseSubtotal = null;
    let clientCount = 0;
    let caseCount = 0;

    let batchCaseCount = 0;
    let batchLogCount = 0;

    console.log("sDate");
    console.log(sDate);
    console.log("eDate");
    console.log(eDate);

    debugger;
    refreshGridNotification = false;
    notificationMessage = "";

    console.log("currentClient");
    console.log(currentClient.length);
    console.log(currentClient);

    console.log("currentCase");
    console.log(currentCase.length);
    console.log(currentCase);

    if (clientData !== undefined && currentClient === "-1") {
      //All clients or all the selected clients
      headerForAllClients();
      batchCaseCount = 0;
      batchLogCount = 0;
      clientData
        .filter((a) => a.status === "ACTIVE")
        .sort(function (a, b) {
          let namea = a.lastName + a.firstName + a.company;
          let nameb = b.lastName + b.firstName + b.company;
          return namea.localeCompare(nameb);
        })
        .map((__client, ix) => {
          let hasLogs_ = false;
          if (__client.cases.filter((a) => a.status === "ACTIVE").length > 0) {
            __client.cases
              .filter((a) => a.status === "ACTIVE")
              .map((__case, indx) => {
                if (hasLogs(__case, __client, true)) {
                  hasLogs_ = true;
                }
              });
          }

          if (
            hasLogs_ &&
            __client.cases.filter((a) => a.status === "ACTIVE").length > 0
          ) {
            headerClient(__client);
            if (logType === "-1" || logType === "SERVICE_LOG")
              headerClientWorkAllCases(__client);
            if (logType === "-1" || logType === "EXPENSE_LOG")
              headerClientExpenseAllCases(__client);
            headerClientTotalAllCases(__client);
            headerClientBreakLine(__client);
            caseCount = 0;
            __client.cases
              .filter((a) => a.status === "ACTIVE")
              .sort(function (a, b) {
                let namea = a.caseCode + a.caseTitle;
                let nameb = b.caseCode + b.caseTitle;
                return namea.localeCompare(nameb);
              })
              .map((__case, indx) => {
                // if (hasLogs(__case, __client, true)) {
                headerCase(__case, __client);
                if (logType === "-1" || logType === "SERVICE_LOG")
                  headerCaseThisWork(__client, __case);
                if (logType === "-1" || logType === "EXPENSE_LOG")
                  headerCaseThisExpense(__client, __case);
                headerCaseSubTotal(__client, __case);
                headerCaseBreakLine(__client, __case);
                batchLogCount =
                  batchLogCount + generateLogs(__case, __client, true);
                caseCount = caseCount + 1;
                batchCaseCount = batchCaseCount + 1;
                // }
              });
            clientCount = clientCount + 1;
          }
        });
      if (batchCaseCount === 0) {
        refreshGridNotification = true;
        notificationMessage = "Chosen clients have no enrolled cases.";
      } else if (batchLogCount === 0) {
        refreshGridNotification = true;
        notificationMessage = "Chosen clients have no logs  for the period";
      } else {
        // ...nada
      }
      debugger;
    } else if (
      clientData !== undefined &&
      currentClient !== "-1" &&
      currentCase === "-1"
    ) {
      // Specified Client + All its Cases
      // clientCount = 0
      // batchCaseCount = 0
      // batchLogCount = 0
      let hasLogs_ = false;
      let hasCases_ = false;
      clientData
        .filter((c) => c.id === currentClient)
        .map((__client, ix) => {
          hasCases_ =
            __client.cases.filter((a) => a.status === "ACTIVE").length > 0;
          if (hasCases_) {
            __client.cases
              .filter((a) => a.status === "ACTIVE")
              .map((__case, indx) => {
                if (hasLogs(__case, __client, true)) {
                  hasLogs_ = true;
                }
              });
          }

          if (
            hasLogs_ &&
            __client.cases.filter((a) => a.status === "ACTIVE").length > 0
          ) {
            headerClient(__client);
            headerClientPeriod(__client);
            if (logType === "-1" || logType === "SERVICE_LOG")
              headerClientWorkAllCases(__client);
            if (logType === "-1" || logType === "EXPENSE_LOG")
              headerClientExpenseAllCases(__client);
            headerClientTotalAllCases(__client);
            headerClientBreakLine(__client);
            // caseCount = 0
            __client.cases
              .filter((a) => a.status === "ACTIVE")
              .sort(function (a, b) {
                let namea = a.caseCode + a.caseTitle;
                let nameb = b.caseCode + b.caseTitle;
                return namea.localeCompare(nameb);
              })
              .map((__case, indx) => {
                // if (hasLogs(__case, __client, true)) {
                headerCase(__case, __client);
                if (logType === "-1" || logType === "SERVICE_LOG")
                  headerCaseThisWork(__client, __case);
                if (logType === "-1" || logType === "EXPENSE_LOG")
                  headerCaseThisExpense(__client, __case);
                headerCaseSubTotal(__client, __case);
                headerCaseBreakLine(__client, __case);
                generateLogs(__case, __client, false);
                // batchLogCount = batchLogCount + generateLogs(__case, __client, false);
                // caseCount = caseCount + 1
                // batchCaseCount = batchCaseCount + 1
                // }
              });
          }
        });
      if (!hasCases_) {
        refreshGridNotification = true;
        notificationMessage =
          "No case for Client " + getClientLabel(currentClient);
      } else if (hasCases_ && !hasLogs_) {
        notificationMessage =
          "No logs for Client " + getClientLabel(currentClient);
        refreshGridNotification = true;
      }
    } else if (
      clientData !== undefined &&
      currentClient !== "-1" &&
      currentCase !== "-1"
    ) {
      // Specified Client + Specific Case
      let hasLogs_ = false;
      let hasCases_ = false;
      clientData
        .filter((c) => c.id === currentClient)
        .map((__client, ix) => {
          hasCases_ =
            __client.cases.filter((a) => a.status === "ACTIVE").length > 0;
          if (hasCases_) {
            __client.cases
              .filter((a) => a.status === "ACTIVE" && a.id === currentCase)
              .map((__case, indx) => {
                if (hasLogs(__case, __client, true)) {
                  hasLogs_ = true;
                }
              });
          }

          if (
            hasLogs_ &&
            __client.cases.filter((a) => a.status === "ACTIVE").length > 0
          ) {
            headerClient(__client);
            headerClientPeriod(__client);
            if (logType === "-1" || logType === "SERVICE_LOG")
              headerClientWorkSelectedCase(__client);
            if (logType === "-1" || logType === "EXPENSE_LOG")
              headerClientExpenseSelectedCase(__client);
            headerClientTotalSelectedCase(__client);
            headerClientBreakLine(__client);
            caseCount = 0;
            __client.cases
              .filter((a) => a.status === "ACTIVE")
              .filter((cs) => cs.id === currentCase)
              .map((__case, ix) => {
                // if (hasLogs(__case, __client, true)) {
                headerCase(__case, __client);
                if (logType === "-1" || logType === "SERVICE_LOG")
                  headerCaseThisWork(__client, __case);
                if (logType === "-1" || logType === "EXPENSE_LOG")
                  headerCaseThisExpense(__client, __case);
                headerCaseSubTotal(__client, __case);
                headerCaseBreakLine(__client, __case);
                generateLogs(__case, __client, false);
                // }
              });
          }
        });
      console.log(batchLogCount);
      if (hasCases_ && !hasLogs_) {
        notificationMessage = "No logs for Case " + getCaseLabel(currentCase);
        refreshGridNotification = true;
      }
    }
    if (!printingDocument && !pdfSimpleLayout) {
      // temp.push({
      //   label: '',
      //   filePath: 'ZZZZZZZZZZZZ.' + '.$.' + 'ZZZZZZZZZZZZ.' + '$.client' + "." +
      //     'ZZZZZZZZZZZZ.' + '.$.' +
      //     'ZZZZZZZZZZZZ.'
      //     + '$.case' + "." +
      //     '0.$.caseHeader2',
      //   // date: 'Date',
      //   // datePrime: 'Date',
      //   // billingType: 'Bill Type',
      //   // hourlyRate: 'Rate',
      //   // hours: 'Hours',
      //   // startTime: 'Start',
      //   // startTimeExcel: 'Start',
      //   // endTime: 'End',
      //   // endTimeExcel: 'End',
      //   // amount: 'Amount',
      // });
    } else {
      if (printingDocument && !pdfSimpleLayout) {
        temp.push({
          label: "",
          filePath: "ZZZZZZZZZZZZ." + "$.headerLegend",
          editDelete: "",
        });
      }
    }
    debugger;
    setRowData(temp);

    function headerClientTotalSelectedCase(__client) {
      temp.push({
        label: "Total",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .5." +
          "$.Total (Selected Case)" +
          "$.Total",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientTotal = { ref: temp[temp.length - 1] };
      clientTotal.ref.editDelete = 0;
    }

    function headerClientExpenseSelectedCase(__client) {
      temp.push({
        label: "Expense",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .4." +
          "$.Expense (Selected Case)" +
          "$.Expense",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientExpense = { ref: temp[temp.length - 1] };
      clientExpense.ref.editDelete = 0;
    }

    function headerClientWorkSelectedCase(__client) {
      temp.push({
        label: "Work",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .3." +
          "$.Work (Selected Case)" +
          "$.Work",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientService = { ref: temp[temp.length - 1] };
      clientService.ref.editDelete = 0;
    }

    function normalzCount(number) {
      if (number < 10) return "0000" + number;
      else if (number < 100) return "000" + number;
      else if (number < 1000) return "00" + number;
      else if (number < 10000) return "0" + number;
      else return "" + number;
    }

    function generateLogs(__case, __client, forAllClients) {
      headerActivity = false;
      let bLogCount = 0;
      let logCount = 0;
      __case.activityLog
        .filter(
          (a) =>
            a.status === "ACTIVE" &&
            deletedlogData &&
            deletedlogData.id === a.id
        )
        .map((__activity, ix) => {
          __activity.status = "DELETED";
        });

      {
        temp.push({
          label: "",
          filePath:
            normalzCount(clientCount) +
            ".$." +
            clientOnGrid(__client) +
            "$.client" +
            "." +
            normalzCount(caseCount) +
            ".$." +
            caseOnGrid(__case) +
            "$.case" +
            "." +
            "0.$.caseHeader",

          date: "Date",
          datePrime: "Date",
          billingType: "Bill Type",
          hourlyRate: "Rate",
          hours: "Hours",
          startTime: "Start",
          startTimeExcel: "(5)",
          endTime: "End",
          endTimeExcel: "(6)",
          amount: "Amount",
          editDelete: "",
          durationHour: '',
          durationMinutes: ''
        });
        headerActivity = !headerActivity;
        displayLegend = true;
      }

      let logs = __case.activityLog.filter(
        (a) =>
          a.status === "ACTIVE" &&
          (!deletedlogData || (deletedlogData && deletedlogData.id !== a.id))
      );
      logs = logs.sort((a, b) => (a.date < b.date ? 1 : -1));
      logs.map((__activity, ix) => {
        if (
          formRef.current.values.logType === "-1" ||
          formRef.current.values.logType === "SERVICE_LOG"
        ) {
          if (sDate <= __activity.date && __activity.date <= eDate) {
            temp.push({
              label:
                __activity.activityLogType +
                " " +
                __activity.activityLogSubType +
                " " +
                __activity.activityLogSubSubType,
              filePath:
                normalzCount(clientCount) +
                ".$." +
                clientOnGrid(__client) +
                "$.client" +
                "." +
                normalzCount(caseCount) +
                ".$." +
                caseOnGrid(__case) +
                "$.case" +
                "." +
                "2.SERVICE LOGS" +
                "$.activity" +
                "$." +
                normalzCount(logCount) +
                "$." +
                __activity.activityLogType +
                " " +
                __activity.activityLogSubType +
                " " +
                __activity.activityLogSubSubType +
                "$.activity2",
              client: clientOnGrid(__client),
              case: caseOnGrid(__case),
              billingTypeDesc:
                __activity.billingType === "HOURLY"
                  ? "Hourly " +
                  __activity.hours +
                  " hrs @" +
                  formatNumber(__activity.hourlyRate) +
                  "/hr"
                  : __activity.billingType === "FIXED"
                    ? "Fixed " + "@" + formatNumber(__activity.fixedRate)
                    : "None",
              logType: "SERVICE_LOG",
              category:
                __activity.activityLogType +
                " " +
                __activity.activityLogSubType +
                " " +
                __activity.activityLogSubSubType,
              activityLogType: __activity.activityLogType,
              activityLogSubType: __activity.activityLogSubType,
              activityLogSubSubType: __activity.activityLogSubSubType,
              memberId: __client.member.id,
              caseId: __case.id,
              clientId: __client.id,
              billingType: __activity.billingType,
              date: new Date(__activity.date),
              datePrime: new Date(__activity.date).toString().substr(4, 11),
              amount: Number(__activity.amount),
              id: __activity.id,
              hours: __activity.hours,
              hourlyRate: __activity.hourlyRate,
              fixedRate: __activity.fixedRate,
              notes: __activity.notes,
              startTime:
                __activity.startTime === null
                  ? ""
                  : new Date(__activity.startTime),
              endTime:
                __activity.endTime === null ? "" : new Date(__activity.endTime),
              startTimeExcel: computeTimeForExcel(
                __activity,
                new Date(__activity.startTime)
              ),
              endTimeExcel: computeTimeForExcel(
                __activity,
                new Date(__activity.endTime)
              ),
              status: __activity.status,
              ord: __activity.ord,
              parentId: __activity.parentId,
              hourlyRateService: __client.hourlyRateService,
              fixedRateExpense: __case.fixedRateExpense,
              hourlyRateExpense: __case.hourlyRateExpense,
              fixedRateService: __case.fixedRateService,
              durationHour: '',
              durationMinutes: ''
            });
            if (forAllClients) {
              clientServiceAll.ref.editDelete =
                clientServiceAll.ref.editDelete + Number(__activity.amount);
              clientTotalAll.ref.editDelete =
                clientTotalAll.ref.editDelete + Number(__activity.amount);
            }
            clientService.ref.editDelete =
              clientService.ref.editDelete + Number(__activity.amount);
            clientTotal.ref.editDelete =
              clientTotal.ref.editDelete + Number(__activity.amount);
            caseService.ref.editDelete =
              caseService.ref.editDelete + Number(__activity.amount);
            caseSubtotal.ref.editDelete =
              caseSubtotal.ref.editDelete + Number(__activity.amount);
          }
        }
        logCount = logCount + 1;
        bLogCount = bLogCount + 1;
      });
      headerExpense = false;
      __case.expenseLog
        .filter(
          (a) =>
            a.status === "ACTIVE" &&
            deletedlogData &&
            deletedlogData.id === a.id
        )
        .map((__expense, ix) => {
          __expense.status = "DELETED";
        });
      logs = __case.expenseLog.filter(
        (a) =>
          a.status === "ACTIVE" &&
          (!deletedlogData || (deletedlogData && deletedlogData.id !== a.id))
      );
      logs = logs.sort((a, b) => (a.date < b.date ? 1 : -1));
      logCount = 0;
      logs.map((__expense, ix) => {
        if (
          formRef.current.values.logType === "-1" ||
          "EXPENSE_LOG" === formRef.current.values.logType
        ) {
          if (sDate <= __expense.date && __expense.date <= eDate) {
            temp.push({
              label: __expense.expenselogType,
              filePath:
                normalzCount(clientCount) +
                ".$." +
                clientOnGrid(__client) +
                "$.client" +
                "." +
                normalzCount(caseCount) +
                ".$." +
                caseOnGrid(__case) +
                "$.case" +
                "." +
                "4.EXPENSE LOGS" +
                "$.expense" +
                "$." +
                normalzCount(logCount) +
                "$." +
                __expense.expenselogType +
                "$.expense2",
              client: clientOnGrid(__client),
              case: caseOnGrid(__case),
              billingTypeDesc:
                __expense.billingType === "HOURLY"
                  ? "Hourly " +
                  __expense.hours +
                  " hrs @" +
                  formatNumber(__expense.hourlyRate) +
                  "/hr"
                  : __expense.billingType === "FIXED"
                    ? "Fixed " + "@" + formatNumber(__expense.fixedRate)
                    : "None",
              logType: "EXPENSE_LOG",
              category: __expense.expenselogType,
              expenselogType: __expense.expenselogType,
              memberId: __client.member.id,
              caseId: __case.id,
              clientId: __client.id,
              billingType: __expense.billingType,
              hours: __expense.hours,
              hourlyRate: __expense.hourlyRate,
              fixedRate: __expense.fixedRate,
              notes: __expense.notes,
              startTime:
                __expense.startTime === null
                  ? ""
                  : new Date(__expense.startTime),
              endTime:
                __expense.endTime === null ? "" : new Date(__expense.endTime),
              startTimeExcel: computeTimeForExcel(
                __expense,
                new Date(__expense.startTime)
              ),
              endTimeExcel: computeTimeForExcel(
                __expense,
                new Date(__expense.endTime)
              ),
              date: new Date(__expense.date),
              datePrime: new Date(__expense.date).toString().substr(4, 11),
              amount: Number(__expense.amount),
              id: __expense.id,
              status: __expense.status,
              ord: __expense.ord,
              parentId: __expense.parentId,
              hourlyRateService: __client.hourlyRateService,
              fixedRateExpense: __case.fixedRateExpense,
              hourlyRateExpense: __case.hourlyRateExpense,
              fixedRateService: __case.fixedRateService,
              durationHour: '',
              durationMinutes: ''
            });
            if (forAllClients) {
              clientExpenseAll.ref.editDelete =
                clientExpenseAll.ref.editDelete + Number(__expense.amount);
              clientTotalAll.ref.editDelete =
                clientTotalAll.ref.editDelete + Number(__expense.amount);
            }
            clientExpense.ref.editDelete =
              clientExpense.ref.editDelete + Number(__expense.amount);
            clientTotal.ref.editDelete =
              clientTotal.ref.editDelete + Number(__expense.amount);
            caseExpense.ref.editDelete =
              caseExpense.ref.editDelete + Number(__expense.amount);
            caseSubtotal.ref.editDelete =
              caseSubtotal.ref.editDelete + Number(__expense.amount);
          }
        }
        bLogCount = bLogCount + 1;
        logCount = logCount + 1;
      });

      return bLogCount;
    }

    function hasLogs(__case, __client, forAllClients) {
      let logs = __case.activityLog.filter(
        (a) =>
          a.status === "ACTIVE" &&
          (sDate <= a.date && a.date <= eDate) &&
          (!deletedlogData || (deletedlogData && deletedlogData.id !== a.id))
      ).length;
      logs =
        logs +
        __case.expenseLog.filter(
          (a) =>
            a.status === "ACTIVE" &&
            (sDate <= a.date && a.date <= eDate) &&
            (!deletedlogData || (deletedlogData && deletedlogData.id !== a.id))
        ).length;

      return logs > 0;
    }

    function headerCaseBreakLine(__client, __case) {
      temp.push({
        label: "---",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          "." +
          normalzCount(caseCount) +
          ".$." +
          caseOnGrid(__case) +
          "$.case" +
          ".6." +
          "$.casebreak",
        client: clientOnGrid(__client),
        case: "       " + caseOnGrid(__case),
        caseCode: __case.caseCode,
        caseTitle: __case.caseTitle,
        caseId: __case.id,
        status: __case.status,
        ord: __case.ord,
        parentId: __case.parentId,
        memberId: __client.member.id,
        clientId: __client.id,
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
      });
    }

    function headerCaseSubTotal(__client, __case) {
      temp.push({
        label: "Subtotal (This Case)",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          "." +
          normalzCount(caseCount) +
          ".$." +
          caseOnGrid(__case) +
          "$.case" +
          ".5." +
          "$.Subtotal (This Case)",
        client: clientOnGrid(__client),
        case: "       " + caseOnGrid(__case),
        caseCode: __case.caseCode,
        caseTitle: __case.caseTitle,
        caseId: __case.id,
        status: __case.status,
        ord: __case.ord,
        parentId: __case.parentId,
        memberId: __client.member.id,
        clientId: __client.id,
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        editDelete: formatNumber("0.00"),
      });
      caseSubtotal = { ref: temp[temp.length - 1] };
      caseSubtotal.ref.editDelete = 0;
    }

    function headerCaseThisExpense(__client, __case) {
      temp.push({
        label: "Expense (This Case)",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          "." +
          normalzCount(caseCount) +
          ".$." +
          caseOnGrid(__case) +
          "$.case" +
          ".3." +
          "$.Expense (This Case)",
        client: clientOnGrid(__client),
        case: "       " + caseOnGrid(__case),
        caseCode: __case.caseCode,
        caseTitle: __case.caseTitle,
        caseId: __case.id,
        status: __case.status,
        ord: __case.ord,
        parentId: __case.parentId,
        memberId: __client.member.id,
        clientId: __client.id,
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        editDelete: formatNumber("0.00"),
      });
      caseExpense = { ref: temp[temp.length - 1] };
      caseExpense.ref.editDelete = 0;
    }

    function headerCaseThisWork(__client, __case) {
      temp.push({
        label: "Work (This Case)",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          "." +
          normalzCount(caseCount) +
          ".$." +
          caseOnGrid(__case) +
          "$.case" +
          ".1." +
          "$.Work (This Case)",
        client: clientOnGrid(__client),
        case: "       " + caseOnGrid(__case),
        caseCode: __case.caseCode,
        caseTitle: __case.caseTitle,
        caseId: __case.id,
        status: __case.status,
        ord: __case.ord,
        parentId: __case.parentId,
        memberId: __client.member.id,
        clientId: __client.id,
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        editDelete: formatNumber("0.00"),
      });
      caseService = { ref: temp[temp.length - 1] };
      caseService.ref.editDelete = 0;
    }

    function headerCase(__case, __client) {
      temp.push({
        label: "Case :" + caseOnGrid(__case),
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          "." +
          normalzCount(caseCount) +
          ".$." +
          caseOnGrid(__case) +
          "$.case",
        client: clientOnGrid(__client),
        case: "       " + caseOnGrid(__case),
        caseCode: __case.caseCode,
        caseTitle: __case.caseTitle,
        caseId: __case.id,
        status: __case.status,
        ord: __case.ord,
        parentId: __case.parentId,
        memberId: __client.member.id,
        clientId: __client.id,
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
      });
    }

    function headerClientPeriod(__client) {
      temp.push({
        label:
          new Date(sDate).toString().substr(4, 11) +
          " to " +
          new Date(eDate).toString().substr(4, 11),
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .01.$. " +
          new Date(sDate).toString().substr(4, 11) +
          " to " +
          new Date(eDate).toString().substr(4, 11) +
          "$.PeriodClient",
      });
    }

    function headerClientBreakLine(__client) {
      temp.push({
        label: "---",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .6." +
          "$.datebreak",
      });
    }

    function headerClientTotalSelectedCases(__client) {
      temp.push({
        label: "Total",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .5." +
          "$.Total (Selected Cases)" +
          "$.Total",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientTotal = { ref: temp[temp.length - 1] };
      clientTotal.ref.editDelete = 0;
    }

    function headerClientExpenseSelectedCases(__client) {
      temp.push({
        label: "Expense (Selected Cases)",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .4." +
          "$.Expense (Selected Cases)" +
          "$.Expense",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientExpense = { ref: temp[temp.length - 1] };
      clientExpense.ref.editDelete = 0;
    }

    function headerClientWorkSelectedCases(__client) {
      temp.push({
        label: "Work (Selected Cases)",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .3." +
          "$.Work (Selected Cases)" +
          "$.Work",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientService = { ref: temp[temp.length - 1] };
      clientService.ref.editDelete = 0;
    }

    function headerClient(__client) {
      temp.push({
        label: "Client: " + clientOnGrid(__client),
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
      });
    }

    function headerClientTotalAllCases(__client) {
      temp.push({
        label: "Total",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .5." +
          "$.Total (All Cases)" +
          "$.Total",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientTotal = { ref: temp[temp.length - 1] };
      clientTotal.ref.editDelete = 0;
    }

    function headerClientExpenseAllCases(__client) {
      temp.push({
        label: "Expense (All Cases)",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .4." +
          "$.Expense (All Cases)" +
          "$.Expense",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientExpense = { ref: temp[temp.length - 1] };
      clientExpense.ref.editDelete = 0;
    }

    function headerClientWorkAllCases(__client) {
      temp.push({
        label: "Work (All Cases)",
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client" +
          " .3." +
          "$.Work (All Cases)" +
          "$.Work",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
        editDelete: formatNumber("0.00"),
      });
      clientService = { ref: temp[temp.length - 1] };
      clientService.ref.editDelete = 0;
    }

    function headerClient(__client) {
      temp.push({
        label: "Client: " + clientOnGrid(__client),
        filePath:
          normalzCount(clientCount) +
          ".$." +
          clientOnGrid(__client) +
          "$.client",
        client: clientOnGrid(__client),
        firstName: __client.firstName,
        lastName: __client.lastName,
        company: __client.company,
        status: __client.status,
        clientId: __client.id,
        ord: __client.ord,
        parentId: __client.parentId,
        memberId: __client.member.id,
      });
    }

    function headerForSelectedClients() {
      temp.push({
        label: "SELECTED CLIENTS",
        filePath: ".00.$. SELECTED CLIENTS$.ClientAll",
      });
      temp.push({
        label:
          new Date(sDate).toString().substr(4, 11) +
          " to " +
          new Date(eDate).toString().substr(4, 11),
        filePath:
          ".01.$. " +
          new Date(sDate).toString().substr(4, 11) +
          " to " +
          new Date(eDate).toString().substr(4, 11) +
          "$.Period",
      });
      temp.push({
        label: "Work (Selected Clients)",
        filePath: ".02.$.Work (Selected Clients)$.WorkAll",
        editDelete: formatNumber("0.00"),
      });
      clientServiceAll = { ref: temp[temp.length - 1] };
      clientServiceAll.ref.editDelete = 0;
      temp.push({
        label: "Expense (Selected Clients)",
        filePath: ".03.$.Expense (Selected Clients)$.ExpenseAll",
        editDelete: formatNumber("0.00"),
      });
      clientExpenseAll = { ref: temp[temp.length - 1] };
      clientExpenseAll.ref.editDelete = 0;
      temp.push({
        label: "Total (Selected Clients)",
        filePath: ".03.$.Total (Selected Clients)$.TotalAll",
      });
      clientTotalAll = { ref: temp[temp.length - 1] };
      clientTotalAll.ref.editDelete = 0;
      temp.push({
        label: "---",
        filePath: ".06." + "$.datebreak",
      });
    }

    function headerForAllClients() {
      temp.push({
        label: "ALL CLIENTS",
        filePath: ".00.$. ALL CLIENTS$.ClientAll",
      });
      temp.push({
        label:
          new Date(sDate).toString().substr(4, 11) +
          " to " +
          new Date(eDate).toString().substr(4, 11),
        filePath:
          ".01.$. " +
          new Date(sDate).toString().substr(4, 11) +
          " to " +
          new Date(eDate).toString().substr(4, 11) +
          "$.Period",
      });
      temp.push({
        label: "Work (All Clients)",
        filePath: ".02.$.Work (All Clients)$.WorkAll",
        editDelete: formatNumber("0.00"),
      });
      clientServiceAll = { ref: temp[temp.length - 1] };
      clientServiceAll.ref.editDelete = 0;
      temp.push({
        label: "Expense (All Clients)",
        filePath: ".03.$.Expense (All Clients)$.ExpenseAll",
        editDelete: formatNumber("0.00"),
      });
      clientExpenseAll = { ref: temp[temp.length - 1] };
      clientExpenseAll.ref.editDelete = 0;
      temp.push({
        label: "Total (All Clients)",
        filePath: ".03.$.Total (All Clients)$.TotalAll",
      });
      clientTotalAll = { ref: temp[temp.length - 1] };
      clientTotalAll.ref.editDelete = 0;
      temp.push({
        label: "---",
        filePath: ".06." + "$.datebreak",
      });
    }
    console.log("end ---------------------refreshGrid");
  }

  function refreshAfterExpenseLogUpdate(expenseLogData) {
    console.log(
      "start -------------- refreshAfterExpenseLogUpdate(expenseLogData) "
    );
    console.log(expenseLogData);
    debugger;
    console.log("refreshAfterExpenseLogUpdate");

    const {
      clientId,
      caseId,
      id,
      date,
      billingType,
      fixedRate,

      hourlyRate,
      hours,

      amount,
      expenselogType,

      notes,
      startTime,
      endTime,

      parentId,
      ord,
      status,
    } = expenseLogData;

    if (clientsByEmailCaseDate !== undefined) {
      clientsByEmailCaseDate
        .filter((a) => a.id === clientId)
        .map((__client, ix) => {
          __client.cases
            .filter((a) => a.id === caseId)
            .map((__case, indx) => {
              __case.expenseLog
                .filter((a) => a.id === id)
                .map((__expense, ix) => {
                  {
                    if (__expense.status === "ACTIVE") {
                      if (id === __expense.id) {
                        __expense.id = id;
                        __expense.date = date;
                        __expense.billingType = billingType;
                        __expense.fixedRate = fixedRate;

                        __expense.hourlyRate = hourlyRate;
                        __expense.hours = hours;
                        __expense.amount = Number(amount);
                        __expense.expenselogType = expenselogType;

                        __expense.notes = notes;
                        __expense.startTime = startTime;
                        __expense.endTime = endTime;

                        __expense.parentId = parentId;
                        __expense.ord = ord;
                        __expense.status = status;
                      }
                    }
                  }
                });
            });
        });
    }
    console.log(
      "end -------------- refreshAfterExpenseLogUpdate(expenseLogData) "
    );
  }

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
    if (dashboardOnClose) {
      props.history.push("/");
    }
  };

  const handleCloseMessageDialogPDF = () => {
    setOpenMessageDialogPDF(false);

  };

  const handleCloseMessageDialogPDFPhone = () => {
    setOpenMessageDialogPDFPhone(false);

  };

  const handleCloseMessageDialogPDFDesktop = () => {
    setOpenMessageDialogPDFDesktop(false);

  };

  function refreshAfterServiceLogUpdate(serviceLogData) {
    console.log(
      "start -------------- refreshAfterServiceLogUpdate(serviceLogData)"
    );
    console.log(serviceLogData);
    debugger;
    console.log("refreshAfterServiceLogUpdate");

    const {
      caseId,
      clientId,
      id,
      date,
      billingType,
      fixedRate,

      hourlyRate,
      hours,
      amount,

      activityLogType,
      activityLogSubType,
      activityLogSubSubType,

      notes,
      startTime,
      endTime,

      parentId,
      ord,
      status,
    } = serviceLogData;

    if (clientsByEmailCaseDate !== undefined) {
      clientsByEmailCaseDate
        .filter((a) => a.id === clientId)
        .map((__client, ix) => {
          __client.cases
            .filter((a) => (a.id = caseId))
            .map((__case, indx) => {
              __case.activityLog
                .filter((a) => a.id === id)
                .map((__activity, ix) => {
                  {
                    if (__activity.status === "ACTIVE") {
                      debugger;
                      console.log("before comparing");
                      console.log(id);
                      console.log(__activity.id);
                      if (id === __activity.id) {
                        console.log("updating rowData");
                        console.log(id);
                        console.log(__activity.id);

                        __activity.id = id;
                        __activity.date = date;
                        __activity.billingType = billingType;
                        __activity.fixedRate = fixedRate;

                        __activity.hourlyRate = hourlyRate;
                        __activity.hours = hours;
                        __activity.amount = Number(amount);

                        __activity.activityLogType = activityLogType;
                        __activity.activityLogSubType = activityLogSubType;
                        __activity.activityLogSubSubType = activityLogSubSubType;

                        __activity.notes = notes;
                        __activity.startTime = startTime;
                        __activity.endTime = endTime;

                        __activity.parentId = parentId;
                        __activity.ord = ord;
                        __activity.status = status;
                        debugger;
                      }
                    }
                  }
                });
            });
        });
    }

    console.log(
      "end -------------- refreshAfterServiceLogUpdate(serviceLogData)"
    );
  }

  const populateCategories = (friend, index, value) => {
    debugger;

    friend.activityL1 = value;

    let l2 = activitiesSource.filter(({ name }) => name === value)[0];
    // debugger;;
    friend.L2 = [];
    if (l2 !== undefined) {
      l2.L2.map((m, ix) => (friend.L2[ix] = m));
    } else {
      friend.activityL1 = activitiesSource[0].name;
      l2 = activitiesSource.filter(({ name }) => name === friend.activityL1)[0];
      l2.L2.map((m, ix) => (friend.L2[ix] = m));
    }
    // debugger;;
    if (friend.L2[0] !== "") {
      const l3 = L2Source.filter(
        ({ name, L2 }) => name === value && L2 === friend.activityL2
      )[0];
      // // debugger;;
      friend.L3 = [];
      if (l3 !== undefined) {
        l3.L3.map((m, ix) => (friend.L3[ix] = m));
      }
      // debugger;;
    } else {
      friend.L3 = [""];
    }

    friend.activity2Options = [];
    friend.L2.map((m) => {
      friend.activity2Options.push({
        value: m,
        label: m,
      });
    });

    friend.activity3Options = [];
    friend.L3.map((m) => {
      friend.activity3Options.push({
        value: m,
        label: m,
      });
    });

    forceUpdate();
  };

  function closeViewLog() {
    setIsOpenViewLog(false);
  }

  function onRowClicked(params) {
    debugger;

    let thisNode = params.node;
    let serviceExpenseCategoryNode = null;
    let caseNode = null;

    let isTotal = thisNode.data.filePath.toLowerCase().endsWith("t o t a l")
      ? true
      : false;
    let isClient = thisNode.data.filePath.toLowerCase().endsWith("$.client")
      ? true
      : false;
    let isCase = thisNode.data.filePath.toLowerCase().endsWith("$.case")
      ? true
      : false;
    let isServiceOrExpenseCategory =
      thisNode.data.filePath.toLowerCase().endsWith("$.activity") ||
        thisNode.data.filePath.toLowerCase().endsWith("$.expense")
        ? true
        : false;
    let isServiceOrExpense =
      thisNode.data.filePath.toLowerCase().endsWith("$.activity2") ||
        thisNode.data.filePath.toLowerCase().endsWith("$.expense2")
        ? true
        : false;

    if (isServiceOrExpense) {
      console.log(params.data)
      if (thisNode.data.filePath.toLowerCase().endsWith("$.activity2"))
        createUserLog(props, "VIEW_LOG", "Opened Work/Service Edit screen", "")
      else
        createUserLog(props, "VIEW_LOG", "Opend Expense Edit screen", "")
      setCalledFrom("onRowClicked");
      setContextParent(params.context.componentParent);
      setSelectedRowData(params.data);
      setSelectedRowDataClone(params.data);

      setIsOpenViewLog(true);

      return;
    }

    let nodes = [];

    if (isClient) {
      debugger;
      if (formRef.current) {
        debugger;
        createUserLog(props, "VIEW_LOG", "Changed Client", "")
        handleChangeClientSelectClick(
          formRef.current.values,
          thisNode.data.clientId
        );
        reArrangeDontRefreshCasesList(setRowData, null);
      }

      return;
    }

    if (isCase) {
      debugger;
      if (formRef.current) {
        debugger;
        createUserLog(props, "VIEW_LOG", "Change Case", "")
        handleChangeCaseSelectClick(
          formRef.current.values,
          thisNode.data.caseId,
          thisNode.data.clientId
        );
        reArrangeDontRefreshCasesList(setRowData, null);
      }
      return;
    }
  }

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    if (!printingDocument) {
      params.api.sizeColumnsToFit();
      window.onresize = () => {
        params.api.sizeColumnsToFit();
      };

      window.addEventListener("resize", function () {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        });
      });
    }
  }

  function autoSizeAll(skipHeader) {
    var allColumnIds = [];
    gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, true);
  }

  const handleChangeLogType = async (values, value) => {
    createUserLog(props, "VIEW_LOG", "Changed Log Type", value === "-1" ? "ALL" : "")
    console.log("start handleChangeLogType");
    document.getElementById("periodFilter").style.display = "block";
    document.getElementById("periodFilter1").style.display = "none";
    // document.getElementById('periodScope').disabled = false
    values.logType = value;
    setLogType(value);
    debugger;
    console.log("end handleChangeLogType");
  };

  const handleChangeClientSelect = async (values, value) => {
    console.log("start ---------------handleChangeClientSelect");
    createUserLog(props, "VIEW_LOG", "Change Client", value === "-1" ? "ALL" : "")
    debugger;

    if (value) {
      if (value === "-1") {
        document.getElementById("caseFilter").style.display = "none";
      } else {
        document.getElementById("caseFilter").style.display = "block";
      }
    }
    values.client = value;
    setCurrentClient(value);
    values.case = "-1";
    setCurrentCase("-1");
    populateClientsAndCases(value, "-1");
    debugger;
    console.log("end ---------------------handleChangeClientSelect");
  };

  const handleChangeCaseSelect = async (values, value, clientId) => {
    console.log("start handleChangeCaseSelect");
    debugger;
    createUserLog(props, "VIEW_LOG", "Changed Case", value === "-1" ? "ALL" : "")
    if (typeof clientId === "undefined" || !clientId)
      setCurrentClient(values.client);
    else {
      setCurrentClient(clientId);
      formRef.current.values.client = clientId;
    }
    formRef.current.values.case = value;

    setCurrentCase(value);
    populateClientsAndCases(formRef.current.values.client, value);
    if (values.client !== "-1") {
      document.getElementById("caseFilter").style.display = "block";
    } else {
      document.getElementById("caseFilter").style.display = "none";
    }

    debugger;
    console.log("end handleChangeCaseSelect");
  };

  const handleChangeClientSelectClick = async (values, value) => {
    console.log("start ---------------handleChangeClientSelectClick");
    createUserLog(props, "VIEW_LOG", "Change Client", value === "-1" ? "ALL" : "")
    debugger;
    values.case = "-1";
    setCurrentCase("-1");

    populateClientsAndCases(value, values.case);
    document.getElementById("logTypeFilter").style.display = "block";
    document.getElementById("logTypeFilter1").style.display = "none";

    debugger;
    console.log("end ---------------------handleChangeClientSelectClick");
  };

  const handleChangeCaseSelectClick = async (values, value, client) => {
    console.log("start handleChangeCaseSelectClick");
    debugger;
    createUserLog(props, "VIEW_LOG", "Changed Case", value === "-1" ? "ALL" : "")
    values.client = client;
    setCurrentClient(client);

    values.case = value;
    setCurrentCase(value);

    document.getElementById("caseFilter").style.display = "block";

    populateClientsAndCases(client, value);

    debugger;
    console.log("end handleChangeCaseSelectClick");
  };

  async function fetchClients() {
    console.log("...................start fetchClients()");
    try {
      const { client } = props;
      const { authUser } = props;
      debugger;
      const { email } = authUser;
      await client.resetStore();
      const response = await client.query({
        query: CLIENTS_BY_EMAIL_QUERY,
        variables: {
          email,
          startDate: startDate_,
          endDate: endDate_,
        },
      });
      const data = response.data;
      console.log("...................end fetchClients()");
      debugger;
      return data;
    } catch (error) {
      throw error;
    }
  }

  function handleCloseDeleteExpenseLog(rowNode) {
    createUserLog(props, "VIEW_LOG", "Deleted Expense log", "")
    delete_expenseLogId = rowNode.id;
    debugger;
    const { client } = props;
    const { data } = client.mutate({
      mutation: DELETE_EXPENSELOG,
      variables: {
        expenseLogId: delete_expenseLogId,
        actionDate: new Date().toISOString(),
      },
    });
    debugger;

    let workDeleteLogData = {
      id: delete_expenseLogId,
    };
    reArrange(setRowData, workDeleteLogData);

    setIsOpenViewLog(false);
    setOpenDeleteDoneLog(true);
    debugger;
  }

  function handleCancelDeleteExpenseLog() {
    debugger;
    createUserLog(props, "VIEW_LOG", "Cancelled delete expense log", "")
    setOpenDeleteExpenseLog(false);
  }

  function handleCloseDeleteServiceLog(rowNode) {
    createUserLog(props, "VIEW_LOG", "Deleted service/work log", "")
    console.log("handleCloseDeleteServiceLog rowNode");
    console.log(rowNode);
    delete_serviceLogId = rowNode.id;
    debugger;
    const { client } = props;
    var { data } = client.mutate({
      mutation: DELETE_SERVICELOG,
      variables: {
        activityLogId: delete_serviceLogId,
        actionDate: new Date().toISOString(),
      },
    });
    debugger;
    let workDeleteLogData = {
      id: delete_serviceLogId,
    };
    reArrange(setRowData, workDeleteLogData);

    setIsOpenViewLog(false);
    setOpenDeleteDoneLog(true);
    debugger;
  }

  function handleCancelDeleteServiceLog() {
    debugger;
    createUserLog(props, "VIEW_LOG", "Cancelled delete service/work log", "")
    setOpenDeleteDoneLog(false);
  }

  const callRefreshTempExpense = () => {
    setRefreshTempExpense(Math.random());
  };

  const callRefreshTempService = () => {
    setRefreshTempService(Math.random());
  };
  const callRefreshTemp = () => {
    setRefreshTemp(Math.random());
  };
  const handleCloseClient = () => {
    setOpenClient(false);
  };

  const handleCloseCase = () => {
    setOpenCase(false);
  };

  function exportAsExcel() {
    if (pdfSimpleLayout) gridApi.exportDataAsExcel({});
    else {
      gridColumnApi.setColumnsVisible(
        [
          "billingType",
          "notes",
          "hourlyRate",
          "category",
          "startTimeExcel",
          "endTimeExcel",
          "hours",
        ],
        true
      );
      gridApi.exportDataAsExcel({});
      gridColumnApi.setColumnsVisible(
        [
          "billingType",
          "notes",
          "hourlyRate",
          "category",
          "startTimeExcel",
          "endTimeExcel",
          "hours",
        ],
        false
      );
    }
  }

  function exportDataAsCsv() {
    if (pdfSimpleLayout) gridApi.exportDataAsCsv({});
    else {
      gridColumnApi.setColumnsVisible(
        [
          "billingType",
          "notes",
          "hourlyRate",
          "category",
          "startTimeExcel",
          "endTimeExcel",
          "hours",
        ],
        true
      );
      gridApi.exportDataAsCsv({});
      gridColumnApi.setColumnsVisible(
        [
          "billingType",
          "notes",
          "hourlyRate",
          "category",
          "startTimeExcel",
          "endTimeExcel",
          "hours",
        ],
        false
      );
    }
  }

  function getClientLabel(clientId) {
    let a = clientArray.filter((a) => a.value === clientId)[0];
    debugger;
    return a.label;
  }

  function getCaseLabel(caseId) {
    let a = caseArray.filter((a) => a.value === caseId)[0];
    debugger;
    return a.label;
  }

  const printDocument = () => {
    setIsOpenWaiting(true);
    setTimeout(() => {
      setTimeout(() => {
        callRefreshTemp();
      }, 500);
      printIt();
    }, 500);
  };

  const printIt = () => {
    const input = document.getElementsByClassName(
      "ag-center-cols-container"
    )[1];
    var HTML_Width = input.offsetWidth;
    var HTML_Height = input.offsetHeight;
    var top_left_margin = 30;
    var PDF_Width = 595.276; // = 21cm
    var PDF_Height = 841.8898; // = 29.7cm

    var canvas_image_width = PDF_Width - 2 * top_left_margin;
    var canvas_image_height =
      HTML_Height * ((PDF_Width - 2 * top_left_margin) / HTML_Width);

    var totalPDFPages =
      Math.ceil(canvas_image_height / (PDF_Height - 2 * top_left_margin)) - 1;

    html2canvas(input, { allowTaint: true }).then((canvas) => {
      canvas.getContext("2d");
      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, 0, PDF_Width, top_left_margin, "F");
      pdf.rect(
        0,
        PDF_Height - top_left_margin,
        PDF_Width,
        top_left_margin,
        "F"
      );

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * (2 * i + 1),
          canvas_image_width,
          canvas_image_height
        );
        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, 0, PDF_Width, top_left_margin, "F");
        pdf.rect(
          0,
          PDF_Height - top_left_margin,
          PDF_Width,
          top_left_margin,
          "F"
        );
      }

      pdf.autoPrint({ variant: "non-conform" });
      pdf.save("document.pdf");

      setTimeout(() => {
        setRowDataPrint([])
        setTimeout(() => {
          setPrintingDocument(false);
          setTimeout(() => {
            callRefreshTemp();
          }, 500);
          setTimeout(() => {
            setIsOpenWaiting(false);
          }, 500);
        }, 500);
      }, 500);
    });
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openServiceLog}
        // TransitionComponent={props.Transition}
        keepMounted
        onClose={handleCloseServiceLog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div
              style={{
                margin: `10px 0px 20px 0`,
                color: primaryColor[0],
                fontSize: "30px",
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              View Log
            </div>
            <Tooltip
              id="tooltip-top"
              title={"Home"}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <MDButton
                className={classes.modalCloseButtonBYN}
                style={{ margin: `-60px  ${size.width <= 414 ? -10 : -20}px 0 0` }}
                href="/app/app"

              >
                <img
                  style={{ width: "50px" }}
                  src={circlelogo}
                  alt="billyounow.com"
                />
              </MDButton>
            </Tooltip>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
            <Waiting
              Transition={Transition}
              isOpenWaiting={isOpenWaiting}
            ></Waiting>
          </div>
          <div id="mainScreen" style={{ marginTop: "0px" }}>
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 30 : 370}px`,
              }}
            >
              <Formik
                innerRef={formRef}
                initialValues={{
                  memberArray: [],
                  caseArray: [],
                  clientArray: [],
                  startDate: null,
                  endDate: null,
                  client: "-1",
                  case: "-1",
                  periodScope: "",
                  logType: "",
                }}
                validate={(values) => {
                  const errors = {};

                  if (values.logType === "") errors.logType = "Required";
                  debugger;

                  if (values.periodScope === "")
                    errors.periodScope = "Required";

                  if (values.startDate && values.endDate) {
                    let today = new Date();
                    let x = today.toISOString();
                    let y = new Date(values.startDate).toISOString();
                    let z = new Date(values.endDate).toISOString();

                    debugger;
                    if (y > x) {
                      errors.startDate = "Future date is not allowed";
                    }
                    if (z > x) {
                      errors.endDate = "Future date is not allowed";
                    }
                    if (y > z) {
                      errors.startDate =
                        "Start date cannot be beyond end date";
                    }
                  } else if (!values.startDate) {
                  } else if (!values.endDate) {
                  } else {
                  }

                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  debugger;
                  createUserLog(props, "VIEW_LOG", "clicked GO", "")
                  if (doQuery) {
                    console.log("start *************FetchClients");
                    setQryStart_(values.startDate);
                    setQryEnd_(values.endDate);
                    setStart_(values.startDate);
                    setEnd_(values.endDate);

                    let deletedlogData = null;

                    console.log("currentClient");
                    console.log(currentClient);
                    console.log("currentCase");
                    console.log(currentCase);
                    debugger;
                    console.log("values.startDate.toISOString()");
                    console.log(values.startDate.toISOString());
                    console.log("values.endDate.toISOString()");
                    console.log(values.endDate.toISOString());

                    setIsOpenWaiting(true);
                    console.log(
                      "...................start query fetchClients()"
                    );
                    const { client } = props;
                    const { authUser } = props;
                    debugger;
                    const { email } = authUser;
                    client
                      .query({
                        query: CLIENTS_BY_EMAIL_QUERY,
                        variables: {
                          email,
                          startDate: values.startDate.toISOString(),
                          endDate: values.endDate.toISOString(),
                        },
                      })
                      .then((response) => {
                        debugger;
                        const { clientsByEmailCaseDate } = response.data;
                        let sortedData = clientsByEmailCaseDate
                          .filter((a) => a.status === "ACTIVE")
                          .sort(function (a, b) {
                            let namea =
                              a.lastName + a.firstName + a.company;
                            let nameb =
                              b.lastName + b.firstName + b.company;
                            return namea.localeCompare(nameb);
                          });
                        debugger;

                        refreshGrid(
                          sortedData,
                          values.startDate.toISOString(),
                          values.endDate.toISOString(),
                          deletedlogData,
                          setRowData
                        );
                        setRefetchLogs(0);
                        setClientsByEmailCaseDate(sortedData);
                        debugger
                        if (sortedData.length === 0) {
                          setDoQuery(false)
                        }
                        setIsOpenWaiting(false);
                        console.log(
                          "...................end query fetchClients()"
                        );

                        if (refreshGridNotification === false) {
                          handleOpenLog();
                        } else {
                          setIsOpenWaiting(false);
                          setMessageOnMessageDialog(notificationMessage);
                          setOpenMessageDialog(true);
                        }
                        console.log("****************FetchClients end");
                      });
                  } else {
                    reArrange(setRowData, null);
                    if (refreshGridNotification === false) {
                      handleOpenLog();
                    } else {
                      setIsOpenWaiting(false);
                      setMessageOnMessageDialog(notificationMessage);
                      setOpenMessageDialog(true);
                    }
                  }
                }}
                render={({
                  values,
                  handleChange,
                  errors,
                  touched,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <Form mode="themed">
                    <div
                      className={classes.container}
                      id="header"
                      style={{
                        width: `${size.width <= 414 ? size.width - 40 : 360
                          }px`,
                        overflow: "auto",
                        height: `${size.height - 150}px`,
                      }}
                    >
                      <TextField
                        style={{ margin: "40px 0 20px 0" }}
                        fullWidth
                        labelId="client_"
                        select
                        label="Client"
                        variant="outlined"
                        id="client_"
                        value={values.client}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          debugger;
                          values.client = e.target.value;
                          handleChangeClientSelect(
                            values,
                            e.target.value
                          );
                        }}
                      >
                        {clientArray.map((cl) => (
                          <MenuItem value={cl.value}>{cl.label}</MenuItem>
                        ))}
                      </TextField>
                      <FormHelperText>
                        {errors.client && touched.client && errors.client}
                      </FormHelperText>

                      <div id="caseFilter" style={{ display: "none" }}>
                        <TextField
                          style={{ margin: "0px 0 20px 0" }}
                          fullWidth
                          id="caseFilter"
                          select
                          label="Case"
                          variant="outlined"
                          disabled={values.client === "-1"}
                          labelId="case_"
                          id="case_"
                          value={values.case}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            debugger;
                            values.case = e.target.value;
                            handleChangeCaseSelect(
                              values,
                              e.target.value,
                              null
                            );
                          }}
                        >
                          {values.caseArray.map((cs) => (
                            <MenuItem value={cs.value}>
                              {cs.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        <FormHelperText>
                          {errors.case && touched.case && errors.case}
                        </FormHelperText>
                      </div>

                      <div
                        id="logTypeFilter"
                        style={{ display: "block" }}
                      >
                        <TextField
                          style={{ margin: "0px 0 20px 0" }}
                          fullWidth
                          labelId="logType"
                          id="logType"
                          label="Log Type"
                          select
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.logType}
                          onChange={(e) => {
                            values.logType = e.target.value;
                            handleChangeLogType(values, e.target.value);
                            setFieldValue("logType", e.target.value);
                          }}
                          required={values.logType.length === 0}
                        >
                          <MenuItem value="-1">BOTH</MenuItem>
                          <MenuItem value="EXPENSE_LOG">EXPENSE</MenuItem>
                          <MenuItem value="SERVICE_LOG">WORK</MenuItem>
                        </TextField>
                        <FormHelperText>
                          <div style={{ color: primaryColor[0] }}>
                            {errors.logType &&
                              touched.logType &&
                              errors.logType}
                          </div>
                        </FormHelperText>
                      </div>

                      <div
                        id="logTypeFilter1"
                        style={{ display: "none" }}
                      >
                        <TextField
                          style={{ margin: "0px 0 20px 0" }}
                          fullWidth
                          disabled
                          labelId="logType"
                          id="logType"
                          label="Log Type"
                          select
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={values.logType}
                          onChange={(e) => {
                            values.logType = e.target.value;
                            handleChangeLogType(values, e.target.value);
                            setFieldValue("logType", e.target.value);
                          }}
                        >
                          <MenuItem value="-1">BOTH</MenuItem>
                          <MenuItem value="EXPENSE_LOG">EXPENSE</MenuItem>
                          <MenuItem value="SERVICE_LOG">WORK</MenuItem>
                        </TextField>
                        <FormHelperText>
                          <div style={{ color: primaryColor[0] }}>
                            {errors.logType &&
                              touched.logType &&
                              errors.logType}
                          </div>
                        </FormHelperText>
                      </div>

                      <div id="periodFilter" style={{ display: "none" }}>
                        <TextField
                          style={{ margin: "0px 0 20px 0" }}
                          fullWidth
                          name="periodScope"
                          value={values.periodScope}
                          labelId="periodScope"
                          id="periodScope"
                          label="Period"
                          select
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            createUserLog(props, "VIEW_LOG", "clicked Period filter", e.target.value)
                            setIsValid(true);
                            debugger;
                            if (e.target.value === "CUSTOM") {
                              debugger
                              document.getElementById(
                                "customPeriod"
                              ).style.display = "block";
                              // setStartDate_(null);
                              // setEndDate_(null);
                              values.startDate = null;
                              values.endDate = null;
                              // paramStart_ = null;
                              // paramEnd_ = null;
                              checkTriggerQuery(values);
                              debugger;
                            } else {
                              document.getElementById(
                                "customPeriod"
                              ).style.display = "none";

                              if (e.target.value === "YEAR-TO-DATE") {
                                let s = new Date(
                                  new Date(new Date().getFullYear(), 0, 1)
                                );
                                values.startDate = s;
                                let e = new Date();
                                values.endDate = e;
                                checkTriggerQuery(values);
                              } else {  //MONTH-TO-DATE
                                let s = new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  1
                                );
                                values.startDate = s;
                                let e = new Date();
                                // setParamEnd_(e)
                                values.endDate = e;
                                checkTriggerQuery(values);
                              }
                            }
                            setFieldValue("periodScope", e.target.value);
                          }}
                          required={values.periodScope.length === 0}
                        >
                          <MenuItem value="MONTH-TO-DATE">
                            MONTH-TO-DATE
                          </MenuItem>
                          <MenuItem value="YEAR-TO-DATE">
                            YEAR-TO-DATE
                          </MenuItem>

                          <MenuItem value="CUSTOM"
                            style={{
                              color: primaryColor[0],
                              fontWeight: '500'
                            }}>
                            CUSTOM {">"}</MenuItem>
                        </TextField>
                        <FormHelperText>
                          <div style={{ color: primaryColor[0] }}>
                            {errors.periodScope &&
                              touched.periodScope &&
                              errors.periodScope}
                          </div>
                        </FormHelperText>
                      </div>

                      <div
                        id="periodFilter1"
                        style={{ display: "block" }}
                      >
                        <TextField
                          style={{ margin: "0px 0 20px 0" }}
                          fullWidth
                          disabled
                          name="periodScope"
                          value={values.periodScope}
                          labelId="periodScope"
                          id="periodScope"
                          label="Period"
                          select
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setIsValid(true);
                            debugger;
                            if (e.target.value === "CUSTOM") {

                            } else {
                              document.getElementById(
                                "customPeriod"
                              ).style.display = "none";

                              if (e.target.value === "YEAR-TO-DATE") {

                              } else {

                              }
                            }
                            setFieldValue("periodScope", e.target.value);
                          }}
                        >
                          <MenuItem value="MONTH-TO-DATE">
                            MONTH-TO-DATE
                          </MenuItem>
                          <MenuItem value="YEAR-TO-DATE">
                            YEAR-TO-DATE
                          </MenuItem>
                          <MenuItem value="CUSTOM">CUSTOM {">"}</MenuItem>
                        </TextField>
                      </div>
                      <div
                        id="customPeriod"
                        width="250px"
                        style={{ display: "none" }}
                      >
                        <div style={style.step1CustomDatesx}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={dateMaterialTheme}>
                              <KeyboardDatePicker
                                style={{ margin: "10px 0 20px 0" }}
                                open={openStartDate}
                                onClose={() => {
                                  setOpenStartDate(false);
                                }}
                                name="startDate"
                                margin="normal"
                                keyboard
                                autoOk
                                maxDate={new Date()}
                                label="Start Date"
                                value={values.startDate}
                                format="MMM dd, yyyy"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required={values.periodScope === "CUSTOM" && values.startDate === null}
                                onChange={(value) => {
                                  debugger;
                                  let d = new Date(value);
                                  d = new Date(
                                    d.getFullYear(),
                                    d.getMonth(),
                                    d.getDate(),
                                    0,
                                    0,
                                    0
                                  );
                                  setStartDate__(d);
                                  setFieldValue("startDate", d);
                                  paramStart_ = d;
                                  // paramEnd_ = end_;
                                  values.startDate = d;
                                  checkTriggerQuery(values);
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                TextFieldComponent={(props) => (
                                  <TextFieldComponent
                                    {...props}
                                    setOpen={setOpenStartDate}
                                  />
                                )}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </div>
                        <div
                          style={style.step1CustomDatesx}
                          style={{ color: primaryColor[0] }}
                        >
                          {errors.startDate && (
                            <div style={style.step3DateError}>
                              <div />
                              {errors.startDate}
                            </div>
                          )}
                        </div>

                        <div style={style.step1CustomDatesx}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={dateMaterialTheme}>
                              <KeyboardDatePicker
                                style={{ margin: "10px 0 0px 0" }}

                                open={openEndDate}
                                onClose={() => {
                                  setOpenEndDate(false);
                                }}
                                name="endDate"
                                margin="normal"
                                keyboard
                                autoOk
                                maxDate={new Date()}
                                label="End Date"
                                value={values.endDate}
                                format="MMM dd, yyyy"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required={values.periodScope === "CUSTOM" && values.endDate === null}
                                onChange={(value) => {
                                  debugger;
                                  let d = new Date(value);
                                  let ddd = new Date();
                                  if (
                                    d.toISOString().split("T")[0] <
                                    ddd.toISOString().split("T")[0]
                                  ) {
                                    ddd = new Date(
                                      d.getFullYear(),
                                      d.getMonth(),
                                      d.getDate(),
                                      23,
                                      59,
                                      0
                                    );
                                  }
                                  setEndDate__(ddd);
                                  setFieldValue("endDate", ddd);
                                  values.endDate = ddd;
                                  paramEnd_ = ddd;
                                  // paramStart_ = start_;
                                  checkTriggerQuery(values);
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                TextFieldComponent={(props) => (
                                  <TextFieldComponent
                                    {...props}
                                    setOpen={setOpenEndDate}
                                  />
                                )}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </div>

                        <div
                          style={style.step1CustomDatesx}
                          style={{ color: primaryColor[0] }}
                        >
                          {errors.endDate && (
                            <div style={style.step3DateError}>
                              <div />
                              {errors.endDate}
                            </div>
                          )}
                        </div>
                      </div>
                      <Typography
                        style={{
                          textAlign: "center", fontSize: '16px', color: primaryColor[0],
                          display: !isValid ? 'block' : 'none'
                        }}>
                        <em>* Required</em>
                      </Typography>
                    </div>

                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <MDButton
                          style={{ width: "60%" }}
                          id="goButton"
                          variant="contained"
                          color={isValid ? "primary" : "gray"}
                          disabled={!isValid || (values.periodScope === "CUSTOM" && (values.endDate === null || values.startDate === null))}
                          type="submit"
                          size="md"
                        >
                          go
                        </MDButton>
                        <MDButton
                          style={{ width: "40%" }}
                          id="cancelButton"
                          variant="outlined"
                          color="primary"
                          // disabled={!isValid}
                          // href="/app/dashboard"
                          // type="submit"
                          size="md"
                          onClick={(e) => {
                            e.preventDefault()
                            createUserLog(props, "VIEW_LOG", "Cancelled View Log ", "clicked CANCEL")
                            props.history.push("/app/dashboard")
                          }}
                        >
                          cancel
                        </MDButton>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              ></Formik>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openLog}
        // TransitionComponent={props.Transition}
        keepMounted
        onClose={handleCloseLog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 850}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0px 20px 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                View Log
              </div>
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <MDButton
                  className={classes.modalCloseButtonBYN}
                  style={{ margin: `-60px  ${size.width <= 414 ? -10 : -20}px  0 0 ` }}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </MDButton>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
              <Waiting
                Transition={Transition}
                isOpenWaiting={isOpenWaiting}
              ></Waiting>
            </div>
            <div id="mainScreenLog" style={{ marginTop: "-80px" }}>
              <GridContainer justify="center" className={classes.cardContainer}>
                <GridItem zeroMinWidth>
                  <Formik
                    initialValues={{}}
                    validate={(values) => {
                      const errors = {};

                      return errors;
                    }}
                    onSubmit={(values) => {
                      handleCloseLog();
                    }}
                    render={({
                      values,
                      handleChange,
                      errors,
                      touched,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                      <Form mode="themed">
                        {size.width > 414 ? (
                          <ThemeProvider theme={moreLessBtnMaterialTheme}>
                            <div
                              style={{
                                margin: "0px 0px 0 0",
                                textAlign: "right",
                              }}
                            >
                              <Tooltip
                                id="tooltip-top"
                                title={
                                  pdfSimpleLayout
                                    ? "Show More Columns"
                                    : "Show Less Columns"
                                }
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  simple
                                  style={{
                                    color: primaryColor[0],
                                    margin: "0 0px 0 0",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    createUserLog(props, "VIEW_LOG", "clicked Show More/Less columns", pdfSimpleLayout ? "Less Columns" : "More Columns")
                                    setPdfSimpleLayout(!pdfSimpleLayout);
                                  }}
                                >
                                  {pdfSimpleLayout
                                    ? "More Columns"
                                    : "Less Columns"}
                                </Button>
                              </Tooltip>
                            </div>
                          </ThemeProvider>
                        ) : null}

                        <div
                          id="Grid"
                          style={{
                            margin: "0 0px 10px -10px",
                            overflowY: "auto",
                            height: `${size.height -
                              (size.width <= 768 ? 172 : 202)}px`,
                            width: `${size.width <= 320
                              ? "295px"
                              : size.width <= 360
                                ? "330px"
                                : size.width <= 375
                                  ? "345px"
                                  : size.width <= 414
                                    ? "390px"
                                    : size.width <= 480
                                      ? "390px"
                                      : size.width <= 667
                                        ? "580px"
                                        : size.width <= 896
                                          ? "850px"
                                          : "870px"
                              }`,
                          }}
                        >
                          <div
                            id="myGrid"
                            className="ag-theme-balham"
                            style={{
                              boxSizing: "border-box",
                              height: "100%",
                              width: is320
                                ? "295px"
                                : is360
                                  ? "330px"
                                  : is375
                                    ? "345px"
                                    : is414
                                      ? "390px"
                                      : is480
                                        ? "390px"
                                        : is667
                                          ? "580px"
                                          : is896
                                            ? "850px"
                                            : isDesktopOrLaptop
                                              ? "850px"
                                              : "900px",
                            }}
                          >
                            <AgGridReact
                              headerHeight={0}
                              columnDefs={columnDefs}
                              rowData={rowData}
                              defaultColDef={defaultColDef}
                              context={contextAssignment}
                              onRowClicked={onRowClicked}
                              onGridReady={onGridReady}
                              domLayout={domLayout}
                              suppressContextMenu={true}
                              tooltipShowDelay={0}
                              frameworkComponents={{
                                ellipsisRenderer: EllipsisRenderer,
                                customTooltip: CustomTooltip,
                              }}
                            />
                          </div>
                        </div>
                        <GridContainer justify="center" >
                          <GridItem zeroMinWidth>
                            <div
                              style={{
                                width: `${size.width <= 414 ? size.width - 30 : 370}px`,
                              }}
                            >

                              <DialogActions >
                                <ThemeProvider theme={defaultBtnMaterialTheme}>
                                  <MDButton
                                    style={{ marginTop: '-7px', width: "60%" }}
                                    variant="contained"
                                    size="md"
                                    color="primary"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      createUserLog(props, "VIEW_LOG", "click PDF", "")
                                      let printIt = false
                                      if (size.width <= 414 && currentClient === "-1") {
                                        setOpenMessageDialogPDF(true)
                                      } else {
                                        printIt = true
                                        const _MS_PER_DAY = 1000 * 60 * 60 * 24
                                        const utc1 = Date.UTC(start_.getFullYear(), start_.getMonth(), start_.getDate());
                                        const utc2 = Date.UTC(end_.getFullYear(), end_.getMonth(), end_.getDate());
                                        let days_gap = Math.floor((utc2 - utc1) / _MS_PER_DAY);
                                        console.log(days_gap)
                                        if (size.width <= 414) {
                                          if (days_gap > 31) {
                                            printIt = false
                                            setOpenMessageDialogPDFPhone(true)
                                          }
                                        } else {
                                          if (days_gap > 366) {
                                            printIt = false
                                            setOpenMessageDialogPDFDesktop(true)
                                          }
                                        }
                                      }
                                      if (printIt) {
                                        setRowDataPrint([...rowData])
                                        setPrintingDocument(true);
                                        callRefreshTemp();
                                        setTimeout(() => {
                                          printDocument();
                                        }, 500);
                                      }
                                    }}
                                    startIcon={<SystemUpdateAlt />}
                                  // disabled={currentClient === "-1"}
                                  >
                                    PDF
                                  </MDButton>

                                  <MDButton
                                    style={{ marginTop: '-7px', width: "40%" }}
                                    id="cancelButton"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleCloseLog}
                                    type="submit"
                                    size="md"
                                  >
                                    BACK
                                  </MDButton>
                                </ThemeProvider>
                              </DialogActions>
                            </div>
                          </GridItem>
                        </GridContainer>
                        <div
                          id="GridPrint"
                          style={{
                            display: 'none',
                            margin: "50px 0 10px 0px",
                            overflowY: "auto",
                            height: `${size.height -
                              202}px`,
                            width: "870px"
                          }}
                        >
                          <div
                            id="myGridPrint"
                            className="ag-theme-balham"
                            style={{
                              boxSizing: "border-box",
                              height: "100%",
                              width: "850px"
                            }}
                          >
                            <AgGridReact
                              headerHeight={0}
                              columnDefs={columnDefsPrint}
                              rowData={rowDataPrint}
                              defaultColDef={defaultColDef}
                              context={contextAssignment}
                              onRowClicked={onRowClicked}
                              onGridReady={onGridReady}
                              domLayout={domLayout}
                              suppressContextMenu={true}
                              tooltipShowDelay={0}
                              frameworkComponents={{
                                ellipsisRenderer: EllipsisRendererPrint,
                                customTooltip: CustomTooltip,
                              }}
                            />
                          </div>
                        </div>
                      </Form>
                    )}
                  ></Formik>
                </GridItem>
              </GridContainer>
            </div>
          </DialogContent>
        </ThemeProvider>
      </Dialog>

      <ViewLogModal
        history={props.history}
        authUser={props.authUser}
        client={props.client}
        Transition={Transition}
        classes={props.classes}
        open={isOpenViewLog}
        setIsOpenViewLog={setIsOpenViewLog}
        close={closeViewLog}
        rowNode={selectedRowData}
        rowNodeClone={selectedRowDataClone}
        contextParent={contextParent}
        calledFrom={calledFrom}
        setCalledFrom={setCalledFrom}
        setOpenDeleteExpenseLog={setOpenDeleteExpenseLog}
        handleCloseDeleteExpenseLog={handleCloseDeleteExpenseLog}
        handleCloseDeleteServiceLog={handleCloseDeleteServiceLog}
        hasDeleteRequest={hasDeleteRequest}
      />

      <ServiceDelete
        history={props.history}
        Transition={Transition}
        classes={props.classes}
        openDeleteDoneLog={openDeleteDoneLog}
        handleCloseDeleteServiceLog={handleCloseDeleteServiceLog}
        handleCancelDeleteServiceLog={handleCancelDeleteServiceLog}
        setIsOpenViewLog={setIsOpenViewLog}
        setCalledFrom={setCalledFrom}
      ></ServiceDelete>

      <ServiceUpdate
        history={props.history}
        authUser={props.authUser}
        client={props.client}
        workLogId={workLogId}
        memberId={memberId}
        clientId={clientId}
        caseId={caseId}
        ord={ord}
        parentId={parentId}
        status={status}
        Transition={Transition}
        activitiesSource={activitiesSource}
        L2Source={L2Source}
        setActivity1Options={setActivity1Options}
        activity1Options={activity1Options}
        openUpdateServiceLog={openUpdateServiceLog}
        initialValues_service={initialValues_service}
        billingType={billingTypeService}
        setOpenUpdateServiceLog={setOpenUpdateServiceLog}
        refreshViewLogs={refreshAfterServiceLogUpdate}
        callRefreshTemp={callRefreshTempService}
        setOpenDeleteDoneLog={setOpenDeleteDoneLog}
        openDeleteDoneLog={openDeleteDoneLog}
        handleCloseDeleteServiceLog={handleCloseDeleteServiceLog}
        handleCancelDeleteServiceLog={handleCancelDeleteServiceLog}
        reArrange={reArrange}
        rowNode={selectedRowData}
        setRowData={setRowData}
        setIsOpenViewLog={setIsOpenViewLog}
        setSelectedRowData={setSelectedRowData}
        setSelectedRowDataClone={setSelectedRowDataClone}
        setCalledFrom={setCalledFrom}
        setIsOpenViewUpdate={setIsOpenViewUpdate}
        setRowNode={setRowNode}
        setOpenConfirmUpdateServiceLog={setOpenConfirmUpdateServiceLog}
      />

      <ExpenseUpdate
        history={props.history}
        authUser={props.authUser}
        client={props.client}
        workLogId={workLogId}
        memberId={memberId}
        clientId={clientId}
        caseId={caseId}
        ord={ord}
        parentId={parentId}
        status={status}
        Transition={Transition}
        openUpdateExpenseLog={openUpdateExpenseLog}
        initialValues_expense={initialValues_expense}
        billingType={billingTypeExpense}
        setOpenUpdateExpenseLog={setOpenUpdateExpenseLog}
        refreshViewLogs={refreshAfterExpenseLogUpdate}
        callRefreshTemp={callRefreshTempExpense}
        setOpenDeleteExpenseLog={setOpenDeleteExpenseLog}
        openDeleteDoneLog={openDeleteDoneLog}
        handleCloseDeleteExpenseLog={handleCloseDeleteExpenseLog}
        handleCancelDeleteExpenseLog={handleCancelDeleteExpenseLog}
        reArrange={reArrange}
        rowNode={selectedRowData}
        setRowData={setRowData}
        setIsOpenViewLog={setIsOpenViewLog}
        setSelectedRowData={setSelectedRowData}
        setSelectedRowDataClone={setSelectedRowDataClone}
        setCalledFrom={setCalledFrom}
        setIsOpenViewUpdate={setIsOpenViewUpdate}
        setRowNode={setRowNode}
        setOpenConfirmUpdateExpenseLog={setOpenConfirmUpdateExpenseLog}
      />

      <MessageDialog
        history={props.history}
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={messageOnMessageDialog}
        messageDialogTitle={"Nothing to View"}
      />

      <MessageDialog2
        history={props.history}
        Transition={props.Transition}
        openMessageDialog={openMessageDialogPDF}
        handleCloseMessageDialog={handleCloseMessageDialogPDF}
        setOpenMessageDialog={setOpenMessageDialogPDF}
        messageOnMessageDialog={"Please select a client"}
        fontSize1={"24"}
        messageDialogTitle={"PDF Generation"}
        variant={"outlined"}
      />

      <MessageDialog3
        history={props.history}
        fromTop320={"40"}
        Transition={props.Transition}
        openMessageDialog={openMessageDialogPDFPhone}
        handleCloseMessageDialog={handleCloseMessageDialogPDFPhone}
        setOpenMessageDialog={setOpenMessageDialogPDFPhone}
        messageOnMessageDialog={"PDF generation is limited to 31 days of logs. To create PDF for more days, use a desktop computer and web browser."}
        fontSize1={"24"}
        messageDialogTitle={"PDF Generation"}
        variant={"outlined"}
      />

      <MessageDialog4
        history={props.history}
        fromTop320={"40"}
        Transition={props.Transition}
        openMessageDialog={openMessageDialogPDFDesktop}
        handleCloseMessageDialog={handleCloseMessageDialogPDFDesktop}
        setOpenMessageDialog={setOpenMessageDialogPDFDesktop}
        messageOnMessageDialog={"PDF generation is limited to 365 days of logs."}
        fontSize1={"24"}
        messageDialogTitle={"PDF Generation"}
        variant={"outlined"}
      />

    </>
  );
}

export const CLIENTS_BY_EMAIL_QUERY = gql`
  query ClientsByEmailCaseDate(
    $email: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    clientsByEmailCaseDate(
      email: $email
      startDate: $startDate
      endDate: $endDate
    ) {
      member {
        id
      }
      id
      lastName
      firstName
      company
      status
      ord
      parentId
      hourlyRateService
      cases {
        id
        caseCode
        caseTitle
        status
        ord
        parentId
        fixedRateExpense
        hourlyRateExpense
        fixedRateService
        activityLog {
          id
          date
          billingType
          hours
          hourlyRate
          activityLogType
          activityLogSubType
          activityLogSubSubType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
        expenseLog {
          id
          date
          billingType
          hours
          hourlyRate
          expenselogType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
      }
    }
  }
`;

export const CLIENTS_ONLY_BY_EMAIL_QUERY = gql`
  query ClientsByEmail($email: String) {
    clientsByEmail(email: $email) {
      member {
        id
      }
      id
      lastName
      firstName
      company
      status
      cases {
        id
        caseCode
        caseTitle
        status
        activityLog {
          status
        }
        expenseLog {
          status
        }
      }
    }
  }
`;

export const DELETE_SERVICELOG = gql`
  mutation deleteServiceLog($activityLogId: ID!, $actionDate: DateTime) {
    deleteServiceLog(activityLogId: $activityLogId, actionDate: $actionDate) {
      id
    }
  }
`;

export const DELETE_EXPENSELOG = gql`
  mutation deleteExpenseLog($expenseLogId: ID!, $actionDate: DateTime) {
    deleteExpenseLog(expenseLogId: $expenseLogId, actionDate: $actionDate) {
      id
    }
  }
`;

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(Logs)))
    )
  )
);
