import React, { useState, useEffect } from "react";
import {
  withStyles,
} from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import {
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { cardTitle } from "assets/jss/material-kit-pro-react.js";
import Waiting from "utils/Waiting";

import ClientsPortfolio from "screens/Portfolio/ClientsPortfolio.js";
import {
  xxCLIENTS_BY_EMAIL_QUERY,
  CASES_BY_CLIENT_QUERY,
  MEMBER_CHECK_V2,
} from "utils/Graphql/queries";
import { withAuthentication } from "utils/Session";
import { withFirebase } from "utils/Firebase";
import CasesPortfolio from "./CasesPortfolio";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import Transition from "utils/Transition";


const useStyles = (theme) => ({
  root: {
    // flexGrow: 1,
    // margin: theme.spacing(20)
    // justifyContent: "center"
  },
  cardTitle,
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textTitle: {
    // paddingInline: "6px 16px",
    // paddingRight: "6px",
    textAlign: "left",
    display: "inline-block",
    width: "40%",
  },
  addButton: {
    textAlign: "right",
    width: "40%",
    display: "inline-block",
  },
  containeroo: {
    // textAlign: "center",
    // display: "inline-flex"
    marginBottom: "10px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  main: {
    maxWidth: "1200px",
    // maxHeight: "300px",
    justifyContent: "center",
    display: "flex",
    margin: "auto",
    marginTop: "-40px",
  },
  cardContainer: {
    height: "100%",
    overflow: "auto",
    // maxHeight: "500px",
    // maxHeight: "60%",
    "@media all and (max-height: 1920px)": {
      maxHeight: "1720px",
    },
    "@media all and (max-height: 1440px)": {
      maxHeight: "1240px",
    },
    "@media all and (max-height: 1366px)": {
      maxHeight: "1166px",
    },
    "@media all and (max-height: 1280px)": {
      maxHeight: "1080px",
    },
    "@media all and (max-height: 1112px)": {
      maxHeight: "912px",
    },
    "@media all and (max-height: 1024px)": {
      maxHeight: "524px",
    },
    "@media all and (max-height: 1080px)": {
      maxHeight: "880px",
    },
    "@media all and (max-height: 1000px)": {
      maxHeight: "800px",
    },
    "@media all and (max-height: 950px)": {
      maxHeight: "750px",
    },
    "@media all and (max-height: 900px)": {
      maxHeight: "700px",
    },
    "@media all and (max-height: 896px)": {
      maxHeight: "696px",
    },
    "@media all and (max-height: 850px)": {
      maxHeight: "650px",
    },
    "@media all and (max-height: 846px)": {
      maxHeight: "646px",
    },
    "@media all and (max-height: 815px)": {
      maxHeight: "615px",
    },
    "@media all and (max-height: 812px)": {
      maxHeight: "612px",
    },
    "@media all and (max-height: 800px)": {
      maxHeight: "700px",
    },
    "@media all and (max-height: 768px)": {
      maxHeight: "568px",
    },
    "@media all and (max-height: 740px)": {
      maxHeight: "540px",
    },
    "@media all and (max-height: 736px)": {
      maxHeight: "536px",
    },
    "@media all and (max-height: 720px)": {
      maxHeight: "520px",
    },
    "@media all and (max-height: 700px)": {
      maxHeight: "500px",
    },
    "@media all and (max-height: 670px)": {
      maxHeight: "470px",
    },
    "@media all and (max-height: 667px)": {
      maxHeight: "467px",
    },
    "@media all and (max-height: 640px)": {
      maxHeight: "440px",
    },
    "@media all and (max-height: 590px)": {
      maxHeight: "410px",
    },
    "@media all and (max-height: 580px)": {
      maxHeight: "400px",
    },
    "@media all and (max-height: 570px)": {
      maxHeight: "390px",
    },
    "@media all and (max-height: 560px)": {
      maxHeight: "380px",
    },
    "@media all and (max-height: 550px)": {
      maxHeight: "370px",
    },
    "@media all and (max-height: 540px)": {
      maxHeight: "360px",
    },
    "@media all and (max-height: 530px)": {
      maxHeight: "350px",
    },
    "@media all and (max-height: 520px)": {
      maxHeight: "340px",
    },
    "@media all and (max-height: 510px)": {
      maxHeight: "330px",
    },
    "@media all and (max-height: 500px)": {
      maxHeight: "320px",
    },
    "@media all and (max-height: 490px)": {
      maxHeight: "320px",
    },
    "@media all and (max-height: 480px)": {
      maxHeight: "310px",
    },
    "@media all and (max-height: 470px)": {
      maxHeight: "300px",
    },
    "@media all and (max-height: 460px)": {
      maxHeight: "290px",
    },
    // "@media all and (max-height: 460px)": {
    //   maxHeight: "290px",
    // },
    "@media all and (max-height: 455px)": {
      maxHeight: "280px",
    },
    "@media all and (max-height: 414px)": {
      maxHeight: "244px",
    },
    "@media all and (max-height: 375px)": {
      maxHeight: "205px",
    },
    "@media all and (max-height: 360px)": {
      maxHeight: "190px",
    },
    "@media all and (max-height: 320px)": {
      maxHeight: "155px",
    },
    // [theme.breakpoints.between('lg', 'xl')]:{
    //   maxHeight: "1000px"
    // },
    // [theme.breakpoints.down('md')]:{
    //   maxHeight: "400px"
    // },

    // [theme.breakpoints.down('700px')]:{
    //   maxHeight: "300px"
    // }
  },
  title: {
    // width: '100%',
    [theme.breakpoints.between("lg", "xl")]: {
      minWidth: "1000px",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "400px",
    },
    [theme.breakpoints.down("700px")]: {
      minWidth: "300px",
    },
    "@media all and (max-height: 570px)": {
      minWidthe: "280px",
    },
    // display: 'flex',
    // justifyContent: "space-between",
    // alignContent: "space-between",
    textAlign: "center",
  },
  spanSpace: {
    padding: "6rem",
  },
  // skeletonCard: {
  //   margin: "0",
  //   height: "10px",
  // }
});

const Portfolio = (props) => {
  const { authUser, client, classes } = props;
  const [memberClients, setMemberClients] = useState([]);
  const [clientCases, setClientCases] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [currentClient, setCurrentClient] = useState([]);
  const [setOpenCases] = useState(false);
  const [member, setMember] = useState([]);
  const [page, setPage] = useState("clients");
  const [loading, setLoading] = useState(false);
  const [openPortfolio, setOpenPortfolio] = useState(true);
  const [hasDeleteRequest, setHasDeleteRequest] = useState(false);

  useEffect(() => {
    if (page === "clients") {
      reFetchClientsByEmail(fetchClientsByEmail, setMemberClients);
    }
  }, [page]);


  useEffect(() => {
    handleMemberCheck();



  }, []);

  const memberCheck = async () => {
    const { email } = authUser;
    try {
      const response = await client.query({
        query: MEMBER_CHECK_V2,
        variables: {
          email,
        },
        fetchPolicy: "no-cache",
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleMemberCheck = () => {
    memberCheck().then((response) => {
      console.log(response);
      if (response.data.memberCheck) {
        const memberCheck = response.data.memberCheck;
        setMember(memberCheck);
        if (
          typeof memberCheck.serviceRequest !== "undefined" &&
          memberCheck.serviceRequest !== null
        ) {
          const { serviceRequest } = memberCheck;
          const deleteServiceRequest = serviceRequest.filter((sr, index) => {
            console.log(sr);
            if (sr.status === "NEW" && sr.requestType === "DELETE") {
              return sr;
            }
          });
          console.log(deleteServiceRequest);
          if (deleteServiceRequest.length > 0) {
            console.log("has delete request");
            setHasDeleteRequest(true);
          }
        }
      }
    });
  };
  const fetchClientsByEmail = async (client, email) => {
    try {
      const response = await client.query({
        query: xxCLIENTS_BY_EMAIL_QUERY,
        variables: {
          email,
        },
        fetchPolicy: "no-cache",
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const reFetchClientsByEmail = (fetchClientsByEmail, setMemberClients) => {
    setLoading(true);
    fetchClientsByEmail(client, authUser.email)
      .then((response) => {
        console.log(response);
        var clients = response.data.clientsByEmail;
        console.log(clients);
        clients = clients.filter((cl, index) => {
          if (cl.status === "ACTIVE" || cl.status === "INACTIVE") {
            return cl;
          }
        });
        console.log(clients);
        if (clients) {
          // debugger;
          // setTimeout(()=>{
          setMemberClients(clients);
          setLoading(false);
          // }, 1000)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getClientCases = async (clientId) => {
    try {
      const cases = await client.query({
        query: CASES_BY_CLIENT_QUERY,
        variables: {
          clientId,
        },
        fetchPolicy: "no-cache",
      });
      return cases;
    } catch (error) {
      return error;
    }
  };
  const reFetchCases = (client) => {
    console.log("reFetchCases...");
    setLoading(true);
    getClientCases(client.id)
      .then((response) => {
        console.log(response);
        if (response.data) {
          if (response.data.casesByClient) {
            console.log("response.data");
            console.log(response.data);
            const activeCases = response.data.casesByClient.filter(
              (cl, index) => {
                console.log(cl);
                if (cl.status === "ACTIVE" || cl.status === "INACTIVE")
                  return cl;
              }
            );
            console.log("activeCases");
            console.log(activeCases);
            setClientData(response.data);
            setClientCases(activeCases);
          }
        }
      })
      .then(() => {
        // setLoading(false)
        setTimeout(() => {
          setLoading(false);
          console.log("reFetchCases... then ...Loading");
        }, 1000);
        setPage("cases");
        console.log("reFetchCases... then");
        // setOpenCases(true)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        console.log("reFetchCases... catch");
      });
  };

  const handleClosePortfolio = () => {
    setOpenPortfolio(false);
  };

  const pageComponent = () => {
    if (page === "clients") {
      return (
        <ClientsPortfolio
          member={member}
          memberClients={memberClients}
          setMemberClients={setMemberClients}
          classes={classes}
          reFetchClientsByEmail={() => {
            reFetchClientsByEmail(fetchClientsByEmail, setMemberClients);
          }}
          setLoading={setLoading}
          setPage={setPage}
          setClientData={setClientData}
          setClientCases={setClientCases}
          loading={loading}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          reFetchCases={reFetchCases}
          hasDeleteRequest={hasDeleteRequest}
          {...props}
        />
      );
    } else if (page === "cases") {
      return (
        <CasesPortfolio
          member={member}
          clientData={clientData}
          clientCases={clientCases}
          classes={classes}
          setPage={setPage}
          reFetchClientsByEmail={() => {
            reFetchClientsByEmail(fetchClientsByEmail, setMemberClients);
          }}
          reFetchCases={() => reFetchCases(currentClient)}
          memberClients={memberClients}
          setOpenCases={setOpenCases}
          currentClient={currentClient}
          hasDeleteRequest={hasDeleteRequest}
          {...props}
        />
      );
    }
  };
  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openPortfolio}
        // TransitionComponent={props.Transition}
        keepMounted
        onClose={handleClosePortfolio}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <CloseButton classes={classes} />
          <LogoButton />

        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div id="waiting" style={{ marginTop: "50px", marginBottom: "20px" }}>
            <Waiting
              Transition={Transition}
              classes={props.classes}
              isOpenWaiting={loading}
            ></Waiting>
          </div>
          <div id="mainScreen" style={{ marginTop: "50px" }}>
            <div className={classes.root + " " + classes.main}>
              <GridContainer justify="center">
                {loading
                  ? null
                  : // <p>hey</p>)
                  pageComponent()}
              </GridContainer>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default withStyles(useStyles)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(Portfolio)))
    )
  )
);
