
import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import Waiting from "utils/Waiting";
import { Formik, Form } from "formik";

import Tooltip from "@material-ui/core/Tooltip";

import circlelogo from 'assets/img/byn-logo-circle.png';

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import "utils/react-datetime/css/react-datetime.css";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";

import { withFirebase } from "utils/Firebase";

import { ThemeProvider } from "@material-ui/styles";

import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"

import MessageDialog from 'utils/MessageDialog';
import Transition from 'utils/Transition';

import { CREATE_DOWNLOAD_REQUEST } from 'utils/Graphql/mutations'
import useWindowSize from 'utils/useWindowSize';
import { createUserLog } from "utils/CreateUserLog";

const style = {
};


function Download(props) {
  const { classes, open, onClose, client, memberData, reFetch } = props;

  const size = useWindowSize()
  const [hasDownloadRequest, setHasDownloadRequest] = useState(false);
  const [isOpenWaiting] = useState(false)
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block"
      document.getElementById("mainScreen").style.opacity = 0.3
    } else {
      if (document && document.getElementById("waiting") && document.getElementById("mainScreen")) {
        document.getElementById("waiting").style.display = "none"
        document.getElementById("mainScreen").style.opacity = 1
      }
    }
  }, [isOpenWaiting])

  useEffect(() => {
    console.log(memberData)
  }, [])
  useEffect(() => {
    console.log(memberData)
    const hasDownloadReq = handleCheckDownloadRequest(memberData.serviceRequest)
    if (hasDownloadReq) setHasDownloadRequest(true)
    // setHasDownloadRequest(handleCheckDownloadRequest(memberData.serviceRequest))
  }, [memberData])

  const handleCheckDownloadRequest = (serviceRequest) => {
    console.log(serviceRequest)
    if (serviceRequest) {
      const sr = serviceRequest.filter((srq, ix) => {
        console.log(srq)
        if (srq.requestType === 'DOWNLOAD' && srq.status === 'NEW') {
          return srq
        }
      })
      console.log(sr)
      if (sr.length > 0) return true
      else return false
    }
    else return false
  }

  const [openMessageDialog, setOpenMessageDialog] = useState(false)

  const handleCloseMessageDialog = () => {
    reFetch()
    setOpenMessageDialog(false)
    onClose()
    // props.history.push('/profile');
  }

  const dataDownload = async () => {
    const { id } = memberData;
    const date = new Date().toISOString();
    let data = await client.mutate({
      mutation: CREATE_DOWNLOAD_REQUEST,
      variables: {
        date,
        memberId: id,
        notes: 'TEST',
      }
    })
    return data;
  }

  const handleDataDownload = () => {
    dataDownload().then((response) => {
      createUserLog(props, "DATA DOWNLOAD", "Created Data Download request", "")
      console.log(response)
      // setOpenConfirmDownload(false)
      setOpenMessageDialog(true)
      // setSuccessMsg('Data download request success. Please wait until we send your data to your email.')
      // setOpenSuccess(true)
    })
  }

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog
        }}
        open={open}
        onClose={onClose}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              {size.width <= 320 ?
                <>
                  <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                    Data
                      </div>
                  <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                    Download
                      </div>
                </>
                :
                <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                  Data Download
                    </div>
              }

              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                {size.width <= 320 ?
                  <Button
                    style={{ margin: `-79px ${size.width <= 414 ? -10 : -20}px 0 0 ` }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img style={{ width: '50px' }} src={circlelogo} alt="billyounow.com" />
                  </Button>
                  :
                  <Button
                    style={{ margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 ` }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img style={{ width: '50px' }} src={circlelogo} alt="billyounow.com" />
                  </Button>
                }
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div id="waiting" style={{ marginBottom: '70px', marginTop: 20 }}>
            <Waiting
              Transition={Transition}
              isOpenWaiting={isOpenWaiting}
            ></Waiting>
          </div>
          {/* {hasDownloadRequest ?
              <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                <Typography>
                  You have a pending delete request. Cancel the delete request to log an expense.
              </Typography>
              </div>
              : ''} */}
          <div id="mainScreen" style={{ marginTop: '0px' }}>
            <div className={classes.container} style={{ width: `${size.width <= 414 ? size.width - 30 : 370}px` }}>
              <Formik
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, { setSubmitting }) => {
                  handleDataDownload()
                }}
              >
                {({
                  values,
                  isSubmitting,
                  handleSubmit,
                  setFieldValue,
                  setSubmitting,
                  touched,
                  errors
                }) => (
                  <Form mode="themed" >
                    <div className={classes.container}
                      style={{
                        overflow: "auto", width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        height: `${size.width <= 320 ? size.height - 220 : size.height - 195}px`
                      }} >
                      {/* <div className={classes.container}> */}
                      <div style={{ margin: '80px 0 0px 0', fontSize: '26px', fontWeight: '400', textAlign: 'center' }}>
                        Request Data
                              </div>
                      <div style={{ margin: '10px 0 60px 0', fontSize: '26px', fontWeight: '400', textAlign: 'center' }}>
                        Download
                              </div>

                      {/* </div> */}
                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{
                            width: "60%"
                          }}
                          variant="contained"
                          color="primary"
                          size="md"
                          disabled={isSubmitting}
                          onClick={handleDataDownload}
                        >
                          Go
                              </Button>
                        <Button
                          style={{
                            width: "40%"
                          }}
                          variant="outlined"
                          color="primary"
                          size="md"
                          // disabled={isSubmitting}
                          // href="/app/data"
                          // type="submit"
                          onClick={onClose}
                        >
                          Back
                              </Button>

                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>

      </Dialog>

      <MessageDialog
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={"Data download"}
        messageOnMessageDialog2={"email will be sent."}
        messageDialogTitle={"Confirmation"}
        messageLabelBtn={"DONE"}
      // push={"/app/data"}

      />


    </>
  )
}

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(Download)))
    )
  )
);