import React from 'react'
import { Typography, withStyles, Box } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer';
import useWindowSize from 'utils/useWindowSize';

const style = theme => ({
  root: {
    width: '20rem',
    margin: theme.spacing(2),
  },
  media: {
    height: 200,
    width: 200
  },
  rootStyle: {
    display: 'flex'
  }
})

const Testimonials = (props) => {
  const { classes } = props


  const size = useWindowSize()

  return (
    <div
      className={classes.container}
      style={{
        overflow: "auto",
        height: `${size.height - 145}px`
      }}
    >


      <Typography style={{ marginTop: '80px', fontSize: '26px', fontWeight: '400', textAlign: "center" }} component='p'>
        Under construction.
                </Typography>

    </div>
  )
}

export default withStyles(style)(Testimonials)
