/* eslint-disable */
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import cx from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Apps from '@material-ui/icons/Apps';
import PersonAdd from '@material-ui/icons/PersonAdd';
import ViewDay from '@material-ui/icons/ViewDay';
import Dns from '@material-ui/icons/Dns';
import Build from '@material-ui/icons/Build';
import ListIcon from '@material-ui/icons/List';
import People from '@material-ui/icons/People';
import Assignment from '@material-ui/icons/Assignment';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import Chat from '@material-ui/icons/Chat';
import Call from '@material-ui/icons/Call';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import AccountBalance from '@material-ui/icons/AccountBalance';
import ArtTrack from '@material-ui/icons/ArtTrack';
import ViewQuilt from '@material-ui/icons/ViewQuilt';
import LocationOn from '@material-ui/icons/LocationOn';
import Fingerprint from '@material-ui/icons/Fingerprint';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Store from '@material-ui/icons/Store';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Layers from '@material-ui/icons/Layers';

import LineStyle from '@material-ui/icons/LineStyle';
import DashboardIcon from '@material-ui/icons/Dashboard';

import Tooltip from '@material-ui/core/Tooltip';

// core components
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';

import headerLinksStyle from 'assets/jss/material-kit-pro-react/components/headerLinksStyle.js';

import { AuthUserContext } from 'utils/Session';
import SignOutButton from 'screens/SignOut';
import Avatar from '@material-ui/core/Avatar';

const styles = {
  avatar: {
    margin: 10
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  }
};
const activeRoute = routeName => {
  return window.location.href.indexOf(routeName) > -1 ? true : false;
};
const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

const smoothScroll = (e, target) => {
  if (window.location.pathname === '/sections') {
    var isMobile = navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );
    if (isMobile) {
      // if we are on mobile device the scroll into view will be managed by the browser
    } else {
      e.preventDefault();
      var targetScroll = document.getElementById(target);
      scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    }
  }
};
const scrollGo = (element, to, duration) => {
  var start = element.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20;

  var animateScroll = function () {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};
var onClickSections = {};

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: props.auth || {},
      //history: props.history || {},
      profile: {}
    };
  }

  goTo(route) {
    this.props.history.replace(`/${route}`);
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  componentDidMount = async () => {
    console.log('in start hderLinx CompnentDidMount');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    console.log('in end hderLinx CompnentDidMount');
  };

  componentDidUpdate = async () => {
    console.log('in start hderLinx componentDidUpdate');

    console.log('in end hderLinx componentDidUpdate');
  };

  componentWillUpdate() {
    console.log('in start hderLinx componentWillUpdate');

    console.log('in end hderLinx componentWillUpdate');
  }

  // resetStore = async () => {
  //   console.log("start resetStore()");
  //   console.log(this.props);
  //   const { client } = this.props;
  //   await client.resetStore();
  //   console.log("end resetStore()");
  // };

  render() {
    const { classes, dropdownHoverColor } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? (
            <List className={classes.list + ' ' + classes.mlAuto}>
              {authUser.username && (
                <ListItem className={classes.listItem}>
                  <Button style={{ background: '#fff', color: '#444' }}>
                    Hello, {authUser.username}
                  </Button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </ListItem>
              )}

              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={dropdownHoverColor}
                  buttonText=""
                  buttonProps={{
                    className: classes.navLink,
                    color: 'transparent'
                  }}
                  buttonIcon={Apps}
                  dropdownList={[
                    <Link to="/logmywork" className={classes.dropdownLink}>
                      <LineStyle className={classes.dropdownIcons} /> Log My
                      Work
                    </Link>,

                    <Link to="/invite" className={classes.dropdownLink}>
                      <LineStyle className={classes.dropdownIcons} /> Invite
                      Members
                    </Link>,
                    <Link to="/passwordChange" className={classes.dropdownLink}>
                      <LineStyle className={classes.dropdownIcons} /> Change
                      Password
                    </Link>
                  ]}
                />
              </ListItem>

              <ListItem className={classes.listItem}>
                <SignOutButton />
              </ListItem>

              {/* <ListItem className={classes.listItem}>
                <Tooltip
                  title={JSON.stringify(authUser.providerData[0].email).replace(
                    /"([^"]+(?="))"/g,
                    '$1'
                  )}
                  aria-label="Add"
                >
                  <Avatar
                    alt=""
                    src={JSON.stringify(
                      authUser.providerData[0].photoURL
                    ).replace(/"([^"]+(?="))"/g, '$1')}
                    className={classes.bigAvatar}
                  />
                </Tooltip>
              </ListItem> */}
            </List>
          ) : (
              <List className={classes.list + ' ' + classes.mlAuto}>
                <ListItem className={classes.listItem}>
                  <NavLink
                    to={'/signup'}
                    className={cx(classes.navLink, {
                      [classes.navLinkActive]: activeRoute('/signup')
                    })}
                  >
                    <PersonAdd className={classes.listItemIcon} />
                    <ListItemText
                      primary={'Sign Up'}
                      disableTypography={true}
                      className={classes.listItemText}
                    />
                  </NavLink>
                </ListItem>

                <ListItem className={classes.listItem}>
                  <NavLink
                    to={'/'}
                    className={cx(classes.navLink, {
                      [classes.navLinkActive]: activeRoute('/')
                    })}
                  >
                    <Fingerprint className={classes.listItemIcon} />
                    <ListItemText
                      primary={'Sign In'}
                      disableTypography={true}
                      className={classes.listItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            )
        }
      </AuthUserContext.Consumer>
    );
  }
}
// onClick={this.logout.bind(this)}
HeaderLinks.defaultProps = {
  hoverColor: 'primary'
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ])
};

//export default withStyles(headerLinksStyle)(withLayout((HeaderLinks)));

export default withStyles(headerLinksStyle, styles)(HeaderLinks);
