import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

import Main from './screens/Main';
import Firebase, { FirebaseContext } from './utils/Firebase';


console.log("............I N D E X..................");
console.log(process.env.NODE_ENV);
console.log("process.env.REACT_APP_endpoint");
console.log(process.env.REACT_APP_endpoint);

const httpLink = new createUploadLink({
  uri:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_endpoint
      : process.env.REACT_APP_prodEndpoint,
  request: (operation) => {
    operation.setContext({
      fetchOptions: {
        credentials: "include",
      },
      headers: null,
    });
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log("*****************************");
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log("*****************************");
    console.log(`[Network error]: ${networkError}`);
  }
});

const link = ApolloLink.from([errorLink, httpLink]);
console.log("link *****************************");
console.log(link);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
  // Provide some optional constructor fields
  name: "react-web-client",
  version: "1.3",
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  shouldBatch: true,
});

console.log("client *****************************");
console.log(client);

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ApolloProvider client={client}>
      <Main client={client} />
    </ApolloProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);
