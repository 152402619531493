
import { createMuiTheme } from "@material-ui/core";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    // primary: red, //C62626
    primary: {
      main: "#000000"
    },
    secondary: { main: "#000000" },
    grey: { main: "#22BF19" }
  },
  // title: {
  //   marginBottom: theme.spacing(3)
  // },
  // form: {
  //   "& .MuiInput-root": {
  //     marginBottom: theme.spacing(1)
  //   },
  //   "& .MuiFormLabel-root.Mui-focused": {
  //     color: theme.palette.type === "dark" ? cyan[300] : theme.palette.primary
  //   },
  //   "& .MuiInput-underline:after": {
  //     borderBottomColor:
  //       theme.palette.type === "dark" ? cyan[300] : theme.palette.primary
  //   }
  // },
  formTitle: {
    padding: 4
  },
  // formTitleIcon: {
  //   marginRight: theme.spacing(1),
  //   color:
  //     theme.palette.type === "dark" ? cyan[300] : theme.palette.secondary.main
  // },
  // paper: {
  //   padding: theme.spacing(2),
  //   marginBottom: theme.spacing(2)
  // },
  // table: {
  //   marginTop: theme.spacing(1)
  // },
  // grandTotal: {
  //   "& td": {
  //     backgroundColor: theme.palette.type === "dark" ? blue[700] : blue[50],
  //     borderBottom: "none"
  //   },
  //   "& .MuiTableCell-root": {
  //     paddingTop: theme.spacing(2),
  //     paddingBottom: theme.spacing(2)
  //   }
  // },
  // pay: {
  //   marginTop: theme.spacing(2),
  //   backgroundColor: green[600],
  //   "&:hover, &:focus, &:active": {
  //     backgroundColor: green[900]
  //   }
  // },
  // error: {
  //   marginTop: theme.spacing(1),
  //   marginBottom: theme.spacing(1)
  // },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
      label: {
        fontWeight: 600,
        fontSize: "20px",
        // padding: "-5px -5px -5px -5px",
        // fontHeight: "1px",
        margin: "-10px -2px -10px -2px",
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: '#C62626',
      },
      root: {
        // "&$focused": {
        color: "#999",
        fontSize: "20px",
        backgroundColor: "#fff",
        margin: "-2px 0 0 -5px",
        padding: "0 10px 0 10px"
        // }
      }
    }
  }
});

export default defaultMaterialTheme