import { gql } from "apollo-boost";

export const MEMBER_CHECK = gql`
  query memberCheck($email: String) {
    memberCheck(email: $email) {
      id
      email
    }
  }
`;

export const MEMBER_CHECK1 = gql`
  query memberCheck1($email: String) {
    memberCheck1(email: $email) {
      id
      email
      userName
    }
  }
`;

export const MEMBER_CHECKV2 = gql`
  query memberCheck($email: String) {
    memberCheck(email: $email) {
      id
      email
      userName
      displayName
      hasConfirmedAgreement
      hasRespondedToInvite
      hasInitiallyLoggedIn
      phoneNo
      isLoggedIn
      lastName,
      firstName,
      middleName,
      serviceRequest {
        id
        member {
          id
          email
        }
        date
        dateServed 
        dateCancelled
        dateDeletion
        requestType
        notes
        status
      }
    }
  }
`;

export const xxCLIENTS_BY_EMAIL_QUERY = gql`
  query ClientsByEmail($email: String) {
    clientsByEmail(email: $email) {
      member {
        id
      }
      id
      lastName
      firstName
      company
      status
      ord
      parentId
      cases {
        id
        caseCode
        caseTitle
        status
        ord
        parentId
        member {
          id
        }
        activityLog {
          id
          date
          billingType
          hours
          hourlyRate
          activityLogType
          activityLogSubType
          activityLogSubSubType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
        expenseLog {
          id
          date
          billingType
          hours
          hourlyRate
          expenselogType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
      }
    }
  }
`;

export const CLIENTS_BY_EMAIL_QUERY = gql`
  query ClientsByEmailCaseDate(
    $email: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    clientsByEmailCaseDate(
      email: $email
      startDate: $startDate
      endDate: $endDate
    ) {
      member {
        id
      }
      id
      lastName
      firstName
      company
      status
      ord
      parentId
      cases {
        id
        caseCode
        caseTitle
        status
        ord
        parentId
        member {
          id
        }
        activityLog {
          id
          date
          billingType
          hours
          hourlyRate
          activityLogType
          activityLogSubType
          activityLogSubSubType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
        expenseLog {
          id
          date
          billingType
          hours
          hourlyRate
          expenselogType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
      }
    }
  }
`;

export const CASES_BY_CLIENT_QUERY = gql`
  query casesByClient($clientId: ID!) {
    casesByClient(clientId: $clientId) {
      id
      caseCode
      caseTitle
      status
      ord
      parentId
      member {
        id
      }
      client {
        id
      }
      activityLog {
        id
        date
        billingType
        hours
        hourlyRate
        activityLogType
        activityLogSubType
        activityLogSubSubType
        amount
        fixedRate
        notes
        startTime
        endTime
        actionDate
        action
        status
        ord
        parentId
      }
      expenseLog {
        id
        date
        billingType
        hours
        hourlyRate
        expenselogType
        amount
        fixedRate
        notes
        startTime
        endTime
        actionDate
        action
        status
        ord
        parentId
      }
    }
  }
`;

export const CASES_BY_CLIENT_NOLOGS_QUERY = gql`
  query casesByClient($clientId: ID!) {
    casesByClient(clientId: $clientId) {
      id
      caseCode
      caseTitle
      status
      ord
    }
  }
`;

export const MEMBER_CHECK_V2 = gql`
  query memberCheck($email: String) {
    memberCheck(email: $email) {
      id
      email
      displayName
      latestClient {
        id
        firstName
        lastName
        company
        hourlyRate
      }
      latestCase {
        id
        caseCode
        caseTitle
        billingType
        fixedRate
      }
      serviceRequest {
        id
        member {
          id
          email
        }
        date
        dateServed
        dateCancelled
        dateDeletion
        requestType
        notes
        status
      }
    }
  }
`;

export const CLIENT_FILTER = gql`
  query clientFilter($filter: String, $memberId: ID!) {
    clientFilter(filter: $filter, memberId: $memberId) {
      member {
        id
      }
      id
      lastName
      firstName
      company
      status
      ord
      parentId
      cases {
        id
        caseCode
        caseTitle
        status
        ord
        parentId
        member {
          id
        }
        activityLog {
          id
          date
          billingType
          hours
          hourlyRate
          activityLogType
          activityLogSubType
          activityLogSubSubType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
        expenseLog {
          id
          date
          billingType
          hours
          hourlyRate
          expenselogType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
      }
    }
  }
`;

export const CASE_FILTER = gql`
  query casesFilter($filter: String, $clientId: ID!) {
    casesFilter(filter: $filter, clientId: $clientId) {
      id
      caseCode
      caseTitle
      status
      ord
      parentId
      member {
        id
      }
      client {
        id
      }
      activityLog {
        id
        date
        billingType
        hours
        hourlyRate
        activityLogType
        activityLogSubType
        activityLogSubSubType
        amount
        fixedRate
        notes
        startTime
        endTime
        actionDate
        action
        status
        ord
        parentId
      }
      expenseLog {
        id
        date
        billingType
        hours
        hourlyRate
        expenselogType
        amount
        fixedRate
        notes
        startTime
        endTime
        actionDate
        action
        status
        ord
        parentId
      }
    }
  }
`;

export const CLIENTS_BY_MEMBERNAME_QUERY = gql`
  query ClientsByMemberName(
    $memberId: ID!
    $lastName: String
    $firstName: String
  ) {
    clientsByMemberName(
      memberId: $memberId
      lastName: $lastName
      firstName: $firstName
    ) {
      id
      lastName
      firstName
      company
      status
    }
  }
`;

export const CLIENTS_BY_MEMBER_QUERY = gql`
  query ClientsByMember($memberId: ID!) {
    clientsByMember(memberId: $memberId) {
      id
      lastName
      firstName
      company
      parentId
      status
      ord
    }
  }
`;

export const IS_BYN_MEMBER_QRY = gql`
  query bynCheck($email: String!) {
    bynCheck(email: $email) {
      email
    }
  }
`;

export const TICKETS_QUERY = gql`
  query  {
    tickets{
      id
      ticketNumber
      name
      message
      dateCreation
      dateCompletion
      status
    }
  }
`

export default MEMBER_CHECK;
