import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import circlelogo from "assets/img/byn-logo-circle.png";
import Waiting from "utils/Waiting";
import * as Yup from "yup";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import useWindowSize from 'utils/useWindowSize';

// // core components
import { withFirebase } from "utils/Firebase";
import * as ROUTES from "constants/routes";
import MessageDialog from "utils/MessageDialog";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import { ThemeProvider } from "@material-ui/styles";
import { Formik, Form } from "formik";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import { LOG_OUT } from "utils/Graphql/mutations";

import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Transition from "utils/Transition";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createUserLog } from "utils/CreateUserLog";

import IconButton from "@material-ui/core/IconButton";

// console.log("............/auth/passwordforget..................");
// console.log("process.env.NODE_ENV");
// console.log(process.env.NODE_ENV);
// console.log("process.env.REACT_APP_endpoint");
// console.log(process.env.REACT_APP_endpoint);

// const httpLink = new createUploadLink({
//   uri:
//     process.env.NODE_ENV === "development"
//       ? process.env.REACT_APP_endpoint
//       : process.env.REACT_APP_prodEndpoint,
//   request: (operation) => {
//     operation.setContext({
//       fetchOptions: {
//         credentials: "include",
//       },
//       headers: null,
//     });
//   },
// });

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors) {
//     graphQLErrors.map(({ message, locations, path }) => {
//       console.log("*****************************");
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       );
//     });
//   }

//   if (networkError) {
//     console.log("*****************************");
//     console.log(`[Network error]: ${networkError}`);
//   }
// });

// const link = ApolloLink.from([errorLink, httpLink]);
// console.log("link *****************************");
// console.log(link);

// const cache = new InMemoryCache();

// const client = new ApolloClient({
//   link,
//   cache,
//   // Provide some optional constructor fields
//   name: "react-web-client",
//   version: "1.3",
//   queryDeduplication: false,
//   defaultOptions: {
//     watchQuery: {
//       fetchPolicy: "cache-and-network",
//     },
//   },
//   shouldBatch: true,
// });



function PasswordForget(props) {
  const { client } = props
  const [isOpenWaiting] = useState(false);

 

  useEffect(() => {
    if (isOpenWaiting) {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "block";
        document.getElementById("mainScreen").style.opacity = 0.3;
      }
    } else {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);
  const size = useWindowSize()

  const [values, setValues] = React.useState({
    email: "",
  });
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [error, setError] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
    // props.history.push("/auth/signin");
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (event) => {
    debugger;
    setIsSubmitting(true);
    props.firebase
      .doPasswordReset(values.email)
      .then((authUser) => {
        const { firebase } = props;

        firebase.doSignOut();

        console.log("start LOG_OUT");
        client
          .mutate({
            mutation: LOG_OUT,
            variables: {
              email: values.email,
            },
          })
          .then(() => {
            let authUser = { email: values.email }
            // this.props.history.push('/auth/dashboard');
            createUserLog({ authUser, client }, "RESET PASSWORD", "reset password " + values.email, values.email + " has requested a reset of his/her password")
            setIsSubmitting(false);
            setOpenMessageDialog(true);
          });
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        debugger;
        setError(error);
      });

    event.preventDefault();
  };

  const { classes } = props;


  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={props.openPasswordForget}
        // TransitionComponent={props.Transition}
        keepMounted
        // onClose={handleCloseExpenseLog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              {size.width <= 414 ? (
                <>
                  <div
                    style={{
                      margin: `10px 0 0 0`,
                      color: primaryColor[0],
                      fontSize: "30px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    Reset
                      </div>
                  <div
                    style={{
                      margin: `10px 0 0 0`,
                      color: primaryColor[0],
                      fontSize: "30px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    Password
                      </div>
                </>
              ) : (
                <div
                  style={{
                    margin: `10px 0 0 0`,
                    color: primaryColor[0],
                    fontSize: "30px",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  Reset Password
                </div>
              )}

              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `${size.width <= 414 ? -62 : -42}px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                  }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/auth/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="notarynet.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
              <Waiting Transition={Transition} isOpenWaiting={isOpenWaiting} />
            </div>
            <div id="mainScreen" style={{ marginTop: "-70px" }}>

              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email format")
                    .required("Required"),
                  // phoneNo: Yup.string().required('Required')
                })}
                onSubmit={(values, { setSubmitting }) => { }}
              >
                {({

                }) => (
                  <Form onSubmit={onSubmit}>
                    <div
                      className={classes.container}
                      style={{
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        overflow: "auto",
                        height: `${size.height - 145
                          }px`,
                      }}
                    >
                      <TextField
                        style={{ marginTop: "60px" }}
                        autoFocus
                        onChange={handleChange("email")}
                        name="email"
                        label="Email"
                        value={values.email}
                        id="input-with-icon-textfield"
                        type="email"
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        required={values.email.length === 0}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Typography
                        style={{
                          textAlign: "center", fontSize: '16px', color: primaryColor[0],
                          display: values.email === "" ? 'block' : 'none'
                        }}>
                        <em>* Required</em>
                      </Typography>

                      <FormHelperText
                        style={{
                          color: primaryColor[0],
                          margin: "10px 0 20px 10px",
                          fontSize: "18px",
                        }}
                      >
                        {error.message ? error.message : null}
                      </FormHelperText>
                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>


                        <Button
                          style={{ width: "60%" }}
                          variant="contained"
                          type="submit"
                          color="primary"
                          size="sm"
                          disabled={isSubmitting || values.email === ""}
                        >
                          Reset
                              </Button>
                        <Button
                          style={{ width: "40%" }}
                          variant="outlined"
                          color="primary"
                          size="sm"

                          onClick={e => {
                            debugger
                            e.preventDefault()
                            props.setOpenPasswordForget(false)
                          }}

                        >
                          Back
                              </Button>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider >
      </Dialog >

      <MessageDialog
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={"Password reset"}
        messageOnMessageDialog2={"email sent."}
        messageDialogTitle={"Confirmation"}
        messageLabelBtn={"DONE"}
      />
    </>
  );
}


export default withStyles(javascriptStyles)(
  withStyles(basicsStyle)(
    withStyles(modalStyle)(withRouter(withFirebase(PasswordForget)))
  )
)