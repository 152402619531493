
import { createMuiTheme } from "@material-ui/core";

const viewLogMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#6C6C6C"
    },
    secondary: { main: "#22BF19" },
    grey: { main: "#22BF19" }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#A4A4A4"
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#A4A4A4",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "#A4A4A4"
          }
        },
        "&$focused $notchedOutline": {
          borderColor: "#A4A4A4",
          borderWidth: "thin"
        }
      }
    },
    MuiFormLabel: {
      root: {
        // "&$focused": {
        color: "#000",
        fontSize: "22px",
        backgroundColor: "#fff",
        margin: "-2px 0 0 -5px",
        padding: "0 10px 0 10px"

      }
      // }
    }
  }
});

export default viewLogMaterialTheme;

