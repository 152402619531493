
export function checkNumber(money) {
  debugger
  let ret = true
  let arr = '0123456789.'.split('')
  let pointCount = 0
  let decimals = 0
  for (let i = 0; i < money.length; i++) {
    if (arr.includes(money[i])) {
      if (pointCount === 1) {
        decimals = decimals + 1
        if (decimals > 2) {
          ret = false
          break
        }
      }
      if (money[i] === '.') {
        pointCount = pointCount + 1
        if (pointCount === 2) {
          ret = false
          break
        }
      }
    } else {
      ret = false
      break
    }
  }
  return ret
}