import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withFirebase } from 'utils/Firebase';
import { withAuthentication } from 'utils/Session';
import Button from '@material-ui/core/Button';
import { gql } from 'apollo-boost';


import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';

class SignOutButton extends React.Component {

  render() {
    const { firebase } = this.props;
    const { classes } = this.props;

    return (
      <Button
        // href="/signoutthankyou"
        color={window.innerWidth < 960 ? 'primary' : 'primary'}
        //target="_blank"
        className={classes.navButton}
        onClick={(e) => {
          e.preventDefault()
          debugger;
          firebase.doSignOut();

        }}
        size="md"
        variant="contained"
      >
        Sign Out
      </Button>
    );
  }
}

export const LOG_OUT = gql`
  mutation logOut($email: String!) {
    logOut(email: $email) {
      id
    }
  }
`;

SignOutButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withAuthentication(withFirebase(withStyles(presentationStyle)(SignOutButton)));
