// import { lazy } from "react";

// @material-ui/icons
// import Lock from "@material-ui/icons/Lock";
import Share from "@material-ui/icons/Share";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import AttachMoney from "@material-ui/icons/AttachMoney";
import FaceIcon from "@material-ui/icons/Face";
import InfoIcon from "@material-ui/icons/Info";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import AccountBox from "@material-ui/icons/SupervisedUserCircle";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import DashboardIcon from "@material-ui/icons/Dashboard";
import ViewList from "@material-ui/icons/ViewList";
import ExitToApp from "@material-ui/icons/ExitToApp";

// import PasswordChange from "screens/PasswordChange";
import SignOutFirebase from "screens/SignOutFirebase";
import InvitePage from "screens/Invite";
import LogService from "screens/LogMyWork/LogService";
import LogExpense from "screens/LogMyWork/LogExpense";
import Logs from "screens/Logs";
import EsqPay from "EsqPay";
// import Profile from "screens/Profile";
import Portfolio from "screens/Portfolio";
import About from "screens/About";
import Contact from "screens/Contact";

import Account from "screens/Account";
import Checkout from 'screens/Checkout';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: DashboardIcon,
    component: EsqPay,
    layout: "/app",
  },
  {
    path: "/about",
    name: "About",
    icon: InfoIcon,
    component: About,
    layout: "/app",
  },
  {
    path: "/logs",
    name: "View Log",
    icon: ViewList,
    component: Logs,
    layout: "/app",
  },
  {
    path: "/logservices",
    name: "Log Work",
    icon: DirectionsRun,
    component: LogService,
    layout: "/app",
  },
  {
    path: "/logexpenses",
    name: "Log Expenses",
    icon: AttachMoney,
    component: LogExpense,
    layout: "/app",
  },
  {
    path: "/portfolio",
    name: "Clients & Cases",
    icon: AccountBox,
    mini: "CL",
    component: Portfolio,
    layout: "/app",
  },
  // {
  //   path: "/profile_",
  //   name: "Account & Profile",
  //   icon: FaceIcon,
  //   component: Profile,
  //   layout: "/app",
  // },
  {
    path: "/account",
    name: "Account & Profile",
    icon: FaceIcon,
    component: Account,
    layout: "/app",
  },
  {
    path: "/contact",
    name: "Contact",
    icon: PermContactCalendarIcon,
    component: Contact,
    layout: "/app",
  },
  {
    path: "/invite",
    name: "Invite",
    icon: Share,
    component: InvitePage,
    layout: "/app",
  },
  // {
  //   path: '/checkout',
  //   name: 'Pay',
  //   icon: CreditCardIcon,
  //   component: Checkout,
  //   layout: '/app'
  // },
  {
    path: "/signout",
    name: "Sign Out",
    icon: ExitToApp,
    component: SignOutFirebase,
    layout: "/app",
  },
];
export default dashRoutes;
