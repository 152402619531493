
import { createMuiTheme } from "@material-ui/core";

const itemChangedMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000"
    },
    // secondary: { main: "#22BF19" },
    // grey: { main: "#22BF19" }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        color: "#000",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#000",
          borderWidth: "2px"
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#000",
          borderWidth: "2px",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "#000"
            // borderWidth: "1px",
          }
        },
        "&$focused $notchedOutline": {
          borderColor: "#000",
          borderWidth: "2px"
        }
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: '#C62626',
      },

      root: {
        // "&$focused": {
        color: "#000",
        fontSize: "22px",
        backgroundColor: "#fff",
        margin: "-2px 0 0 -5px",
        padding: "0 10px 0 10px",

        // }

      }
    }
  }



  // palette: {
  //   primary: {
  //     main: "#0000"
  //   },
  //   secondary: { main: "#22BF19" },
  //   grey: { main: "#22BF19" }
  // },
  // overrides: {
  //   MuiOutlinedInput: {
  //     root: {
  //       position: "relative",
  //       "& $notchedOutline": {
  //         borderColor: "#000",
  //         borderWidth: "2px",
  //       },
  //       "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
  //         borderColor: "#000",
  //         // Reset on touch devices, it doesn't add specificity
  //         "@media (hover: none)": {
  //           borderColor: "#000",
  //           // borderWidth: "2px"
  //         }
  //       },
  //       "&$focused $notchedOutline": {
  //         borderColor: "#000",
  //         borderWidth: "2px",

  //       }
  //     }
  //   },
  //   MuiFormLabel: {
  //     root: {
  //       // "&$focused": {
  //       color: "#000",
  //       fontSize: "22px",
  //       fontWeight: "600",
  //       backgroundColor: "#fff",
  //       margin: "-2px 0 0 -5px",
  //       padding: "0 10px 0 10px"
  //       // }
  //     }
  //   }
  // }
});

export default itemChangedMaterialTheme