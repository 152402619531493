import React, { useState, useEffect } from 'react'
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultMatTheme from "utils/Themes/defaultMatTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import useWindowSize from 'utils/useWindowSize';

import {
  Dialog, DialogTitle, withStyles,
  Typography,
  ThemeProvider,
  Tooltip, Button, DialogActions,
  TextField
} from '@material-ui/core';
import Transition from "utils/Transition";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Waiting from "utils/Waiting";

import {
  grayColor,
  primaryColor,
} from 'assets/jss/material-kit-pro-react.js';
import circlelogo from 'assets/img/byn-logo-circle.png';
import Email from '@material-ui/icons/Email';
import { TICKETS_QUERY } from 'utils/Graphql/queries'
import { CREATE_TICKET_MUTATION } from 'utils/Graphql/mutations'

import InputAdornment from "@material-ui/core/InputAdornment";
/*
  https://github.com/mui-org/material-ui/issues/11517#issuecomment-407509327
 */
function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

const styles = theme => ({
  root: {
    maxWidth: '100%',
    width: '100%',
    textAlign: 'left',
    boxShadow: '1px 0px 2px 0.5px rgba(0,0,0,0.75)'
  },

  fbButton: {
    color: '#3B5998'
  },
  twtButton: {
    color: '#00ACEE'
  }
})

const combinedStyles = combineStyles(styles, modalStyle, javascriptStyles, basicsStyle)

const Contact = (props) => {
  const { classes } = props
  console.log(classes)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  const [message, setMessage] = useState("Send message and/or questions to billyounow.com is under construction.")



  useEffect(() => {
    if (isOpenWaiting) {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "block";
        document.getElementById("mainScreen").style.opacity = 0.3;
      }
    } else {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);
  const size = useWindowSize()

  const onSubmit = async (event) => {
    debugger;
    const { client } = props;

    event.preventDefault();
    debugger;
    setIsOpenWaiting(true);
    setOpenMessageDialog(true)
    setIsOpenWaiting(false);

  };

  const handleSubmit = async (values) => {
    let ticketNumber = '00000009'
    setIsOpenWaiting(true);
    getTicketNumber()
      .then(async response => {
        console.log(response)
        if (response.data.tickets) {
          let tickets = response.data.tickets
          let ticketsCount = response.data.tickets.length
          console.log(ticketsCount)
          let ticketNo = await tix(ticketsCount)
          console.log(ticketNo)
          sendContact(values, ticketNo)
            .then((response) => {
              console.log(response)
              // setMessage(`Something went wrong. Please try again later.`)
              setMessage(`You have sent a message to BillYouNow. Your ticket number is ${ticketNo}`)
              setOpenMessageDialog(true)
            })
        }
      })
      .catch(error => {
        console.log(error)
        setMessage(`Something went wrong. Please try again later.`)
        setOpenMessageDialog(true)
      })
      .finally(() => {
        setIsOpenWaiting(false);
      })
  }

  const sendContact = async (values, ticketNumber) => {
    console.log(ticketNumber)
    const { client } = props
    const { name, email, message } = values
    const dateCreation = new Date().toISOString();
    try {
      let data = await client.mutate({
        mutation: CREATE_TICKET_MUTATION,
        variables: {
          ticketNumber,
          name,
          email,
          message,
          dateCreation
        }
      })
      console.log(data)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const getTicketNumber = async () => {
    const { client } = props
    try {
      let data = await client.query({
        query: TICKETS_QUERY,
      })
      console.log(data)
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const tix = async (n) => {
    const ticketMaxChar = 8
    let ticketNumber = ''
    let no = n + 1;
    let numberOfString = no.toString()
    let numberOfZeroes = ticketMaxChar - numberOfString.length
    console.log(numberOfZeroes)

    if (numberOfZeroes > 0) {
      let zeroes = ''
      for (let i = 0; i < numberOfZeroes; i++) {
        zeroes = zeroes + `0`
      }
      console.log(zeroes)
      ticketNumber = zeroes + numberOfString
      console.log(ticketNumber)
    }
    else {
      ticketNumber = numberOfString.toString()
    }
    return ticketNumber
  }

  const checkForDuplicateTickets = (prevTixNum, newTixNum) => {

  }


  return (
    <>
      <div
        style={{ marginTop: -100 }}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Contact Us!
              </div>
              {/* <div
                style={{
                  margin: `10px 0 0 0`,
                  color: grayColor[0],
                  fontSize: "12px",
                  fontWeight: "300",
                  textAlign: "left",
                }}
              >
                (under construction)
              </div> */}

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ //reset marginTop to -42px later after construction completion
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}

                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
              <Waiting
                Transition={Transition}
                isOpenWaiting={isOpenWaiting}
              ></Waiting>
            </div>
            <div id="mainScreen" style={{ marginTop: "-70px" }}>

              <Formik
                initialValues={{ name: "", email: "", message: "" }}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email format")
                    .required("Required"),
                  name: Yup.string()
                    .required("Required"),
                  message: Yup.string()
                    .required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  // sendContact(values)
                  handleSubmit(values)
                  // getTicketNumber()
                  // tix()
                }}
              >
                {({
                  values,
                  isValid,
                  setFieldValue,
                  isSubmitting,
                  errors
                }) => (
                  <Form
                  // onSubmit={onSubmit}
                  >
                    <div
                      className={classes.container}
                      style={{
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        overflow: "auto",
                        height: `${size.height - 165}px`
                      }}
                    >
                      <Typography
                        style={{ margin: `${size.width <= 414 ? 10 : 40}px  0 10px 0` }}
                        variant='subtitle2'
                      >

                        Mobile Numbers
                      </Typography>

                      <Typography>
                        {'Smart: 0999-985-1699'}
                      </Typography>
                      <Typography>
                        {'Globe: 0995-021-5342'}
                      </Typography>

                      <Typography
                        variant='subtitle2'
                        style={{
                          margin: '20px 0 10px 0'
                        }}
                      >
                        Email
                      </Typography>
                      <Typography>
                        business@billyounow.com
                      </Typography>
                      {/* <Typography>
                          helpdesk@billyounow.com
                      </Typography> */}

                      <ThemeProvider theme={defaultMatTheme}>

                        <TextField
                          autoFocus
                          style={{
                            margin: '30px 0 0 0'
                          }}
                          variant='outlined'
                          fullWidth
                          label='Name'
                          value={values.name}
                          onChange={(event) => {
                            values.name = event.target.value
                            setFieldValue(
                              "name",
                              event.target.value
                            );

                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required={values.name.length === 0}
                        />
                        <TextField
                          style={{ margin: "20px 0px 0px 0" }}

                          name="email"
                          value={values.email}
                          type="email"
                          label="Email"
                          className={classes.textField}
                          margin="normal"
                          fullWidth
                          step="0"
                          variant="outlined"
                          onChange={(event) => {
                            values.email = event.target.value
                            setFieldValue(
                              "email",
                              event.target.value
                            );

                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          required={values.email.length === 0}
                          InputProps={{
                            readOnly: false,
                            endAdornment: (
                              <InputAdornment position="end" color="red">
                                <Email />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          style={{
                            margin: '20px 0 20px 0'
                          }}
                          multiline
                          rows={5}
                          variant='outlined'
                          fullWidth
                          label='Message'
                          value={values.message}
                          onChange={(event) => {
                            values.message = event.target.value
                            setFieldValue(
                              "message",
                              event.target.value
                            );

                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required={values.message.length === 0}
                        />
                        <Typography
                          style={{
                            textAlign: "center", fontSize: '16px', color: primaryColor[0],
                            display: values.email === "" || values.name === "" || values.message === "" ? 'block' : 'none'
                          }}>
                          <em>* Required</em>
                        </Typography>

                      </ThemeProvider>
                    </div >
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{
                            width: "60%"
                          }}
                          variant="contained"
                          color="primary"
                          size="md"
                          type="submit"
                          disabled={!isValid || isSubmitting || values.email === "" || values.name === "" || values.message === ""}
                        >
                          Send
                              </Button>
                        <Button
                          style={{
                            width: "40%"
                          }}
                          variant="outlined"
                          color="primary"
                          size="md"
                          onClick={e => {
                            e.preventDefault()
                            props.history.push("/app/dashboard")
                          }}
                        >
                          BACK
                              </Button>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </div>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openMessageDialog}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={props.handleCloseMessageDialog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Contact Us!
              </div>


              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ //reset marginTop to -42px later after construction completion
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340
                  }px`,
                overflow: "auto",
                height: `${size.height - 145}px`,
              }}
            >
              <Typography style={{ margin: "60px 0 0 0", fontSize: '24px', color: grayColor[1], textAlign: "center" }}>
                {message}
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  onClick={() => {
                    setOpenMessageDialog(false)
                  }}
                  color="primary"
                >
                  close
                  </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>


    </>
  )
}

export default withStyles(combinedStyles)(Contact)
