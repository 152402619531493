import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import useWindowSize from 'utils/useWindowSize';

import { withFirebase } from "utils/Firebase";
import circlelogo from "assets/img/byn-logo-circle.png";
import * as Yup from "yup";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import Email from "@material-ui/icons/Email";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultMatTheme from "utils/Themes/defaultMatTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import moreLessBtnMaterialTheme from "utils/Themes/moreLessBtnMaterialTheme";

import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react.js";

import { gql } from "apollo-boost";
import Waiting from "utils/Waiting";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";

import {
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";
import { Formik, Form } from "formik";
import { Helmet } from "react-helmet";

import { ThemeProvider } from "@material-ui/styles";

import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Transition from "utils/Transition";
import Tooltip from "@material-ui/core/Tooltip";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PasswordForget from "screens/PasswordForget"
import { createUserLog } from "utils/CreateUserLog";
// const INITIAL_STATE = {
//   error: null,
//   error1: null,
// };

// const ERROR_CODE_ACCOUNT_EXISTS =
//   "auth/account-exists-with-different-credential";

// const ERROR_MSG_ACCOUNT_EXISTS = `
//   An account with an E-Mail address to
//   this social account already exists. Try to login from
//   this account instead and associate your social accounts on
//   your personal account page.
// `;

const ERROR_MSG_GENERIC_CREDENTIALS = `Sorry. There’s something wrong with your login credentials. 
`;

const ERROR_MSG_NOT_IN_LIST = ` is not associated with any registered member or is not a designated user
`;

const ERROR_MSG_NOT_REGISTERED = ` has not yet registered.   Please read your latest invite/email and proceed from there.
`;
const ERROR_MSG_ALREADY_LOGGED_IN = `Looks like you are still logged in through another device. Please log out from that device. 
`;

function SignInGooglePage(props) {
  const { client } = props
  useEffect(() => {

    let authUser = { email: "system@byn.com" }
    fetch(
      `https://geolocation-db.com/json/${process.env.GEO_LOCATION_KEY}`
    )
      .then(response => response.json())
      .then(data => {
        setDetails(data)
        createUserLog({ authUser, client }, "LOG-IN (not logged-in yet)", "Opened Log-In Screen", `${data.city}, ${data.state}, ${data.country_name} (${data.country_code}) ${data.IPv4}`)
      })
      .catch(error => {

        createUserLog({ authUser, client }, "LOG-IN (not logged-in yet)", "Opened Log-In Screen", "")
        console.log('LOG-IN error', error)
      })
  }, []);
  const [classes] = useState(props.classes);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [messageOnMessageDialog, setMessageOnMessageDialog] = useState("");
  // const [error, setError] = useState("");
  const [error1] = useState("");
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: "",
  });

  const [openPasswordForget, setOpenPasswordForget] = useState(false)
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  const [details, setDetails] = useState(null);


  useEffect(() => {
    if (isOpenWaiting) {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "block";
        document.getElementById("mainScreen").style.opacity = 0.3;
      }
    } else {
      if (
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);
  const size = useWindowSize()
  // const [closeRightMargin, setCloseRightMargin] = useState("-20px");



  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  // const handleClickShowPasswordOne = () => {
  //   setValues({ ...values, showPasswordOne: !values.showPasswordOne });
  // };

  // const handleClickShowPasswordTwo = () => {
  //   setValues({ ...values, showPasswordTwo: !values.showPasswordTwo });
  // };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (event) => {
    debugger;
    const { client } = props;
    var proceed = true;
    let noSignUpIssues = true;
    // let alreadyLoggedIn = false;

    // let emailFromDB = ''

    event.preventDefault();
    debugger;
    setIsOpenWaiting(true);
    console.log("start MEMBER_CHECK");
    client
      .query(
        {
          query: MEMBER_CHECK1,
          variables: {
            email: values.email,
          },
        },
        {
          options: { fetchPolicy: "network-only" },
        }
      )
      .then((response) => {
        const { data } = response;

        console.log("start MEMBER_CHECK  return data");
        console.log(JSON.stringify(data));
        debugger;
        if (data) {
          if (data.memberCheck1.length === 0) {
            client.clearStore()
            debugger;
            proceed = false;
            setIsOpenWaiting(false);
            console.log(
              "start MEMBER_CHECK  return data .. employee is not in"
            );
            // setError({
            //   message: "<" + values.email + ">" + ERROR_MSG_NOT_IN_LIST,
            // });
            setMessageOnMessageDialog(
              ERROR_MSG_GENERIC_CREDENTIALS
            );
            setOpenMessageDialog(true);
          } else if (data.memberCheck1.length === 1) {
            const {
              id,
              email,
              isLoggedIn,
              hasConfirmedAgreement,
              hasInitiallyLoggedIn,
              hasRespondedToInvite,
            } = data.memberCheck1[0];

            // if (typeof isLoggedIn !== "undefined" && isLoggedIn) {
            // debugger;
            // // alreadyLoggedIn = true;

            // proceed = false;
            // setIsOpenWaiting(false);
            // console.log({
            //   message:
            //     "start LOG_IN  return data .. employee is already logged-in",
            // });
            // // setError({
            // //   message: "<" + values.email + ">" + ERROR_MSG_ALREADY_LOGGED_IN,
            // // });
            // client.clearStore()
            // setMessageOnMessageDialog(
            //   ERROR_MSG_ALREADY_LOGGED_IN
            // );
            // setOpenMessageDialog(true);
            // } else 
            if (!hasRespondedToInvite) {
              debugger;
              proceed = false;
              setIsOpenWaiting(false);
              console.log(
                "start MEMBER_CHECK  return data .. employee is not registered"
              );
              // setError({
              //   message: "<" + values.email + ">" + ERROR_MSG_NOT_REGISTERED,
              // });
              client.clearStore()
              setMessageOnMessageDialog(
                ERROR_MSG_GENERIC_CREDENTIALS
              );
              setOpenMessageDialog(true);
            } else if (!hasConfirmedAgreement) {
              debugger;
              proceed = false;
              setIsOpenWaiting(false);
            } else if (!hasInitiallyLoggedIn) {
              debugger;
              proceed = false;
              setIsOpenWaiting(false);
            }

            if (proceed) {
              props.firebase
                .doSignInWithEmailAndPassword(email, values.password)
                .then((authUser) => {
                  if (noSignUpIssues === false) {
                    if (
                      hasConfirmedAgreement !== true &&
                      hasRespondedToInvite === true
                    ) {
                      setIsOpenWaiting(false);
                      setTimeout(
                        props.history.push("/termsOfService", {
                          detail: data.memberCheck1,
                        }),
                        0
                      );
                    } else if (
                      hasInitiallyLoggedIn !== true &&
                      hasConfirmedAgreement === true
                    ) {
                      setIsOpenWaiting(false);
                      setTimeout(
                        props.history.push("/initialLogin", {
                          detail: data.memberCheck1,
                        }),
                        0
                      );
                    } else if (hasRespondedToInvite !== true) {
                      setIsOpenWaiting(false);
                      // const { error1 } = this.state;
                      // setError({ message: error1 });
                      client.clearStore()
                      setMessageOnMessageDialog(ERROR_MSG_GENERIC_CREDENTIALS);
                      setOpenMessageDialog(true);
                    } else {
                      setIsOpenWaiting(false);
                      // const { error1 } = this.state;
                      // setError({ message: error1 });
                      client.clearStore()
                      setMessageOnMessageDialog(ERROR_MSG_GENERIC_CREDENTIALS);
                      setOpenMessageDialog(true);
                    }
                  } else {
                    // document.getElementById('mainScreen').style.display = 'none'
                    client
                      .mutate({
                        mutation: LOG_IN,
                        variables: {
                          id,
                        },
                      })
                      .then((response) => {
                        authUser.email = email
                        debugger
                        createUserLog({ authUser, client }, "LOG-IN", "logged-in " + email, `${details.city}, ${details.state}, ${details.country_name}(${details.country_code}) ${details.IPv4}`)
                      })
                      .catch((error) => {
                        client.clearStore()
                        setIsOpenWaiting(false);
                        // setError(error);
                        setMessageOnMessageDialog(error.message);
                        setOpenMessageDialog(true);
                      });
                    document.getElementById("mainScreen").style.display =
                      "none";
                    // props.history.push("/app");
                  }
                })
                .catch((error) => {
                  debugger;
                  client.clearStore()
                  setIsOpenWaiting(false);
                  // setError(error);
                  setMessageOnMessageDialog(ERROR_MSG_GENERIC_CREDENTIALS);
                  setOpenMessageDialog(true);
                });

              event.preventDefault();
            }
          }
        }
      })
      .catch((error) => {
        debugger;
        // setError(error);
        client.clearStore()
        setIsOpenWaiting(false);
        console.log(error.message);
        setMessageOnMessageDialog(error.message);
        setOpenMessageDialog(true);
        // console.log(error)
      });
  };

  return (
    <>
      <Helmet>
        <title>BillYouNow</title>
        <meta
          name="description"
          content="BillYouNow is a secure mobile web application that conveniently logs work
      done and expenses incurred by lawyers for clients."
        />
        <meta
          name="keywords"
          content="lawyers,legal,professionals,lawyer,legal fees,attorney fees and charges,billing,bill,you,now"
        />
      </Helmet>

      <div
        style={{
          margin: `-100px 0 0 0`
        }}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Log In
              </div>
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                  }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/auth/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
              <Waiting
                Transition={Transition}
                isOpenWaiting={isOpenWaiting}
              ></Waiting>
            </div>
            <div id="mainScreen" style={{ marginTop: "-70px" }}>
              <Formik
                initialValues={{ email: "" }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email format")
                    .required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => { }}
              >
                {({ }) => (
                  <Form onSubmit={onSubmit}>
                    <div
                      className={classes.container}
                      style={{
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        overflow: "auto",
                        height: `${size.height - 145}px`,
                      }}
                    >
                      <ThemeProvider theme={defaultMatTheme}>
                        <TextField
                          autoFocus
                          style={{ marginTop: "60px" }}
                          onChange={handleChange("email")}
                          name="email"
                          label="User Name or Email"
                          value={values.email}
                          id="input-with-icon-textfield"
                          type="text"
                          margin="normal"
                          fullWidth
                          variant="outlined"
                          required={values.email.length === 0}
                          InputLabelProps={{
                            shrink: true,
                          }}

                        />

                        <TextField
                          style={{ marginTop: "10px" }}
                          id="outlined-adornment-password"
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handlePasswordChange("password")}
                          label="Password"
                          textTransform="normal"
                          fullWidth
                          required={values.password.length === 0}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  color="primary"
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )
                                  }
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </ThemeProvider>
                      <Typography
                        style={{
                          textAlign: "center", fontSize: '16px', color: primaryColor[0],
                          display: values.email === "" || values.password === "" ? 'block' : 'none'
                        }}>
                        <em>* Required</em>
                      </Typography>

                      <DialogActions className={classes.modalFooter}>
                        <ThemeProvider theme={moreLessBtnMaterialTheme}>
                          <Button
                            style={{ marginTop: "20px" }}
                            variant="text"
                            // href="/auth/passwordForget"
                            onClick={() => {
                              setOpenPasswordForget(true);
                            }}
                            color="#22BF19"
                            size="sm"
                            fullWidth
                          >
                            Forgot Password?
                          </Button>
                        </ThemeProvider>
                      </DialogActions>

                      {/* <FormHelperText
                          style={{
                            color: primaryColor[0],
                            margin: "10px 0 20px 10px",
                            fontSize: "18px",
                          }}
                        >
                          {error.message ? error.message : null}
                        </FormHelperText> */}
                    </div>

                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{ width: "60%" }}
                          disabled={
                            values.email === "" ||
                            values.email.length < 4 ||
                            values.password === "" ||
                            messageOnMessageDialog.includes(
                              "currently logged in"
                            )
                          }
                          variant="contained"
                          type="submit"
                          color="primary"
                        >
                          Go
                        </Button>
                        <Button
                          style={{ width: "40%" }}
                          variant="outlined"
                          type="submit"
                          color="primary"
                          onClick={e => {
                            e.preventDefault()
                            props.history.push("/auth/dashboard")
                          }}
                        >
                          Back
                        </Button>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </div>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openMessageDialog}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={props.handleCloseMessageDialog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: `${size.width <= 320 ? 26 : 30}px`,
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Log In
              </div>
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-42px  ${size.width <= 414 ? -10 : -20}px 0 0`,
                  }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/auth/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 145}px`,
              }}
            >
              <Typography
                style={{
                  margin: "40px 0 0 0",
                  fontSize: "18px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                {messageOnMessageDialog}
              </Typography>

              {!messageOnMessageDialog.includes("Please log out from that device") ?
                <Typography
                  style={{
                    margin: "40px 0 0px 0",
                    fontSize: "18px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  Please contact us by clicking this
                  <a href={`${process.env.REACT_APP_URL}/auth/contact`}>
                    <strong>
                      <em style={{
                        fontSize: "18px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}>&nbsp;link.</em>
                    </strong>
                  </a>
                </Typography>
                : null
              }
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  onClick={() => {
                    setOpenMessageDialog(false);
                  }}
                  color="primary"
                >
                  BACK
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>
      <PasswordForget
        client={client}
        openPasswordForget={openPasswordForget}
        setOpenPasswordForget={setOpenPasswordForget}
      />
    </>
  );
}
export const MEMBER_CHECK1 = gql`
  query memberCheck1($email: String!) {
    memberCheck1(email: $email) {
      id
      email
      userName
      displayName
      hasConfirmedAgreement
      hasRespondedToInvite
      hasInitiallyLoggedIn
      phoneNo
      isLoggedIn
    }
  }
`;

export const LOG_IN = gql`
  mutation logIn($id: ID) {
    logIn(id: $id) {
      id
    }
  }
`;

SignInGooglePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SignInPage = compose(
  withRouter,
  withFirebase
)(SignInGooglePage);

export default withStyles(javascriptStyles)(
  withStyles(basicsStyle)(
    withStyles(modalStyle)(withFirebase(withAuthentication(SignInPage)))
  )
);
