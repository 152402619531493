import React from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards-pellucid.js";

import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import Tooltip from "@material-ui/core/Tooltip";
import circlelogo from "assets/img/byn-logo-circle.png";
import useWindowSize from 'utils/useWindowSize';

// import Transition from "utils/Transition";

const LogDelete = (props) => {
  const { classes } = props;
  // Render nothing if the "openDeleteExpenseLog" prop is false
  const size = useWindowSize()
  if (!props.openDeleteDoneLog) return null;

  return (
    <Dialog
      classes={{
        root: props.classes.modalRoot,
        paper: props.classes.modalLog,
      }}
      open={props.openDeleteDoneLog}
      // TransitionComponent={props.Transition}
      keepMounted
      // onClose={props.handleCloseDeleteServiceLog}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
      fullWidth
      fullScreen
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <div
          className={classes.container}
          style={{ width: `${size.width <= 414 ? size.width - 30 : 600}px` }}
        >
          <ThemeProvider theme={defaultMaterialTheme}>

            <div
              style={{
                margin: `10px 0px 20px  0`,
                color: primaryColor[0],
                fontSize: "30px",
                fontWeight: "400",
                textAlign: "left",
              }}
            >Delete Item
              </div>

            <Tooltip
              id="tooltip-top"
              title={!props.isOpenWaitingOnConfirm ? "Confirmation" : "Saving..."}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                style={{
                  margin: `-60px  ${size.width <= 414 ? -10 : -20}px 0 0`,
                }}
                className={classes.modalCloseButtonBYN}
              // href="/app/app"
              >
                <img
                  style={{ width: "50px" }}
                  src={circlelogo}
                  alt="billyounow.com"
                />
              </Button>
            </Tooltip>
          </ThemeProvider>
        </div>
      </DialogTitle>
      <ThemeProvider theme={defaultBtnMaterialTheme}>
        <div
          className={classes.container}
          style={{ width: `${size.width <= 414 ? size.width - 30 : 600}px` }}
        >
          <div>
            <div
              className={classes.container}
              style={{
                overflow: "auto",
                width: `${size.width <= 414 ? size.width - 40 : 570}px`,
                height: `${size.height - 145}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 30px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Log Deleted.
          </Typography>
            </div>
            <DialogActions className={props.classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  onClick={props.handleCancelDeleteServiceLog}
                  color="primary"
                >
                  Done
            </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </div>
      </ThemeProvider>
    </Dialog >
  );
};

LogDelete.propTypes = {
  children: PropTypes.node,
};

export default withStyles(styles)(withStyles(modalStyle)(LogDelete));
