import React, { Component } from 'react';

import {
  whiteColor,
  blackColor,
  primaryColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";
export default class CustomTooltip extends Component {
  getReactContainerClasses() {
    return ['custom-tooltip'];
  }

  render() {
    debugger
    const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex)
      .data;
    const path = data.filePath;
    let d = ""

    console.log('start yyyyyy this.props.column ')
    console.log(this.props.column)
    console.log('end yyyyyy this.props.column ')

    if (this.props.column === "label") {

      if (path.endsWith('$.activity2') || path.endsWith('$.expense2')) {
        d = path.split('$.')[7]
      }
    } else if (this.props.column === 'date') {
      let dt = data.date
      if (typeof dt === 'string' && ((dt.includes('(') && dt.includes(')')) || dt.includes('Date'))) {
        // if (typeof dt === 'string' && dt.includes('(') && dt.includes(')')) {
        d = '(1) Date'
      }
    } else if (this.props.column === 'billingType') {
      let dt = data.billingType
      if (typeof dt === 'string' && ((dt.includes('(') && dt.includes(')')) || dt.includes('Bill'))) {
        d = '(2) Bill Type'
      }
    } else if (this.props.column === 'hourlyRate') {
      let dt = data.hourlyRate
      if (typeof dt === 'string' && ((dt.includes('(') && dt.includes(')')) || dt.includes('Rate'))) {
        d = '(3) Rate'
      }

    } else if (this.props.column === 'hours') {
      let dt = data.hours
      if (typeof dt === 'string' && ((dt.includes('(') && dt.includes(')')) || dt.includes('Hours'))) {
        d = '(4) Duration in hrs'
      }

    } else if (this.props.column === 'startTime') {
      let dt = data.startTime
      if (typeof dt === 'string' && ((dt.includes('(') && dt.includes(')')) || dt.includes('Start'))) {
        d = '(5) Start Time'
      }
    } else if (this.props.column === 'endTime') {
      let dt = data.endTime
      if (typeof dt === 'string' && ((dt.includes('(') && dt.includes(')')) || dt.includes('End'))) {
        d = '(6) End Time'
      }
    } else if (this.props.column === 'amount') {
      let dt = data.amount
      console.log('start xxxxxx')
      console.log(dt)
      console.log('end xxxxxx')
      if (typeof dt === 'string' && ((dt.includes('(') && dt.includes(')')) || dt.includes('Amount'))) {
        d = '(7) Amount'
      }
    }

    return (
      (d !== ''
        ?
        <div
          style={
            {
              color: whiteColor,
              width: "140px",
              paddingTop: "1px",
              paddingBottom: "1px",
              paddingRight: "0px",
              paddingLeft: '0px',
              minWidth: "80px",
              lineHeight: "22px",
              background: "rgba(" + hexToRgb(primaryColor[0]) + ",0.7)",
              border: "none",
              borderRadius: "3px",
              boxShadow:
                "0 8px 10px 1px rgba(" +
                hexToRgb(blackColor) +
                ", 0.14), 0 3px 14px 2px rgba(" +
                hexToRgb(blackColor) +
                ", 0.12), 0 5px 5px -3px rgba(" +
                hexToRgb(blackColor) +
                ", 0.2)",
              maxWidth: "200px",
              textAlign: "center",
              fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "400",
              textShadow: "none",
              textTransform: "none",
              letterSpacing: "normal",
              wordBreak: "normal",
              wordSpacing: "normal",
              wordWrap: "normal",
              whiteSpace: "normal",
              lineBreak: "auto"
            }
          }
        >
          <p>{d}</p>
        </div >
        :
        null
      )
    );
  }
}