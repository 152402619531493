import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import useWindowSize from 'utils/useWindowSize';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const PrivacyPolicy = (props) => {

  const classes = useStyles();
  const size = useWindowSize()


  return (
    <div className={classes.container}
      style={{ marginTop: "20px", overflow: "auto", height: `${size.height - (size.width <= 414 ? 160 : 160)}px`, width: `${size.width <= 414 ? size.width - 30 : 600}px` }}
    >
      <GridContainer>
        <GridItem>
          <div id='introduction' style={{ marginTop: '20px' }}>
            <Typography style={{
              fontSize: "20px", textAlign: "left", marginBottom: "20px"
            }}>
              {"WHAT INFORMATION DO WE COLLECT?"}
            </Typography>
            <Typography>
              {"Provider collects information from user when user registers on Provider’s site and when user logs work or expenses. When registering on Provider’s site, as appropriate, user may be asked to enter your name, email address, mailing address or phone number. Provider may contract with third-party service providers to assist Provider in better understanding its site visitors. These service providers are not permitted to use the information collected on its behalf except to help Provider conduct and improve its business."}
            </Typography>
          </div>
        </GridItem>
        <GridItem>
          <div id='introduction' style={{ marginTop: '20px' }}>
            <Typography style={{
              fontSize: "20px", textAlign: "left", marginBottom: "20px"
            }}>
              {"WHAT INFORMATION DO WE COLLECT?"}
            </Typography>
            <Typography>
              {"Provider collects information from user when user registers on Provider’s site and when user logs work or expenses. When registering on Provider’s site, as appropriate, user may be asked to enter your name, email address, mailing address or phone number. Provider may contract with third-party service providers to assist Provider in better understanding its site visitors. These service providers are not permitted to use the information collected on its behalf except to help Provider conduct and improve its business."}
            </Typography>
          </div>
        </GridItem>
        <GridItem>
          <div id='introduction' style={{ marginTop: '20px' }}>
            <Typography style={{
              fontSize: "20px", textAlign: "left", marginBottom: "20px"
            }}>
              {"HOW DO WE PROTECT YOUR INFORMATION?"}
            </Typography>
            <Typography>
              {`Provider fully complies with the provisions of Republic Act No. 10173 otherwise known as the “Data Privacy Act of 2012” and is registered with the National Privacy Commission. Provider implements a variety of security measures to maintain the safety of user’s personal information when user registers, logs in work or expenses or accesses personal information.


Provider protects all of user’s information through encryption, cryptographic protocol transmission and uses Swiss-based cloud servers for added protection. 

Personnel who work at BillYouNow are made to understand how to be security conscious and recognize suspicious activity. BillYouNow employees are required to acknowledge security policies prior to being granted systems access. Employees also take part in mandatory security and privacy training for new hires and receive regular security awareness training via informational emails, talks, presentations, and resources available on our internal knowledge base. BillYouNow will notify User in the event of a data breach, as required by applicable law. Provider maintains incident response policies and procedures, including a breach notification process, which enables Provider to notify affected customers and the Data Privacy Commission, as needed.   
`}
            </Typography>
          </div>
        </GridItem>
        <GridItem>
          <div id='introduction' style={{ marginTop: '20px' }}>
            <Typography style={{
              fontSize: "20px", textAlign: "left", marginBottom: "20px"
            }}>
              {"DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES?"}
            </Typography>
            <Typography>
              {`Provider does not sell, trade, or otherwise transfer to outside parties user’s personally identifiable information. Provider shall only release user information when this is necessary  to comply with the law or a court order, to enforce Provider policies, or protect Provider’s or others rights, property, or safety. However, aggregated, non-personally identifiable user information may be provided to other parties for marketing, advertising, or other uses. 

In addition, all information that is provided to the Service by a User pertaining to a professional engagement with a  client is privileged communication since it is covered by the attorney-client privilege. Provider or any of its officers or employees may only divulge such information if there  is consent of the client and the subscriber or if ordered to do so by the judicial authorities, despite the existence of the said privilege.
`}
            </Typography>
          </div>
        </GridItem>
        <GridItem>
          <div id='introduction' style={{ marginTop: '20px' }}>
            <Typography style={{
              fontSize: "20px", textAlign: "left", marginBottom: "20px"
            }}>
              {"CAN USERS ACCESS, CORRECT AND/OR DELETE THEIR INFORMATION?"}
            </Typography>
            <Typography>
              {`Provider respects User’s privacy rights and provides User with reasonable access to the Personal Data that User may have provided through the use of the Services. User may access or amend any Personal Data, or request to delete or to transfer any information about User.


Users may update, correct, or delete account information at any time by accessing account settings page on the Service. Provider may retain all information User submits for backups, archiving, prevention of fraud and abuse, analytics, satisfaction of legal obligations, or where Provider otherwise reasonably believes that there is a legitimate reason to do so.


User may decline to share certain Personal Data, in which case Provider may not be able to provide some of the features and functionality of the Service.


At any time, User may object to the processing of his Personal Data, on legitimate grounds, except if otherwise permitted by applicable law. For any objection on the processing of personal data or any violation of rights under the Data Privacy Act, please contact us immediately.
`}
            </Typography>
          </div>
        </GridItem>
        <GridItem>
          <div id='introduction' style={{ marginTop: '20px' }}>
            <Typography style={{
              fontSize: "20px", textAlign: "left", marginBottom: "20px"
            }}>
              {"IS USER NOTIFIED IN CASE OF BREACH OF DATA?"}
            </Typography>
            <Typography>
              {`In general, User is notified in case of breach of data but Provider does not have to notify the data subjects if the breach involves anonymized data or any personal information that cannot be used for identity fraud. Specifically, the notice to data subjects is not required if the data controller has implemented pseudonymization techniques like encryption along with adequate technical and organizational protection measures to the personal data affected by the data breach.`}
            </Typography>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default PrivacyPolicy
