import React, { Suspense } from 'react';

import cx from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import SignInDrawerNavbar from 'components/Navbars/SignInDrawerNavbar.js';
// import Footer from 'components/Footer/Footer.js';
import SignInDrawerSidebar from 'components/Sidebar/SignInDrawerSidebar.js';
import Spinner from 'components/Spinner/Spinner';
import routes from 'pellucid.js';

import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';

import { createUserLog } from "utils/CreateUserLog";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { client, ...rest } = props;
  // debugger;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  // const [image] = React.useState(require('assets/img/sidebar-3.jpg'));
  const [color] = React.useState('purple');
  const [bgColor] = React.useState('white');
  // const [hasImage, setHasImage] = React.useState(true);
  // const [fixedClasses, setFixedClasses] = React.useState('dropdown');
  const [logo] = React.useState(require('assets/img/byn-logo-circle.png'));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: false,
        suppressScrollY: false
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });
  // functions for changeing the states from components
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleBgColorClick = bgColor => {
  //   switch (bgColor) {
  //     case 'white':
  //       setLogo(require('assets/img/logo.svg'));
  //       break;
  //     default:
  //       setLogo(require('assets/img/logo-white.svg'));
  //       break;
  //   }
  //   setBgColor(bgColor);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === 'dropdown') {
  //     setFixedClasses('dropdown show');
  //   } else {
  //     setFixedClasses('dropdown');
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const retainMobileOpen = () => {
    setMobileOpen(true);
  };

  const getRoute = () => {
    return window.location.pathname !== '/auth/full-screen-maps';
  };
  const getActiveRoute = routes => {
    // let activeRoute ='Home';
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].name !== "Home") {
            createUserLog(props, "BYN Drawer Menu items", "clicked " + routes[i].name, "")
          }
          return routes[i].name !== "Home" ? routes[i].name : "";
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      //debugger;
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            // component={prop.component}
            render={props => <prop.component client={client} {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <SignInDrawerSidebar
        routes={routes}
        logoText={""}
        logo={logo}
        // image={image}
        handleDrawerToggle={handleDrawerToggle}
        retainMobileOpen={retainMobileOpen}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <SignInDrawerNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Suspense
                fallback={
                  <div>
                    <Spinner />
                  </div>
                }
              >
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/auth" to="/auth/signin" />
                </Switch>
              </Suspense>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Suspense
              fallback={
                <div>
                  <Spinner />
                </div>
              }
            >
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/auth" to="/auth/signin" />
              </Switch>
            </Suspense>
          </div>
        )}
        {/* {getRoute() ? <Footer fluid /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        /> */}
      </div>
    </div>
  );
}
