
import { createMuiTheme } from "@material-ui/core";
import red from "utils/colors/red";

const aboutPageMaterialTheme = createMuiTheme({
  palette: {
    primary: red
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        textTransform: "none"
      },
      label: {
        fontWeight: 400,
        fontSize: "20px",
        // padding: "-5px -5px -5px -5px",
        fontHeight: "1px",
        // margin: "0px 0 0px 0",
      }
    },
    MuiFormLabel: {
      root: {
        // "&$focused": {
        color: "#999",
        fontSize: "22px",
        backgroundColor: "#fff",
        margin: "-2px 0 0 -5px",
        padding: "0 10px 0 10px"
        // }
      }
    }
  }
});

export default aboutPageMaterialTheme