import { CREATE_LOG_MUTATION } from 'utils/Graphql/mutations'

export async function createUserLog(
  props,
  screen,
  log,
  remarks
) {
  // console.log("... enter createUserLog");
  const { client, authUser } = props;
  debugger
  if (!authUser) {
    console.log('*****authUser is null, please check', screen, log)
  }
  else {
    // console.log(new Date().toISOString())
    // console.log(authUser.email)
    // console.log(screen)
    // console.log(log)
    // console.log('remarks', remarks)
    try {
      const { data } = await client.mutate({
        mutation: CREATE_LOG_MUTATION,
        variables: {
          date: new Date().toISOString(),
          email: authUser.email,
          screen,
          log,
          remarks
        },
      });
      debugger
      // console.log(data.addLog);
      // console.log("... exit createUserLog");
      return data.addLog;
    } catch (error) {
      console.log("***CreateUserLog", error)
      console.log("... error createUserLog");
    }
  }
};


