
import React, { useEffect, useState } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import {
  primaryColor,
  grayColor
} from 'assets/jss/material-kit-pro-react.js';

import withStyles from '@material-ui/core/styles/withStyles';
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import circlelogo from "assets/img/byn-logo-circle.png"

import Transition from "utils/Transition";
import useWindowSize from 'utils/useWindowSize';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { createUserLog } from "utils/CreateUserLog";

function Campaign222(props) {
  const { classes, openCampaign222, setOpenSignUp } = props
  const size = useWindowSize()
  const [step, setStep] = useState(1);

  return (
    <>
      {/* PAGE 1 */}
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={(openCampaign222 === true) && step === 1}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                {'Place title here'}
              </div>

              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-42px ${size.width <= 414 ? -10 : -20}px -20px 0 `,
                  }}
                  className={classes.modalCloseButtonBYN}
                  href="/auth/dashboard"
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="notarypublic360.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 320 ? size.width - 60 :
                  size.width <= 360 ? size.width - 60 :
                    size.width <= 375 ? size.width - 60 :
                      size.width <= 414 ? size.width - 60 : 400
                  }px`,
                marginLeft: "-40px",
                display: "grid",
                gridTemplateColumns: `40px ${size.width <= 320 ? size.width - 100 :
                  size.width <= 360 ? size.width - 100 :
                    size.width <= 375 ? size.width - 100 :
                      size.width <= 414 ? size.width - 100 : 320
                  }px 40px`,
                gridTemplateRows: "auto auto",
              }}
            >

              <div>

              </div>
              <div>
                <div
                  className={classes.container}
                  style={{

                    width: `${size.width <= 320 ? size.width - 60 :
                      size.width <= 360 ? size.width - 60 :
                        size.width <= 375 ? size.width - 60 :
                          size.width <= 414 ? size.width - 60 : 400
                      }px`,
                    overflow: "auto",
                    height: `${size.width <= 320 ? size.height - 120 :
                      size.width <= 360 ? size.height - 120 :
                        size.width <= 365 ? size.height - 120 :
                          size.width <= 375 ? size.height - 120 :
                            size.width <= 414 ? size.height - 120 :
                              size.height - 120
                      }px`,
                  }}
                >
                  <div style={{
                    marginTop: 40,
                    width: `${size.width <= 320 ? size.width - 80 :
                      size.width <= 360 ? size.width - 80 :
                        size.width <= 375 ? size.width - 80 :
                          size.width <= 414 ? size.width - 80 : 340
                      }px`,
                    marginLeft: '-10px',
                  }}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        color: grayColor[1],
                      }}
                    >
                      Place campaign222 content for page 1 in here!
                    </Typography>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  style={{
                    marginTop: `${size.width <= 320 ? (size.height - 120) / 2 :
                      size.width <= 360 ? (size.height - 120) / 2 :
                        size.width <= 365 ? (size.height - 120) / 2 :
                          size.width <= 375 ? (size.height - 120) / 2 :
                            size.width <= 414 ? (size.height - 120) / 2 :
                              (size.height - 120) / 2
                      }px`,
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setStep(2)
                  }}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </div>
            </div>

            <ThemeProvider theme={defaultBtnMaterialTheme}>
              <Button
                style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
                variant="contained"
                color="primary"
                fullWidth
                // href={email ? `/auth/signupweb?email=${email}` : `/auth/signupweb`}
                onClick={(e) => {
                  e.preventDefault()
                  setOpenSignUp(true)

                }}

              >
                Register
              </Button>
            </ThemeProvider>
          </div>
        </ThemeProvider>
      </Dialog>

      {/* PAGE 2 */}
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={step === 2}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                {'Place title here'}
              </div>

              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-42px ${size.width <= 414 ? -10 : -20}px -20px 0 `,
                  }}
                  className={classes.modalCloseButtonBYN}
                  href="/auth/dashboard"
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="notarypublic360.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 320 ? size.width - 60 :
                  size.width <= 360 ? size.width - 60 :
                    size.width <= 375 ? size.width - 60 :
                      size.width <= 414 ? size.width - 60 : 400
                  }px`,
                marginLeft: "-40px",
                display: "grid",
                gridTemplateColumns: `40px ${size.width <= 320 ? size.width - 100 :
                  size.width <= 360 ? size.width - 100 :
                    size.width <= 375 ? size.width - 100 :
                      size.width <= 414 ? size.width - 100 : 320
                  }px 40px`,
                gridTemplateRows: "auto auto",
              }}
            >

              <div>
                <Button
                  style={{
                    marginTop: `${size.width <= 320 ? (size.height - 120) / 2 :
                      size.width <= 360 ? (size.height - 120) / 2 :
                        size.width <= 365 ? (size.height - 120) / 2 :
                          size.width <= 375 ? (size.height - 120) / 2 :
                            size.width <= 414 ? (size.height - 120) / 2 :
                              (size.height - 120) / 2
                      }px`,
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setStep(1)
                  }}
                >
                  <ArrowBackIosIcon />
                </Button>
              </div>
              <div>
                <div
                  className={classes.container}
                  style={{

                    width: `${size.width <= 320 ? size.width - 60 :
                      size.width <= 360 ? size.width - 60 :
                        size.width <= 375 ? size.width - 60 :
                          size.width <= 414 ? size.width - 60 : 400
                      }px`,
                    overflow: "auto",
                    height: `${size.width <= 320 ? size.height - 120 :
                      size.width <= 360 ? size.height - 120 :
                        size.width <= 365 ? size.height - 120 :
                          size.width <= 375 ? size.height - 120 :
                            size.width <= 414 ? size.height - 120 :
                              size.height - 120
                      }px`,
                  }}
                >
                  <div style={{
                    marginTop: 40,
                    width: `${size.width <= 320 ? size.width - 80 :
                      size.width <= 360 ? size.width - 80 :
                        size.width <= 375 ? size.width - 80 :
                          size.width <= 414 ? size.width - 80 : 340
                      }px`,
                    marginLeft: '-10px',
                  }}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        color: grayColor[1],
                      }}
                    >
                      Place campaign222 content for page 2 in here!
                    </Typography>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  style={{
                    marginTop: `${size.width <= 320 ? (size.height - 120) / 2 :
                      size.width <= 360 ? (size.height - 120) / 2 :
                        size.width <= 365 ? (size.height - 120) / 2 :
                          size.width <= 375 ? (size.height - 120) / 2 :
                            size.width <= 414 ? (size.height - 120) / 2 :
                              (size.height - 120) / 2
                      }px`,
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setStep(3)
                  }}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </div>
            </div>

            <ThemeProvider theme={defaultBtnMaterialTheme}>
              <Button
                style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
                variant="contained"
                color="primary"
                fullWidth
                // href={email ? `/auth/signupweb?email=${email}` : `/auth/signupweb`}
                onClick={(e) => {
                  e.preventDefault()
                  setOpenSignUp(true)

                }}

              >
                Register
              </Button>
            </ThemeProvider>
          </div>
        </ThemeProvider>
      </Dialog>


      {/* PAGE 3 */}
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={step === 3}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                {'Place title here'}
              </div>

              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-42px ${size.width <= 414 ? -10 : -20}px -20px 0 `,
                  }}
                  className={classes.modalCloseButtonBYN}
                  href="/auth/dashboard"
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="notarypublic360.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 320 ? size.width - 60 :
                  size.width <= 360 ? size.width - 60 :
                    size.width <= 375 ? size.width - 60 :
                      size.width <= 414 ? size.width - 60 : 400
                  }px`,
                marginLeft: "-40px",
                display: "grid",
                gridTemplateColumns: `40px ${size.width <= 320 ? size.width - 100 :
                  size.width <= 360 ? size.width - 100 :
                    size.width <= 375 ? size.width - 100 :
                      size.width <= 414 ? size.width - 100 : 320
                  }px 40px`,
                gridTemplateRows: "auto auto",
              }}
            >

              <div>
                <Button
                  style={{
                    marginTop: `${size.width <= 320 ? (size.height - 120) / 2 :
                      size.width <= 360 ? (size.height - 120) / 2 :
                        size.width <= 365 ? (size.height - 120) / 2 :
                          size.width <= 375 ? (size.height - 120) / 2 :
                            size.width <= 414 ? (size.height - 120) / 2 :
                              (size.height - 120) / 2
                      }px`,
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setStep(2)
                  }}
                >
                  <ArrowBackIosIcon />
                </Button>
              </div>
              <div>
                <div
                  className={classes.container}
                  style={{

                    width: `${size.width <= 320 ? size.width - 60 :
                      size.width <= 360 ? size.width - 60 :
                        size.width <= 375 ? size.width - 60 :
                          size.width <= 414 ? size.width - 60 : 400
                      }px`,
                    overflow: "auto",
                    height: `${size.width <= 320 ? size.height - 120 :
                      size.width <= 360 ? size.height - 120 :
                        size.width <= 365 ? size.height - 120 :
                          size.width <= 375 ? size.height - 120 :
                            size.width <= 414 ? size.height - 120 :
                              size.height - 120
                      }px`,
                  }}
                >
                  <div style={{
                    marginTop: 40,
                    width: `${size.width <= 320 ? size.width - 80 :
                      size.width <= 360 ? size.width - 80 :
                        size.width <= 375 ? size.width - 80 :
                          size.width <= 414 ? size.width - 80 : 340
                      }px`,
                    marginLeft: '-10px',
                  }}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        color: grayColor[1],
                      }}
                    >
                      Place campaign222 content for page 3 in here!
                    </Typography>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  style={{
                    marginTop: `${size.width <= 320 ? (size.height - 120) / 2 :
                      size.width <= 360 ? (size.height - 120) / 2 :
                        size.width <= 365 ? (size.height - 120) / 2 :
                          size.width <= 375 ? (size.height - 120) / 2 :
                            size.width <= 414 ? (size.height - 120) / 2 :
                              (size.height - 120) / 2
                      }px`,
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setStep(4)
                  }}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </div>
            </div>

            <ThemeProvider theme={defaultBtnMaterialTheme}>
              <Button
                style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
                variant="contained"
                color="primary"
                fullWidth
                // href={email ? `/auth/signupweb?email=${email}` : `/auth/signupweb`}
                onClick={(e) => {
                  e.preventDefault()
                  setOpenSignUp(true)
                }}
              >
                Register
              </Button>
            </ThemeProvider>
          </div>
        </ThemeProvider>
      </Dialog>

      {/* PAGE 4 */}
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={step === 4}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                {'Place title here'}
              </div>

              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-42px ${size.width <= 414 ? -10 : -20}px -20px 0 `,
                  }}
                  className={classes.modalCloseButtonBYN}
                  href="/auth/dashboard"
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="notarypublic360.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 400}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 320 ? size.width - 60 :
                  size.width <= 360 ? size.width - 60 :
                    size.width <= 375 ? size.width - 60 :
                      size.width <= 414 ? size.width - 60 : 400
                  }px`,
                marginLeft: "-40px",
                display: "grid",
                gridTemplateColumns: `40px ${size.width <= 320 ? size.width - 100 :
                  size.width <= 360 ? size.width - 100 :
                    size.width <= 375 ? size.width - 100 :
                      size.width <= 414 ? size.width - 100 : 320
                  }px 40px`,
                gridTemplateRows: "auto auto",
              }}
            >

              <div>
                <Button
                  style={{
                    marginTop: `${size.width <= 320 ? (size.height - 120) / 2 :
                      size.width <= 360 ? (size.height - 120) / 2 :
                        size.width <= 365 ? (size.height - 120) / 2 :
                          size.width <= 375 ? (size.height - 120) / 2 :
                            size.width <= 414 ? (size.height - 120) / 2 :
                              (size.height - 120) / 2
                      }px`,
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setStep(3)
                  }}
                >
                  <ArrowBackIosIcon />
                </Button>
              </div>
              <div>
                <div
                  className={classes.container}
                  style={{

                    width: `${size.width <= 320 ? size.width - 60 :
                      size.width <= 360 ? size.width - 60 :
                        size.width <= 375 ? size.width - 60 :
                          size.width <= 414 ? size.width - 60 : 400
                      }px`,
                    overflow: "auto",
                    height: `${size.width <= 320 ? size.height - 120 :
                      size.width <= 360 ? size.height - 120 :
                        size.width <= 365 ? size.height - 120 :
                          size.width <= 375 ? size.height - 120 :
                            size.width <= 414 ? size.height - 120 :
                              size.height - 120
                      }px`,
                  }}
                >
                  <div style={{
                    marginTop: 40,
                    width: `${size.width <= 320 ? size.width - 80 :
                      size.width <= 360 ? size.width - 80 :
                        size.width <= 375 ? size.width - 80 :
                          size.width <= 414 ? size.width - 80 : 340
                      }px`,
                    marginLeft: '-10px',
                  }}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        color: grayColor[1],
                      }}
                    >
                      Place campaign222 content for page 4 in here!
                    </Typography>
                  </div>
                </div>
              </div>
              <div>

              </div>
            </div>

            <ThemeProvider theme={defaultBtnMaterialTheme}>
              <Button
                style={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
                variant="contained"
                color="primary"
                fullWidth
                // href={email ? `/ auth / signupweb ? email = ${email} ` : ` / auth / signupweb`}
                onClick={(e) => {
                  e.preventDefault()
                  setOpenSignUp(true)
                }}
              >
                Register
              </Button>
            </ThemeProvider>
          </div>
        </ThemeProvider>
      </Dialog>
    </>

  );
}

export default withStyles(styles)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(withStyles(modalStyle)(Campaign222))
  )
);