import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import useWindowSize from 'utils/useWindowSize';
import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards-pellucid.js";

import Button from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/styles";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import Waiting from "utils/Waiting";
import Tooltip from "@material-ui/core/Tooltip";
import circlelogo from "assets/img/byn-logo-circle.png";

import Transition from "utils/Transition";

const ConfirmExpenseUpdate = (props) => {
  const { classes } = props;
  const size = useWindowSize()

  if (
    document &&
    document.getElementById("waiting") &&
    document.getElementById("mainScreenConfirmExp")
  ) {
    if (props.isOpenWaitingOnConfirm) {
      document.getElementById("waiting").style.display = "block";
      document.getElementById("waiting").style.marginBottom = "70px";
      document.getElementById("mainScreenConfirmExp").style.marginTop = "-20px";
      document.getElementById("mainScreenConfirmExp").style.opacity = 0.1;
    } else {
      document.getElementById("waiting").style.display = "none";
      document.getElementById("waiting").style.marginBottom = "-70px";
      document.getElementById("mainScreenConfirmExp").style.marginTop = "30px";
      document.getElementById("mainScreenConfirmExp").style.opacity = 1;
    }
  }
  // Render nothing if the "show" prop is false
  if (!props.openConfirmUpdateExpenseLog) return null;

  function handleCancelConfirmUpdateExpenseLog() {
    props.setOpenConfirmUpdateExpenseLog(false);
    props.handleCloseUpdateExpenseLog();
  }
  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={props.openConfirmUpdateExpenseLog}
        // TransitionComponent={props.Transition}
        keepMounted
        // onClose={props.handleCloseConfirmUpdateExpenseLog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 400}px` }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              <div
                style={{
                  margin: `10px 0px 20px  0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                {!props.isOpenWaitingOnConfirm ? (
                  "Confirmation"
                ) : (
                  "Saving..."
                )}
              </div>

              <Tooltip
                id="tooltip-top"
                title={!props.isOpenWaitingOnConfirm ? "Confirmation" : "Saving..."}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-72px  ${size.width <= 414 ? -10 : -20}px 0 0`,
                  }}
                  className={classes.modalCloseButtonBYN}
                // href="/app/app"
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultBtnMaterialTheme}>
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 400}px` }}
          >
            <div id="waiting">
              <Waiting
                Transition={Transition}
                isOpenWaiting={props.isOpenWaitingOnConfirm}
              ></Waiting>
            </div>
            <div id="mainScreenConfirmExp">
              <div
                className={classes.container}
                style={{
                  overflow: "auto",
                  width: `${size.width <= 414 ? size.width - 40 : 360}px`,
                  height: `${size.height - 165}px`,
                }}
              >
                {!props.isOpenWaitingOnConfirm ? (
                  <Typography
                    style={{
                      margin: "80px 0 40px 0",
                      fontSize: "26px",
                      color: grayColor[1],
                      textAlign: "center",
                    }}
                  >
                    Log Edit Saved.
                  </Typography>
                ) : null}
              </div>
              <DialogActions className={classes.modalFooter}>
                <ThemeProvider theme={defaultBtnMaterialTheme}>
                  {!props.isOpenWaitingOnConfirm ? (
                    <Button
                      style={{ width: "100%" }}
                      disabled={props.isOpenWaitingOnConfirm}
                      variant="contained"
                      onClick={handleCancelConfirmUpdateExpenseLog}
                      color="primary"
                    >
                      Done
                    </Button>
                  ) : null}
                </ThemeProvider>
              </DialogActions>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(withStyles(modalStyle)(ConfirmExpenseUpdate));
