import React, { Suspense } from "react";
import cx from "classnames";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AppDrawerNavbar from "components/Navbars/AppDrawerNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/AppDrawerSidebar.js";
import Spinner from "components/Spinner/Spinner";

import routes_ from "pellucidroutes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import { compose } from "recompose";
import { withFirebase } from "utils/Firebase";
import {
  //withEmailVerification,
  withAuthentication,
} from "utils/Session";
import { IS_BYN_MEMBER_QRY } from "utils/Graphql/queries";
import { createUserLog } from "utils/CreateUserLog";

var ps;

const useStyles = makeStyles(styles);

const DashboardBase = (props) => {
  const { authUser, client, ...rest } = props;
  //debugger;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [color] = React.useState("purple");
  const [bgColor] = React.useState("white");
  const [logo] = React.useState(
    require("assets/img/byn-logo-circle.png")
  );

  const [routes, setRoutes] = React.useState([]);

  // styles
  const classes = useStyles();

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div

  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: false,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  React.useEffect(() => {
    const { client, authUser } = props;
    console.log("DashboardBase useEffect []");
    debugger;
    client
      .query({
        query: IS_BYN_MEMBER_QRY,
        variables: {
          email: authUser.email,
        },
        fetchPolicy: "cache-first",
      })
      .then((response) => {
        debugger;
        const { bynCheck } = response.data;
        debugger;
        console.log("bynCheck");
        console.log(bynCheck);
        setRoutes(
          routes_.filter((r) => bynCheck || (!bynCheck && r.name !== "Invite"))
        );

        console.log("DashboardBase...useEffect []");
      });
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const retainMobileOpen = () => {
    setMobileOpen(true);
  };

  const getRoute = () => {
    return window.location.pathname !== "/app/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    // console.log("getActiveRoute()");
    let activeRoute = ""; //'BillYouNow';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].name !== "Home") {
            createUserLog(props, "BYN Drawer Menu items", "clicked " + routes[i].name, "")
          }
          return routes[i].name !== "Home" ? routes[i].name : "";
        }
      }
    }
    console.log("..getActiveRoute()");
    return activeRoute;
  };
  const getRoutes = (routes) => {
    console.log("getRoutes()");
    if (authUser) {
      return routes.map((prop, key) => {
        if (prop.collapse) {
          return getRoutes(prop.views);
        }
        if (prop.layout === "/app") {
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => <prop.component client={client} {...props} />}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    } else {
      // do nothing
    }
    console.log("..getRoutes()");
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 10) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        client={client}
        authUser={authUser}
        routes={routes}
        logoText={""}
        logo={logo}
        //image={image}
        handleDrawerToggle={handleDrawerToggle}
        retainMobileOpen={retainMobileOpen}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AppDrawerNavbar
          client={client}
          authUser={authUser}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Suspense
                fallback={
                  <div>
                    <Spinner />
                  </div>
                }
              >
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/app" to="/app/dashboard" />
                </Switch>
              </Suspense>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Suspense
              fallback={
                <div>
                  <Spinner />
                </div>
              }
            >
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/app" to="/app/dashboard" />
              </Switch>
            </Suspense>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        /> */}
      </div>
    </div>
  );
};

const Dashboard = compose(
  withRouter,
  withFirebase
)(DashboardBase);

export default withFirebase(withAuthentication(Dashboard));
