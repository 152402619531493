import React, { useState } from "react";
// import FormControl from "@material-ui/core/FormControl";
import { Button, Tooltip } from "@material-ui/core";
import useWindowSize from 'utils/useWindowSize';

// import Close from "@material-ui/icons/Close";
// import Person from "@material-ui/icons/Person";
// import SupervisorAccount from "@material-ui/icons/SupervisorAccount";

// @material-ui/core components
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import circlelogo from "assets/img/byn-logo-circle.png";
import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";

// import { gql } from "apollo-boost";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

// import xClose from "assets/img/x27.png";

// import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
// import red from "utils/colors/red";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
// import errorMaterialTheme from "utils/Themes/errorMaterialTheme";
import { primaryColor, grayColor } from "assets/jss/material-kit-pro-react.js";
// import FormHelperText from "@material-ui/core/FormHelperText";

import { CREATE_CLIENT_MUTATION } from "utils/Graphql/mutations";
import { CLIENTS_BY_MEMBER_QUERY } from "utils/Graphql/queries";
import { createUserLog } from "utils/CreateUserLog";
// import Transition from "utils/Transition";





// const style = {
//   grid: {
//     width: "80%",
//   },
//   step1: {
//     display: "grid",
//     gridTemplateColumns: "1fr ",
//     gridTemplateRows: "auto auto",
//   },
//   step1Client: {
//     display: "grid",
//     gridTemplateColumns: "1fr",
//     gridTemplateRows: "auto auto",
//     marginTop: "20px",
//     marginBottom: "40px",
//   },
//   // step1ClientCompany
//   step1ClientCompany: {
//     display: "grid",
//     gridTemplateColumns: "1fr",
//     gridTemplateRows: "auto auto",
//     marginTop: "40px",
//     marginBottom: "20px",
//   },
// };

var _CreateClient = {
  id: "",
  lastName: "",
  firstName: "",
  company: "",
  line: [],
};

function CreateClient(props) {
  const { classes } = props;

  const [openNotification, setOpenNotification] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const [line1, setLine1] = useState("");
  // const [line2, setLine2] = useState("");
  const [line3, setLine3] = useState("");

  const handleCloseNotification = () => {
    debugger;
    setOpenNotification(false);
  };

  const size = useWindowSize()

  const callCreateClient = async (values, setFieldValue) => {
    return new Promise((resolve, reject) => {
      const { _client, _member } = props;
      const { id } = _member;
      const { lastName, firstName, company } = values;
      debugger;
      _client
        .query({
          query: CLIENTS_BY_MEMBER_QUERY,
          variables: {
            memberId: id,
            lastName,
            firstName,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { clientsByMember } = response.data;
          console.log("clientsByMember");
          console.log(clientsByMember);

          debugger;
          let client_ = [];
          clientsByMember
            .filter(
              (s) =>
                (s.status === "ACTIVE" || s.status === "INACTIVE") &&
                s.lastName === lastName &&
                s.firstName === firstName &&
                s.company === company
            )
            .map((s) => {
              client_.push({
                lastName: s.lastName,
                firstName: s.firsName,
                company: s.company,
              });
            });

          if (client_.length === 1) {
            let s = "";
            if (lastName && firstName && company) {
              s = s + lastName + ", " + firstName + " (" + company + ")";
            } else if (lastName && firstName && !company) {
              s = s + lastName + ", " + firstName
            } else if (!lastName && firstName && company) {
              s = s + firstName + " (" + company + ")";
            } else if (!lastName && firstName && !company) {
              s =
                s + firstName
            } else if (lastName && !firstName && company) {
              s = s + lastName + " (" + company + ")";
            } else if (lastName && !firstName && !company) {
              s =
                s + lastName
            } else if (!lastName && !firstName && company) {
              s =
                s +
                "(" +
                company +
                ")";
            } else if (!lastName && !firstName && !company) {
              s = s + "";
            }
            setLine1(s);
            reject(new Error(s));
          } else {
            let clients = [];
            clientsByMember
              .filter(
                (s) =>
                  (s.status === "ACTIVE" || s.status === "INACTIVE") &&
                  ((s.lastName &&
                    lastName &&
                    s.lastName.length > 1 &&
                    lastName.length > 1 &&
                    (s.lastName === lastName)) ||
                    (s.company &&
                      company &&
                      s.company.length > 1 &&
                      company.length > 1 &&
                      (s.company === company))
                  )
              )
              .map((s) => {
                clients.push({
                  lastName: s.lastName,
                  firstName: s.firstName,
                  company: s.company,
                });
              });

            if (clients.length > 0) {
              let s = "";
              let m = "";
              if (lastName && firstName) {
                s = firstName + " " + lastName;
              } else if (lastName && !firstName) {
                s = lastName;
              } else if (!lastName && firstName) {
                s = firstName;
              }
              // setLine1(s)
              if (company) {
                m = s + " (" + company + ")";
              } else {
                m = s
              }
              setLine3(m);
              let ts = "";

              _CreateClient.line = [];
              for (let i = 0; i < clients.length; i++) {
                let s1 = "";
                if (clients[i].lastName && clients[i].firstName) {
                  s1 = clients[i].firstName + " " + clients[i].lastName;
                } else if (clients[i].lastName && !clients[i].firstName) {
                  s1 = clients[i].lastName;
                } else if (!clients[i].lastName && clients[i].firstName) {
                  s1 = clients[i].firstName;
                }
                // setLine1(s)
                if (clients[i].company) {
                  ts = s1 + " (" + clients[i].company + ")";
                } else {
                  ts = s1
                }

                if (_CreateClient.line.filter((ss) => ss === ts).length === 0)
                  _CreateClient.line.push(ts);
              }
              setLine1(ts);
              setOpenNotification(true);
            } else {
              _client
                .mutate({
                  mutation: CREATE_CLIENT_MUTATION,
                  variables: {
                    id,
                    lastName: lastName.toUpperCase(),
                    firstName: firstName.toUpperCase(),
                    company: company.toUpperCase(),
                    hourlyRate: 0.0,
                    memberId: id,
                  },
                })
                .then((response) => {
                  debugger;
                  console.log(response);
                  const { createClient } = response.data;
                  debugger;
                  _CreateClient.id = createClient.id
                  props.handleCloseClient();
                  setOpenSuccess(true)

                  // props.initialValues.client = createClient.id;
                  // props.initialValues.case_ = "";
                  // debugger;

                  // props.getClients().then((response) => {
                  //   debugger;
                  //   console.log("handleSubmitClient in getClients");

                  //   const { clientsByEmail } = response.data;
                  //   console.log(clientsByEmail);

                  //   let currentClient = clientsByEmail.filter(
                  //     ({ id }) => id === createClient.id
                  //   )[0];

                  //   props
                  //     .changeClient(
                  //       createClient.id,
                  //       currentClient,
                  //       clientsByEmail
                  //     )
                  //     .then(() => {
                  //       console.log("out");
                  //       props.handleCloseClient();
                  //     });
                  // });
                });
              debugger;
            }
          }
        });
    });
  };

  const createClient = async () => {
    const { _client, _member } = props;
    const { id } = _member;
    debugger;
    _client
      .mutate({
        mutation: CREATE_CLIENT_MUTATION,
        variables: {
          id,
          lastName: _CreateClient.lastName,
          firstName: _CreateClient.firstName,
          company: _CreateClient.company,
          hourlyRate: 0.0,
          memberId: id,
        },
      })
      .then((response) => {
        debugger;
        console.log(response);
        const { createClient } = response.data;
        debugger;
        _CreateClient.id = createClient.id
        props.handleCloseClient();
        setOpenSuccess(true)


        // props.initialValues.client = createClient_.id;
        // props.initialValues.case_ = "";
        // debugger;

        // props.getClients().then((response) => {
        //   debugger;
        //   console.log("handleSubmitClient in getClients");

        //   const { clientsByEmail } = response.data;
        //   console.log(clientsByEmail);

        //   let currentClient = clientsByEmail.filter(
        //     ({ id }) => id === createClient.id
        //   )[0];

        //   props
        //     .changeClient(createClient.id, currentClient, clientsByEmail)
        //     .then(() => {
        //       console.log("out");


        //     });
        // });
      });
  };

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        // TransitionComponent={props.Transition}
        keepMounted
        open={props.openClient}
        onClose={props.handleCloseClient}
        scroll={props.scroll}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <GridContainer justify="center">
            <GridItem zeroMinWidth>
              <div
                style={{
                  width: `${size.width <= 414 ? size.width - 120 : 400}px`,
                }}
              >
                <ThemeProvider theme={defaultMaterialTheme}>
                  <div
                    style={{
                      margin: `10px 0 0 0`,
                      color: primaryColor[0],
                      fontSize: "30px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    Add Client
                  </div>
                  <ThemeProvider theme={defaultBtnMaterialTheme}>
                    <Tooltip
                      id="tooltip-top"
                      title={"Home"}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        style={{
                          margin: `-40px -20px 0 0 `,
                        }}
                        className={classes.modalCloseButtonBYN}
                        onClick={e => {
                          e.preventDefault()
                          props.history.push("/app/dashboard")
                        }}
                      >
                        <img
                          style={{ width: "50px" }}
                          src={circlelogo}
                          alt="billyounow.com"
                        />
                      </Button>
                    </Tooltip>
                  </ThemeProvider>
                </ThemeProvider>
              </div>
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent>
          <ThemeProvider theme={defaultMaterialTheme}>
            <GridContainer justify="center">
              <GridItem zeroMinWidth>
                <div
                  style={{
                    width: `${size.width <= 414 ? size.width - 120 : 400}px`,
                  }}
                >
                  <Formik
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      company: "",
                    }}
                    validate={(values) => {
                      // debugger
                      console.log("validate...");
                      const errors = {};

                      if (
                        !values.firstName &&
                        !values.lastName &&
                        !values.company
                      ) {
                        errors.company = "Please fill at least one field.";
                      }
                      console.log("errors");
                      console.log(errors);
                      console.log("...validate");
                      return errors;
                    }}
                    onSubmit={(
                      values,
                      { isSubmitting, setSubmitting, setFieldValue }
                    ) => {
                      debugger;
                      isSubmitting = true;
                      // handleSubmitClient(values, isSubmitting)
                      createUserLog(props, "CREATE NEW CLIENT", "Created a new Client", "")
                      callCreateClient(values, setFieldValue).catch((e) => {
                        setSubmitting(false);
                        setOpenDuplicate(true);
                      });
                    }}
                    render={({
                      values,
                      isSubmitting,
                      setSubmitting,
                      handleChange,
                      setFieldValue,
                      errors,
                      touched,
                    }) => (
                      <Form mode="themed">
                        <GridContainer justify="center">
                          <GridItem zeroMinWidth>
                            <div
                              style={{
                                width: `${size.width <= 414 ? size.width - 120 : 400
                                  }px`,
                              }}
                            >
                              <ThemeProvider theme={defaultMaterialTheme}>
                                <div
                                  style={{
                                    overflow: "auto",
                                    height: `${size.height - 180}px`,
                                  }}
                                >
                                  <TextField
                                    style={{
                                      margin: "60px 0 0 0",
                                      textTranform: "uppercase",
                                    }}
                                    autoFocus
                                    fullWidth
                                    name="firstName"
                                    label="First Name"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                                    onChange={(event) => {
                                      values.firstName = event.target.value.toUpperCase();
                                      setFieldValue(
                                        "firstName",
                                        event.target.value.toUpperCase()
                                      );
                                      _CreateClient.firstName = event.target.value.toUpperCase();
                                    }}
                                    value={values.firstName}
                                  />

                                  <TextField
                                    style={{
                                      margin: "20px 0 0 0",
                                      textTranform: "uppercase",
                                    }}
                                    name="lastName"
                                    fullWidth
                                    label="Last Name"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                                    onChange={(event) => {
                                      values.lastName = event.target.value.toUpperCase();
                                      setFieldValue(
                                        "lastName",
                                        event.target.value.toUpperCase()
                                      );
                                      _CreateClient.lastName = event.target.value.toUpperCase();
                                    }}
                                    value={values.lastName}
                                  />
                                  <TextField
                                    style={{
                                      margin: "20px 0 20px 0",
                                      textTranform: "uppercase",
                                    }}
                                    fullWidth
                                    name="company"
                                    label="Company"
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                                    onChange={(event) => {
                                      values.company = event.target.value.toUpperCase();
                                      setFieldValue(
                                        "company",
                                        event.target.value.toUpperCase()
                                      );
                                      _CreateClient.company = event.target.value.toUpperCase();
                                    }}
                                    value={values.company}
                                  />
                                  <Typography
                                    style={{
                                      textAlign: "center", fontSize: '16px', color: primaryColor[0],
                                      display: values.firstName === "" && values.lastName === "" && values.company === "" ? 'block' : 'none'
                                    }}>
                                    <em>* Required</em>
                                  </Typography>
                                  <Typography
                                    style={{
                                      textAlign: "center",
                                      fontSize: "18px",
                                      color: grayColor[1],
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Fill in at least one field.
                                  </Typography>
                                </div>
                              </ThemeProvider>
                              <DialogActions className={classes.modalFooter}>
                                <ThemeProvider theme={defaultBtnMaterialTheme}>
                                  <Button
                                    style={{ width: "60%" }}
                                    disabled={
                                      isSubmitting ||
                                      (!isSubmitting &&
                                        ((values.lastName === "" || values.lastName.length < 2) &&
                                          values.firstName === "" &&
                                          (values.company === "" || values.company.length < 2)))
                                    }
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="md"
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    style={{ width: "40%" }}
                                    variant="outlined"
                                    color="primary"
                                    size="md"
                                    onClick={props.handleCloseClient}
                                  >
                                    Cancel
                                  </Button>
                                </ThemeProvider>
                              </DialogActions>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </Form>
                    )}
                  ></Formik>
                </div>
              </GridItem>
            </GridContainer>
          </ThemeProvider>
        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <GridContainer justify="center">
            <GridItem zeroMinWidth>
              <div
                style={{
                  width: `${size.width <= 414 ? size.width - 120 : 400}px`,
                }}
              >
                <ThemeProvider theme={defaultMaterialTheme}>
                  <div
                    style={{
                      margin: `10px 0 0 0`,
                      color: primaryColor[0],
                      fontSize: "30px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    Verify
                  </div>
                  <ThemeProvider theme={defaultBtnMaterialTheme}>
                    <Tooltip
                      id="tooltip-top"
                      title={"Home"}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        style={{
                          margin: `-40px -20px 0 0 `,
                        }}
                        className={classes.modalCloseButtonBYN}
                        onClick={e => {
                          e.preventDefault()
                          props.history.push("/app/dashboard")
                        }}
                      >
                        <img
                          style={{ width: "50px" }}
                          src={circlelogo}
                          alt="billyounow.com"
                        />
                      </Button>
                    </Tooltip>
                  </ThemeProvider>
                </ThemeProvider>
              </div>
            </GridItem>
          </GridContainer>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                The following client/s
                  </Typography>
              <Typography
                style={{
                  margin: "0 0 20px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                have similar name/s:
                  </Typography>
              {_CreateClient.line.map((s) => (
                <Typography
                  style={{
                    margin: "0 0 0 0",
                    fontSize: "16px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  {s}
                </Typography>
              ))}
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "16px",
                  color: primaryColor[0],
                  textAlign: "center",
                }}
              >
                {line3}
              </Typography>
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Verify client addition.
              </Typography>
            </div>

            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    createClient();
                    setOpenNotification(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenNotification(false);
                  }}
                >
                  No
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog >

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openDuplicate}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 400}px` }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              <div
                style={{
                  margin: `10px 0px 20px  0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Add Client
              </div>

              <Tooltip
                id="tooltip-top"
                title={!props.isOpenWaitingOnConfirm ? "Confirmation" : "Saving..."}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-60px  ${size.width <= 414 ? -10 : -20}px 0 0`,
                  }}
                  className={classes.modalCloseButtonBYN}
                // href="/app/app"
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 400}px` }}
          >
            <div>

              <div
                className={classes.container}
                style={{
                  overflow: "auto",
                  width: `${size.width <= 414 ? size.width - 40 : 360}px`,
                  height: `${size.height - 135}px`,
                }}
              >
                <Typography
                  style={{
                    margin: "80px 0 10px 0",
                    fontSize: "22px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  Client already exists, cannot add same client
                </Typography>
              </div>

              <DialogActions className={classes.modalFooter}>
                <ThemeProvider theme={defaultBtnMaterialTheme}>
                  <Button
                    style={{ width: "100%" }}
                    variant="outlined"
                    color="primary"
                    size="md"
                    onClick={() => {
                      setOpenDuplicate(false);
                    }}
                  >
                    Close
                </Button>
                </ThemeProvider>
              </DialogActions>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openSuccess}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 10px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Client added.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenSuccess(false);
                    props.initialValues.client = _CreateClient.id
                    props.initialValues.case_ = "";
                    debugger;
                    props.getClients().then((response) => {
                      debugger;
                      console.log("handleSubmitClient in getClients");

                      const { clientsByEmail } = response.data;
                      console.log(clientsByEmail);

                      let currentClient = clientsByEmail.filter(
                        ({ id }) => id === _CreateClient.id
                      )[0];

                      props
                        .changeClient(_CreateClient.id, currentClient, clientsByEmail)
                        .then(() => {
                          console.log("out");
                          // props.handleCloseClient();

                        });
                    });
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>
    </>
  );
}

export default withStyles(javascriptStyles)(
  withStyles(basicsStyle)(withStyles(modalStyle)(CreateClient))
);
