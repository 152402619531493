import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import useWindowSize from 'utils/useWindowSize';

import Waiting from "utils/Waiting";
import { Formik, Form } from "formik";
// import { useMediaQuery } from "react-responsive";

import FormHelperText from "@material-ui/core/FormHelperText";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { gql } from "apollo-boost";
import circlelogo from "assets/img/byn-logo-circle.png";

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { createUserLog } from "utils/CreateUserLog";

import "utils/react-datetime/css/react-datetime.css";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";

import { withFirebase } from "utils/Firebase";

import { ThemeProvider } from "@material-ui/styles";

import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import sameTheme from "utils/Themes/viewLogMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import MessageDialog from "utils/MessageDialog";
import MessageDialog2 from "utils/MessageDialog";
import Transition from "utils/Transition";

import { UPDATE_MEMBER_MUTATION } from 'utils/Graphql/mutations'




const style = {
  step3Notes480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
};

const initialValues = {
  oldUserName: "",
  newUserName: "",
};

function UserName(props) {
  const { classes, open, onClose, reFetch, memberData } = props;

  // const [hasDeleteRequest, setHasDeleteRequest] = useState(false);
  const [hasDeleteRequest] = useState(false);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block";
      document.getElementById("mainScreen").style.opacity = 0.3;
    } else {
      if (
        document &&
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);

  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [openMessageDialog2, setOpenMessageDialog2] = useState(false);
  const size = useWindowSize()
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px");
  // const [closeRightMargin, setCloseRightMargin] = useState("0px");

  useEffect(() => {
    console.log(memberData);
    initialValues.oldUserName = memberData ? memberData.userName : '';
    initialValues.newUserName = ''

  }, [memberData]);
  const handleCloseMessageDialog = () => {
    reFetch();
    setOpenMessageDialog(false);
    onClose();
    // props.history.push('/profile');
  };

  const handleCloseMessageDialog2 = () => {
    // reFetch();
    setOpenMessageDialog2(false);
    // onClose();
    // props.history.push('/profile');
  };
  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog
        }}
        open={open}
        onClose={onClose}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                User Name
              </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
            <Waiting
              Transition={Transition}
              isOpenWaiting={isOpenWaiting}
            ></Waiting>
          </div>
          {hasDeleteRequest ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "30px",
              }}
            >
              <Typography>
                You have a pending delete request. Cancel the delete request to
                log an expense.
              </Typography>
            </div>
          ) : (
            ""
          )}
          <div id="mainScreen" style={{ marginTop: "-30px" }}>
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 30 : 370}px`,
              }}
            >
              <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting }) => {
                  // setSubmitting(true);
                }}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  setSubmitting,
                  touched,
                  errors,
                }) => (
                  <Form mode="themed">
                    <div
                      className={classes.container}
                      style={{
                        overflow: "auto",
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        height: `${size.height - 165}px`,
                      }}
                    >
                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          style={{ margin: "40px 0px 0px 0" }}
                          fullWidth
                          name="oldUserName"
                          label="Old"
                          variant="outlined"
                          disabled={hasDeleteRequest}
                          // onChange={(event) => {
                          //   values.oldUserName = event.target.value;
                          //   setFieldValue(
                          //     "oldUserName",
                          //     event.target.value
                          //   );
                          // }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          value={values.oldUserName}
                        />
                        <FormHelperText
                          style={{ color: primaryColor[0], marginLeft: "10px" }}
                        >
                          {errors.oldUserName}
                        </FormHelperText>
                      </ThemeProvider>

                      <TextField
                        style={{ margin: "20px 0px 0px 0" }}
                        autoFocus
                        fullWidth
                        name="newUserName"
                        label="New"
                        variant="outlined"
                        disabled={hasDeleteRequest}
                        onChange={(event) => {
                          values.newUserName = event.target.value
                          setFieldValue(
                            "newUserName",
                            event.target.value
                          );
                          console.log('typing new username')
                          console.log(values.newUserName)
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={values.newUserName.length === 0}

                        value={values.newUserName}
                      />
                      <Typography
                        style={{
                          textAlign: "center", fontSize: '16px', color: primaryColor[0],
                          display: values.newUserName === "" ? 'block' : 'none'
                        }}>
                        <em>* Required</em>
                      </Typography>
                      <FormHelperText
                        style={{ color: primaryColor[0], marginLeft: "10px" }}
                      >
                        {errors.newUserName}
                      </FormHelperText>
                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>

                        <Button
                          style={{
                            width: "60%"
                          }}
                          variant="contained"
                          color="primary"
                          size="md"
                          disabled={isSubmitting || values.newUserName.length < 3 || (values.oldUserName === values.newUserName)}
                          onClick={() => {
                            if (values.oldUserName === values.newUserName) {
                              setOpenMessageDialog2(true);
                            }
                            else {
                              console.log('UserName values')
                              console.log(values)
                              setSubmitting(true);
                              setIsOpenWaiting(true)
                              const { client } = props
                              client.query({
                                query: MEMBER_CHECK1,
                                variables: {
                                  email: values.newUserName
                                }
                              })
                                .then(response => {
                                  const { data } = response
                                  const { memberCheck1 } = data
                                  if (memberCheck1.length === 0) {
                                    client.mutate({
                                      mutation: UPDATE_MEMBER_MUTATION,
                                      variables: {
                                        memberId: memberData.id,
                                        userName: values.newUserName,
                                      }
                                    })
                                      .then(response => {
                                        createUserLog(props, "USERNAME", "Changed user name", "")
                                        setSubmitting(false);
                                        setIsOpenWaiting(false)
                                        setOpenMessageDialog(true);
                                        reFetch()
                                      })
                                  } else {
                                    console.log('user name exists')
                                    setSubmitting(false);
                                    setIsOpenWaiting(false)
                                    setOpenMessageDialog2(true);
                                  }
                                })
                                .catch(e => {
                                  console.log(e)
                                })
                            }

                          }}
                        >
                          Save
                          </Button>

                        <Button
                          style={{
                            width: "40%"
                          }}
                          variant="outlined"
                          color="primary"
                          size="md"
                          // disabled={isSubmitting}
                          onClick={onClose}
                        // type="submit"
                        >
                          Cancel
                          </Button>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>

      <MessageDialog
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={"User name changed."}
        messageOnMessageDialog2={""}
        messageDialogTitle={"User Name"}
        messageLabelBtn={"done"}
        variant={"contained"}
      // push={"/app/profile"}
      />

      <MessageDialog2
        Transition={props.Transition}
        openMessageDialog={openMessageDialog2}
        handleCloseMessageDialog={handleCloseMessageDialog2}
        setOpenMessageDialog={setOpenMessageDialog2}
        messageOnMessageDialog={"That user name is associated"}
        messageOnMessageDialog2={" with an existing account."}
        messageDialogTitle={"User Name"}
        messageLabelBtn={"back"}
        variant={"outlined"}
      // push={"/app/profile"}
      />

    </>

  );
}

export const MEMBER_CHECK1 = gql`
  query memberCheck1($email: String!) {
    memberCheck1(email: $email) {
      id
      email
      userName
      displayName
      hasConfirmedAgreement
      hasRespondedToInvite
      hasInitiallyLoggedIn
      phoneNo
      isLoggedIn
    }
  }
`;

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(UserName)))
    )
  )
);
