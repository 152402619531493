import React from 'react';
import Typography from "@material-ui/core/Typography";
import './PasswordStrength.css';
import zxcvbn from 'zxcvbn';

function PasswordStrength(props) {

  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }

  const { password } = props;
  const testedResult = zxcvbn(password);
  return (
    <div style={{ margin: "-5px 0 0px 0" }} className="password-strength-meter">
      <progress
        className={`password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`}
        value={testedResult.score}
        max="4"
        style={{ margin: "-10px 0 0 0" }}
      />
      {password && (
        <Typography style={{ margin: "-5px 0 5px 0", fontSize: '11px', textlign: 'center' }}>
          Password strength: {createPasswordLabel(testedResult)}
        </Typography>
      )}
    </div>
  )
}

export default PasswordStrength;
