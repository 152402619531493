import React, { useState, useEffect, useRef } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@material-ui/core";
import useWindowSize from 'utils/useWindowSize';

import {
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  Formik,
} from "formik";
import { Form } from "react-formik-ui";
import {
  Switch,
  FormControlLabel,
  Button,
  TextField,
} from "@material-ui/core";
import { primaryColor, grayColor } from "assets/jss/material-kit-pro-react.js";
// import FailDialog from "./failDialog";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import circlelogo from "assets/img/byn-logo-circle.png";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { UPDATE_CLIENT_MUTATION } from "utils/Graphql/mutations";
import { createUserLog } from "utils/CreateUserLog";



const _EditClient = {
  values: {
    lastName: "",
    firstName: "",
    company: "",
    status: "",
  },
};
const Switchy = withStyles({
  switchBase: {
    color: primaryColor[0],
    "&$checked": {
      color: primaryColor[0],
    },
    "&$checked + $track": {
      backgroundColor: primaryColor[0],
    },
  },
  checked: {},
  track: {},
})(Switch);

const EditClient = (props) => {
  const { classes } = props;
  const size = useWindowSize()
  const formRef = useRef();
  const {
    client,
    currentClient,
    open,
    onClose,
    handleSubmitEditClient,
    getActiveCases,
  } = props;
  const [toggled, setToggled] = useState(true);
  const [openFail, setOpenFail] = useState(false);

  const [openActive, setOpenActive] = useState(false);
  const [openInActive, setOpenInActive] = useState(false);
  const [openStatusNotification, setOpenStatusNotification] = useState(false);
  //

  useEffect(() => {
    console.log(currentClient.status);
    if (currentClient.status === "ACTIVE") setToggled(true);
    else if (currentClient.status === "INACTIVE") setToggled(false);
  }, [currentClient.status]);

  useEffect(() => {
    console.log(client);
  }, [open]);

  const handleChangeStatus = (event, values, setFieldValue) => {
    const stat = event.target.checked;
    console.log(stat);
    console.log(getActiveCases(currentClient));
    console.log("pwed");
    debugger;
    setToggled(stat);
    if (stat === true) {
      setFieldValue("status", "ACTIVE");
      values.status = "ACTIVE";
      console.log("values");
      console.log(values);
      const { lastName, firstName, company, status } = values;
      const { member, id, parentId, ord } = props.currentClient;
      client
        .mutate({
          mutation: UPDATE_CLIENT_MUTATION,
          variables: {
            memberId: member.id,
            clientId: id,
            lastName: lastName ? lastName.toUpperCase() : lastName,
            firstName: firstName ? firstName.toUpperCase() : firstName,
            company: company ? company.toUpperCase() : company,
            parentId,
            ord,
            status: status ? status.toUpperCase() : status,
            actionDate: new Date().toISOString(),
          },
        })
        .then((response) => {
          createUserLog(props, "EDIT CLIENT", "Edited Client info", "")
          console.log("...handleChangeStatus");
          setOpenActive(true);
        });
    } else {
      setFieldValue("status", "INACTIVE");
      values.status = "INACTIVE";
      // props.setEditValues(values);
      _EditClient.values = { ...values };
      console.log("values");
      console.log(values);
      setOpenStatusNotification(true);
    }
    // }
  };

  const handleCloseFail = () => {
    setOpenFail(false);
  };
  return (
    <>
      <Dialog
        classes={{
          root: props.classes.modalRoot,
          paper: props.classes.modalLog,
        }}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Edit Client
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: props.classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={props.classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <Formik
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={{
                firstName: currentClient.firstName
                  ? currentClient.firstName
                  : "",
                lastName: currentClient.lastName ? currentClient.lastName : "",
                company: currentClient.company ? currentClient.company : "",
                status: currentClient.status,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                console.log(values);
                handleSubmitEditClient(values);

              }}
            >
              {({
                values,
                isSubmitting,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                touched,
                errors,
                dirty,
                handleChange,
                handleSubmit,
                handleReset,
                initialErrors,
                isInitialValid,
                isValid,
              }) => (
                <Form>
                  <div
                    style={{
                      width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                      overflow: "auto",
                      height: `${size.height - 135}px`,
                    }}
                  >
                    <TextField
                      id="firstName"
                      style={{ margin: "80px 0 20px 0" }}
                      variant="outlined"
                      label="First Name"
                      value={values.firstName}
                      onChange={(e) => {
                        values.firstName = e.target.value.toUpperCase();
                        setFieldValue(
                          "firstName",
                          e.target.value.toUpperCase()
                        );
                      }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                    />
                    <TextField
                      id="lastName"
                      style={{ marginBottom: "20px" }}
                      variant="outlined"
                      label="Last Name"
                      value={values.lastName}
                      onChange={(e) => {
                        values.lastName = e.target.value.toUpperCase();
                        setFieldValue("lastName", e.target.value.toUpperCase());
                      }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                    />
                    <TextField
                      id="company"
                      style={{ marginBottom: "20px" }}
                      variant="outlined"
                      label="Company"
                      value={values.company}
                      onChange={(e) => {
                        values.company = e.target.value.toUpperCase();
                        setFieldValue("company", e.target.value.toUpperCase());
                      }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.firstName.length === 0 && values.lastName.length === 0 && values.company.length === 0}
                    />
                    <Typography
                      style={{
                        textAlign: "center", fontSize: '16px', color: primaryColor[0],
                        display: values.firstName === "" && values.lastName === "" && values.company === "" ? 'block' : 'none'
                      }}>
                      <em>* Required</em>
                    </Typography>

                    <Typography
                      style={{
                        fontSize: "18px",
                        margin: "20px 0 -5px 0",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      Status
                    </Typography>
                    <div style={{ textAlign: "center" }}>
                      <FormControlLabel
                        control={
                          <Switchy
                            checked={toggled}
                            onChange={(e) => {
                              handleChangeStatus(e, values, setFieldValue);

                              debugger;
                            }}
                            disableRipple
                            color={primaryColor[0]}
                            name="status"
                          />
                        }
                        label={toggled === true ? "ACTIVE" : "INACTIVE"}
                      />
                    </div>
                  </div>
                  <DialogActions className={props.classes.modalFooter}>
                    <ThemeProvider theme={defaultBtnMaterialTheme}>
                      <Button
                        style={{ width: "60%" }}
                        variant="contained"
                        disableElevation
                        color="primary"
                        type="submit"
                      >
                        Go
                      </Button>
                      <Button
                        style={{ width: "40%" }}
                        variant="outlined"
                        disableElevation
                        color="primary"
                        variant="outlined"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    </ThemeProvider>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </div>
        </ThemeProvider>
      </Dialog>

      {/* <FailDialog
        message={failMessage}
        title={failTitle}
        open={openFail}
        onClose={handleCloseFail}
      /> */}

      <Dialog
        classes={{
          root: props.classes.modalRoot,
          paper: props.classes.modalLog,
        }}
        open={openActive}
        // TransitionComponent={props.Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: props.classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={props.classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 -10px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Client status
              </Typography>
              <Typography
                style={{
                  margin: "0px 0 40px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                set to active.
              </Typography>
            </div>
            <DialogActions className={props.classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenActive(false);
                    props.reFetchClientsByEmail();
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: props.classes.modalRoot,
          paper: props.classes.modalLog,
        }}
        open={openInActive}
        // TransitionComponent={props.Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: props.classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={props.classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 -10px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Client status
              </Typography>
              <Typography
                style={{
                  margin: "0px 0 40px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                set to inactive.
              </Typography>
            </div>
            <DialogActions className={props.classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenInActive(false);
                    setOpenStatusNotification(false);
                    props.reFetchClientsByEmail();
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: props.classes.modalRoot,
          paper: props.classes.modalLog,
        }}
        open={openStatusNotification}
        // TransitionComponent={props.Transition}
        keepMounted
        // onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Verify
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: props.classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={props.classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Logging and viewing
              </Typography>
              <Typography
                style={{
                  margin: "0 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                functions disabled for
              </Typography>
              <Typography
                style={{
                  margin: "0 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                client on inactive
              </Typography>
              <Typography
                style={{
                  margin: "0 0 0px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                status.
              </Typography>

              <Typography
                style={{
                  margin: "30px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Verify setting client
              </Typography>
              <Typography
                style={{
                  margin: "0 0 40px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                status to inactive.
              </Typography>
            </div>
            <DialogActions className={props.classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    console.log(_EditClient.values);
                    const {
                      lastName,
                      firstName,
                      company,
                      status,
                    } = _EditClient.values;
                    const { member, id, parentId, ord } = props.currentClient;
                    client
                      .mutate({
                        mutation: UPDATE_CLIENT_MUTATION,
                        variables: {
                          memberId: member.id,
                          clientId: id,
                          lastName: lastName
                            ? lastName.toUpperCase()
                            : lastName,
                          firstName: firstName
                            ? firstName.toUpperCase()
                            : firstName,
                          company: company ? company.toUpperCase() : company,
                          parentId,
                          ord,
                          status: status ? status.toUpperCase() : status,
                          actionDate: new Date().toISOString(),
                        },
                      })
                      .then((response) => {
                        console.log("...handleChangeStatus");
                        setOpenStatusNotification(false);
                        setOpenInActive(true);
                      });
                  }}
                >
                  Verify
                </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    console.log(_EditClient.values);
                    const { status } = _EditClient.values;
                    debugger;
                    if (status === "ACTIVE") {
                      formRef.current.values.status = "INACTIVE";
                      currentClient.status = "INACTIVE";
                      setToggled(false);
                    } else {
                      formRef.current.values.status = "ACTIVE";
                      currentClient.status = "ACTIVE";
                      setToggled(true);
                    }
                    setOpenStatusNotification(false);
                  }}
                >
                  Cancel
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>
    </>
  );
};

export default withStyles(javascriptStyles)(
  withStyles(basicsStyle)(withStyles(modalStyle)(EditClient))
);
