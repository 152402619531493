import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import {
  // withEmailVerification, 
  withAuthentication
} from './utils/Session';
import useWindowSize from 'utils/useWindowSize';

import { withFirebase } from './utils/Firebase';
import logotype from 'assets/img/byn-logotype-oval.png'
import {
  primaryColor,
} from 'assets/jss/material-kit-pro-react.js';

// import customCheckboxRadioSwitchStyle from 'assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js';
import signupPageStyle from 'assets/jss/material-kit-pro-react/views/signupPageStyle.js';
import { Helmet } from "react-helmet";
import { ThemeProvider } from '@material-ui/styles';
import defaultMaterialTheme from "utils/Themes/homePageMaterialTheme"
import { createUserLog } from "utils/CreateUserLog";


const EsqPay = (props) => {
  const size = useWindowSize()
  const [details, setDetails] = useState(null);
  useEffect(() => {


    fetch(
      `https://geolocation-db.com/json/${process.env.GEO_LOCATION_KEY}`
    )
      .then(response => response.json())
      .then(data => {
        setDetails(data)
        createUserLog({ props }, "DASHBOARD", "Dashboard view", `${data.city}, ${data.state}, ${data.country_name} (${data.country_code}) ${data.IPv4}`)
      })
      .catch(error => {
        createUserLog({ props }, "DASHBOARD", "Dashboard view", "")
        console.log("DASHBOARD error", error)
      })

  }, [])

  return (
    <React.Fragment>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Helmet>
          <title>BillYouNow</title>
          <meta name="description" content="BillYouNow is a mobile app that will help lawyers maximize income, tailored to the speciﬁc needs of Philippine legal professionals." />
          <meta name="keywords" content="lawyers,legal,professionals,lawyer,legal fees,attorney fees and charges,billing,bill,you,now" />
        </Helmet>
        <div className={props.classes.container}>

          <div className={classNames(props.classes.main, props.classes.mainRaised)}>
            <div className={props.classes.brand}>
              {/* <img style={{ width: '250px', marginBottom: '50px' }} src={logotype} alt="logo" /> */}
              <img style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                height: `${size.width <= 320 ? 80 :
                  size.width <= 365 ? 90 :
                    size.width <= 375 ? 100 :
                      115
                  }px`,
                width: `${size.width <= 320 ? 240 :
                  size.width <= 365 ? 270 :
                    size.width <= 375 ? 300 :
                      345
                  }px`,
                marginTop: `${size.width <= 320 ? -35 :
                  size.width <= 365 ? -30 :
                    size.width <= 375 ? 20 :
                      40
                  }px`,
                marginBottom: '50px'
              }}
                src={logotype} alt="logo" />
              <div className={props.classes.container}>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <Button variant="text" style={{ width: "100%", color: primaryColor[1], textAlign: "center" }}
                    // href="/app/logservices"
                    onClick={(e) => {
                      e.preventDefault()
                      createUserLog(props, "DASHBOARD", "clicked LOG WORK", details ? `${details.city}, ${details.state}, ${details.country_name}(${details.country_code}) ${details.IPv4}` : "")
                      props.history.push('/app/logservices')
                    }}
                  >
                    Log Work
                  </Button>
                  <Button variant="text" style={{ width: "100%", color: primaryColor[1], textAlign: "center" }}
                    // href="/app/logexpenses"
                    onClick={(e) => {
                      e.preventDefault()
                      createUserLog(props, "DASHBOARD", "clicked LOG EXPENSE", details ? `${details.city}, ${details.state}, ${details.country_name}(${details.country_code}) ${details.IPv4}` : "")
                      props.history.push('/app/logexpenses')
                    }}
                  >
                    Log Expense
                  </Button>
                  <Button variant="text" style={{ width: "100%", color: primaryColor[1], textAlign: "center" }}
                    // href="/app/logs"
                    onClick={(e) => {
                      e.preventDefault()
                      createUserLog(props, "DASHBOARD", "clicked VIEW LOG", details ? `${details.city}, ${details.state}, ${details.country_name}(${details.country_code}) ${details.IPv4}` : "")
                      props.history.push('/app/logs')
                    }}
                  >
                    View Log
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment >
  );
}

export default withStyles(signupPageStyle)(
  withFirebase(withAuthentication(EsqPay))
);
