var React = require('react'),
	createClass = require('create-react-class'),
	DaysView = require('./DaysView'),
	TimeView = require('./TimeView'),
	MonthsView = require('./MonthsView'),
	YearsView = require('./YearsView')

	;

var CalendarContainer = createClass({
	viewComponents: {
		days: DaysView,
		time: TimeView,
		months: MonthsView,
		years: YearsView

	},

	render: function () {
		return React.createElement(this.viewComponents[this.props.view], this.props.viewProps);
	}
});

module.exports = CalendarContainer;
