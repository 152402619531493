import React from 'react';
import Typography from "@material-ui/core/Typography";
import Spinner from 'components/Spinner/Spinner';

const Waiting = props => {

  if (!props.isOpenWaiting)
    return null;

  return (
    <div
      open={props.isOpenWaiting}
    >
      <Spinner />
      <Typography style={{ margin: '-60px 0 0 0', textAlign: 'center' }}>
        Please wait...
                </Typography>
    </div>
  )
}

export default Waiting;