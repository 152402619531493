import { gql } from "apollo-boost";

export const UPDATE_MEMBER_USERNAME = gql`
  mutation UpdateMemberx(
    $memberId: ID!
    $userName: String!
  ) {
    updateMemberx(
      memberId: $memberId
      userName: $userName
    ) {
      id
      userName
    }
  }
`;

export const UPDATE_MEMBER_EMAIL = gql`
  mutation UpdateMemberx(
    $memberId: ID!
    $email: String!
  ) {
    updateMemberx(
      memberId: $memberId
      email: $email
    ) {
      id
      email
    }
  }
`;

export const UPDATE_MEMBER_PHONENUMBER = gql`
  mutation UpdateMemberx(
    $memberId: ID!
    $phoneNo: String!
  ) {
    updateMemberx(
      memberId: $memberId
      phoneNo: $phoneNo
    ) {
      id
      phoneNo
    }
  }
`;


export const UPDATE_MEMBER_MUTATION = gql`
  mutation UpdateMemberx(
    $memberId: ID!
    $email: String
    $userName: String
    $displayName: String
    $firstName: String
    $middleName: String
    $lastName: String
    $phoneNo: String
    $isLoggedIn: Boolean
    $logInDate: DateTime
  ) {
    updateMemberx(
      memberId: $memberId
      email: $email
      userName: $userName
      displayName: $displayName
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      phoneNo: $phoneNo
      isLoggedIn: $isLoggedIn
      logInDate: $logInDate
    ) {
      email
    }
  }
`;

export const UPDATE_MEMBER_MUTATION_SIGNUP = gql`
  mutation UpdateMemberx(
    $memberId: ID!
    $hasRespondedToInvite: Boolean
    $hasConfirmedAgreement: Boolean
    $hasInitiallyLoggedIn: Boolean
  ) {
    updateMemberx(
      memberId: $memberId
      hasRespondedToInvite: $hasRespondedToInvite
      hasConfirmedAgreement: $hasConfirmedAgreement
      hasInitiallyLoggedIn: $hasInitiallyLoggedIn
    ) {
      id
    }
  }
`;

export const REGISTRATION_COMPLETE_MUTATION = gql`
  mutation REGISTRATION_COMPLETE_MUTATION(
    $email: String!
    $displayName: String
    $phoneNo: String
  ) {
    registrationComplete(
      email: $email
      displayName: $displayName
      phoneNo: $phoneNo
    ) {
      message
    }
  }
`;

export const CREATE_CLIENT_MUTATION = gql`
  mutation CreateClient(
    $memberId: ID!
    $lastName: String
    $firstName: String
    $company: String
    $hourlyRate: Float
    $parentId: ID
    $status: String
    $ord: Int
  ) {
    createClient(
      memberId: $memberId
      lastName: $lastName
      firstName: $firstName
      company: $company
      hourlyRate: $hourlyRate
      parentId: $parentId
      status: $status
      ord: $ord
    ) {
      id
      lastName
      firstName
      company
      hourlyRate
      parentId
      status
      ord
    }
  }
`;

export const CREATE_CASE_MUTATION = gql`
  mutation CreateCase(
    $memberId: ID!
    $caseCode: String
    $caseTitle: String
    $billingType: String
    $fixedRate: Float
    $clientId: ID!
    $parentId: ID
    $status: String
    $ord: Int
  ) {
    createCase(
      memberId: $memberId
      caseCode: $caseCode
      caseTitle: $caseTitle
      billingType: $billingType
      fixedRate: $fixedRate
      clientId: $clientId
      parentId: $parentId
      status: $status
      ord: $ord
    ) {
      id
      caseCode
      caseTitle
      billingType
      fixedRate
      parentId
      status
      ord
    }
  }
`;

export const CREATE_MEMBER_MUTATION = gql`
  mutation CreateMember($email: String!, $wasInvitedBy: ID) {
    createMember(email: $email, wasInvitedBy: $wasInvitedBy) {
      id
    }
  }
`;


export const CREATE_MEMBER_MUTATION2 = gql`
  mutation CreateMember2($email: String!, $userName: String) {
    createMember2(email: $email, userName: $userName) {
      id
    }
  }
`;

export const INVITE_MEMBER_MUTATION = gql`
  mutation INVITE_MEMBER_MUTATION($email: String!, $displayName: String) {
    inviteMember(email: $email, displayName: $displayName) {
      message
    }
  }
`;

export const CREATE_MEMBER_MUTATION1 = gql`
  mutation CreateMemberx(
    $email: String!
    $displayName: String
    $photoURL: String
    $hasRespondedToInvite: Boolean
    $hasConfirmedAgreement: Boolean
    $hasInitiallyLoggedIn: Boolean
  ) {
    createMemberx(
      email: $email
      displayName: $displayName
      photoURL: $photoURL
      hasRespondedToInvite: $hasRespondedToInvite
      hasConfirmedAgreement: $hasConfirmedAgreement
      hasInitiallyLoggedIn: $hasInitiallyLoggedIn
    ) {
      id
    }
  }
`;
export const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClient(
    $memberId: ID!
    $clientId: ID!
    $lastName: String
    $firstName: String
    $company: String
    $hourlyRate: Float
    $actionDate: DateTime
    $action: String
    $parentId: ID
    $status: String
    $ord: Int
  ) {
    updateClient(
      memberId: $memberId
      clientId: $clientId
      lastName: $lastName
      firstName: $firstName
      company: $company
      hourlyRate: $hourlyRate
      actionDate: $actionDate
      action: $action
      parentId: $parentId
      status: $status
      ord: $ord
    ) {
      id
      firstName
      lastName
      company
      parentId
      status
      ord
      actionDate
    }
  }
`;

export const UPDATE_CASE_MUTATION = gql`
  mutation updateCase(
    $memberId: ID!
    $clientId: ID!
    $caseId: ID!
    $caseCode: String
    $caseTitle: String
    $parentId: ID
    $status: String
    $ord: Int
    $actionDate: DateTime
  ) {
    updateCase(
      memberId: $memberId
      clientId: $clientId
      caseId: $caseId
      caseCode: $caseCode
      caseTitle: $caseTitle
      parentId: $parentId
      status: $status
      ord: $ord
      actionDate: $actionDate
    ) {
      id
      caseCode
      caseTitle
      parentId
      status
      ord
      actionDate
    }
  }
`;

export const DELETE_CLIENT_MUTATION = gql`
  mutation DeleteClient($clientId: ID!) {
    deleteClient(clientId: $clientId) {
      id
      firstName
      lastName
      company
      parentId
      status
      ord
      actionDate
    }
  }
`;
export const DELETE_CASE_MUTATION = gql`
  mutation DeleteCase($caseId: ID!) {
    deleteCase(caseId: $caseId) {
      id
      caseCode
      caseTitle
      parentId
      status
      ord
      actionDate
    }
  }
`;

export const CREATE_DOWNLOAD_REQUEST = gql`
  mutation CreateDownloadRequest(
    $memberId: ID!
    $date: DateTime!
    $notes: String
  ) {
    createDownloadRequest(memberId: $memberId, date: $date, notes: $notes) {
      id
      member {
        id
        email
        serviceRequest {
          id
          date
          dateServed
          dateCancelled
          notes
          status
        }
      }
      date
      dateServed
      dateCancelled
      requestType
      notes
      status
    }
  }
`;

export const CREATE_DELETE_ACCOUNT_REQUEST = gql`
  mutation CreateDeleteRequest(
    $memberId: ID!
    $date: DateTime!
    $notes: String
    $dateDeletion: DateTime
  ) {
    createDeleteRequest(memberId: $memberId, date: $date, notes: $notes, dateDeletion: $dateDeletion) {
      id
      member {
        id
        email
        serviceRequest {
          id
          date
          dateServed
          dateCancelled
          dateDeletion
          notes
          status
        }
      }
      date
      dateServed
      dateCancelled
      dateDeletion
      requestType
      notes
      status
    }
  }
`;

export const CANCEL_SERVICE_REQUEST = gql`
  mutation CancelServiceRequest(
    $serviceId: ID!
    $dateCancelled: DateTime!
  ) # $memberId: ID!
  {
    cancelServiceRequest(
      serviceId: $serviceId
      dateCancelled: $dateCancelled
    ) # memberId: $memberId
    {
      id
      date
      dateServed
      dateCancelled
      requestType
      notes
      status
    }
  }
`;

export const LOG_OUT = gql`
  mutation logOut($email: String!) {
    logOut(email: $email) {
      id
    }
  }
`;

export const CREATE_TICKET_MUTATION = gql`
  mutation AddTicket(
    $ticketNumber: String!
    $name: String
    $message: String
    $email: String
    $dateCreation: DateTime
  ){
    addTicket(
      ticketNumber: $ticketNumber
      name: $name
      message: $message
      email: $email
      dateCreation: $dateCreation
    ){
      id
      ticketNumber
      name
      message
      email
      dateCreation
    }
  }
`

export const CREATE_LOG_MUTATION = gql`
  mutation addLog(
    $email: String
    $date: DateTime
    $screen: String
    $log: String
    $remarks: String

  ){
    addLog(
      email: $email
      date: $date
      screen: $screen
      log: $log
      remarks: $remarks
    ){
      id
      email
    }
  }
`
export default UPDATE_MEMBER_MUTATION;
