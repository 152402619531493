import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import useWindowSize from 'utils/useWindowSize';

import { Formik, Form } from "formik";

import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import circlelogo from "assets/img/byn-logo-circle.png";

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { withAuthentication } from "utils/Session";
import { withFirebase } from "utils/Firebase";

import { gql } from "apollo-boost";

import { ThemeProvider } from "@material-ui/styles";
// import red from "utils/colors/red";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";

import { grayColor } from "assets/jss/material-kit-pro-react.js";
import { createUserLog } from "utils/CreateUserLog";

function SignOutFirebase(props) {



  const size = useWindowSize()
  const { classes } = props;
  debugger;
  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={true}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Sign Out
              </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/auth/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>

          <div id="mainScreen" style={{ marginTop: "-20px" }}>
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 30 : 370}px`,
              }}
            >
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting }) => {
                }}
              >
                {({

                }) => (
                  <Form mode="themed">
                    <div
                      className={classes.container}
                      style={{
                        overflow: "auto",
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        height: `${size.height - 145}px`,
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gridTemplateRows: "auto",
                          margin: "0px 0 30px 5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "24px",
                            color: grayColor[1],
                            textAlign: "center",
                            marginTop: '80px'
                          }}
                        >
                          Are you sure you want to sign out?
                        </Typography>
                        <div />
                      </div>
                    </div>

                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{ width: "60%" }}
                          round
                          color="primary"
                          size="md"
                          variant="contained"
                          onClick={() => {
                            const { firebase } = props;

                            firebase.doSignOut();

                            const { client, authUser } = props;
                            console.log("start LOG_OUT");
                            console.log("authUser");
                            console.log(authUser);
                            client
                              .mutate({
                                mutation: LOG_OUT,
                                variables: {
                                  email: authUser.email,
                                },
                              })
                              .then(() => {
                                createUserLog({ authUser, client }, "SIGN-OUT", "signed-out " + authUser.email, authUser.email + " has signed-out")
                                props.history.push("/auth/dashboard");
                              });
                          }}
                        >
                          Yes
                      </Button>

                        <Button
                          style={{ width: "40%" }}
                          variant="outlined"
                          color="primary"
                          size="md"
                          onClick={e => {
                            e.preventDefault()
                            const { client, authUser } = props;
                            createUserLog({ authUser, client }, "SIGN-OUT", "Cancelled signed-out, back to the dashboard", "")
                            props.history.push("/auth/dashboard")
                          }}
                        >
                          No
                          </Button>
                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>

    </>
  );
}

export const LOG_OUT = gql`
  mutation logOut($email: String!) {
    logOut(email: $email) {
      id
    }
  }
`;

export default
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(SignOutFirebase)))
    )
  );
