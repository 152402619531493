import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import TextFieldComponent from "utils/TextFieldComponent";
import useWindowSize from 'utils/useWindowSize';

import Waiting from "utils/Waiting";
import { Formik, Form } from "formik";
// import { useMediaQuery } from "react-responsive";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import MuiAlert from '@material-ui/lab/Alert';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Datetime from "utils/react-datetime/DateTime";
import Typography from "@material-ui/core/Typography";

import CreateCase from "./CreateCase.js";
import CreateClient from "./CreateClient";

import { gql } from "apollo-boost";
import circlelogo from "assets/img/byn-logo-circle.png";

import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import "utils/react-datetime/css/react-datetime.css";
import DateTimeLog from "./DateTimeLog";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";

import { withFirebase } from "utils/Firebase";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { createMuiTheme, Snackbar } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import { removeComma } from "utils/RemoveComma";
import { formatMoney } from "utils/FormatMoney";
import { formatNumber_ } from "utils/FormatNumber_";
import { checkMoney } from "utils/CheckMoney";
import { createUserLog } from "utils/CreateUserLog";

import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import errorMaterialTheme from "utils/Themes/errorMaterialTheme";
import viewLogMaterialTheme from "utils/Themes/viewLogMaterialTheme";
import dateMaterialTheme from "utils/Themes/dateMaterialTheme";

import Transition from "utils/Transition";

import LogCountMessage from 'utils/LogCountMessage';
import { AlertTitle } from "@material-ui/lab";

function Alert(props) {
  // return <MuiAlert elevation={6} variant="filled" {...props} />;
  return <MuiAlert elevation={6}  {...props} />;
}

const style = {
  step2ActivityL1480: {
    marginLeft: "0px",
    marginTop: "20px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step2Activity480: {
    marginLeft: "0px",
    marginTop: "-1px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step2Category: {
    margin: "20px 0 20px 0",
  },
  step2Category480: {
    margin: "20px 0 20px 0",
  },
  step3: {
    marginLeft: "0px",
    marginTop: "10px",
    marginBottom: "-25px",
    display: "grid",
    gridTemplateColumns: "1fr 10px",
    gridTemplateRows: "auto auto",
  },
  step3RadioBillingType480: {
    marginLeft: "-30px",
    marginTop: "10px",
    marginBottom: "10px",
    display: "grid",
    gridTemplateColumns: "100px 10px",
    gridTemplateRows: "auto auto",
  },
  step3RadioFixed480: {
    marginLeft: "0px",
    marginTop: "-10px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: " 1fr 10px",
    gridTemplateRows: "auto auto",
  },
  step3RadioHourly480: {
    marginLeft: "0px",
    marginTop: "-20px",
    marginBottom: "20px",
    display: "grid",
    gridTemplateColumns: " 1fr 10px",
    gridTemplateRows: "auto auto",
  },
  step3RadioError480: {
    marginLeft: "0px",
    marginTop: "-30px",
    marginBottom: "20px",
    display: "grid",
    gridTemplateColumns: " 1fr 10px",
    gridTemplateRows: "auto auto",
  },
  step3Date480: {
    marginLeft: "0px",
    marginTop: "5px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3DateError: {
    marginTop: "-5px",
    marginLeft: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    color: "red",
    gridTemplateRows: "auto auto",
  },
  step3TimeRenderInput: {
    marginLeft: "0px",
    marginTop: "5px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3TimeElapsed480: {
    marginLeft: "0px",
    marginBottom: "0px",
    marginTop: "10px",
    display: "grid",
    gridTemplateColumns: "180px",
    gridTemplateRows: "auto auto",
  },
  step3TimeHeader480: {
    marginLeft: "0px",
    marginBottom: "-10px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3TimeRenderInput480: {
    marginTop: "5px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Time480: {
    marginLeft: "0px",
    marginBottom: "10px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3TimeError480: {
    marginLeft: "0px",
    marginTop: "0px",
    marginBottom: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Notes480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Client480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Case480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3billing480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3billingType: {
    marginLeft: "0px",
    marginTop: "25px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
};

const getValidDate = (date) => {
  let validDate = date;
  if (typeof date === "object") {
    if (Object.prototype.toString.call(date) !== "[object Date]") {
      validDate = date.toDate();
    }
  }
  return validDate;
};

const constraintDate = (date) => {
  let _date = getValidDate(date);
  let mins = _date.getMinutes();
  // console.log(mins);
  if (0 <= mins && mins <= 14) mins = 0;
  else if (15 <= mins && mins <= 29) mins = 15;
  else if (30 <= mins && mins <= 44) mins = 30;
  else mins = 45;
  // console.log(mins);
  let newDate = new Date(_date);
  newDate.setMinutes(mins, 0, 0);
  // console.log(newDate);
  return newDate;
};

const initialValues = {
  date: constraintDate(new Date()),
  client: "",
  case_: "",
  currentClient: "",
  freshCase_: null,

  activityL1: "",
  activityL2: "",
  activityL3: "",
  // activity1Options: [],
  activity2Options: [],
  activity3Options: [],
  L2: [""],
  L3: [""],
  hours: "0.00",
  minutes: "0",
  amount: "0.00",
  billingType: "",
  fixedRate: "",
  hourlyRate: "",
  notes: "",
  startTime: "",
  endTime: "",
  elapsed: "0",
  clientArray: [],
  caseArray: [],
  justInitialized: true,
  durationHour: "",
  durationMinutes: "",
};


export const activitiesSource = [
  // {
  //   name: '',
  //   L2: ['']
  // },

  {
    name: "ATTEND >",
    prompt: "Attended what?",
    L2: ["Attended what?", "RESEARCH", "HEARING >", "MEETING", "OTHERS"],
  },
  {
    name: "CALL >",
    prompt: "What kind?",
    L2: ["What kind?", "TEXT", "VOICE", "OTHERS"],
  },
  {
    name: "READ >",
    prompt: "Read what?",
    L2: [
      "Read what?",
      "CASE FILE",
      "DECISION",
      "EVIDENCE",
      "JURISPRUDENCE",
      "LAW",
      "ORDER",
      "PLEADING",
      "RULES OF COURT",
      "OTHERS",
    ],
  },
  {
    name: "WRITE >",
    prompt: "Wrote what?",
    L2: ["Wrote what?", "CONTRACTS", "EMAIL", "LETTER", "PLEADING >", "OTHERS"],
  },
  {
    name: "OTHERS",
    L2: [""],
  },
];

export const L2Source = [
  {
    name: "",
    L2: "",
    L3: [""],
  },
  {
    name: "ATTEND >",
    L2: "Attended what?",
    L3: [""],
  },
  {
    name: "ATTEND >",
    L2: "HEARING >",
    prompt: "What kind?",
    L3: [
      "What kind?",
      "ARRAIGNMENT",
      "INJUNCTION",
      "JUDICIAL DISPUTE RESOLUTION",
      "MEDIATION",
      "MOTION",
      "PRE-TRIAL",
      "TRIAL",
      "OTHERS",
    ],
  },

  {
    name: "ATTEND >",
    L2: "RESEARCH",
    L3: [""],
  },
  {
    name: "ATTEND >",
    L2: "MEETING",
    L3: [""],
  },

  {
    name: "ATTEND >",
    L2: "OTHERS",
    L3: [""],
  },
  {
    name: "CALL >",
    L2: "What kind?",
    L3: [""],
  },
  {
    name: "CALL >",
    L2: "VOICE",
    L3: [""],
  },
  {
    name: "CALL >",
    L2: "TEXT",
    L3: [""],
  },
  {
    name: "CALL >",
    L2: "OTHERS",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "Read what?",
    L3: [""],
  },
  {
    name: "READ",
    L2: "CASE FILE",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "DECISION",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "EVIDENCE",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "JURISPRUDENCE",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "LAW",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "ORDER",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "PLEADING",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "RULES OF COURT",
    L3: [""],
  },
  {
    name: "READ >",
    L2: "OTHERS",
    L3: [""],
  },
  {
    name: "WRITE >",
    L2: "Wrote what?",
    L3: [""],
  },
  {
    name: "WRITE >",
    L2: "CONTRACTS",
    L3: [""],
  },
  {
    name: "WRITE >",
    L2: "EMAIL",
    L3: [""],
  },
  {
    name: "WRITE >",
    L2: "LETTER",
    L3: [""],
  },
  {
    name: "WRITE >",
    prompt: "What kind?",
    L2: "PLEADING >",
    L3: [
      "What kind?",
      "ANSWER",
      "BRIEF",
      "COMMENT",
      "COMPLAINT",
      "JUDICIAL AFFIDAVIT",
      "MANIFESTATION",
      "MEMORANDUM",
      "MOTION",
      "OPPOSITION",
      "PETITION",
      "POSITION PAPER",
      "PRE-TRIAL BRIEF",
      "REJOINDER",
      "REPLY",
      "OTHERS",
    ],
  },
  {
    name: "WRITE >",
    L2: "OTHERS",
    L3: [""],
  },
  {
    name: "OTHERS",
    L2: "",
    L3: [""],
  },
];

const LogService = (props) => {
  const { classes } = props;

  const [billingType, setBillingType] = React.useState("");
  const [activityL1, setActivityL1] = React.useState("");
  const handleActivityL1 = (event) => {
    setActivityL1(event.target.value);
  };

  const [activityL2, setActivityL2] = React.useState("");
  const handleActivityL2 = (event) => {
    setActivityL2(event.target.value);
  };

  const [activityL3, setActivityL3] = React.useState("");
  const handleActivityL3 = (event) => {
    setActivityL3(event.target.value);
  };

  const handleClient = (event) => {
    setClient(event.target.value);
  };

  const handleCase = (event) => {
    setCase_(event.target.value);
  };
  const formRef = useRef();
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block";
      document.getElementById("mainScreen").style.opacity = 0.3;
    } else {
      if (
        document &&
        document.getElementById("waiting") &&
        document.getElementById("mainScreen")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreen").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);

  const size = useWindowSize()
  const [openMessageDialog, setOpenMessageDialog] = useState(false)

  const scroll = "body";
  const [isLogging, setIsLogging] = useState(true);
  const [openExpenseLog, setOpenExpenseLog] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [noClientCase, setNoClientCase] = useState(false);
  const [openNoClient, setOpenNoClient] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [openCase, setOpenCase] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [case_, setCase_] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [id, setId] = useState("");
  const [client, setClient] = useState("");
  const [date, setDate] = useState(null);
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const [activity1Options, setActivity1Options] = useState([]);
  const [clientArray, setClientArray] = useState([]);
  const [caseArray, setCaseArray] = useState([]);
  const [member_, setMember_] = useState({});
  const [openWorkDate, setOpenWorkDate] = useState(false);
  const [startExceeds, setStartExceeds] = useState({
    date: "",
    dateString: "",
    lesserOrGreater: "",
  });
  const [endExceeds, setEndExceeds] = useState({
    date: "",
    dateString: "",
    lesserOrGreater: "",
  });
  const [selectedTime, setSelectedTime] = useState("");
  const [durationHour, setDurationHour] = useState("");
  const [durationMinutes, setDurationMinutes] = useState("");
  const [hasDeleteRequest, setHasDeleteRequest] = useState(false);
  const [openAlert, setOpenAlert] = useState(false)

 

  const [details, setDetails] = useState(null);
  useEffect(() => {
    fetch(
      `https://geolocation-db.com/json/${process.env.GEO_LOCATION_KEY}`
    )
      .then(response => response.json())
      .then(data => {
        setDetails(data)
        createUserLog({ props }, "LOG_WORK", "Log Work Init Screen", `${data.city}, ${data.state}, ${data.country_name} (${data.country_code}) ${data.IPv4}`)
      })
      .catch(error => {
        createUserLog({ props }, "LOG_WORK", "Log Work Init Screen", "")
        console.log('LOG_WORK error', error)
      })

    reload_()
  }, [])

  const [reload, setReload] = useState(true)
  useEffect(() => {
    reload_()
  }, [reload])

  const reload_ = () => {
    createUserLog(props, "LOG_WORK", "Open a new Work log", "")
    setIsOpenWaiting(true);

    setActivityL3("")
    setActivityL2("")
    setActivityL1("")
    setDurationHour("")
    setDurationMinutes("")
    if (typeof formRef.current !== "undefined") {
      formRef.current.values.activityL3 = ""
      formRef.current.values.activityL2 = ""
      formRef.current.values.activityL1 = ""
      formRef.current.values.startTime = "";
      formRef.current.values.endTime = "";
      formRef.current.values.hours = "0.00"
      formRef.current.values.minutes = "0"
      formRef.current.values.fixedRate = ""
      formRef.current.values.amount = "0.00"
      formRef.current.values.notes = ""
      // formRef.current.values.billType = ""
      // setBillingType("")
      // formRef.current.values.hourlyRate = ""
      formRef.current.values.durationHour = ""
      formRef.current.values.durationMinutes = ""
    }
    else {
      initialValues.activityL3 = ""
      initialValues.activityL2 = ""
      initialValues.activityL1 = ""
      initialValues.startTime = "";
      initialValues.endTime = "";
      initialValues.hours = "0.00"
      initialValues.minutes = "0"
      initialValues.fixedRate = ""
      initialValues.amount = "0.00"
      initialValues.notes = ""
      // initialValues.billType = ""
      // setBillingType("")
      // initialValues.hourlyRate = ""
      initialValues.durationHour = ""
      initialValues.durationMinutes = ""
    }

    let activity1Options = [];
    activitiesSource.map((item) => {
      console.log(item);
      activity1Options.push({
        value: item.name,
        label: item.name,
      });
    });
    setActivity1Options(activity1Options)
    if (typeof formRef.current !== "undefined") {
      formRef.current.values.activity2Options = [];
      formRef.current.values.activity3Options = [];
    }
    const { client, authUser } = props;
    // debugger;;
    const { email } = authUser;
    // console.log(email);
    client
      .query({
        query: MEMBER_CHECK,
        variables: {
          email,
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        debugger;
        const { memberCheck } = response.data;
        console.log(memberCheck);
        setMember_(memberCheck);
        if (
          typeof memberCheck.latestClient !== "undefined" &&
          memberCheck.latestClient !== null
        ) {
          initialValues.client = memberCheck.latestClient.id;
          initialValues.currentClient = memberCheck.latestClient;
          setClient(memberCheck.latestClient.id);
          setCurrentClient(memberCheck.latestClient);
        }
        if (
          typeof memberCheck.latestCase !== "undefined" &&
          memberCheck.latestCase !== null
        ) {
          initialValues.case_ = memberCheck.latestCase.id;
          setCase_(memberCheck.latestCase.id);
        }
        if (
          typeof memberCheck.serviceRequest !== "undefined" &&
          memberCheck.serviceRequest !== null
        ) {
          const { serviceRequest } = memberCheck;
          const deleteServiceRequest = serviceRequest.filter((sr, index) => {
            console.log(sr);
            if (sr.status === "NEW" && sr.requestType === "DELETE") {
              return sr;
            }
          });
          console.log(deleteServiceRequest);
          if (deleteServiceRequest.length > 0) {
            console.log("has delete request");
            setHasDeleteRequest(true);
          }
        }
        console.log("out getMemberCheck");

        client
          .query({
            query: CLIENTS_BY_EMAIL_QUERY,
            variables: {
              email,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            const { clientsByEmail } = response.data;
            console.log(clientsByEmail);
            setClientsByEmail(clientsByEmail);

            setIsOpenWaiting(false);
            document.getElementById("waiting").style.display = "none";
            document.getElementById("mainScreen").style.opacity = 1;
            debugger
            if (1 <= memberCheck.openLogWorkCounter && memberCheck.openLogWorkCounter <= 2)
              setOpenMessageDialog(true)
          });
      });
    debugger;

    let d = new Date();
    let min = d.getMinutes();
    if (0 <= min && min <= 14) min = 0;
    else if (15 <= min && min <= 29) min = 15;
    else if (30 <= min && min <= 44) min = 30;
    else min = 45;

    let date_ = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      min,
      0,
      0
    );
  }

  const [casesByClient, setCasesByClient] = useState([]);
  const [currentClient, setCurrentClient] = useState([]);

  const [clientsByEmail, setClientsByEmail] = useState([]);
  // const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    console.log("in start useEffect [clientsByEmail]");
    let clientArray = [];
    let caseArray = [];
    debugger;
    console.log(clientsByEmail);
    if (typeof clientsByEmail !== "undefined" && clientsByEmail.length > 0) {
      let optionItems = clientsByEmail
        .filter((a) => a.status === "ACTIVE")
        .sort(function (a, b) {
          let namea = a.lastName + a.firstName + a.company;
          let nameb = b.lastName + b.firstName + b.company;
          return namea.localeCompare(nameb);
        })
        .map((item) => {
          if (item.status === "ACTIVE") {
            clientArray.push({
              value: item.id,
              label:
                item.lastName && item.firstName && item.company
                  ? item.lastName +
                  ", " +
                  item.firstName +
                  " (" +
                  item.company +
                  ")"
                  : item.lastName && item.firstName && !item.company
                    ? item.lastName + ", " + item.firstName
                    : item.lastName && !item.firstName && item.company
                      ? item.lastName + " (" + item.company + ")"
                      : item.lastName && !item.firstName && !item.company
                        ? item.lastName
                        : !item.lastName && item.firstName && item.company
                          ? item.firstName + " (" + item.company + ")"
                          : !item.lastName && !item.firstName && item.company
                            ? "(" + item.company + ")"
                            : !item.lastName && item.firstName && !item.company
                              ? item.firstName
                              : null,
            });
          }
        });
      clientArray.push({
        value: "NEWCLIENT",
        label: "ADD NEW CLIENT >",
      });
      debugger;
      console.log("client");
      console.log(client);
      let _currentClient = clientsByEmail.filter(({ id }) => id === client)[0];
      // values.currentClient = currentClient;
      console.log("_currentClient");
      console.log(_currentClient);
      debugger;

      // values.case_ = '';
      let _case_ = "";
      let _casesByClient = [];
      if (typeof _currentClient !== "undefined") {
        const { hourlyRateService } = _currentClient;
        _casesByClient = _currentClient.cases;
        if (
          typeof _currentClient.cases !== "undefined" &&
          _currentClient.cases.length > 0
        ) {
          debugger;
          initialValues.case_ = case_; // from state, possibly from changeCase
          initialValues.case_ =
            initialValues.case_ === ""
              ? _currentClient.cases[0].id
              : initialValues.case_;
          _case_ = initialValues.case_;

          if (initialValues.case_ === "") {
            console.log('if (initialValues.case_ === "") ');
            console.log(initialValues.case_);
            initialValues.case_ = _currentClient.cases[0].id;
            initialValues.billingType =
              _currentClient.cases[0].billingTypeService ? _currentClient.cases[0].billingTypeService : "";
            setBillingType(initialValues.billingType);
            formRef.current.values.billingType = initialValues.billingType
            // initialValues.fixedRate = formatMoney(formatNumber_(_currentClient.cases[0].fixedRateService))
            initialValues.fixedRate = "";
            initialValues.hourlyRate = formatMoney(
              formatNumber_(hourlyRateService)
            );
            initialValues.hourlyRate = initialValues.hourlyRate === "0.00" ? "" : initialValues.hourlyRate
          } else {
            console.log("if (initialValues.case_ has value)");
            console.log(initialValues.case_);
            _casesByClient
              .filter((c) => c.id === initialValues.case_)
              .map(({ billingTypeService, fixedRateService }) => {
                console.log(billingTypeService);
                console.log(hourlyRateService);
                console.log(fixedRateService);
                initialValues.billingType = billingTypeService ? billingTypeService : "";
                setBillingType(initialValues.billingType);
                formRef.current.values.billingType = initialValues.billingType;
                // initialValues.fixedRate = formatMoney(formatNumber_(fixedRateService))
                initialValues.fixedRate = "";
                initialValues.hourlyRate = formatMoney(
                  formatNumber_(hourlyRateService)
                );
                initialValues.hourlyRate = initialValues.hourlyRate === "0.00" ? "" : initialValues.hourlyRate
              });
          }
        } else {
          initialValues.billingType = "";
          setBillingType("");
          formRef.current.values.billingType = "";
          initialValues.fixedRate = "";
          initialValues.hourlyRate = "";
        }
      } else {
        console.log("_currentClient === 'undefined'");
      }
      debugger;

      setClient(initialValues.client);
      setCase_(_case_);
      setCurrentClient(_currentClient);
      setCasesByClient(_casesByClient);

      if (typeof _currentClient !== "undefined") {
        _casesByClient = _currentClient.cases;

        if (
          initialValues.freshCase_ === undefined ||
          initialValues.freshCase_ === null
        ) {
          let caseItems = _casesByClient
            .filter((c) => c.status === "ACTIVE")
            .sort(function (a, b) {
              let namea = a.caseCode + a.caseTitle;
              let nameb = b.caseCode + b.caseTitle;
              return namea.localeCompare(nameb);
            })
            .map(({ id, caseCode, caseTitle, status }) => {
              if (status === "ACTIVE") {
                caseArray.push({
                  value: id,
                  label:
                    caseCode && caseTitle
                      ? caseCode + " - " + caseTitle
                      : caseCode && !caseTitle
                        ? caseCode
                        : !caseCode && caseTitle
                          ? caseTitle
                          : "",
                });
              }
            });
        } else {
          debugger;
          let tcase = _casesByClient
            .filter(
              (c) =>
                c.id === initialValues.freshCase_.toUpperCase() &&
                c.status === "ACTIVE"
            )
            .sort(function (a, b) {
              let namea = a.caseCode + a.caseTitle;
              let nameb = b.caseCode + b.caseTitle;
              return namea.localeCompare(nameb);
            })
            .map(({ id, caseCode, caseTitle, status }) => {
              if (status === "ACTIVE") {
                formRef.current.values.caseArray.push({
                  value: id,
                  label:
                    caseCode && caseTitle
                      ? caseCode + " - " + caseTitle
                      : caseCode && !caseTitle
                        ? caseCode
                        : !caseCode && caseTitle
                          ? caseTitle
                          : "",
                });
              }
            });

          _casesByClient
            .filter(
              (cs) =>
                cs.id !== initialValues.freshCase_.toUpperCase() &&
                cs.status === "ACTIVE"
            )
            .sort(function (a, b) {
              let namea = a.caseCode + a.caseTitle;
              let nameb = b.caseCode + b.caseTitle;
              return namea.localeCompare(nameb);
            })
            .map(({ id, caseCode, caseTitle, status }) => {
              if (status === "ACTIVE") {
                caseArray.push({
                  value: id,
                  label:
                    caseCode && caseTitle
                      ? caseCode + " - " + caseTitle
                      : caseCode && !caseTitle
                        ? caseCode
                        : !caseCode && caseTitle
                          ? caseTitle
                          : "",
                });
              }
            });
        }
        caseArray.push({
          value: "NEWCASE",
          label: "ADD NEW CASE >",
        });
      }
    } else {
      clientArray.push({
        value: "NEWCLIENT",
        label: "ADD NEW CLIENT >",
      });
      caseArray.push({
        value: "NEWCASE",
        label: "ADD NEW CASE >",
      });
    }
    setClientArray(clientArray);
    setCaseArray(caseArray);

    debugger;

    console.log("in end useEffect [clientsByEmail]");
  }, [clientsByEmail]);

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false)
  }

  function handleCloseExpenseLog() {
    setOpenExpenseLog(false);
  }

  const handleCloseClient = () => {
    createUserLog(props, "LOG_WORK - CREATE CLIENT", "Cancelled Create Client", "clicked CANCEL")
    setOpenClient(false);
  };

  const handleCloseNoClientCase = () => {
    setNoClientCase(false);
  };

  const handleCloseNoClient = () => {
    setOpenNoClient(false);
  };

  const handleCloseCase = () => {
    createUserLog(props, "LOG_WORK - CREATE CASE", "Cancelled Create Case", "clicked CANCEL")
    setOpenCase(false);
  };

  const handleCloseNotification = () => {
    debugger;
    setOpenNotification(false);
  };

  const handleChangeClientSelect = (values, value) => {
    createUserLog(props, "LOG_WORK", "Changed Client", value === "NEWCLIENT" ? "Add New Client" : "")
    console.log("start handleChangeClientSelect");
    debugger;
    values.client = value;
    initialValues.freshCase_ = null;

    let scroll = "body";
    if (value === "NEWCLIENT") {
      values.activityL1 = "";
      values.activityL2 = "";
      values.activityL3 = "";
      setActivityL1("")
      setActivityL2("")
      setActivityL3("")
      values.notes = "";
      values.billType = "";
      setBillingType("")
      values.hourlyRate = "";
      values.fixedRate = "";
      values.amount = "0.00";
      values.durationHour = "";
      values.durationMinutes = "";
      values.startTime = "";
      values.endTime = "";
      values.hours = "0.00";
      values.minutes = "0";

      values.client = "";
      setClient("");
      values.case_ = "";
      setCase_("");

      setOpenClient(true);

      return;
    }

    let _currentClient = clientsByEmail.filter(
      ({ id }) => id === values.client
    )[0];
    values.currentClient = _currentClient;
    console.log("_currentClient");
    console.log(_currentClient);
    debugger;

    values.case_ = "";
    let _case_ = "";
    let _casesByClient = [];
    if (typeof _currentClient !== "undefined") {
      const { hourlyRateService } = _currentClient;
      console.log(hourlyRateService);
      _casesByClient = _currentClient.cases.filter(
        (a) => a.status === "ACTIVE"
      );
      console.log(_casesByClient);
      if (
        typeof _currentClient.cases !== "undefined" &&
        _currentClient.cases.length > 0
      ) {
        if (
          typeof _currentClient.latestCase !== "undefined" &&
          _currentClient.latestCase !== null
        ) {
          values.case_ = _currentClient.latestCase.id;
          _case_ = values.case_;
          console.log("_currentClient.latestCase !== null");
          console.log(_case_);
          console.log(
            _currentClient.latestCase.caseCode +
            " " +
            _currentClient.latestCase.caseTitle
          );
        } else {
          console.log("_currentClient.latestCase === null");
          values.case_ = _currentClient.cases[0].id;
          _case_ = values.case_;
          console.log(_case_);
          console.log(
            _currentClient.cases[0].caseCode +
            " " +
            _currentClient.cases[0].caseTitle
          );
        }
        _casesByClient
          .filter((c) => c.id === values.case_)
          .map(({ billingTypeService, fixedRateService }) => {
            values.billingType = billingTypeService ? billingTypeService : "";
            setBillingType(values.billingType);
            console.log(billingTypeService);
            console.log(hourlyRateService);
            console.log(fixedRateService);
            // values.fixedRate = !fixedRateService ? '0.00' : formatMoney(formatNumber_(fixedRateService))
            values.fixedRate = "";
            values.hourlyRate = !hourlyRateService
              ? ""
              : formatMoney(formatNumber_(hourlyRateService));
            initialValues.hourlyRate = initialValues.hourlyRate === "0.00" ? "" : initialValues.hourlyRate
            console.log("fixedRateService " + fixedRateService);
            console.log("hourlyRateService " + hourlyRateService);
          });
      } else {
        setDate(values.date);
        // _case_ = 'NEWCASE'
        values.billingType = "";
        setBillingType("");
        values.fixedRate = "";
        values.hourlyRate = "";
        console.log("fixedRateExpense ");
        console.log("hourlyRateExpense ");
        console.log("billingType ");
      }
    } else {
    }
    debugger;

    setClient(values.client);
    setCase_(_case_);
    setCurrentClient(_currentClient);
    setCasesByClient(_casesByClient);

    let caseArray = [];
    _casesByClient
      .filter((a) => a.status === "ACTIVE")
      .sort(function (a, b) {
        let namea = a.caseCode + a.caseTitle;
        let nameb = b.caseCode + b.caseTitle;
        return namea.localeCompare(nameb);
      })
      .map(({ id, caseCode, caseTitle, status }) => {
        if (status === "ACTIVE") {
          caseArray.push({
            value: id,
            label:
              caseCode && caseTitle
                ? caseCode + " - " + caseTitle
                : caseCode && !caseTitle
                  ? caseCode
                  : !caseCode && caseTitle
                    ? caseTitle
                    : "",
          });
        }
      });

    caseArray.push({
      value: "NEWCASE",
      label: "ADD NEW CASE >",
    });

    setCaseArray(caseArray);
    setDate(values.date);
    values.case_ = _case_;
    setCase_(_case_);

    console.log("end handleChangeClientSelect");
  };

  const handleChangeCaseSelect = (values, value) => {
    createUserLog(props, "LOG_WORK", "Changed Case", value === "NEWCASE" ? "Add New Case" : "")
    debugger;

    values.case_ = value;
    values.freshCase_ = null;

    let scroll = "body";
    if (value === "NEWCASE") {
      values.activityL1 = "";
      values.activityL2 = "";
      values.activityL3 = "";
      setActivityL1("")
      setActivityL2("")
      setActivityL3("")
      values.notes = "";
      values.billType = "";
      setBillingType("")
      values.hourlyRate = "";
      values.fixedRate = "";
      values.amount = "0.00";
      values.durationHour = "";
      values.durationMinutes = "";
      values.startTime = "";
      values.endTime = "";
      values.hours = "0.00";
      values.minutes = "0";
      if (
        typeof values.client === "undefined" ||
        (typeof values.client !== "undefined" && values.client === "")
      ) {
        values.case_ = "";
        setCase_("");
        setValues(values);
        setOpenNoClient(true);
      } else {
        values.case_ = "";
        setCase_("");
        setValues(values);
        setOpenCase(true);
        return;
      }
    }

    let _currentClient = clientsByEmail.filter(
      ({ id }) => id === values.client
    )[0];
    values.currentClient = _currentClient;
    console.log("_currentClient");
    console.log(_currentClient);
    const { hourlyRateService } = _currentClient;
    console.log(hourlyRateService);
    debugger;

    let _casesByClient = [];
    _casesByClient = _currentClient.cases;
    console.log(_casesByClient);

    let _case = _casesByClient
      .filter((c) => c.id === values.case_)
      .map(({ billingTypeService, fixedRateService }) => {
        values.billingType = billingTypeService ? billingTypeService : "";
        setBillingType(values.billingType);
        console.log(billingTypeService);
        console.log(hourlyRateService);
        console.log(fixedRateService);
        // values.fixedRate = !fixedRateService ? '0.00' : formatMoney(formatNumber_(fixedRateService))
        values.fixedRate = "";
        values.hourlyRate = !hourlyRateService
          ? ""
          : formatMoney(formatNumber_(hourlyRateService));
        initialValues.hourlyRate = initialValues.hourlyRate === "0.00" ? "" : initialValues.hourlyRate
        console.log("fixedRateService " + fixedRateService);
        console.log("hourlyRateService " + hourlyRateService);
      });
    if (!_case) {
      values.billingType = "";
      setBillingType("");
      values.fixedRate = "";
      values.hourlyRate = "";
      console.log("fixedRateExpense ");
      console.log("hourlyRateExpense ");
      console.log("billingType ");
    }
    debugger;

    setCase_(value);
    setOpen(true);
    debugger;
  };

  const changeCase = async (case_, _casesByClient, _clientsByEmail) => {
    console.log("start changeCase");
    formRef.current.values.case_ = case_;
    setCase_(case_);
    setCasesByClient(_casesByClient);
    setClientsByEmail(_clientsByEmail);
    console.log("end changeCase");
  };

  const changeClient = async (_client, _currentClient, _clientsByEmail) => {
    console.log("start changeClient");
    formRef.current.values.client = _client;
    setCurrentClient(_currentClient);
    setClient(_client);
    setCasesByClient([]);
    setCase_();
    setClientsByEmail(_clientsByEmail);
    console.log("end changeClient");
  };

  const handleChangeInSubcomponent = (values, value) => {
    createUserLog(props, "LOG_WORK", "Changed Work/Activity Category", value)
    debugger;

    values.activityL1 = value;

    values.activity3Options = [];
    values.activityL3 = "";
    values.activity2Options = [];
    values.activityL2 = "";
    setActivityL2("");
    setActivityL3("");
    debugger;
    if (value === "") {
      return;
    }
    const l2 = activitiesSource.filter(({ name }) => name === value)[0];
    if (l2 && l2.prompt !== undefined) {
      setActivityL2(l2.prompt);
      values.activityL2 = l2.prompt;
    }

    // debugger;
    values.L2 = [];
    l2.L2.map((m, ix) => (values.L2[ix] = m));
    debugger;

    values.activity2Options = [];
    values.L2.map((m) => {
      console.log(m);
      values.activity2Options.push({
        value: m,
        label: m,
      });
    });
  };

  const handleChangeInSubSubcomponent = (values, value, errors) => {
    createUserLog(props, "LOG_WORK", "Changed Work/Activity Sub category", value)
    values.activityL2 = value;

    values.activity3Options = [];
    values.activityL3 = "";
    setActivityL3("");
    debugger;
    const l3 = L2Source.filter(
      ({ name, L2 }) => name === values.activityL1 && L2 === values.activityL2
    )[0];
    if (l3 && l3.prompt !== undefined) {
      setActivityL3(l3.prompt);
      values.activityL3 = l3.prompt;
    }
    debugger;
    values.L3 = [];
    l3.L3.map((m, ix) => (values.L3[ix] = m));

    values.activity3Options = [];
    values.L3.map((m) => {
      console.log(m);
      values.activity3Options.push({
        value: m,
        label: m,
      });
    });
  };

  const getClients = async () => {
    console.log("in getClients");
    const { client, authUser } = props;
    debugger;
    const { email } = authUser;
    console.log(email);
    debugger;
    console.log("out getClients");
    await client.resetStore();
    return await client.query({
      query: CLIENTS_BY_EMAIL_QUERY,
      variables: {
        email,
      },
      fetchPolicy: "no-cache",
    });
  };

  const handleSaveActivityLog = async (values, { setSubmitting }) => {
    createUserLog(props, "LOG_WORK", "Created a new Work log", "clicked on SAVE")
    console.log("start handleSaveActivityLog");
    debugger;
    if (values.client.length === 0 || values.case_.length === 0) return;

    values.activityL1 = values.activityL1.replace(" >", "");
    values.activityL2 = values.activityL2.replace(" >", "");
    setIsOpenWaiting(true);
    const { date, case_, client } = values;
    const { id } = member_;
    let member = id;

    let date_ = date;
    if (typeof date === "undefined") {
      date_ = new Date();
    }
    setId(id);
    setDate(date_);
    setClient(values.client);
    setCase_(values.case_);
    debugger;
    let activityLogs = [];
    if (values.billingType === "FIXED") {
      values.hourlyRate = "0.00";
      values.hours = "0";
      activityLogs.push({
        date: values.date.toISOString(),
        billingType: values.billingType,
        activityL1: values.activityL1,
        activityL2: values.activityL2,
        activityL3: values.activityL3,
        fixedRate: removeComma(values.fixedRate),
        hourlyRate: values.houryRate,
        amount: removeComma(values.fixedRate),
        notes: values.notes,
        startTime: null,
        endTime: null,
        hourlyRate: "0.00",
        hours: "0.0",
        action: "NEW",
        status: "ACTIVE",
        ord: 0,
        clientId: values.client,
        caseId: values.case_,
        memberId: member_.id,
      });
    } else if (values.billingType === "HOURLY") {
      values.fixedRate = "0.00";
      if (!values.startTime || !values.endTime) {
        values.startTime = null;
        values.endTime = null;
      }
      activityLogs.push({
        date: values.date.toISOString(),
        billingType: values.billingType,
        activityL1: values.activityL1,
        activityL2: values.activityL2,
        activityL3: values.activityL3,
        fixedRate: values.fixedRate,
        hourlyRate: removeComma(values.hourlyRate),
        amount: removeComma(values.hourlyRate) * values.hours,
        hours: values.hours,
        notes: values.notes,
        startTime: values.startTime !== "" ? values.startTime : null,
        endTime: values.endTime !== "" ? values.endTime : null,
        action: "NEW",
        status: "ACTIVE",
        ord: 0,
        clientId: values.client,
        caseId: values.case_,
        memberId: member_.id,
      });
    } else if (values.billingType === "NO CHARGE") {
      debugger
      values.fixedRate = "0.00";
      values.amount = "0.00";
      values.hourlyRate = "0.00";
      activityLogs.push({
        date: values.date.toISOString(),
        billingType: values.billingType,
        activityL1: values.activityL1,
        activityL2: values.activityL2,
        activityL3: values.activityL3,
        fixedRate: values.fixedRate,
        hourlyRate: values.hourlyRate,
        amount: values.amount,
        hours: values.hours,
        notes: values.notes,
        startTime: values.startTime !== "" ? values.startTime : null,
        endTime: values.endTime !== "" ? values.endTime : null,
        action: "NEW",
        status: "ACTIVE",
        ord: 0,
        clientId: values.client,
        caseId: values.case_,
        memberId: member_.id,
      });
    }

    debugger;

    console.log(values);
    createActivityLogs(activityLogs).then((data) => {
      setIsOpenWaiting(false);
      setOpenNotification(true);
      setSubmitting(false);
      console.log("end handleSaveActivityLog");
      debugger;
    });
  };

  const createActivityLogs = async (activitylogs) => {
    return await Promise.all(
      activitylogs.map((item, ix) => createLog(item, ix))
    );
  };

  const createLog = async (item, ix) => {
    if (item.hourlyRate) item.hourlyRate = formatNumber_(item.hourlyRate);
    if (item.amount) item.amount = formatNumber_(item.amount);
    if (item.elapsed) item.elapsed = formatNumber_(item.elapsed);
    if (item.hours) item.hours = formatNumber_(item.hours);
    if (item.fixedRate) item.fixedRate = formatNumber_(item.fixedRate);

    // console.log("activity log #" + (ix + 1));
    // console.log("billingType: " + item.billingType);
    // console.log("activityL1: " + item.activityL1);
    // console.log("activityL2: " + item.activityL2);
    // console.log("activityL3: " + item.activityL3);
    // console.log("amount: " + item.amount);
    // console.log("fixedRate: " + item.fixedRate);
    // console.log("hourlyRate: " + item.hourlyRate);
    // console.log("hours: " + item.hours);

    // console.log("notes" + item.notes);
    // console.log("startTime: " + item.startTime);
    // console.log("endTime: " + item.endTime);
    // console.log("endTime: " + item.elapsed);
    // console.log("date: " + item.date);
    // console.log("member: " + item.id);
    // console.log("client: " + item.clientId);
    // console.log("case: " + item.caseId);

    await createActivityLog(
      item.date,
      item.billingType,
      item.fixedRate,
      item.hourlyRate,
      item.hours,
      item.amount,
      item.activityL1,
      item.activityL2,
      item.activityL3,
      item.notes,
      item.startTime,
      item.endTime,
      item.caseId,
      item.memberId,
      item.clientId,
      item.action,
      item.status,
      item.ord
    );
  };

  const createActivityLog = async (
    date,
    billingType,
    fixedRate,
    hourlyRate,
    hours,
    amount,
    activityLogType,
    activityLogSubType,
    activityLogSubSubType,
    notes,
    startTime,
    endTime,
    caseId,
    memberId,
    clientId,
    action,
    status,
    ord
  ) => {
    debugger;
    const { client } = props;
    const { data } = await client.mutate({
      mutation: CREATE_ACTIVITY_LOG,
      variables: {
        date,

        billingType,
        fixedRate,
        hourlyRate,
        hours,
        amount,
        activityLogType,
        activityLogSubType,
        activityLogSubSubType,
        notes,
        startTime,
        endTime,
        caseId,
        memberId,
        clientId,
        actionDate: new Date().toISOString(),
        action,
        status,
        ord,
      },
    });
    console.log(data.createActivityLog);
    return data.createActivityLog;
  };


  function handleStartTime(value) {
    try {
      value.toDate();
    } catch (e) {
      console.log(e.toString());
      return;
    }
    // formRef.current.values.startTime = value.toDate()
    handleStartTime_(value);
    setStartTime(value);
  }

  function handleStartTime_(value) {
    createUserLog(props, "LOG_WORK", "Changed Start Time", value)
    console.log("handleStartTime_...");
    console.log(Object.prototype.toString.call(value));
    if (Object.prototype.toString.call(value) === "[object Object]") {
      try {
        formRef.current.values.startTime = value.toDate();

        let end = formRef.current.values.endTime;
        console.log("end");
        console.log(end);
        let start = formRef.current.values.startTime;
        console.log("start");
        console.log(start);

        if (typeof start === "object") {
          if (Object.prototype.toString.call(start) !== "[object Date]") {
            start = start.toDate();
          }
        }
        if (typeof end === "object") {
          if (Object.prototype.toString.call(end) !== "[object Date]") {
            end = end.toDate();
          }
        }
        if (end && start) {
          var elapsed = end.getTime() - start.getTime();
        }
        console.log("elapsed 1");
        console.log(elapsed);

        elapsed = elapsed / (1000 * 60 * 60);
        console.log("elapsed 2");
        console.log(elapsed);

        if (elapsed < 0) {
          formRef.current.values.elapsed = elapsed.toFixed(2);
        } else {
          formRef.current.values.elapsed = formatNumber_(elapsed);

          if (!formRef.current.values.hourlyRate) {
            formRef.current.values.hourlyRate = formatNumber_("0.0");
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    // setEndTime(value)

    // console.log(startTime);
    // console.log(endTime);

    // console.log(formRef.current.values.startTime);
    // console.log(formRef.current.values.endTime);

    // console.log(formRef.current.values.elapsed);
    // console.log(formRef.current.values.hours);
    // console.log(formRef.current.values.fixed);
    // console.log(formRef.current.values.hourlyRate);
    // console.log(formRef.current.values.amount);
    console.log("...handleEndTime_");
  }

  function handleEndTime(value) {
    try {
      value.toDate();
    } catch (e) {
      console.log(e.toString());
      return;
    }
    handleEndTime_(value);
    setEndTime(value);
  }

  function handleEndTime_(value) {
    createUserLog(props, "LOG_WORK", "Changed End Time", value)
    console.log("handleEndTime_...");
    console.log(Object.prototype.toString.call(value));
    if (Object.prototype.toString.call(value) === "[object Object]") {
      try {
        formRef.current.values.endTime = value.toDate();
        console.log("formRef.current.values.endTime");
        console.log(formRef.current.values.endTime);

        let end = formRef.current.values.endTime;
        console.log("end");
        console.log(end);
        let start = formRef.current.values.startTime;
        console.log("start");
        console.log(start);

        if (typeof start === "object") {
          if (Object.prototype.toString.call(start) !== "[object Date]") {
            start = start.toDate();
          }
        }
        if (typeof end === "object") {
          if (Object.prototype.toString.call(end) !== "[object Date]") {
            end = end.toDate();
          }
        }

        if (end && start) {
          var elapsed = end.getTime() - start.getTime();
        }
        console.log("elapsed 1");
        console.log(elapsed);

        elapsed = elapsed / (1000 * 60 * 60);
        console.log("elapsed 2");
        console.log(elapsed);

        if (elapsed < 0) {
          formRef.current.values.elapsed = elapsed.toFixed(2);
          formRef.current.values.amount = formatNumber_("0");
        } else {
          formRef.current.values.elapsed = formatNumber_(elapsed);

          if (!formRef.current.values.hourlyRate) {
            formRef.current.values.hourlyRate = formatNumber_("0.0");
          }
          // formRef.current.values.amount = formatNumber_(
          //   formRef.current.values.hourlyRate * elapsed
          // );
        }
      } catch (e) {
        console.log(e);
      }
    }
    console.log("...handleEndTime_");
  }

  const validate = (values) => {
    console.log("Formik validate() start ...");
    console.log(values);
    values.justInitialized = false;
    debugger;
    const errors = {};

    if (!values.client) errors.client = "Required";
    if (!values.case_) errors.case_ = "Required";
    if (!values.activityL1) errors.activityL1 = "Required";

    if (values.activityL2 && values.activityL2.includes("?"))
      errors.activityL2 = "Required";
    if (values.activityL3 && values.activityL3.includes("?"))
      errors.activityL3 = "Required";

    if (values.startTime) {
      let logDate = values.date;
      if (!isStartTimeValid(values.startTime, logDate))
        errors.startTime = "Start time should not preceed or exceed log date.";
    }
    // debugger;
    let today = new Date();
    today.setHours(23, 59, 59, 999);
    let x = today.toJSON();
    let y = new Date(values.date).toJSON();
    let start = values.startTime;
    let end = values.startTime;
    if (typeof start === "object") {
      if (Object.prototype.toString.call(start) !== "[object Date]") {
        start = start.toDate();
      }
    }
    if (typeof end === "object") {
      if (Object.prototype.toString.call(end) !== "[object Date]") {
        end = end.toDate();
      }
    }
    if (end && start) {
      var elapsed = end.getTime() - start.getTime();
    }
    // debugger;
    if (y > x) {
      errors.date = "Future date is not allowed";
    }

    if (values.activityL1) {
      if (!values.billingType) {
        errors.billingType = "Required";
      }

      if (values.billingType && values.billingType === "FIXED") {
        if (
          !values.fixedRate ||
          (values.fixedRate && formatNumber_(values.fixedRate) === "0.00")
        ) {
          errors.fixedRate = "Required";
        } else {
          const match = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]*\.[0-9]*[1-9][0-9]*)$/.test(
            removeComma(values.fixedRate)
          );
          if (!match) errors.fixedRate = "Invalid Value";
        }
      } else if (values.billingType && values.billingType === "HOURLY") {
        if (
          !values.hourlyRate ||
          (values.hourlyRate &&
            (formatNumber_(values.hourlyRate) === "0.00" ||
              values.hourlyRate.length === 0))
        ) {
          errors.hourlyRate = "Required";
        } else {
          const match = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]*\.[0-9]*[1-9][0-9]*)$/.test(
            removeComma(values.hourlyRate)
          );
          if (!match) errors.hourlyRate = "Invalid Value";
        }

        if (
          !values.durationMinutes ||
          (values.durationMinutes &&
            formatNumber_(values.durationMinutes) === "0.00")
        ) {
          if (
            !values.durationHour ||
            (values.durationHour &&
              formatNumber_(values.durationHour) === "0.00")
          ) {
            console.log("values.durationHour");
            console.log(values.durationHour);
            console.log("values.durationMinutes");
            console.log(values.durationMinutes);
            errors.hours = "Required";
          }
        }

      }
    }
    // console.log("errors");
    // console.log(errors);
    // console.log("values");
    // console.log(values);
    // console.log("Object.keys(errors).length");
    // console.log(Object.keys(errors).length);
    // console.log("Formik validate() end ...");
    debugger;
    return errors;
  };

  const enableDates = (dt) => {
    return (
      dt.isAfter(Datetime.moment().subtract(14, "day")) &&
      dt.isBefore(Datetime.moment())
    );
  };

  const handleHourChange = (value, values, setFieldValue) => {
    if (
      (values.startTime && values.startTime !== "") ||
      (values.endTime && values.endTime !== "")
    ) {
      let date = new DateTimeLog(values.hours);
      let endTime = date.getEndTimeFromStartTime(values.startTime);
      let a = date.isDateLesserOrGreater(
        getValidDate(endTime),
        getValidDate(values.date)
      );
      if (a.lesserOrGreater !== "") {
        console.log(a.lesserOrGreater);
        setEndExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: a.lesserOrGreater,
        });
      } else {
        setEndExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: "",
        });
      }
      date.validateEndDateHours(values.date, endTime);

      setFieldValue("endTime", endTime);
    }
  };

  const handleDateChange = (value, values, setFieldValue) => {
    if (
      (values.startTime && values.startTime !== "") ||
      (values.endTime && values.endTime !== "")
    ) {
      let date = new DateTimeLog(values.hours);
      let newEndTime = date.getSynchedEndYearMonthDate(value, values.endTime);
      let newStartTime = date.getStartTimeFromEndTime(newEndTime);
      let a = date.isDateLesserOrGreater(
        getValidDate(newStartTime),
        getValidDate(value)
      );
      let b = date.isDateLesserOrGreater(
        getValidDate(newEndTime),
        getValidDate(value)
      );
      if (a.lesserOrGreater !== "") {
        console.log(a.lesserOrGreater);
        setStartExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: a.lesserOrGreater,
        });
      } else {
        setStartExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: "",
        });
      }
      if (b.lesserOrGreater !== "") {
        setEndExceeds({
          date: b.date,
          dateString: b.dateString,
          lesserOrGreater: b.lesserOrGreater,
        });
      } else {
        setEndExceeds({
          date: b.date,
          dateString: b.dateString,
          lesserOrGreater: "",
        });
      }
      console.log(newStartTime);
      console.log(newEndTime);
      setFieldValue("startTime", newStartTime);
      setFieldValue("endTime", newEndTime);
    }
  };

  const handleStartTimeChange = (value, values, setFieldValue) => {
    console.log(value);
    let newStart = value;
    let start = "";
    let cdate = "";
    start = getValidDate(values.startTime);
    cdate = getValidDate(values.date);
    console.log(start);
    newStart = cdate.setHours(
      start.getHours(),
      start.getMinutes(),
      start.getSeconds(),
      start.getMilliseconds()
    );
    newStart = new Date(newStart);
    console.log(newStart);
    console.log(values.date);
    let date = new DateTimeLog(values.hours);
    let endTime = date.getEndTimeFromStartTime(newStart);
    let a = date.isDateLesserOrGreater(
      getValidDate(newStart),
      getValidDate(values.date)
    );
    let b = date.isDateLesserOrGreater(
      getValidDate(endTime),
      getValidDate(values.date)
    );
    if (a.lesserOrGreater !== "") {
      console.log(a.lesserOrGreater);
      setStartExceeds({
        date: a.date,
        dateString: a.dateString,
        lesserOrGreater: a.lesserOrGreater,
      });
    } else {
      setStartExceeds({
        date: a.date,
        dateString: a.dateString,
        lesserOrGreater: "",
      });
    }
    if (b.lesserOrGreater !== "") {
      setEndExceeds({
        date: b.date,
        dateString: b.dateString,
        lesserOrGreater: b.lesserOrGreater,
      });
    } else {
      setEndExceeds({
        date: b.date,
        dateString: b.dateString,
        lesserOrGreater: "",
      });
    }
    setFieldValue("startTime", newStart);
    setFieldValue("endTime", endTime);
  };

  const handleEndTimeChange = (value, values, setFieldValue) => {
    let date = new DateTimeLog(values.hours);
    let startTime = date.getStartTimeFromEndTime(value);
    let a = date.isDateLesserOrGreater(
      getValidDate(value),
      getValidDate(values.date)
    );
    let b = date.isDateLesserOrGreater(
      getValidDate(startTime),
      getValidDate(values.date)
    );
    if (a.lesserOrGreater !== "") {
      console.log(a.lesserOrGreater);
      setEndExceeds({
        date: a.date,
        dateString: a.dateString,
        lesserOrGreater: a.lesserOrGreater,
      });
    } else {
      setEndExceeds({
        date: a.date,
        dateString: a.dateString,
        lesserOrGreater: "",
      });
    }
    if (b.lesserOrGreater !== "") {
      setStartExceeds({
        date: b.date,
        dateString: b.dateString,
        lesserOrGreater: b.lesserOrGreater,
      });
    } else {
      setStartExceeds({
        date: b.date,
        dateString: b.dateString,
        lesserOrGreater: "",
      });
    }
    setFieldValue("endTime", value);
    setFieldValue("startTime", startTime);
  };

  const handleClickBillingType = (value, setFieldValue, values) => {
    createUserLog(props, "LOG_WORK", "Changed BillingType", value)
    setFieldValue("billingType", value);
    setBillingType(value);
    values.billingType = value
    if (value === "FIXED") {
      values.amount = formatMoney(formatNumber_(removeComma(values.fixedRate)))
      setFieldValue('amount', values.amount)
    } else if (value === "HOURLY") {
      let durationHr = formatNumber_(values.durationHour);
      let durationMinutes = formatNumber_(values.durationMinutes)
      let durationMinDec = 0;
      let duration = 0;
      if (durationMinutes === "" || durationMinutes === "00") {
        durationMinutes = "00";
      } else {
        if (durationMinutes) {
          durationMinDec = Number.parseFloat(durationMinutes) / 60;
        }
        values.durationMinutes = durationMinutes;
      }
      duration = Number.parseFloat(durationHr) + durationMinDec;
      values.amount = formatMoney(formatNumber_(duration * removeComma(values.hourlyRate)))
      setFieldValue('amount', values.amount)
    } else if (value === "NO CHARGE") {
    }
  };

  const popHours = () => {
    let hr = 0;
    let hrArray = [];
    while (hr < 12) {
      if (hr === 0) hrArray.push(hr);
      hr++;
      hrArray.push(hr);
    }
    return hrArray;
  };
  const popMinutes = () => {
    let min = 0;
    let minArray = [];
    while (min < 45) {
      if (min === 0) minArray.push(min);
      min += 15;
      minArray.push(min);
    }
    console.log(minArray);
    return minArray;
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleDateChange2 = (value, values, setFieldValue) => {
    if (
      (values.startTime && values.startTime !== "") ||
      (values.endTime && values.endTime !== "")
    ) {
      console.log(values.hours);
      setFieldValue("date", value);
      let date = new DateTimeLog(values.hours);
      let newStartTime = date.getSynchedStartYearMonthDate(
        value,
        values.startTime
      );
      let newEndTime = date.getEndTimeFromStartTime(newStartTime);
      setFieldValue("startTime", newStartTime);
      setFieldValue("endTime", newEndTime);
      setStartTime(newStartTime);
      setEndTime(newEndTime);
      let a = date.isDateLesserOrGreater(
        getValidDate(newStartTime),
        getValidDate(value)
      );
      let b = date.isDateLesserOrGreater(
        getValidDate(newEndTime),
        getValidDate(value)
      );
      if (a.lesserOrGreater !== "") {
        console.log(a.lesserOrGreater);
        setStartExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: a.lesserOrGreater,
        });
      } else {
        setStartExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: "",
        });
      }
      if (b.lesserOrGreater !== "") {
        setEndExceeds({
          date: b.date,
          dateString: b.dateString,
          lesserOrGreater: b.lesserOrGreater,
        });
      } else {
        setEndExceeds({
          date: b.date,
          dateString: b.dateString,
          lesserOrGreater: "",
        });
      }
    }
  };

  const handleDurationHourChange = (value, values, setFieldValue) => {
    if (value !== "") {
      let durationHr = value;
      let durationMinDec = 0;
      let duration = 0;
      setDurationHour(durationHr);
      values.durationHour = durationHr;
      setFieldValue("durationHour", durationHr);
      if (durationMinutes === "" || durationMinutes === "00") {
        setDurationMinutes("00");
        values.durationMinutes = "00";
        setFieldValue("durationMinutes", "00");
      } else {
        //convert minutes to decimal
        if (durationMinutes) {
          durationMinDec = Number.parseFloat(durationMinutes) / 60;
          console.log(durationMinDec);
        }
        setDurationMinutes(durationMinutes);
        values.durationMinutes = durationMinutes;
        setFieldValue("durationMinutes", durationMinutes);
      }
      duration = Number.parseFloat(durationHr) + durationMinDec;
      console.log(duration);
      if (duration > 8) setOpenAlert(true)
      setFieldValue("hours", duration);
      setFieldValue(
        "amount",
        formatMoney(formatNumber_(duration * removeComma(values.hourlyRate)))
      );
      if (
        (values.startTime && values.startTime !== "") ||
        (values.endTime && values.endTime !== "")
      ) {
        let date = new DateTimeLog(duration);
        let endTime = date.getEndTimeFromStartTime(values.startTime);
        let a = date.isDateLesserOrGreater(
          getValidDate(endTime),
          getValidDate(values.date)
        );

        if (a.lesserOrGreater !== "") {
          console.log(a.lesserOrGreater);
          setEndExceeds({
            date: a.date,
            dateString: a.dateString,
            lesserOrGreater: a.lesserOrGreater,
          });
        } else {
          setEndExceeds({
            date: a.date,
            dateString: a.dateString,
            lesserOrGreater: "",
          });
        }
        date.validateEndDateHours(values.date, endTime);

        setFieldValue("endTime", endTime);
      }
    }
  };
  const handleDurationMinutesChange = (value, values, setFieldValue) => {
    if (value !== "") {
      console.log(value);
      let durationMin = value;
      let durationMinDec = 0;
      let durationHr = values.durationHour;
      let duration = 0;
      setDurationMinutes(durationMin);
      values.durationMinutes = durationMin;
      setFieldValue("durationMinutes", durationMin);
      if (durationHour === "" || durationHour === "00") {
        setDurationHour("0");
        values.durationHour = "0";
        setFieldValue("durationHour", "0");
        durationHr = 0;
      } else {
        setDurationHour(durationHour);
        values.durationHour = durationHour;
        setFieldValue("durationHour", durationHour);
        durationHr = durationHour;
      }
      durationMinDec = Number.parseFloat(durationMin) / 60;

      console.log(durationMinDec);
      duration = Number.parseFloat(durationHr) + durationMinDec;
      console.log(duration);
      if (duration > 8) setOpenAlert(true)
      setFieldValue("hours", duration);
      setFieldValue(
        "amount",
        formatMoney(formatNumber_(duration * removeComma(values.hourlyRate)))
      );
      if (
        (values.startTime && values.startTime !== "") ||
        (values.endTime && values.endTime !== "")
      ) {
        let date = new DateTimeLog(duration);
        let endTime = date.getEndTimeFromStartTime(values.startTime);
        let a = date.isDateLesserOrGreater(
          getValidDate(endTime),
          getValidDate(values.date)
        );
        if (a.lesserOrGreater !== "") {
          console.log(a.lesserOrGreater);
          setEndExceeds({
            date: a.date,
            dateString: a.dateString,
            lesserOrGreater: a.lesserOrGreater,
          });
        } else {
          setEndExceeds({
            date: a.date,
            dateString: a.dateString,
            lesserOrGreater: "",
          });
        }
        date.validateEndDateHours(values.date, endTime);
        setFieldValue("endTime", endTime);
      }
    }
  };

  const isStartTimeValid = (startTime, logDate) => {
    let date = getValidDate(logDate);
    let logDateMax = date.setHours(23, 59, 59, 999);
    let logDateMin = date.setHours(0, 0, 0, 0);
    let startTimex = getValidDate(startTime);
    if (startTimex > logDateMax || startTimex < logDateMin) {
      return false;
    } else {
      return true;
    }
  };


  let sameTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#999",
      },
      secondary: { main: "#22BF19" },
      grey: { main: "#22BF19" },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          position: "relative",
          "& $notchedOutline": {
            borderColor: "#999",
          },
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: "#999",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              borderColor: "#999",
            },
          },
          "&$focused $notchedOutline": {
            borderColor: "#999",
            borderWidth: 1,
          },
        },
      },
      MuiFormLabel: {
        root: {
          // "&$focused": {
          color: "#999",
          fontSize: "22px",
          backgroundColor: "#fff",
          margin: "-2px 0 0 -5px",
          padding: "0 10px 0 10px",
          // }
        },
      },
    },
  });

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openExpenseLog}
        // TransitionComponent={props.Transition}
        keepMounted
        onClose={handleCloseExpenseLog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 600}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Log Work
                  </div>
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0` }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <div
          className={classes.container}
          style={{
            width: `${size.width <= 414 ? size.width - 30 : 600}px`,
          }}
        >
          <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
            <Waiting
              Transition={Transition}
              isOpenWaiting={isOpenWaiting}
            ></Waiting>
          </div>
          {hasDeleteRequest ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "30px",
              }}
            >
              <Typography>
                You have a pending delete request. Cancel the delete request to
                log an expense.
              </Typography>
            </div>
          ) : (
            ""
          )}
          <div id="mainScreen" style={{ marginTop: "0px" }}>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validate={validate}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                // createUserLog(props, "LOG_WORK", "Create a new Work log", "clicked SAVE")
                handleSaveActivityLog(values, { setSubmitting });
              }}
            >
              {({
                values,
                isSubmitting,
                setFieldValue,
                touched,
                errors,
              }) => (
                <Form mode="themed">
                  <ThemeProvider
                    theme={
                      isLogging
                        ? defaultMaterialTheme
                        : viewLogMaterialTheme
                    }
                  >
                    <div
                      style={{
                        width: `${size.width <= 320 ? size.width - 60 :
                          size.width <= 360 ? size.width - 60 :
                            size.width <= 375 ? size.width - 60 :
                              size.width <= 414 ? size.width - 60 : 600
                          }px`,
                      }}

                    >
                      <div
                        style={{
                          margin: "0 auto 0 auto",
                          width: `${size.width <= 320 ? size.width - 40 :
                            size.width <= 360 ? size.width - 40 :
                              size.width <= 375 ? size.width - 40 :
                                size.width <= 414 ? size.width - 40 : 600
                            }px`,
                          overflow: "auto",
                          height: `${size.height - 150}px`,
                        }}
                      >
                        <div
                          style={{
                            margin: "0 auto 0 auto",
                            width: `${size.width <= 320 ? size.width - 60 :
                              size.width <= 360 ? size.width - 60 :
                                size.width <= 375 ? size.width - 60 :
                                  size.width <= 414 ? size.width - 60 : 370
                              }px`,
                          }}

                        >

                          <div style={{ marginTop: "40px" }} />
                          <ThemeProvider
                            theme={
                              isLogging
                                ? defaultMaterialTheme
                                : viewLogMaterialTheme
                            }
                          >
                            <TextField
                              fullWidth
                              labelId="client_"
                              id="client_"
                              value={client}
                              variant="outlined"
                              select
                              label="Client"
                              disabled={hasDeleteRequest}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required={client.length === 0}
                              InputProps={{
                                readOnly: isLogging ? false : true,
                              }}
                              onChange={(e) => {
                                debugger;
                                values.client = e.target.value;
                                handleChangeClientSelect(
                                  values,
                                  e.target.value
                                );
                                setFieldValue("client", e.target.value);
                              }}
                            >
                              {clientArray.map((client, index) => (
                                <MenuItem key={index} value={client.value}
                                  style={{
                                    color: `${client.label.endsWith('>') ? primaryColor[0] : grayColor[1]}`,
                                    fontWeight: `${client.label.endsWith('>') ? '600' : '400'}`
                                  }}>
                                  {client.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            {/* <FormHelperText>
                            <div style={{ color: primaryColor[0] }}>
                              {errors.client &&
                                touched.client &&
                                errors.client}
                            </div>
                          </FormHelperText> */}
                          </ThemeProvider>

                          <div style={style.step3Case480}>
                            <ThemeProvider
                              theme={
                                isLogging
                                  ? defaultMaterialTheme
                                  : viewLogMaterialTheme
                              }
                            >
                              <FormControl className={classes.formControl}>
                                <TextField
                                  disabled={hasDeleteRequest || client.length === 0}
                                  labelId="case_"
                                  id="case_"
                                  value={case_}
                                  variant="outlined"
                                  select
                                  label="Case"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  required={case_ && case_.length === 0}
                                  InputProps={{
                                    readOnly: isLogging ? false : true,
                                  }}
                                  onChange={(e) => {
                                    debugger;
                                    handleCase(e);
                                    handleChangeCaseSelect(
                                      values,
                                      e.target.value
                                    );
                                    setFieldValue("case_", e.target.value);
                                  }}
                                >
                                  {caseArray.map((case_, index) => (
                                    <MenuItem key={index} value={case_.value}
                                      style={{
                                        color: `${case_.label.endsWith('>') ? primaryColor[0] : grayColor[1]}`,
                                        fontWeight: `${case_.label.endsWith('>') ? '600' : '400'}`
                                      }}>
                                      {case_.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {/* <FormHelperText>
                                <div style={{ color: primaryColor[0] }}>
                                  {errors.case_ &&
                                    touched.case_ &&
                                    errors.case_}
                                </div>
                              </FormHelperText> */}
                              </FormControl>
                            </ThemeProvider>
                          </div>

                          <div style={style.step3Date480}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider
                                theme={
                                  isLogging
                                    ? dateMaterialTheme
                                    : viewLogMaterialTheme
                                }
                              >
                                <KeyboardDatePicker
                                  open={isLogging ? openWorkDate : false}
                                  onClose={() => {
                                    setOpenWorkDate(false);
                                  }}
                                  disabled={hasDeleteRequest}
                                  name="date"
                                  margin="normal"
                                  keyboard
                                  autoOk
                                  maxDate={new Date()}
                                  label="Work Date"
                                  value={values.date}
                                  format="MMM dd, yyyy"
                                  disableFuture
                                  onChange={(value) => {
                                    setFieldValue("date", value);
                                    setSelectedDate(value);
                                    handleDateChange2(
                                      value,
                                      values,
                                      setFieldValue
                                    );
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": isLogging
                                      ? "change date"
                                      : "",
                                  }}
                                  TextFieldComponent={(props) =>
                                    isLogging ? (
                                      <TextFieldComponent
                                        {...props}
                                        setOpen={setOpenWorkDate}
                                      />
                                    ) : (
                                      <TextFieldComponent
                                        {...props}
                                        setOpen={setOpenWorkDate}
                                        InputProps={{
                                          readOnly: isLogging ? false : true,
                                        }}
                                      />
                                    )
                                  }
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                          </div>
                          <div style={style.step3DateError}>
                            {/* <FormHelperText>
                            <div style={{ color: primaryColor[0] }}>
                              {errors.date && errors.date}
                            </div>
                          </FormHelperText> */}
                          </div>
                          <div style={style.step2ActivityL1480}>
                            <ThemeProvider
                              theme={
                                isLogging
                                  ? defaultMaterialTheme
                                  : viewLogMaterialTheme
                              }
                            >
                              <FormControl className={classes.formControl}>
                                <TextField
                                  labelId="activityL1"
                                  id="activityL1"
                                  value={activityL1}
                                  variant="outlined"
                                  select
                                  label="Category"
                                  disabled={hasDeleteRequest}
                                  onChange={(e) => {
                                    handleActivityL1(e);
                                    values.activityL1 = e.target.value;
                                    handleChangeInSubcomponent(
                                      values,
                                      e.target.value
                                    );
                                    setFieldValue("activityL1", e.target.value);
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  required={isLogging && (values.activityL1.length === 0 || values.activityL2.includes("?") || values.activityL3.includes("?"))}
                                  InputProps={{
                                    readOnly: isLogging ? false : true,
                                  }}
                                >
                                  {activity1Options.map((activity) => (
                                    <MenuItem value={activity.value}
                                      style={{
                                        color: `${activity.label.endsWith('>') ? primaryColor[0] : grayColor[1]}`,
                                        fontWeight: `${activity.label.endsWith('>') ? '500' : '400'}`
                                      }}>
                                      {activity.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {/* <FormHelperText>
                                <div style={{ color: primaryColor[0] }}>
                                  {errors.activityL1 &&
                                    touched.activityL1 &&
                                    errors.activityL1}
                                </div>
                                {/* {errors.activityL1 && touched.activityL1 && errors.activityL1} 
                              </FormHelperText> */}
                              </FormControl>
                            </ThemeProvider>
                          </div>

                          {values.activity2Options.length > 1 ? (
                            <div style={style.step2Activity480}>
                              <ThemeProvider
                                theme={
                                  isLogging
                                    ? defaultMaterialTheme
                                    : viewLogMaterialTheme
                                }
                              >
                                <FormControl className={classes.formControl}>
                                  <TextField
                                    labelId="activityL2"
                                    id="activityL2"
                                    value={activityL2}
                                    variant="outlined"
                                    select
                                    disabled={hasDeleteRequest}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={isLogging && values.activityL2.includes("?")}
                                    InputProps={{
                                      readOnly: isLogging ? false : true,
                                    }}
                                    onChange={(e) => {
                                      handleActivityL2(e);
                                      values.activityL2 = e.target.value;
                                      handleChangeInSubSubcomponent(
                                        values,
                                        e.target.value,
                                        errors
                                      );
                                      setFieldValue(
                                        "activityL2",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {values.activity2Options.map((activity) => (
                                      <MenuItem value={activity.value}
                                        style={{
                                          color: `${activity.label.endsWith('>') ? primaryColor[0] : grayColor[1]}`,
                                          fontWeight: `${activity.label.endsWith('>') ? '500' : '400'}`
                                        }}>
                                        {activity.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  {/* <FormHelperText>
                                  <div style={{ color: primaryColor[0] }}>
                                    {errors.activityL2 &&
                                      touched.activityL2 &&
                                      errors.activityL2}
                                  </div>
                                </FormHelperText> */}
                                </FormControl>
                              </ThemeProvider>
                            </div>
                          ) : (
                            <div />
                          )}

                          {values.activity3Options.length > 1 ? (
                            <div style={style.step2Activity480}>
                              <ThemeProvider
                                theme={
                                  isLogging
                                    ? defaultMaterialTheme
                                    : viewLogMaterialTheme
                                }
                              >
                                <FormControl className={classes.formControl}>
                                  <TextField
                                    labelId="activityL3"
                                    id="activityL3"
                                    value={values.activityL3}
                                    variant="outlined"
                                    select
                                    disabled={hasDeleteRequest}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={isLogging && values.activityL3.includes("?")}
                                    InputProps={{
                                      readOnly: isLogging ? false : true,
                                    }}
                                    onChange={(e) => {
                                      handleActivityL3(e);
                                      createUserLog(props, "LOG_WORK", "Changed Work/Activity Sub Sub category", e.target.value)
                                      values.activityL3 = e.target.value;
                                      setFieldValue(
                                        "activityL3",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {values.activity3Options.map((activity) => (
                                      <MenuItem value={activity.value}>
                                        {activity.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  {/* <FormHelperText>
                                  <div style={{ color: primaryColor[0] }}>
                                    {errors.activityL3 &&
                                      touched.activityL3 &&
                                      errors.activityL3}
                                  </div>
                                </FormHelperText> */}
                                </FormControl>
                              </ThemeProvider>
                            </div>
                          ) : (
                            <div />
                          )}

                          <div style={style.step3Notes480}>
                            <ThemeProvider
                              theme={
                                isLogging
                                  ? defaultMaterialTheme
                                  : viewLogMaterialTheme
                              }
                            >
                              <TextField
                                fullWidth
                                multiline="true"
                                rowsMax="3"
                                name="notes"
                                label="Notes"
                                variant="outlined"
                                disabled={hasDeleteRequest}
                                onChange={(event) => {
                                  values.notes = event.target.value.toUpperCase();
                                  setFieldValue(
                                    "notes",
                                    event.target.value.toUpperCase()
                                  );
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  readOnly: isLogging ? false : true,
                                }}
                                value={values.notes}
                              />
                            </ThemeProvider>
                          </div>
                          <div style={style.step3billingType}>
                            <ThemeProvider
                              theme={
                                isLogging
                                  ? defaultMaterialTheme
                                  : viewLogMaterialTheme
                              }
                            >
                              <FormControl className={classes.formControl}>
                                <TextField
                                  labelId="billingType"
                                  id="billingType"
                                  value={values.billingType}
                                  variant="outlined"
                                  select
                                  label="Bill Type"
                                  disabled={hasDeleteRequest}
                                  onChange={(e) => {
                                    handleClickBillingType(
                                      e.target.value,
                                      setFieldValue,
                                      values
                                    );
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  required={values.billingType.length === 0}
                                  InputProps={{
                                    readOnly: isLogging ? false : true,
                                  }}
                                >
                                  {["FIXED", "HOURLY", "NO CHARGE"].map(
                                    (bt, index) => {
                                      return (
                                        <MenuItem value={bt}>{bt}</MenuItem>
                                      );
                                    }
                                  )}
                                </TextField>
                                {/* <FormHelperText>
                                <div style={{ color: primaryColor[0] }}>
                                  {errors.billingType &&
                                    touched.billingType &&
                                    errors.billingType}
                                </div>
                              </FormHelperText> */}
                              </FormControl>
                            </ThemeProvider>
                          </div>
                          {values.billingType === "FIXED" ? (
                            <div id="divFixed">
                              <div style={style.step3billing480}>
                                <ThemeProvider
                                  theme={
                                    isLogging ?
                                      defaultMaterialTheme
                                      : viewLogMaterialTheme
                                  }
                                >
                                  <TextField
                                    name="fixedRate"
                                    label="Fixed Amount"
                                    placeholder="0.00"
                                    variant="outlined"
                                    disabled={hasDeleteRequest}
                                    onChange={(e) => {
                                      debugger
                                      if (checkMoney(e.target.value)) {
                                        values.fixedRate = e.target.value;
                                        setFieldValue(
                                          "fixedRate",
                                          e.target.value
                                        );
                                        values.amount = formatMoney(
                                          formatNumber_(e.target.value)
                                        );
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={isLogging && values.billingType === "FIXED" && (formatNumber_(values.amount) === "0.00" || formatNumber_(values.amount) === 0)}
                                    InputProps={{
                                      readOnly: isLogging ? false : true,
                                    }}
                                    onFocus={(e) => {
                                      if (Number.parseFloat(removeComma(values.fixedRate)) === 0) {
                                        values.fixedRate = ""
                                      } else {
                                        values.fixedRate =
                                          values.fixedRate.length > 0
                                            ? removeComma(values.fixedRate)
                                            : "";
                                        setFieldValue("fixedRate", values.fixedRate);
                                      }
                                    }}
                                    onBlur={(e) => {
                                      if (Number.parseFloat(removeComma(values.fixedRate)) === 0 || values.fixedRate === "") {
                                        values.fixedRate = "0.00"
                                      } else {
                                        values.fixedRate =
                                          values.fixedRate.length > 0
                                            ? formatMoney(
                                              formatNumber_(values.fixedRate)
                                            )
                                            : "";
                                        setFieldValue("fixedRate", values.fixedRate);
                                      }
                                    }}
                                    value={values.fixedRate}
                                  />
                                </ThemeProvider>
                                {/* <FormHelperText
                                style={{
                                  color: primaryColor[0],
                                  marginLeft: "10px",
                                }}
                              >
                                {errors.fixedRate && touched.fixedRate
                                  ? errors.fixedRate
                                  : null}
                              </FormHelperText> */}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {(values.billingType === "HOURLY") ||
                            values.billingType === "NO CHARGE" ? (
                            <div id="divHourly">
                              {billingType === "HOURLY" ? (
                                <div style={style.step3billing480}>
                                  <ThemeProvider
                                    theme={
                                      isLogging
                                        ? errors.hourlyRate &&
                                          touched.hourlyRate
                                          ? defaultMaterialTheme //errorMaterialTheme
                                          : defaultMaterialTheme
                                        : viewLogMaterialTheme
                                    }
                                  >
                                    <TextField
                                      name="hourlyRate"
                                      label="Hourly Rate"
                                      placeholder="0.00"
                                      variant="outlined"
                                      disabled={hasDeleteRequest}
                                      onChange={(e) => {
                                        if (checkMoney(e.target.value)) {
                                          if (values.hours !== "") {
                                            setFieldValue(
                                              "amount",
                                              formatMoney(
                                                formatNumber_(
                                                  values.hours * e.target.value
                                                )
                                              )
                                            );
                                          }
                                          values.hourlyRate = e.target.value;
                                          setFieldValue(
                                            "hourlyRate",
                                            e.target.value
                                          );
                                        }
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      required={isLogging && values.billingType !== "NO CHARGE" && (formatNumber_(values.hourlyRate) === "0.00" || formatNumber_(values.hourlyRate) === 0)}
                                      InputProps={{
                                        readOnly: isLogging ? false : true,
                                      }}
                                      onFocus={(e) => {
                                        if (Number.parseFloat(removeComma(values.hourlyRate)) === 0) {
                                          values.hourlyRate = ""
                                        } else {
                                          values.hourlyRate =
                                            values.hourlyRate.length > 0
                                              ? removeComma(values.hourlyRate)
                                              : "";
                                          setFieldValue("hourlyRate", values.hourlyRate);
                                        }
                                      }}
                                      onBlur={(e) => {
                                        if (Number.parseFloat(removeComma(values.hourlyRate)) === 0 || values.hourlyRate === "") {
                                          values.hourlyRate = "0.00"
                                        } else {
                                          values.hourlyRate =
                                            values.hourlyRate.length > 0
                                              ? formatMoney(
                                                formatNumber_(values.hourlyRate)
                                              )
                                              : "";
                                          setFieldValue("hourlyRate", values.hourlyRate);
                                        }
                                      }}
                                      value={values.hourlyRate}
                                    />
                                  </ThemeProvider>
                                  {/* <FormHelperText
                                    style={{
                                      color: primaryColor[0],
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {errors.hourlyRate & touched.hourlyRate
                                      ? errors.hourlyRate
                                      : null}
                                  </FormHelperText> */}
                                  <div />
                                </div>
                              ) : (
                                ""
                              )}
                              <div style={style.step3billing480}>
                                <ThemeProvider
                                  theme={
                                    isLogging
                                      ? errors.hours &&
                                        (touched.durationHour ||
                                          touched.durationMinutes)
                                        ? defaultMaterialTheme //errorMaterialTheme
                                        : defaultMaterialTheme
                                      : viewLogMaterialTheme
                                  }
                                >
                                  <div>
                                    <div
                                      style={{
                                        marginLeft: "4px",
                                        marginBottom: "10px",
                                        display: "inline-flex",
                                      }}
                                    >
                                      {/* {errors.hours &&
                                        (touched.durationHour ||
                                          touched.durationMinutes) ? (
                                          <Typography
                                            variant="caption"
                                            style={{ color: primaryColor[0] }}
                                          >
                                            Duration
                                          </Typography>
                                        ) : (
                                          <Typography variant="body1">
                                            Duration
                                          </Typography>
                                        )} */}
                                      <Typography variant="body1">
                                        Duration
                                      </Typography>
                                    </div>
                                    <div />
                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: ".49fr .02fr .49fr",
                                        gridTemplateRows: "auto auto",
                                      }}>
                                      <TextField
                                        labelId="durationHour"
                                        id="durationHour"
                                        value={durationHour}
                                        select
                                        label="Hours"
                                        variant="outlined"
                                        disabled={hasDeleteRequest}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required={isLogging && values.billingType !== "NO CHARGE" && (formatNumber_(values.hours) === "0.00" || formatNumber_(values.hours) === 0)}
                                        InputProps={{
                                          readOnly: isLogging ? false : true,
                                        }}
                                        onChange={(e) => {
                                          handleDurationHourChange(
                                            e.target.value,
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                      >
                                        {popHours().map((hr, index) => (
                                          <MenuItem value={hr} key={index}>
                                            {hr}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                      <div />
                                      <TextField
                                        labelId="durationMinutes"
                                        id="durationMinutes"
                                        value={durationMinutes}
                                        select
                                        label="Minutes"
                                        variant="outlined"
                                        disabled={hasDeleteRequest}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        required={isLogging && values.billingType !== "NO CHARGE" && (formatNumber_(values.hours) === "0.00" || formatNumber_(values.hours) === 0)}
                                        InputProps={{
                                          readOnly: isLogging ? false : true,
                                        }}
                                        onChange={(e) => {
                                          handleDurationMinutesChange(
                                            e.target.value,
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                      >
                                        {popMinutes().map((min, index) => (
                                          <MenuItem
                                            value={min === 0 ? "00" : min}
                                            key={index}
                                          >
                                            {min === 0 ? "00" : min}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </div>
                                  </div>
                                </ThemeProvider>
                                <div />
                              </div>
                              {/* <ThemeProvider
                                theme={
                                  isLogging
                                    ? errors.hours &&
                                      (touched.durationHour ||
                                        touched.durationMinutes)
                                      ? defaultMaterialTheme //errorMaterialTheme
                                      : defaultMaterialTheme
                                    : viewLogMaterialTheme
                                }
                              >
                                <FormHelperText
                                  style={{
                                    margin: "0 0 0 10px",
                                    color: primaryColor[0],
                                  }}
                                >
                                  {çƒ√hours &&
                                    (touched.durationHour ||
                                      touched.durationMinutes)
                                    ? errors.hours
                                    : null}
                                </FormHelperText>
                              </ThemeProvider> */}
                              <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                                <Alert onClose={handleCloseAlert} severity="warning">
                                  <AlertTitle>Alert</AlertTitle>
                                  Duration exceeds 8 hours.
                                </Alert>
                              </Snackbar>
                              {(values.hours &&
                                values.hours !== 0 &&
                                values.billingType === "HOURLY") ||
                                values.billingType === "NO CHARGE" ? (
                                <>
                                  <div style={{ margin: "15px 0 15px 0" }}>
                                    <ThemeProvider
                                      theme={
                                        isLogging
                                          ? defaultMaterialTheme
                                          : viewLogMaterialTheme
                                      }
                                    >
                                      <Datetime
                                        inputProps={{
                                          name: "startTime",
                                          id: "startTime",
                                          placeholder: "",
                                        }}
                                        isValidDate={enableDates}
                                        viewMode="time"
                                        dateFormat=""
                                        timeFormat={true}
                                        value={values.startTime}
                                        logDate={values.date}
                                        disabled={hasDeleteRequest}
                                        timeConstraints={{
                                          hours: { min: 0, max: 23, step: 1 },
                                          minutes: {
                                            min: 0,
                                            max: 45,
                                            step: 15,
                                          },
                                          seconds: { min: 0, max: 0, step: 0 },
                                          milliseconds: {
                                            min: 0,
                                            max: 0,
                                            step: 0,
                                          },
                                        }}
                                        onChange={(value) => {
                                          if (isLogging) {
                                            handleStartTime(value);
                                            handleStartTimeChange(
                                              value,
                                              values,
                                              setFieldValue
                                            );
                                            if (selectedTime === "")
                                              setSelectedTime("start");
                                          }
                                        }}
                                        renderInput={function (
                                          props,
                                          openCalendar
                                        ) {
                                          return (
                                            <div
                                              style={{
                                                marginTop: "5px",
                                                display: "grid",
                                                gridTemplateColumns: `${size.width <= 320 ? size.width - 90 :
                                                  size.width <= 360 ? size.width - 90 :
                                                    size.width <= 375 ? size.width - 90 :
                                                      size.width <= 414 ? size.width - 90 : 324
                                                  }px`,
                                                gridTemplateRows: "auto auto",
                                              }}
                                            >
                                              <TextField
                                                {...props}
                                                label="Start time (Optional)"
                                                variant="outlined"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                InputProps={{
                                                  readOnly: isLogging
                                                    ? false
                                                    : true,
                                                }}
                                                disabled={!isLogging}
                                              />
                                              {isLogging ? (
                                                <Button
                                                  disabled={
                                                    selectedTime === "end"
                                                      ? true
                                                      : false
                                                  }
                                                  justIcon
                                                  round
                                                  size="lg"
                                                  color="transparent"
                                                  style={{
                                                    margin: `-60px 0 0 ${size.width <= 320 ? 180 :
                                                      size.width <= 360 ? 220 :
                                                        size.width <= 375 ? 235 :
                                                          size.width <= 414 ? 274 : 274}px`,
                                                  }}
                                                  onClick={openCalendar}
                                                >
                                                  <Icon
                                                    style={{
                                                      color: grayColor[1],
                                                    }}
                                                  >
                                                    schedule
                                                  </Icon>
                                                </Button>
                                              ) : null}
                                            </div>
                                          );
                                        }}
                                      />
                                    </ThemeProvider>
                                  </div>

                                  <ThemeProvider
                                    theme={
                                      isLogging
                                        ? defaultMaterialTheme
                                        : viewLogMaterialTheme
                                    }
                                  >
                                    <FormHelperText>
                                      <div
                                        style={{
                                          margin: "0 0 10px 10px",
                                          color: primaryColor[0],
                                        }}
                                      >
                                        {startExceeds.lesserOrGreater ===
                                          "lesser" && startExceeds !== ""
                                          ? `Note: Start time day precedes expense date day (${startExceeds.dateString}).`
                                          : startExceeds.lesserOrGreater ===
                                            "greater" && startExceeds !== ""
                                            ? `Note: Start time day exceeds expense date day (${startExceeds.dateString}).`
                                            : ""}
                                      </div>
                                    </FormHelperText>
                                  </ThemeProvider>

                                  {/* <ThemeProvider
                                      theme={
                                        isLogging
                                          ? defaultMaterialTheme
                                          : viewLogMaterialTheme
                                      }
                                    >
                                      <FormHelperText>
                                        <div
                                          style={{
                                            margin: "-10px 0 10px 10px",
                                            color: primaryColor[0],
                                          }}
                                        >
                                          {errors.startTime && touched.startTime
                                            ? errors.startTime
                                            : null}
                                        </div>
                                      </FormHelperText>
                                    </ThemeProvider> */}

                                  <div style={{ margin: "25px 0 40px 0" }}>
                                    <ThemeProvider
                                      theme={
                                        isLogging
                                          ? defaultMaterialTheme
                                          : viewLogMaterialTheme
                                      }
                                    >
                                      <Datetime
                                        inputProps={{
                                          name: "endTime",
                                          id: "endTime",
                                          placeholder: "",
                                        }}
                                        isValidDate={enableDates}
                                        viewMode="time"
                                        dateFormat=""
                                        timeFormat={true}
                                        value={values.endTime}
                                        logDate={values.date}
                                        disabled={hasDeleteRequest}
                                        timeConstraints={{
                                          minutes: {
                                            min: 0,
                                            max: 45,
                                            step: 15,
                                          },
                                          seconds: { min: 0, max: 0, step: 0 },
                                          milliseconds: {
                                            min: 0,
                                            max: 0,
                                            step: 0,
                                          },
                                        }}
                                        onChange={(value) => {
                                          if (isLogging) {
                                            handleEndTime(value);
                                            handleEndTimeChange(
                                              value,
                                              values,
                                              setFieldValue
                                            );
                                            if (selectedTime === "")
                                              setSelectedTime("end");
                                          }
                                        }}
                                        renderInput={function (
                                          props,
                                          openCalendar
                                        ) {
                                          return (
                                            <div
                                              style={{
                                                // style.step3TimeRenderInput480
                                                marginTop: "5px",
                                                display: "grid",
                                                gridTemplateColumns: `${size.width <= 320 ? size.width - 90 :
                                                  size.width <= 360 ? size.width - 90 :
                                                    size.width <= 375 ? size.width - 90 :
                                                      size.width <= 414 ? size.width - 90 : 324
                                                  }px`,
                                                gridTemplateRows: "auto auto",
                                              }}
                                            >
                                              <TextField
                                                {...props}
                                                label="End time (Optional)"
                                                variant="outlined"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                InputProps={{
                                                  readOnly: isLogging
                                                    ? false
                                                    : true,
                                                }}
                                                disabled={!isLogging}
                                              />
                                              {isLogging ? (
                                                <Button
                                                  disabled={
                                                    selectedTime === "start"
                                                      ? true
                                                      : false
                                                  }
                                                  justIcon
                                                  round
                                                  size="lg"
                                                  color="transparent"
                                                  style={{
                                                    margin: `-60px 0 0 ${size.width <= 320 ? 180 :
                                                      size.width <= 360 ? 220 :
                                                        size.width <= 375 ? 235 :
                                                          size.width <= 414 ? 274 : 274}px`,
                                                  }}
                                                  onClick={openCalendar}
                                                >
                                                  <Icon
                                                    style={{
                                                      color: grayColor[1],
                                                    }}
                                                  >
                                                    schedule
                                                  </Icon>
                                                </Button>
                                              ) : null}
                                            </div>
                                          );
                                        }}
                                      />
                                    </ThemeProvider>
                                  </div>
                                  {/* <ThemeProvider
                                      theme={
                                        isLogging
                                          ? defaultMaterialTheme
                                          : viewLogMaterialTheme
                                      }
                                    >
                                      <FormHelperText>
                                        <div
                                          style={{
                                            margin: "-10px 0 0 10px",
                                            color: primaryColor[0],
                                          }}
                                        >
                                          {errors.endTime && touched.endTime
                                            ? errors.endTime
                                            : null}
                                        </div>
                                      </FormHelperText>
                                    </ThemeProvider> */}
                                  {values.billingType === "HOURLY" ? (
                                    <div style={style.step3billing480}>
                                      <ThemeProvider theme={sameTheme}>
                                        <TextField
                                          name="amount"
                                          label="Amount"
                                          variant="outlined"
                                          onChange={(e) => {
                                            e.target.value = values.amount;
                                          }}
                                          value={formatMoney(
                                            formatNumber_(
                                              removeComma(values.amount)
                                            )
                                          )}
                                          disabled={hasDeleteRequest}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          InputProps={{
                                            readOnly: isLogging ? false : true,
                                          }}
                                        />
                                      </ThemeProvider>
                                    </div>
                                  ) : null}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div />
                          <Typography
                            style={{
                              textAlign: "center", fontSize: '16px', color: primaryColor[0],
                              display: (
                                isLogging &&
                                (
                                  (client.length === 0) ||
                                  (case_ && case_.length === 0) ||
                                  (values.activityL1.length === 0 || values.activityL2.includes("?") || values.activityL3.includes("?")) ||
                                  values.activityL2.includes("?") ||
                                  values.activityL3.includes("?") ||
                                  (values.billingType.length === 0) ||
                                  (values.billingType === "FIXED" && (formatNumber_(values.amount) === "0.00" || formatNumber_(values.amount) === 0)) ||
                                  (values.billingType === "HOURLY" && (formatNumber_(values.hourlyRate) === "0.00" || formatNumber_(values.hourlyRate) === 0)) ||
                                  (values.billingType === "HOURLY" && (formatNumber_(values.hours) === "0.00" || formatNumber_(values.hours) === 0))
                                )

                              )
                                ? 'block' : 'none'
                            }}>
                            <em>* Required</em>
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        {isLogging ? (
                          <>
                            <Button
                              style={{ width: "60%" }}
                              variant="contained"
                              color="primary"
                              size="md"
                              disabled={
                                isSubmitting ||
                                (!isSubmitting &&
                                  (values.justInitialized) ||
                                  (
                                    isLogging &&
                                    (
                                      (client.length === 0) ||
                                      (case_ && case_.length === 0) ||
                                      (values.activityL1.length === 0 || values.activityL2.includes("?") || values.activityL3.includes("?")) ||
                                      values.activityL2.includes("?") ||
                                      values.activityL3.includes("?") ||
                                      (values.billingType.length === 0) ||
                                      (values.billingType === "FIXED" && (formatNumber_(values.amount) === "0.00" || formatNumber_(values.amount) === 0)) ||
                                      (values.billingType === "HOURLY" && (formatNumber_(values.hourlyRate) === "0.00" || formatNumber_(values.hourlyRate) === 0)) ||
                                      (values.billingType === "HOURLY" && (formatNumber_(values.hours) === "0.00" || formatNumber_(values.hours) === 0))
                                    )
                                  )
                                )
                              }
                              type="submit"
                            >
                              Save
                                </Button>
                            <Button
                              style={{ width: "40%" }}
                              variant="outlined"
                              color="primary"
                              size="md"
                              disabled={isSubmitting || hasDeleteRequest}
                              onClick={(e) => {
                                e.preventDefault()
                                createUserLog(props, "LOG_WORK", "Cancelled Create Work Log", "clicked CANCEL")
                                props.history.push("/app/dashboard")
                              }}
                            >
                              Cancel
                                </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              style={{ width: "60%" }}
                              variant="contained"
                              color="primary"
                              // href="/app/logservices"
                              size="sm"
                              disabled={hasDeleteRequest}
                              onClick={(e) => {
                                e.preventDefault()
                                createUserLog(props, "LOG_WORK", "Open another Work log", "clicked ANOTHER")
                                props.history.push("/app/logservices")
                              }}
                            >
                              ANOTHER
                                </Button>
                            <Button
                              disabled={hasDeleteRequest}
                              style={{ width: "40%" }}
                              variant="outlined"
                              color="primary"
                              size="md"
                              onClick={(e) => {
                                e.preventDefault()
                                createUserLog(props, "LOG_WORK", "Back to DASHBOARD", "clicked DONE")
                                props.history.push("/app/dashboard")
                              }}
                            >
                              Done
                                </Button>
                          </>
                        )}
                      </ThemeProvider>
                    </DialogActions>
                  </ThemeProvider>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 600}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
                  </div>
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ margin: `-42px  ${size.width <= 414 ? -10 : -20}px 0 0` }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 600}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                marginLeft: "-10px",
                width: `${size.width <= 414 ? size.width - 30 : 570
                  }px`,
                overflow: "auto",
                height: `${size.height - 150}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 0 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Work Log Saved.
            </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <>
                  <Button
                    style={{ width: "60%" }}
                    variant="contained"
                    color="primary"
                    size="sm"
                    disabled={hasDeleteRequest}
                    onClick={(e) => {
                      e.preventDefault()
                      createUserLog(props, "LOG_WORK", "Open another Work log", "clicked ANOTHER")
                      setOpenNotification(false)
                      setReload(!reload)
                    }}
                  >
                    ANOTHER
                                </Button>
                  <Button
                    disabled={hasDeleteRequest}
                    style={{ width: "40%" }}
                    variant="outlined"
                    color="primary"
                    size="md"
                    onClick={(e) => {
                      e.preventDefault()
                      createUserLog(props, "LOG_WORK (Work Log Saved)", "Back to DASHBOARD", "clicked DONE")
                      props.history.push("/app/dashboard")
                    }}
                  >
                    Done
                                </Button>
                </>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <CreateClient
        authUser={props.authUser}
        client={props.client}
        _member={member_}
        _client={props.client}
        Transition={Transition}
        openClient={openClient}
        handleCloseClient={handleCloseClient}
        scroll={scroll}
        getClients={getClients}
        initialValues={initialValues}
        currentClient={currentClient}
        changeClient={changeClient}
      />

      <CreateCase
        authUser={props.authUser}
        client={props.client}
        _member={member_}
        _client={props.client}
        Transition={Transition}
        openCase={openCase}
        handleCloseCase={handleCloseCase}
        scroll={scroll}
        CASES_BY_CLIENT_QUERY={CASES_BY_CLIENT_QUERY}
        initialValues={initialValues}
        currentClient={currentClient}
        changeCase={changeCase}
        getClients={getClients}
      />

      <LogCountMessage
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={`For your convenience we will auto-fill your logs with the previous values you entered. You can always edit these auto-filled values. `}
        messageDialogTitle={"Message"}
        messageLabelBtn={"Close"}
      />
    </>
  );
}

export const MEMBER_CHECK = gql`
  query memberCheck($email: String) {
          memberCheck(email: $email) {
          id
      email
      displayName
      openLogWorkCounter
      latestClient {
          id
        firstName
        lastName
        company
        hourlyRateService
      }
      latestCase {
          id
        caseCode
        caseTitle
        billingType
        billingTypeService
        fixedRateService
      }
      serviceRequest {
          id
        member {
          id
          email
        }
        date
        dateServed
        dateCancelled
        requestType
        notes
        status
      }
    }
  }
`;

export const CREATE_ACTIVITY_LOG = gql`
  mutation CreateActivityLog(
    $date: DateTime!
    $billingType: String
    $fixedRate: String
    $hourlyRate: String
    $hours: String
    $amount: String!
    $activityLogType: String!
    $activityLogSubType: String!
    $activityLogSubSubType: String
    $notes: String
    $startTime: DateTime
    $endTime: DateTime
    $caseId: ID!
    $memberId: ID!
    $clientId: ID!
    $actionDate: DateTime
    $action: String
    $parentId: ID
    $status: String
    $ord: Int
  ) {
          createActivityLog(
            date: $date
      billingType: $billingType
      fixedRate: $fixedRate
      hourlyRate: $hourlyRate
      hours: $hours
      amount: $amount
      activityLogType: $activityLogType
      activityLogSubType: $activityLogSubType
      activityLogSubSubType: $activityLogSubSubType
      notes: $notes
      startTime: $startTime
      endTime: $endTime
      caseId: $caseId
      memberId: $memberId
      clientId: $clientId
      actionDate: $actionDate
      action: $action
      parentId: $parentId
      status: $status
      ord: $ord
    ) {
          id
        }
  }
`;

export const CLIENTS_BY_EMAIL_QUERY = gql`
  query ClientsByEmail($email: String!) {
          clientsByEmail(email: $email) {
          id
      lastName
      firstName
      company
      hourlyRateService
      parentId
      status
      ord
      latestCase {
          id
        caseCode
        caseTitle
        billingType
        billingTypeService
        billingTypeService
        fixedRateService
        parentId
        status
        ord
      }
      cases {
          id
        caseCode
        caseTitle
        billingType
        billingTypeService
        billingTypeService
        fixedRateService
        parentId
        status
        ord
      }
    }
  }
`;

export const CASES_BY_CLIENT_QUERY = gql`
  query CasesByClient($clientId: ID!) {
          casesByClient(clientId: $clientId) {
          id
      caseCode
      caseTitle
      billingType
      billingTypeService
      fixedRateService
      parentId
      status
      ord
    }
  }
`;

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(LogService)))
    )
  )
);
