import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import logotype from 'assets/img/byn-logotype-oval.png'

import {
  //  withEmailVerification, 
  withAuthentication
} from './utils/Session';
import useWindowSize from 'utils/useWindowSize';

import { withFirebase } from './utils/Firebase';
import {
  primaryColor,
} from 'assets/jss/material-kit-pro-react.js';

import signupPageStyle from 'assets/jss/material-kit-pro-react/views/signupPageStyle.js';
import { Helmet } from "react-helmet";
import { ThemeProvider } from '@material-ui/styles';
import defaultMaterialTheme from "utils/Themes/homePageMaterialTheme"
import { createUserLog } from "utils/CreateUserLog";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

    
console.log("............V I E W NNP..................");
console.log(process.env.NODE_ENV);
console.log("process.env.REACT_APP_endpoint");
console.log(process.env.REACT_APP_endpoint);

const httpLink = new createUploadLink({
  uri:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_endpoint
      : process.env.REACT_APP_prodEndpoint,
  request: (operation) => {
    operation.setContext({
      fetchOptions: {
        credentials: "include",
      },
      headers: null,
    });
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log("*****************************");
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log("*****************************");
    console.log(`[Network error]: ${networkError}`);
  }
});

const link = ApolloLink.from([errorLink, httpLink]);
// console.log("link *****************************");
// console.log(link);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
  // Provide some optional constructor fields
  name: "react-web-client",
  version: "1.3",
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  shouldBatch: true,
});

// console.log("client *****************************");
// console.log(link);

const EsqPaySignup = (props) => {
  let authUser = {
    email: 'system@byn.com'
  }
  const [details, setDetails] = useState(null);
  useEffect(() => {


    fetch(
      `https://geolocation-db.com/json/${process.env.GEO_LOCATION_KEY}`
    )
      .then(response => response.json())
      .then(data => {
        setDetails(data)
        createUserLog({ authUser, client }, "DASHBOARD (not logged-in)", "Dashboard view", `${data.city}, ${data.state}, ${data.country_name} (${data.country_code}) ${data.IPv4}`)
      })
      .catch(error => {
        createUserLog({ authUser, client }, "DASHBOARD (not logged-in)", "Dashboard view", "")
        console.log("DASHBOARD (not yet signed-in) error", error)
      })

  }, [])

  const size = useWindowSize()
  const { classes } = props;
  return (
    <React.Fragment>
      <Helmet>
        <title>BillYouNow</title>
        <meta name="description" content="BillYouNow is a mobile app that will help lawyers maximize income, tailored to the speciﬁc needs of Philippine legal professionals." />
        <meta name="keywords" content="lawyers,legal,professionals,lawyer,legal fees,attorney fees and charges,billing,bill,you,now" />
      </Helmet>
      <div className={props.classes.container}>
        <div className={classNames(props.classes.main, props.classes.mainRaised)}>
          <div className={props.classes.brand}>
            {/* <img style={{ width: '250px', marginBottom: '50px' }} src={logotype} alt="logo" /> */}
            <img style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              height: `${size.width <= 320 ? 80 :
                size.width <= 365 ? 90 :
                  size.width <= 375 ? 100 :
                    115
                }px`,
              width: `${size.width <= 320 ? 240 :
                size.width <= 365 ? 270 :
                  size.width <= 375 ? 300 :
                    345
                }px`,
              marginTop: `${size.width <= 320 ? -35 :
                size.width <= 365 ? -30 :
                  size.width <= 375 ? 20 :
                    40
                }px`,
              marginBottom: '50px'
            }}
              src={logotype} alt="logo" />
            <div className={classes.container}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <Button variant="text" style={{ width: "100%", color: primaryColor[1], textAlign: "center" }}
                  // href="/auth/signupweb"
                  onClick={(e) => {
                    e.preventDefault()
                    createUserLog({ authUser, client }, "DASHBOARD (not logged-in)", "clicked Register", details ? `${details.city}, ${details.state}, ${details.country_name}(${details.country_code}) ${details.IPv4}` : "")
                    props.history.push('/auth/signupweb')
                  }}
                >
                  Register
                  </Button>
                <Button variant="text" style={{ width: "100%", color: primaryColor[1], textAlign: "center" }}
                  // href="/auth/signin"
                  onClick={(e) => {
                    e.preventDefault()
                    createUserLog({ authUser, client }, "DASHBOARD (not logged-in)", "clicked Log-in", details ? `${details.city}, ${details.state}, ${details.country_name}(${details.country_code}) ${details.IPv4}` : "")
                    props.history.push('/auth/signin')
                  }}
                >
                  Log In
                  </Button>

              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default withStyles(signupPageStyle)(
  withFirebase(withAuthentication(EsqPaySignup))
);
