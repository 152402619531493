import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useWindowSize from 'utils/useWindowSize';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const QuickStartGuide = (props) => {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const size = useWindowSize()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.container}
      style={{ marginTop: "20px", overflow: "auto", height: `${size.height - (size.width <= 414 ? 160 : 160)}px`, width: `${size.width <= 414 ? size.width - 30 : 570}px` }}
    >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            Use BillYouNow Instantly
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            BillYouNow logs your work so you can automatically generate a report to bill your clients.
            You can log two types of activities:
            <ol>
              <li><a href="logservices">Log Work</a> is for recording billable activities like attending hearings or writing pleadings.</li>
              <li><a href="logexpenses">Log Expense</a> is for recording billable extra expenses like docket and representation fees.</li>
            </ol>
            It's that easy. Try it now! Click the round BillYouNow logo (upper right), then pick <a href="logservices">Log Work</a> or <a href="logexpenses">Log Expense</a>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            What are Logs?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Logs are the heart of BillYouNow. They remember details of your billable work and expenses. Every log remembers the Client and the Case. Work logs remember the date and hours you spent. Expense logs remember the type of billable expense.
          <br /><br />BillYouNow uses all this information to auto-create detailed reports for you. No need to use formulas in spreadsheets. BillYouNow just remembers it for you.
          <br /><br />To try it now, click the round BillYouNow logo (upper right), then pick <a href="logservices">Log Work</a> or <a href="logexpenses">Log Expense</a>.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I log an activity?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In Log Work, choose client, case, date and category of activity, bill type; enter fixed amount or rate and billable time then click "Save."
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I log an expense?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In Log Expense, choose client, case, date and category of expense; enter fixed amount or rate and billable time then click "Save."
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I view my logs?
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In View Log, choose client, case, period (month, year or custom) then click "Go."
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I edit a log?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In View Log, choose corresponding client and case, view the log through the "View Log" page. Click the ellipsis on the log line then click "Edit." Follow succeeding steps.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I delete a log?
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In View Log, choose corresponding client and case and view the log through the "View Log" page. Click the ellipsis on the log line then click "Delete." Follow succeeding steps.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I add a client?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can add a client in three ways:
            <ul>
              <li>
                In the left menu, click Clients and Cases then click the Add Client butto. Fill the Add New Client form. To avoid adding the same Client twice, we suggest you do a search first.
              </li>
              <li>
                If logging work, just click Log Work and click Client dropdown &gt; Add New Client and fill the Add New Client form.
              </li>
              <li>
                If logging an expense, go straight to Log Expense and click Client &gt; Add New Client dropdown in the Client and fill the Add New Client form.
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I add a case?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can add a case in three ways:
            <ul>
              <li>
                In the left menu, click Clients and Cases, find the correct Client card. Click View then the Add Case button. Fill the form.
              </li>
              <li>
                If logging work, just click Log Work then Case dropdown &gt; Add New Case. Fill the form.
              </li>
              <li>
                If logging an expense, click Log Expense then Case dropdown &gt; Add New Case. Fill the form.
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I delete a client?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In the left menu, click Clients and Cases, find the right Client card and click the Delete button in that client's card. Follow succeeding steps.
            <br /><br />Deleting a client deletes all that client's cases and all logs in those cases.
            <br /><br /><i>Be careful. DELETE is permanent. Once deleted, your records cannot be recovered.</i> This is in compliance with data privacy regulation.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I delete a case?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In the left menu, click Clients and Cases, find the client's card, click View. Find the case you want to delete and click the Delete button.
          <br /><br />Deleting a case deletes all logs in that case. <i>Be careful. DELETE is permanent. Once deleted, your records cannot be recovered.</i> This is in compliance with data privacy regulation.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I download a client log summary?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Click View Log &gt; Download PDF.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I download all my data?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In the left menu, click Account and Profile &gt; Data and Account tab. Click Request to download data.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I delete all my data?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In the left menu, click Account and Profile &gt; Data and Account tab. Click Request to delete account.
            <br /><br /><i>Be careful. This will delete your account and all your records. DELETE is permanent. Once deleted, your account and your logs cannot be recovered.</i> This is in compliance with data privacy regulation.
          </Typography>
        </AccordionDetails>
      </Accordion>

    </div >
  )
}

export default QuickStartGuide
