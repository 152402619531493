
import { createMuiTheme } from "@material-ui/core";

const itemChangedMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#C62626"
    },
    // secondary: { main: "#22BF19" },
    // grey: { main: "#22BF19" }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        color: "#C62626",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#C62626",
          borderWidth: "0.25px"
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#C62626",
          borderWidth: "2px",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "#C62626"
            // borderWidth: "1px",
          }
        },
        "&$focused $notchedOutline": {
          borderColor: "#C62626",
          borderWidth: "2.75px"
        }
      }
    },
    MuiFormLabel: {
      root: {
        // "&$focused": {
        color: "#C62626",
        fontSize: "22px",
        backgroundColor: "#fff",
        margin: "-2px 0 0 -5px",
        padding: "0 10px 0 10px",

        // }

      }
    }
  }
});

export default itemChangedMaterialTheme