import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useWindowSize from 'utils/useWindowSize';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Faqs = (props) => {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const size = useWindowSize()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.container}
    style={{ marginTop: "20px", overflow: "auto", height: `${size.height - (size.width <= 414 ? 160 : 160)}px`, width: `${size.width <= 414 ? size.width - 30 : 570}px` }}
  >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I log an activity?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In "Log Work" page, choose client, case, date and category of activity, bill type; enter fixed amount or rate and billable time then click "Save."
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I log an expense?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In "Log Expense" page, choose client, case, date and category of expense; enter fixed amount or rate and billable time then click "Save."
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I edit a log?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Choose corresponding client and case, view the log through the "View Log" page. Click the ellipsis on the log line then click "Edit." Follow succeeding steps.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I delete a log?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Choose corresponding client and case and view the log through the "View Log" page. Click the ellipsis on the log line then click "Delete." Follow succeeding steps.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I view my logs?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In "View Log" page, choose client, case, period (month, year or custom) then click "Go."
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I add a client?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can add a client: (1) in the "Clients and Cases" menu by clicking "Add Client;" (2) in the "Log Work" page by clicking the "Add New Client" dropdown in the "Client" field; or, (3) in the "Log Expense" by clicking the "Add New Client" dropdown in the "Client" field. Follow succeeding steps.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I add a case?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can add a case: (1) in the "Clients and Cases" menu by viewing a client card and clicking "Add Case;" (2) in the "Log Work" page by clicking the "Add New Case" dropdown in the "Case" field, or, (3) in the "Log Expense" by clicking the "Add New Case" dropdown in the "Case" field. Follow succeeding steps.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I delete a client?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can delete a client in the "Clients and Cases" menu by clicking "Delete" in that client's card. Follow succeeding steps. Deleting a client deletes all that client's cases and all logs in those cases. Deleted items cannot be recovered.How do I delete a client?
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I delete a case?

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can delete a case in the "Clients and Cases" menu by viewing the corresponding client's card and clicking "Delete" in that case's card. Follow succeeding steps. Deleting a case deletes all logs in that case. Deleted items cannot be recovered.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I download a client log summary?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            View a client's logs through the "View Log" page and click "Download PDF."
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I download all my data?

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In "Account & Profile" page, open "Data" folder and click "Download Data." Follow succeeding steps.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            How do I delete all my data?
            
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In "Account & Profile" page, open "Data" folder and click "Delete Data." Follow succeeding steps.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Faqs
