import React from 'react';

import classNames from 'classnames';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Button from 'components/CustomButtons/Button.js';
import Email from '@material-ui/icons/Email';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Footer from 'components/Footer/Footer.js';
import Parallax from 'components/Parallax/Parallax.js';

import AuthUserContext from './context';
import { withFirebase } from 'utils/Firebase';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    componentWillMount = async () => {
      console.log('start withEmailVerification.componentWillMount');

      console.log('end withEmailVerification.componentWillMount');
    };

    componentDidMount = async () => {
      console.log('start withEmailVerification.componentDidMount');

      console.log('end withEmailVerification.componentDidMount');
    };

    componentDidUpdate = async () => {
      console.log('start withEmailVerification.componentDidUpdate');

      console.log('end withEmailVerification.componentDidUpdate');
    };

    render() {
      console.log('start withEmailVerification.render');
      const { classes } = this.props;
      console.log('end withEmailVerification.render');
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <React.Fragment>
                <Parallax


                  // image={require('assets/img/bg10.jpg')}
                  className={classes.parallax}
                >
                  <div className={classes.container}>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={10} md={10}>
                        <Card
                          className={classNames(
                            classes.mainRaised
                          )}
                        >
                          <CardBody>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={5} md={5}>
                                {this.state.isSent ? (
                                  <React.Fragment>
                                    <InfoArea
                                      className={classes.infoArea}
                                      title="E-Mail confirmation sent"
                                      description="Check your E-Mails (Spam folder included) for a confirmation E-Mail."
                                      icon={Email}
                                      iconColor="primary"
                                    />
                                    <InfoArea
                                      className={classes.infoArea}
                                      description="Refresh this page once you confirmed your E-Mail."
                                      icon={Email}
                                      iconColor="primary"
                                    />
                                  </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                      <InfoArea
                                        className={classes.infoArea}
                                        title="Verify your E-Mail"
                                        description="Check your E-Mails (Spam folder included) for a confirmation E-Mail."
                                        icon={Email}
                                        iconColor="primary"
                                      />
                                      <InfoArea
                                        className={classes.infoArea}
                                        title="...didn't receive the email?"
                                        description="Send another confirmation E-Mail by clicking the button on the right."
                                        icon={Email}
                                        iconColor="primary"
                                      />
                                    </React.Fragment>
                                  )}
                              </GridItem>
                              <GridItem xs={12} sm={5} md={5}>
                                <GridContainer spacing={24} justify="center">
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />
                                  <GridItem xs={12} sm={12} />

                                  <div className={classes.textCenter}>
                                    {this.state.isSent ? (
                                      <Button
                                        round
                                        onClick={this.onSendEmailVerification}
                                        disabled={this.state.isSent}
                                        color="default"
                                        size="sm"
                                      >
                                        Resend a confirmation E-Mail
                                      </Button>
                                    ) : (
                                        <Button
                                          round
                                          onClick={this.onSendEmailVerification}
                                          disabled={this.state.isSent}
                                          color="danger"
                                          size="sm"
                                        >
                                          Resend a confirmation E-Mail
                                        </Button>
                                      )}
                                  </div>
                                </GridContainer>
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </div>
                </Parallax>

                <Footer
                  className={classes.footer}
                  content={
                    <div>
                      <div className={classes.left}>
                        <small>
                          <span className="Footer-text">Built by</span>{' '}
                          <a target="_blank" rel="noopener noreferrer" href="">
                            Pellucidum Incorporated
                          </a>
                          <span> with &hearts;</span>
                        </small>
                      </div>
                      <div className={classes.pullCenter}>
                        <small>
                          <span>
                            1601 West Tower, Philippine Stock Exchange Centre
                            Exchange Road, Ortigas Center Pasig City 1605,
                            PHILIPPINES
                          </span>
                          <span className="Footer-text">.</span>
                        </small>
                      </div>
                    </div>
                  }
                />
              </React.Fragment>
            ) : (
                <Component {...this.props} />
              )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
