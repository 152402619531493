import React from 'react';
import TextField from '@material-ui/core/TextField';

const TextFieldComponent = (props) => {
  const { setOpen } = props;
  return (
    <TextField {...props}
      variant="outlined"
      inputProps={{
        readOnly: true,
      }}
      onClick={(e) => {
        e.preventDefault()
        setOpen(true)
      }}
    />
  )
}

export default TextFieldComponent;