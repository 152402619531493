// import { lazy } from 'react';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Lock from '@material-ui/icons/Lock';
import TransitEnterExit from '@material-ui/icons/TransitEnterexit';

// import Fingerprint from '@material-ui/icons/Fingerprint';
import PersonAdd from '@material-ui/icons/PersonAdd';

import SignInPage from 'screens/SignIn';
import PasswordForgot from 'screens/PasswordForget/PasswordForgot';
import SignUpWeb from 'screens/SignUpWeb';
import EsqPaySignup from 'EsqPaySignUp';

// const SignInPage = lazy(() => import('screens/SignIn'));
// const PasswordForget = lazy(() => import('screens/PasswordForget'));
// const SignUpWeb = lazy(() => import('screens/SignUpWeb'));
// const EsqPaySignup = lazy(() => import('EsqPaySignUp'));

var dashRoutes = [
  {
    path: '/dashboard',
    name: 'Home',
    icon: DashboardIcon,
    component: EsqPaySignup,
    layout: '/auth'
  },
  {
    path: '/signupweb',
    name: 'Register',
    icon: PersonAdd,
    component: SignUpWeb,
    layout: '/auth'
  },
  {
    path: '/signin',
    name: 'Log In',
    icon: TransitEnterExit,
    component: SignInPage,
    layout: '/auth'
  },
  {
    path: '/passwordforgot',
    name: 'Forgot Password',
    icon: Lock,
    component: PasswordForgot,
    layout: '/auth'
  }
];
export default dashRoutes;
