import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  // const [openNotification, setOpenNotification] = React.useState(null);
  // const handleClickNotification = event => {
  //   if (openNotification && openNotification.contains(event.target)) {
  //     setOpenNotification(null);
  //   } else {
  //     setOpenNotification(event.currentTarget);
  //   }
  // };
  // const handleCloseNotification = () => {
  //   setOpenNotification(null);
  // };
  // const [openProfile, setOpenProfile] = React.useState(null);
  // const handleClickProfile = event => {
  //   if (openProfile && openProfile.contains(event.target)) {
  //     setOpenProfile(null);
  //   } else {
  //     setOpenProfile(event.currentTarget);
  //   }
  // };
  // const handleCloseProfile = () => {
  //   setOpenProfile(null);
  // };
  const classes = useStyles();
  const { rtlActive } = props;
  // const searchButton =
  //   classes.top +
  //   ' ' +
  //   classes.searchButton +
  //   ' ' +
  //   classNames({
  //     [classes.searchRTL]: rtlActive
  //   });
  // const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
  //   [classes.dropdownItemRTL]: rtlActive
  // });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  // const managerClasses = classNames({
  //   [classes.managerClasses]: true
  // });
  return <div className={wrapper}></div>;
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
