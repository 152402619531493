
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import useWindowSize from 'utils/useWindowSize';

import Waiting from "utils/Waiting";
import { Formik, Form } from "formik";
// import { useMediaQuery } from 'react-responsive';

import FormHelperText from "@material-ui/core/FormHelperText";

import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// import { gql } from "apollo-boost";
import circlelogo from 'assets/img/byn-logo-circle.png';

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";

import { UPDATE_MEMBER_MUTATION } from 'utils/Graphql/mutations'

import "utils/react-datetime/css/react-datetime.css";

import { ThemeProvider } from "@material-ui/styles";


import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import sameTheme from "utils/Themes/viewLogMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import MessageDialog from 'utils/MessageDialog';
import Transition from 'utils/Transition';
import {
  withAuthentication,
} from "utils/Session";
import { createUserLog } from "utils/CreateUserLog";


const style = {

  step3Notes480: {
    marginLeft: "0px",
    marginTop: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
};

const initialValues = {
  oldPhoneNo: "",
  newPhoneNo: ""
};

function PhoneNumber(props) {
  const { classes, client, open, onClose, memberData, reFetch } = props;

  const [hasDeleteRequest] = useState(false);
  const [isOpenWaiting] = useState(false)
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block"
      document.getElementById("mainScreen").style.opacity = 0.3
    } else {
      if (document && document.getElementById("waiting") && document.getElementById("mainScreen")) {
        document.getElementById("waiting").style.display = "none"
        document.getElementById("mainScreen").style.opacity = 1
      }
    }
  }, [isOpenWaiting])

  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const size = useWindowSize()
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px")
  // const [closeRightMargin, setCloseRightMargin] = useState("0px")

  const [setError] = useState(null)

  useEffect(() => {
    initialValues.oldPhoneNo = memberData ? memberData.phoneNo : ''
    initialValues.newPhoneNo = ''
  }, [memberData])

  const handleCloseMessageDialog = () => {
    reFetch()
    setOpenMessageDialog(false)
    onClose()
    // props.history.push('/profile');
  }

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    phoneNumberUpdate(values)
      .then(response => {
        createUserLog(props, "PHONE NUMBER", "Changed phone number", "")
        console.log(response)
        // handleSuccess()
        // setOpen(false)
        // reFetch()
        setOpenMessageDialog(true)
        resetForm({ values: { newPhoneNo: '' } })
      })
      .catch(error => {
        console.log(error)
        setError(error)
        setSubmitting(false)
      })
      .finally(() => {
        resetForm({ values: '' })
      })
  }

  const phoneNumberUpdate = async (values) => {
    console.log(client)
    const { newPhoneNo } = values
    try {
      let data = await client.mutate({
        mutation: UPDATE_MEMBER_MUTATION,
        variables: {
          memberId: memberData.id,
          email: memberData.email,
          phoneNo: newPhoneNo,
        }
      })
      console.log(data)
      return data
    }
    catch (e) {
      console.log(e)
      throw e
    }
  }

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog
        }}
        open={open}
        onClose={onClose}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>

              {size.width <= 320 ?
                <>
                  <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                    Phone
                      </div>
                  <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                    Number
                      </div>
                </>
                :
                <div style={{ margin: `10px 0 0 0`, color: primaryColor[0], fontSize: '30px', fontWeight: '400', textAlign: 'left' }}>
                  Phone Number
                    </div>
              }
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                {size.width <= 320 ?
                  <Button
                    style={{ margin: `-79px ${size.width <= 414 ? -10 : -20}px 0 0 ` }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img style={{ width: '50px' }} src={circlelogo} alt="billyounow.com" />
                  </Button>
                  :
                  <Button
                    style={{ margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 ` }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img style={{ width: '50px' }} src={circlelogo} alt="billyounow.com" />
                  </Button>
                }

              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>

          <div id="waiting" style={{ marginBottom: '70px', marginTop: 20 }}>
            <Waiting
              Transition={Transition}
              isOpenWaiting={isOpenWaiting}
            ></Waiting>
          </div>
          {hasDeleteRequest ?
            <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
              <Typography>
                You have a pending delete request. Cancel the delete request to log an expense.
              </Typography>
            </div>
            : ''}
          <div id="mainScreen" style={{ marginTop: '0px' }}>
            <div
              className={classes.container}
              style={{ width: `${size.width <= 414 ? size.width - 30 : 370}px` }}
            >
              <Formik
                initialValues={initialValues}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  // setSubmitting(true)
                  handleSubmit(values, setSubmitting, resetForm)
                }}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  setSubmitting,
                  handleSubmit,
                  touched,
                  errors
                }) => (
                  <Form mode="themed" >
                    <div
                      className={classes.container}
                      style={{
                        overflow: "auto",
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        height: `${size.width <= 320 ? size.height - 220 : size.height - 195}px`
                      }}
                    >
                      <ThemeProvider theme={sameTheme}>
                        <TextField
                          style={{ margin: "10px 0 0 0" }}
                          fullWidth
                          name='oldPhoneNo'
                          label='Old'
                          variant="outlined"
                          disabled={hasDeleteRequest}
                          onChange={(event) => {
                            values.oldPhoneNo = event.target.value.toUpperCase()
                            setFieldValue('oldPhoneNo', event.target.value.toUpperCase())
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            readOnly: true
                          }}
                          value={values.oldPhoneNo}
                        />
                        <FormHelperText style={{ color: primaryColor[0], marginLeft: "10px" }}>
                          {errors.oldPhoneNo}
                        </FormHelperText>
                      </ThemeProvider>

                      <TextField
                        style={{ margin: "20px 0 0 0" }}
                        fullWidth
                        name='newPhoneNo'
                        label='New'
                        variant="outlined"
                        disabled={hasDeleteRequest}
                        onChange={(event) => {
                          values.newPhoneNo = event.target.value.toUpperCase()
                          setFieldValue('newPhoneNo', event.target.value.toUpperCase())
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={values.newPhoneNo.length === 0}
                        value={values.newPhoneNo}
                      />
                      <Typography
                        style={{
                          textAlign: "center", fontSize: '16px', color: primaryColor[0],
                          display: values.newPhoneNo === "" ? 'block' : 'none'
                        }}>
                        <em>* Required</em>
                      </Typography>
                      <FormHelperText style={{ color: primaryColor[0], marginLeft: "10px" }}>
                        {errors.newPhoneNo}
                      </FormHelperText>

                    </div>
                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{
                            width: "60%"
                          }}
                          variant="contained"
                          color="primary"
                          size="md"
                          disabled={isSubmitting || values.newPhoneNo.length < 7}
                          onClick={handleSubmit}
                        >
                          Save
                              </Button>

                        <Button
                          style={{
                            width: "40%"
                          }}
                          variant="outlined"
                          color="primary"
                          size="md"
                          // disabled={isSubmitting}
                          // href="/app/profile"
                          onClick={onClose}
                        // type="submit"
                        >
                          Cancel
                              </Button>

                      </ThemeProvider>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>

      </Dialog >

      <MessageDialog
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={"Phone number changed"}
        messageOnMessageDialog2={""}
        messageDialogTitle={"Phone Number"}
        messageLabelBtn={"DONE"}
        variant={"contained"}
      // push={"/app/profile"}
      />


    </>
  )
}


export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withAuthentication(PhoneNumber))
    )
  )
);