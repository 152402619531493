
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import useWindowSize from 'utils/useWindowSize';

// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import FormHelperText from "@material-ui/core/FormHelperText";

import Waiting from "utils/Waiting";
import { Formik, Form } from "formik";
// import { useMediaQuery } from 'react-responsive';

// import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
// import Datetime from "utils/react-datetime/DateTime";
import Typography from "@material-ui/core/Typography";

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import { Debug } from "components/Debug";

// import { gql } from "apollo-boost";
import circlelogo from 'assets/img/byn-logo-circle.png';

import { primaryColor } from "assets/jss/material-kit-pro-react.js";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { createUserLog } from "utils/CreateUserLog";

import "utils/react-datetime/css/react-datetime.css";

import {
  // AuthUserContext,
  // withEmailVerification,
  withAuthentication,
} from "utils/Session";

import { withFirebase } from "utils/Firebase";

import { ThemeProvider } from "@material-ui/styles";


import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme"
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme"
import MessageDialog from 'utils/MessageDialog';
import Transition from 'utils/Transition';
import PasswordStrength from 'utils/PasswordStrength';
import MEMBER_CHECK from 'utils/Graphql/queries';




const style = {

};

const INITIAL_STATE = {
  // step: 1,
  oldPassword: '',
  passwordOne: '',
  password: '',
  showPassword: true

  // error: null
};


function Password(props) {
  const { classes, client, reFetch, open, onClose, memberData, firebase } = props;

  const [hasDeleteRequest, setHasDeleteRequest] = useState(false);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false)
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block"
      document.getElementById("mainScreen").style.opacity = 0.3
    } else {
      if (document && document.getElementById("waiting") && document.getElementById("mainScreen")) {
        document.getElementById("waiting").style.display = "none"
        document.getElementById("mainScreen").style.opacity = 1
      }
    }
  }, [isOpenWaiting])

  useEffect(() => {
    console.log(memberData)
    const hasDeleteReq = handleCheckDeleteRequest(memberData.serviceRequest)
    if (hasDeleteReq) setHasDeleteRequest(true)
    else setHasDeleteRequest(false)
  }, [memberData.serviceRequest])

  // const [memberData, setMemberData] = useState({});

  const [values_, setValues_] = React.useState({
    showOldPassword: false,
    showPassword: false,
    showPasswordOne: false
  });
  const size = useWindowSize()
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [password, setPassword] = useState('')
  // const [passwordOne, setPasswordOne] = useState('')
  // const [selectedTime, setSelectedTime] = useState("");
  // const [startHour, setStartHour] = useState('');
  // const [startMinutes, setStartMinutes] = useState('');
  // const [endHour, setEndHour] = useState('');
  // const [endMinutes, setEndMinutes] = useState('');
  // const [errorHour, setErrorHour] = useState('');
  // const [durationHour, setDurationHour] = useState('');
  // const [durationMinutes, setDurationMinutes] = useState('');
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px")
  // const [closeRightMargin, setCloseRightMargin] = useState("0px")
  const [error, setError] = useState(null)
  const [deleteDaysRemaining, setDeleteDaysRemaining] = useState(7)



  const handleClickShowOldPassword = () => {
    setValues_({ ...values_, showOldPassword: !values_.showOldPassword });
    // setShowPasswordOne(!showPasswordOne )
  };
  const handleClickShowPassword = () => {
    setValues_({ ...values_, showPassword: !values_.showPassword });
  };

  const handleClickShowPasswordOne = () => {
    setValues_({ ...values_, showPasswordOne: !values_.showPasswordOne });
    // setShowPasswordOne(!showPasswordOne )
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleCloseMessageDialog = () => {
    reFetch()
    setOpenMessageDialog(false)
    onClose()
    // props.history.push('/profile');
  }

  const ndays = (request, days) => {
    let n = days
    if (request) {
      let reqOriginalDate = new Date(request.date)
      let reqDate = new Date(request.date)
      reqDate.setDate(reqDate.getDate() + n);
      console.log(reqOriginalDate)
      console.log(reqDate)
      let d = Math.abs(reqDate.getDate() - reqOriginalDate.getDate())
      console.log(d)
      return d;
    }
    else {
      return 0
    }
  }

  const handleCheckDeleteRequest = (serviceRequest) => {
    console.log(serviceRequest)
    if (serviceRequest) {
      const sr = serviceRequest.filter((srq, ix) => {
        console.log(srq)
        if (srq.requestType === 'DELETE' && srq.status === 'NEW') {
          setDeleteDaysRemaining(ndays(srq, 7));
          return srq
        }
      })
      console.log(sr)
      if (sr.length > 0) return true
      else return false
    }
    else return false
  }
  const doQuery = async (email) => {
    const { data } = await client.query({
      query: MEMBER_CHECK,
      variables: {
        email
      }
    })
    return data;
    // if(data.memberCheck == null){
    //   setError({error: ERROR_NOT_IN_EMP})
    // } else {
    //   //success
    //   handleSuccess()
    // }
  }

  const handleSubmitChangePassword = async (values, resetForm, setSubmitting) => {
    console.log(values)
    const { oldPassword, password, passwordOne } = values;

    firebase
      .doSignInWithEmailAndPassword(memberData.email, oldPassword)
      .then((userCred) => {
        createUserLog(props, "PASSWORD", "Changed password", "")
        console.log(userCred)
        firebase
          .doPasswordUpdate(passwordOne)
          .then(() => {
            setOpenMessageDialog(true)
            setError(null)
            resetForm({ values: '' })
            // doQuery(email).then((response) => {
            //   console.log(response)
            //   if (response.memberCheck == null) {
            //     setError({ error: ERROR_NOT_IN_EMP })
            //     setSubmitting(false)
            //   } else {
            //     //success
            //     setOpenMessageDialog(true)
            //     setError(null)
            //     resetForm({ values: '' })
            //   }
            // });
          })
          .catch(error => {
            console.log(error)
            setError(error)
            setSubmitting(false)
            resetForm({ values: '' })
            // setIsOpenWaiting(false)
          });
      })
      .catch(error => {
        console.log(error)
        setError(error)
        setSubmitting(false)
        // setIsOpenWaiting(false)
      })
    // .finally(()=>{
    //   setSubmitting(false)
    // });
  }
  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog
        }}
        open={open}
        onClose={onClose}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Password
                </div>

              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0`,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div className={classes.container} style={{ width: `${size.width <= 414 ? size.width - 30 : 370}px` }}>
            <div id="waiting" style={{ marginBottom: '70px', marginTop: 20 }}>
              <Waiting
                Transition={Transition}
                isOpenWaiting={isOpenWaiting}
              ></Waiting>
            </div>
            {hasDeleteRequest ?
              <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                <Typography>
                  You have a pending delete request. Cancel the delete request to log an expense.
              </Typography>
              </div>
              : ''}
            <div id="mainScreen" style={{ marginTop: '-30px' }}>
              <Formik
                initialValues={INITIAL_STATE}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  // setSubmitting(true)
                  handleSubmitChangePassword(values, resetForm, setSubmitting)
                }}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  handleSubmit,
                  setSubmitting,
                  touched,
                  errors
                }) => (
                  <Form mode="themed" >
                    <div className={classes.container}
                      style={{
                        overflow: "auto",
                        width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                        height: `${size.height - 165}px`
                      }}
                    >
                      <TextField
                        style={{
                          margin: `${size.width <= 320 ? 30 :
                            size.width <= 360 ? 40 :
                              size.width <= 375 ? 50 :
                                size.width <= 414 ? 60
                                  : 60
                            }px 0 0px 0`
                        }}
                        onChange={e => {
                          setFieldValue('oldPassword', e.target.value)
                          setPassword(e.target.value)

                        }}
                        disabled={hasDeleteRequest}
                        name="oldPassword"
                        value={values.oldPassword}
                        type={values_.showOldPassword ? 'text' : 'password'}
                        label="Old Password"
                        className={classes.textField}
                        margin="normal"
                        fullWidth
                        step="2"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        required={values.oldPassword.length === 0}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">

                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                color="primary"
                              >

                                {values_.showOldPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )
                                }

                              </IconButton>

                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText
                        style={{
                          color: primaryColor[0],
                          margin: "10px 0 20px 10px",
                          fontSize: "12px",
                        }}
                      >
                        {error && error.message ? error.message : null}
                      </FormHelperText>
                      <Typography style={{ margin: "20px 0 0px 0", fontSize: '11px', textlign: 'center' }}>
                        To secure your records, please use a strong password by combining letters, numbers, and special characters.
                        </Typography>
                      <PasswordStrength password={values.password} />
                      <TextField
                        style={{ margin: "10px 0 0 0", width: "100%" }}
                        onChange={e => {
                          setFieldValue('password', e.target.value)
                          setPassword(e.target.value)

                        }}
                        disabled={hasDeleteRequest}
                        name="password"
                        value={values.password}
                        type={values_.showPassword ? 'text' : 'password'}
                        label="New Password"
                        className={classes.textField}
                        margin="normal"
                        fullWidth
                        step="2"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        required={values.password.length === 0}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">

                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                color="primary"
                              >
                                {
                                  values_.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )
                                }


                              </IconButton>

                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        style={{ margin: "20px 0 0 0", width: "100%" }}
                        onChange={e => {
                          setFieldValue('passwordOne', e.target.value)
                          setPassword(e.target.value)

                        }}
                        name="passwordOne"
                        value={values.passwordOne}
                        type={values_.showPasswordOne ? 'text' : 'password'}
                        label="Confirm Password"
                        className={classes.textField}
                        margin="normal"
                        fullWidth
                        step="3"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        disabled={hasDeleteRequest}
                        required={values.passwordOne.length === 0}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordOne}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                color="primary"
                              >
                                {
                                  values_.showPasswordOne ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )
                                }
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography
                        style={{
                          textAlign: "center", fontSize: '16px', color: primaryColor[0],
                          display: values.email === "" || values.password === "" || values.passwordOne === "" ? 'block' : 'none'
                        }}>
                        <em>* Required</em>
                      </Typography>
                    </div>

                    <DialogActions className={classes.modalFooter}>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        <Button
                          style={{
                            width: "60%"
                          }}
                          variant="contained"
                          color="primary"
                          size="md"
                          disabled={isSubmitting || values.oldPassword.length < 5 || values.password.length < 8 || values.password !== values.passwordOne || values.password === '' || hasDeleteRequest}
                          // onClick={() => {
                          //   setOpenMessageDialog(true)
                          // }}
                          onClick={handleSubmit}
                        >
                          Save
                              </Button>

                        <Button
                          style={{
                            width: "40%"
                          }}
                          variant="outlined"
                          color="primary"
                          size="md"
                          // disabled={isSubmitting}
                          // href="/app/passwordChange"
                          // type="submit"
                          onClick={onClose}
                        >
                          Cancel
                              </Button>

                      </ThemeProvider>
                    </DialogActions>

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>

      </Dialog >

      <MessageDialog
        Transition={props.Transition}
        openMessageDialog={openMessageDialog}
        handleCloseMessageDialog={handleCloseMessageDialog}
        setOpenMessageDialog={setOpenMessageDialog}
        messageOnMessageDialog={"Password"}
        messageOnMessageDialog2={"changed."}
        messageDialogTitle={"Confirmation"}
        messageLabelBtn={"DONE"}
      // push={"/app/passwordchange"}

      />


    </>
  )
}

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(
      withStyles(modalStyle)(withFirebase(withAuthentication(Password)))
    )
  )
);