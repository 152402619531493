import React, { useState, useEffect, useRef } from "react";

import { gql } from "apollo-boost";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import IconButton from "@material-ui/core/IconButton";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import useWindowSize from 'utils/useWindowSize';
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";

import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import MuiAlert from '@material-ui/lab/Alert';
import Datetime from "utils/react-datetime/DateTimeForUpdate";

import moment from "moment";
import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react.js";

import "utils/react-datetime/css/react-datetime.css";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle-pellucid.js";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles-pellucid.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import DateTimeLog from "screens/LogMyWork/DateTimeLog";
import TextFieldComponent from "utils/TextFieldComponent";

import Tooltip from "@material-ui/core/Tooltip";
import { activitiesSource, L2Source } from "screens/LogMyWork/LogService.js";

import { removeComma } from "utils/RemoveComma";
import { formatMoney } from "utils/FormatMoney";
import { formatNumber_ } from "utils/FormatNumber_";
import { checkMoney } from "utils/CheckMoney";
import { createUserLog } from "utils/CreateUserLog";

import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import sameTheme from "utils/Themes/viewLogMaterialTheme";
import viewLogMaterialTheme from "utils/Themes/viewLogMaterialTheme";
import changedTheme from "utils/Themes/itemChangedMaterialTheme";
import dateMaterialTheme from "utils/Themes/dateMaterialTheme";

import circlelogo from "assets/img/byn-logo-circle.png";
import Waiting from "utils/Waiting";
import ConfirmServiceUpdate from "./ConfirmServiceUpdate";
import Transition from "utils/Transition";
import { AlertTitle } from "@material-ui/lab";


import InputAdornment from "@material-ui/core/InputAdornment";
import { Snackbar } from "@material-ui/core";

function Alert(props) {
  // return <MuiAlert elevation={6} variant="filled" {...props} />;
  return <MuiAlert elevation={6}  {...props} />;
}

const style = {
  grid: {
    width: "60%",
  },
  step3Client480: {
    marginLeft: "0px",
    marginTop: "40px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Case480: {
    marginLeft: "0px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step2ActivityL1480: {
    marginTop: "5px",
    marginBottom: "-1px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step2Activity480: {
    marginTop: "0px",
    marginBottom: "-1px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3duration: {
    marginLeft: "0px",
    marginTop: "18px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3hrmin: {
    marginLeft: "0px",
    marginTop: "0px",
    display: "grid",
    gridTemplateColumns: ".49fr .02fr .49fr",
    gridTemplateRows: "auto auto",
  },
  step3startTimeRender480: {
    marginLeft: "0px",
    marginTop: "10px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3endTimeRender480: {
    marginLeft: "0px",
    marginTop: "-5px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3TimeRenderInput480b: {
    marginLeft: "0px",
    marginTop: "5px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3billing: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    margin: "20px 0 0px 0px",
  },
  step3fixedRate: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    margin: "20px 0 0px 0px",
  },
  step3hourly: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    margin: "20px 0 0 0px",
  },
  step3amount: {
    marginLeft: "0px",
    marginTop: "35px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3startTime: {
    marginLeft: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3endTime: {
    marginLeft: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step3Date: {
    marginTop: "5px",
    marginBottom: "15px",
    display: "grid",
    gridTemplateColumns: "0px 1fr",
    gridTemplateRows: "auto auto",
  },
  step3DateError: {
    marginTop: "-10px",
    display: "grid",
    gridTemplateColumns: "0px 200px 10px",
    color: "primary",
    gridTemplateRows: "auto auto",
  },
  step3Notes: {
    marginTop: "14px",
    marginBottom: "0px",
    display: "grid",
    gridTemplateColumns: "0px 1fr",
    gridTemplateRows: "auto auto",
  },
};

const getValidDate = (date) => {
  let validDate = date;
  if (typeof date === "object") {
    if (Object.prototype.toString.call(date) !== "[object Date]") {
      validDate = date.toDate();
    }
  }
  return validDate;
};

const constraintDate = (date) => {
  let _date = getValidDate(date);
  let mins = _date.getMinutes();
  console.log(mins);
  if (0 <= mins && mins <= 14) mins = 0;
  else if (15 <= mins && mins <= 29) mins = 15;
  else if (30 <= mins && mins <= 44) mins = 30;
  else mins = 45;
  console.log(mins);
  let newDate = new Date(_date);
  newDate.setMinutes(mins, 0, 0);
  console.log(newDate);
  return newDate;
};

const getDateFromLogDate = (date) => {
  let _date = getValidDate(date);
  let current = new Date();
  _date.setHours(current.getHours());
  return constraintDate(_date);
};

const ServiceUpdate = (props) => {
  const { classes } = props;
  const formRef = useRef();

  const size = useWindowSize()

  const [amount, setAmount] = useState("");
  const [isOpenWaitingOnConfirm, setIsOpenWaitingOnConfirm] = useState(true);
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  useEffect(() => {
    if (isOpenWaiting) {
      document.getElementById("waiting").style.display = "block";
      document.getElementById("mainScreenSvc").style.opacity = 0.3;
    } else {
      if (
        document &&
        document.getElementById("waiting") &&
        document.getElementById("mainScreenSvc")
      ) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("mainScreenSvc").style.opacity = 1;
      }
    }
  }, [isOpenWaiting]);

  const getDuration = (duration) => {
    let d = new DateTimeLog(duration);
    let hr = d.getDurationHours();
    let min = d.getDurationMinutes();
    console.log(hr, min);
    return { hours: hr, minutes: min };
  };

  const [
    openConfirmUpdateServiceLog,
    setOpenConfirmUpdateServiceLog,
  ] = useState(false);

  const [isConfirmingEdit, setIsConfirmingEdit] = useState(false);
  const [openLogDate, setOpenLogDate] = React.useState(false);
  const [billingType, setBillingType] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [startExceeds, setStartExceeds] = useState({
    date: "",
    dateString: "",
    lesserOrGreater: "",
  });
  const [endExceeds, setEndExceeds] = useState({
    date: "",
    dateString: "",
    lesserOrGreater: "",
  });
  const [selectedTime, setSelectedTime] = useState("");
  const [initialValues, setInitialValues] = useState(
    props.initialValues_service
  );
  // const [startHour, setStartHour] = useState("");
  // const [startMinutes, setStartMinutes] = useState("");
  // const [endHour, setEndHour] = useState("");
  // const [endMinutes, setEndMinutes] = useState("");
  // const [errorHour, setErrorHour] = useState("");
  const [durationHour, setDurationHour] = useState("");
  const [durationMinutes, setDurationMinutes] = useState("");
  const [activityL1, setActivityL1] = React.useState("");
  const [activityL2, setActivityL2] = React.useState("");
  const [activityL3, setActivityL3] = React.useState("");
  const [serviceLogFriend, setServiceLogFriend] = useState();

  const [openAlert, setOpenAlert] = useState(false)

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    props.initialValues_service.case_ = props.rowNode.case;
    props.initialValues_service.client = props.rowNode.client;
  }, [props.rowNode])

  useEffect(() => {
    // do  nothing
  }, [serviceLogFriend]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    console.log("ServiceUpdate start useEffect []");
    setBillingType(props.initialValues_service.billingType);
    console.log(props.initialValues_service.billingType);
    console.log("ServiceUpdate end useEffect []");
  }, [props.initialValues_service.billingType]);

  // useEffect(() => {
  //   if (props.initialValues_service.hours) {
  //     let a = getDuration(props.initialValues_service.hours);
  //     setDurationHour(a.hours);
  //     setDurationMinutes(a.minutes === 0 ? "00" : a.minutes);
  //     props.initialValues_service.durationHour = a.hours
  //     props.initialValues_service.durationMinutes = a.minutes === 0 ? "00" : a.minutes
  //   }
  // }, [props.initialValues_service.hours]);

  // const [closeRightMargin, setCloseRightMargin] = useState("0px");
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px");

  useEffect(() => {
    console.log("ExpenseUpdate start useEffect []");
    let d = new Date();
    let min = d.getMinutes();
    if (0 <= min && min <= 14) min = 0;
    else if (15 <= min && min <= 29) min = 15;
    else if (30 <= min && min <= 44) min = 30;
    else min = 45;

    let date_ = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      min,
      0,
      0
    );
    setStartTime(date_);
    setEndTime(date_);

    console.log("ServiceUpdate end useEffect []");
  }, []);

  useEffect(() => {
    props.initialValues_service.case_ = props.rowNode.case;
    props.initialValues_service.client = props.rowNode.client;
    if (props.initialValues_service.hours) {
      let a = getDuration(props.initialValues_service.hours);
      setDurationHour(a.hours);
      setDurationMinutes(a.minutes === 0 ? "00" : a.minutes);
      props.initialValues_service.durationHour = a.hours
      props.initialValues_service.durationMinutes = a.minutes === 0 ? "00" : a.minutes
    }
  }, [props.initialValues_service]);

  if (!props.openUpdateServiceLog) return null;

  function handleCloseUpdateServiceLog() {
    createUserLog(props, "SERVCE_UPDATE", "Closed Work Edit screen", "clicked on CLOSE")
    props.setOpenUpdateServiceLog(false);
  }

  const handleActivityL1 = (values, value, setFieldValue) => {
    createUserLog(props, "SERVCE_UPDATE", "Changed Work Category", "")
    debugger;

    setFieldValue("activityL1", value);
    setFieldValue("activityL2", "");
    setActivityL1(value);
    setFieldValue("activity3Options", []);
    setFieldValue("activityL3", "");
    setActivityL2("");
    setActivityL3("");
    debugger;
    if (value === "") {
      return;
    }
    const l2 = activitiesSource.filter(({ name }) => name === value)[0];
    if (l2 && l2.prompt !== undefined) {
      setActivityL2(l2.prompt);
      setFieldValue("activityL2", l2.prompt);
      values.activityL2 = l2.prompt;
    }

    values.L2 = [];
    l2.L2.map((m, ix) => (values.L2[ix] = m));
    debugger;

    values.activity2Options = [];
    values.L2.map((m) => {
      values.activity2Options.push({
        value: m,
        label: m,
      });
    });
    setFieldValue("activity2Options", values.activity2Options);
  };

  const handleActivityL2 = (values, value, setFieldValue) => {
    createUserLog(props, "SERVCE_UPDATE", "Changed Work Sub Category", "")
    setFieldValue("activityL2", value);
    setFieldValue("activityL3", "");
    setActivityL3("");
    debugger;
    const l3 = L2Source.filter(
      ({ name, L2 }) => name === values.activityL1 && L2 === value
    )[0];

    if (l3 && l3.prompt !== undefined) {
      values.activityL3 = l3.prompt;
      setFieldValue("activityL3", l3.prompt);
      setActivityL3(l3.prompt);
    }
    debugger;
    values.L3 = [];
    l3.L3.map((m, ix) => (values.L3[ix] = m));

    values.activity3Options = [];
    values.L3.map((m) => {
      values.activity3Options.push({
        value: m,
        label: m,
      });
    });
    setFieldValue("activity3Options", values.activity3Options);
  };

  const handleActivityL3 = (value, setFieldValue) => {
    createUserLog(props, "SERVCE_UPDATE", "Change Work Sub SubCategory", "")
    setActivityL3(value);
    setFieldValue("activityL3", value);
  };

  function handleStartTime(value) {
    createUserLog(props, "SERVCE_UPDATE", "Changed Start Time", "")
    try {
      value.toDate();
    } catch (e) {
      console.log(e.toString());
      return;
    }
    handleStartTime_(value);
    setStartTime(value);
  }

  function handleStartTime_(value) {
    console.log("handleStartTime_...");
    if (Object.prototype.toString.call(value) === "[object Object]") {
      try {
        formRef.current.values.startTime = value.toDate();

        let end = formRef.current.values.endTime;
        let start = value;

        if (typeof start === "object") {
          if (Object.prototype.toString.call(start) !== "[object Date]") {
            start = start.toDate();
          }
        }
        if (typeof end === "object") {
          if (Object.prototype.toString.call(end) !== "[object Date]") {
            end = end.toDate();
          }
        }

        if (end && start) {
          var elapsed = end.getTime() - start.getTime();
        }

        elapsed = elapsed / (1000 * 60 * 60);

        if (elapsed < 0) {
          formRef.current.values.elapsed = elapsed.toFixed(2);
        } else {
          formRef.current.values.elapsed = formatNumber_(elapsed);
          if (!formRef.current.values.hourlyRate) {
            formRef.current.values.hourlyRate = formatNumber_("0.0");
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    console.log("...handleEndTime_");
  }

  function handleEndTime(value) {
    createUserLog(props, "SERVCE_UPDATE", "Changed End Time", "")
    try {
      value.toDate();
    } catch (e) {
      console.log(e.toString());
      return;
    }
    formRef.current.values.endTime = value.toDate();
    handleEndTime_(value);
    setEndTime(value);
  }

  function handleEndTime_(value) {
    console.log("handleEndTime_...");
    if (Object.prototype.toString.call(value) === "[object Object]") {
      try {
        formRef.current.values.endTime = value.toDate();

        let end = value;

        let start = formRef.current.values.startTime;

        if (typeof start === "object") {
          if (Object.prototype.toString.call(start) !== "[object Date]") {
            start = start.toDate();
          }
        }
        if (typeof end === "object") {
          if (Object.prototype.toString.call(end) !== "[object Date]") {
            end = end.toDate();
          }
        }

        if (end && start) {
          var elapsed = end.getTime() - start.getTime();
        }

        elapsed = elapsed / (1000 * 60 * 60);

        if (elapsed < 0) {
          formRef.current.values.elapsed = elapsed.toFixed(2);
        } else {
          formRef.current.values.elapsed = formatNumber_(elapsed);
          if (!formRef.current.values.hourlyRate) {
            formRef.current.values.hourlyRate = formatNumber_("0.0");
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    console.log("...handleEndTime_");
  }

  const validate = (values) => {
    console.log("Formik validate() start ...");
    console.log(values);
    values.justInitialized = false;
    debugger;
    const errors = {};

    if (!values.client) errors.client = "Required";
    if (!values.case_) errors.case_ = "Required";
    if (!values.activityL1) errors.activityL1 = "Required";

    if (values.activityL2 && values.activityL2.includes("?"))
      errors.activityL2 = "Required";
    if (values.activityL3 && values.activityL3.includes("?"))
      errors.activityL3 = "Required";

    if (values.startTime) {
      let logDate = values.date;
      if (!isStartTimeValid(values.startTime, logDate))
        errors.startTime = "Start time should not preceed or exceed log date.";
    }
    // debugger;
    let today = new Date();
    today.setHours(23, 59, 59, 999);
    let x = today.toJSON();
    let y = new Date(values.date).toJSON();
    let start = values.startTime;
    let end = values.startTime;
    if (typeof start === "object") {
      if (Object.prototype.toString.call(start) !== "[object Date]") {
        start = start.toDate();
      }
    }
    if (typeof end === "object") {
      if (Object.prototype.toString.call(end) !== "[object Date]") {
        end = end.toDate();
      }
    }
    if (end && start) {
      var elapsed = end.getTime() - start.getTime();
    }
    // debugger;
    if (y > x) {
      errors.date = "Future date is not allowed";
    }

    if (values.activityL1) {
      if (!values.billingType) {
        errors.billingType = "Required";
      }

      if (values.billingType && values.billingType === "FIXED") {
        if (
          !values.fixedRate ||
          (values.fixedRate && formatNumber_(values.fixedRate) === "0.00")
        ) {
          errors.fixedRate = "Required";
        } else {
          const match = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]*\.[0-9]*[1-9][0-9]*)$/.test(
            removeComma(values.fixedRate)
          );
          if (!match) errors.fixedRate = "Invalid Value";
        }
      } else if (values.billingType && values.billingType === "HOURLY") {
        if (
          !values.hourlyRate ||
          (values.hourlyRate &&
            (formatNumber_(values.hourlyRate) === "0.00" ||
              values.hourlyRate.length === 0))
        ) {
          errors.hourlyRate = "Required";
        } else {
          const match = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]*\.[0-9]*[1-9][0-9]*)$/.test(
            removeComma(values.hourlyRate)
          );
          if (!match) errors.hourlyRate = "Invalid Value";
        }

        if (
          !values.durationMinutes ||
          (values.durationMinutes &&
            formatNumber_(values.durationMinutes) === "0.00")
        ) {
          if (
            !values.durationHour ||
            (values.durationHour &&
              formatNumber_(values.durationHour) === "0.00")
          ) {
            console.log("values.durationHour");
            console.log(values.durationHour);
            console.log("values.durationMinutes");
            console.log(values.durationMinutes);
            errors.hours = "Required";
          }
        }

      }
    }
    console.log(errors)
    debugger;
    return errors;
  };

  const enableStartTimeDates = (dt) => {
    console.log("-----------------------------------------");
    let dss = dt.toDate().toLocaleString();
    let dyyyy = Number.parseInt(dss.split("/")[2]);
    let dmm = Number.parseInt(dss.split("/")[0]) - 1;
    let ddd = Number.parseInt(dss.split("/")[1]);

    let ss = formRef.current.values.endTime.toLocaleString();
    let yyyy = Number.parseInt(ss.split("/")[2]);
    let mm = Number.parseInt(ss.split("/")[0]) - 1;
    let dd = Number.parseInt(ss.split("/")[1]);

    return (
      moment([dyyyy, dmm, ddd]).isAfter(
        moment([yyyy, mm, dd]).subtract(2, "day")
      ) &&
      moment([dyyyy, dmm, ddd]).isBefore(moment([yyyy, mm, dd]).add(1, "day"))
    );
  };

  const enableEndTimeDates = (dt) => {
    console.log("-----------------------------------------");
    let todaysDate = new Date();
    let today = todaysDate.toJSON();

    let logDateValue = formRef.current.values.date;
    let logDateMoment = moment([
      logDateValue.getFullYear(),
      logDateValue.getMonth(),
      logDateValue.getDate(),
    ]);
    let logDate = new Date(logDateMoment).toJSON();

    let startDate = formRef.current.values.startTime;
    let startTimeMoment = moment([
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
    ]);

    let endTimeMoment = startTimeMoment;
    let endTime = new Date(endTimeMoment).toJSON();
    if (endTime < today && endTime < logDate) {
      endTimeMoment = startTimeMoment.add(1, "day");
    }

    let dss = dt.toDate().toLocaleString();
    let dyyyy = Number.parseInt(dss.split("/")[2]);
    let dmm = Number.parseInt(dss.split("/")[0]) - 1;
    let ddd = Number.parseInt(dss.split("/")[1]);

    let ss = endTimeMoment.toDate().toLocaleString();
    let yyyy = Number.parseInt(ss.split("/")[2]);
    let mm = Number.parseInt(ss.split("/")[0]) - 1;
    let dd = Number.parseInt(ss.split("/")[1]);
    return (
      moment([dyyyy, dmm, ddd]).isAfter(
        moment([yyyy, mm, dd]).subtract(2, "day")
      ) &&
      moment([dyyyy, dmm, ddd]).isBefore(moment([yyyy, mm, dd]).add(1, "day"))
    );
  };

  const handleHourChange = (value, values, setFieldValue) => {
    if (
      (values.startTime && values.startTime !== "") ||
      (values.endTime && values.endTime !== "")
    ) {
      let date = new DateTimeLog(value);
      let endTime = date.getEndTimeFromStartTime(values.startTime);
      let a = date.isDateLesserOrGreater(
        getValidDate(endTime),
        getValidDate(values.date)
      );
      if (a.lesserOrGreater !== "") {
        console.log(a.lesserOrGreater);
        setEndExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: a.lesserOrGreater,
        });
      } else {
        setEndExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: "",
        });
      }
      date.validateEndDateHours(values.date, endTime);

      setFieldValue("endTime", endTime);
      console.log(values.hours);
    }
  };

  const handleDateChange = (value, values, setFieldValue) => {
    if (
      (values.startTime && values.startTime !== "") ||
      (values.endTime && values.endTime !== "")
    ) {
      let date = new DateTimeLog(values.hours);
      let newEndTime = date.getSynchedEndYearMonthDate(value, values.endTime);
      let newStartTime = date.getStartTimeFromEndTime(newEndTime);
      let a = date.isDateLesserOrGreater(
        getValidDate(newStartTime),
        getValidDate(value)
      );
      let b = date.isDateLesserOrGreater(
        getValidDate(newEndTime),
        getValidDate(value)
      );
      if (a.lesserOrGreater !== "") {
        console.log(a.lesserOrGreater);
        setStartExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: a.lesserOrGreater,
        });
      } else {
        setStartExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: "",
        });
      }
      if (b.lesserOrGreater !== "") {
        setEndExceeds({
          date: b.date,
          dateString: b.dateString,
          lesserOrGreater: b.lesserOrGreater,
        });
      } else {
        setEndExceeds({
          date: b.date,
          dateString: b.dateString,
          lesserOrGreater: "",
        });
      }
      console.log(newStartTime);
      console.log(newEndTime);
      setFieldValue("startTime", newStartTime);
      setFieldValue("endTime", newEndTime);
    }
  };

  const handleStartTimeChange = (value, values, setFieldValue) => {
    createUserLog(props, "SERVCE_UPDATE", "Changed Start Time", "")
    console.log(value);
    let newStart = value;
    let start = "";
    let cdate = "";
    start = getValidDate(values.startTime);
    cdate = getValidDate(values.date);
    console.log(start);
    newStart = cdate.setHours(
      start.getHours(),
      start.getMinutes(),
      start.getSeconds(),
      start.getMilliseconds()
    );
    newStart = new Date(newStart);
    console.log(newStart);
    console.log(values.date);
    let date = new DateTimeLog(values.hours);
    let endTime = date.getEndTimeFromStartTime(newStart);
    let a = date.isDateLesserOrGreater(
      getValidDate(newStart),
      getValidDate(values.date)
    );
    let b = date.isDateLesserOrGreater(
      getValidDate(endTime),
      getValidDate(values.date)
    );
    if (a.lesserOrGreater !== "") {
      console.log(a.lesserOrGreater);
      setStartExceeds({
        date: a.date,
        dateString: a.dateString,
        lesserOrGreater: a.lesserOrGreater,
      });
    } else {
      setStartExceeds({
        date: a.date,
        dateString: a.dateString,
        lesserOrGreater: "",
      });
    }
    if (b.lesserOrGreater !== "") {
      setEndExceeds({
        date: b.date,
        dateString: b.dateString,
        lesserOrGreater: b.lesserOrGreater,
      });
    } else {
      setEndExceeds({
        date: b.date,
        dateString: b.dateString,
        lesserOrGreater: "",
      });
    }
    setFieldValue("startTime", newStart);
    setFieldValue("endTime", endTime);
  };

  const handleEndTimeChange = (value, values, setFieldValue) => {
    createUserLog(props, "SERVCE_UPDATE", "Changed End Time", "")
    let date = new DateTimeLog(values.hours);
    let startTime = date.getStartTimeFromEndTime(value);
    let a = date.isDateLesserOrGreater(
      getValidDate(value),
      getValidDate(values.date)
    );
    let b = date.isDateLesserOrGreater(
      getValidDate(startTime),
      getValidDate(values.date)
    );
    if (a.lesserOrGreater !== "") {
      console.log(a.lesserOrGreater);
      setEndExceeds({
        date: a.date,
        dateString: a.dateString,
        lesserOrGreater: a.lesserOrGreater,
      });
    } else {
      setEndExceeds({
        date: a.date,
        dateString: a.dateString,
        lesserOrGreater: "",
      });
    }
    if (b.lesserOrGreater !== "") {
      setStartExceeds({
        date: b.date,
        dateString: b.dateString,
        lesserOrGreater: b.lesserOrGreater,
      });
    } else {
      setStartExceeds({
        date: b.date,
        dateString: b.dateString,
        lesserOrGreater: "",
      });
    }
    setFieldValue("endTime", value);
    setFieldValue("startTime", startTime);
  };

  const handleNotes = (value, setFieldValue) => {
    setFieldValue("notes", value);
    setNotes(value);
    console.log(value);
  };

  const handleClickBillingType = (value, setFieldValue, values) => {
    createUserLog(props, "SERVCE_UPDATE", "Changed BillingType", "")
    setFieldValue("billingType", value);
    setBillingType(value);
    console.log(value);
    if (value === "FIXED") {
      values.amount = formatMoney(formatNumber_(removeComma(values.fixedRate)))
      setFieldValue('amount', values.amount)
    } else if (value === "HOURLY") {
      let durationHr = formatNumber_(values.durationHour);
      let durationMinutes = formatNumber_(values.durationMinutes)
      let durationMinDec = 0;
      let duration = 0;
      if (durationMinutes === "" || durationMinutes === "00") {
        durationMinutes = "00";
      } else {
        if (durationMinutes) {
          durationMinDec = Number.parseFloat(durationMinutes) / 60;
        }
        values.durationMinutes = durationMinutes;
      }
      duration = Number.parseFloat(durationHr) + durationMinDec;
      values.amount = formatMoney(formatNumber_(duration * removeComma(values.hourlyRate)))
      setFieldValue('amount', values.amount)

    } else if (value === "NO CHARGE") {
    }
  };

  const popHours = () => {
    let hr = 0;
    let hrArray = [];
    while (hr < 12) {
      if (hr === 0) hrArray.push(hr);
      hr++;
      hrArray.push(hr);
    }
    return hrArray;
  };
  const popMinutes = () => {
    let min = 0;
    let minArray = [];
    while (min < 45) {
      if (min === 0) minArray.push(min);
      min += 15;
      minArray.push(min);
    }
    console.log(minArray);
    return minArray;
  };

  const handleDateChange2 = (value, values, setFieldValue) => {
    if (
      (values.startTime && values.startTime !== "") ||
      (values.endTime && values.endTime !== "")
    ) {
      console.log(values.hours);
      // setFieldValue('date', value)
      let date = new DateTimeLog(values.hours);
      let newStartTime = date.getSynchedStartYearMonthDate(
        value,
        values.startTime
      );
      let newEndTime = date.getEndTimeFromStartTime(newStartTime);
      setFieldValue("startTime", newStartTime);
      setFieldValue("endTime", newEndTime);
      setStartTime(newStartTime);
      setEndTime(newEndTime);
      let a = date.isDateLesserOrGreater(
        getValidDate(newStartTime),
        getValidDate(value)
      );
      let b = date.isDateLesserOrGreater(
        getValidDate(newEndTime),
        getValidDate(value)
      );
      if (a.lesserOrGreater !== "") {
        console.log(a.lesserOrGreater);
        setStartExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: a.lesserOrGreater,
        });
      } else {
        setStartExceeds({
          date: a.date,
          dateString: a.dateString,
          lesserOrGreater: "",
        });
      }
      if (b.lesserOrGreater !== "") {
        setEndExceeds({
          date: b.date,
          dateString: b.dateString,
          lesserOrGreater: b.lesserOrGreater,
        });
      } else {
        setEndExceeds({
          date: b.date,
          dateString: b.dateString,
          lesserOrGreater: "",
        });
      }
    }
  };

  const handleDurationHourChange = (value, values, setFieldValue) => {
    if (value !== "") {
      let durationHr = value;
      let durationMinDec = 0;
      let duration = 0;
      setDurationHour(durationHr);
      setFieldValue("durationHour", durationHr);
      if (durationMinutes === "" || durationMinutes === "00") {
        setDurationMinutes("00");
        setFieldValue("00");
      } else {
        //convert minutes to decimal
        if (durationMinutes) {
          durationMinDec = Number.parseFloat(durationMinutes) / 60;
          console.log(durationMinDec);
        }
        setDurationMinutes(durationMinutes);
        setFieldValue("durationMinutes", durationMinutes);
      }
      duration = Number.parseFloat(durationHr) + durationMinDec;
      console.log(duration);
      setFieldValue("hours", duration);
      if (duration > 8) setOpenAlert(true)
      setFieldValue(
        "amount",
        formatMoney(formatNumber_(duration * removeComma(values.hourlyRate)))
      );
      if (
        (values.startTime && values.startTime !== "") ||
        (values.endTime && values.endTime !== "")
      ) {
        let date = new DateTimeLog(duration);
        let endTime = date.getEndTimeFromStartTime(values.startTime);
        let a = date.isDateLesserOrGreater(
          getValidDate(endTime),
          getValidDate(values.date)
        );

        if (a.lesserOrGreater !== "") {
          console.log(a.lesserOrGreater);
          setEndExceeds({
            date: a.date,
            dateString: a.dateString,
            lesserOrGreater: a.lesserOrGreater,
          });
        } else {
          setEndExceeds({
            date: a.date,
            dateString: a.dateString,
            lesserOrGreater: "",
          });
        }
        date.validateEndDateHours(values.date, endTime);

        setFieldValue("endTime", endTime);
      }
    }
  };
  const handleDurationMinutesChange = (value, values, setFieldValue) => {
    if (value !== "") {
      console.log(value);
      let durationMin = value;
      let durationMinDec = 0;
      let durationHr = values.durationHour;
      let duration = 0;
      setDurationMinutes(durationMin);
      setFieldValue("durationMinutes", durationMin);
      if (durationHour === "" || durationHour === "00") {
        setDurationHour("0");
        setFieldValue("durationHour", "0");
        durationHr = 0;
      } else {
        setDurationHour(durationHour);
        setFieldValue("durationHour", durationHour);
        durationHr = durationHour;
      }
      durationMinDec = Number.parseFloat(durationMin) / 60;

      console.log(durationMinDec);
      duration = Number.parseFloat(durationHr) + durationMinDec;
      console.log(duration);
      if (duration > 8) setOpenAlert(true)
      setFieldValue("hours", duration);
      setFieldValue(
        "amount",
        formatMoney(formatNumber_(duration * removeComma(values.hourlyRate)))
      );
      if (
        (values.startTime && values.startTime !== "") ||
        (values.endTime && values.endTime !== "")
      ) {
        let date = new DateTimeLog(duration);
        let endTime = date.getEndTimeFromStartTime(values.startTime);
        let a = date.isDateLesserOrGreater(
          getValidDate(endTime),
          getValidDate(values.date)
        );
        if (a.lesserOrGreater !== "") {
          console.log(a.lesserOrGreater);
          setEndExceeds({
            date: a.date,
            dateString: a.dateString,
            lesserOrGreater: a.lesserOrGreater,
          });
        } else {
          setEndExceeds({
            date: a.date,
            dateString: a.dateString,
            lesserOrGreater: "",
          });
        }
        date.validateEndDateHours(values.date, endTime);
        setFieldValue("endTime", endTime);
      }
    }
  };

  const isStartTimeValid = (startTime, logDate) => {
    let date = getValidDate(logDate);
    let logDateMax = date.setHours(23, 59, 59, 999);
    let logDateMin = date.setHours(0, 0, 0, 0);
    console.log(logDateMax);
    console.log(logDateMin);
    let startTimex = getValidDate(startTime);
    if (startTimex > logDateMax || startTimex < logDateMin) {
      return false;
    } else {
      return true;
    }
  };

  const isDurationValid = (duration) => {
    let hr = duration.toString();
    let hrDec = hr.split(".", 2);
    if (
      hrDec[1] === "" ||
      hrDec[1] === undefined ||
      hrDec[1] === "0" ||
      hrDec[1] === "25" ||
      hrDec[1] === "5" ||
      hrDec[1] === "75"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpenConfirmUpdateServiceLog = (values) => {
    createUserLog(props, "SERVCE_UPDATE", "Opened Confirm Work edit screen", "clicked EDIT")
    debugger;
    values.hourlyRate = removeComma(values.hourlyRate);
    values.fixedRate = removeComma(values.fixedRate);
    values.amount = removeComma(values.amount);
    handleCloseConfirmUpdateServiceLog(values);
  };

  function handleCloseConfirmUpdateServiceLog(values) {
    createUserLog(props, "SERVCE_UPDATE", "Closed Confirm Work edit screen", "clicked CLOSE")
    console.log(
      "start ------------------------------ handleCloseConfirmUpdateServiceLog"
    );
    setIsOpenWaitingOnConfirm(true);
    setOpenConfirmUpdateServiceLog(true);
    let {
      amount,
      activityL1,
      activityL2,
      activityL3,
      billingType,
      fixedRate,
      hourlyRate,
      hours,
      notes,
      date,
      startTime,
      endTime,
      durationHour,
      durationMinutes,
      case_
    } = values;

    activityL1 = activityL1.replace(" >", "");
    activityL2 = activityL2.replace(" >", "");
    const {
      workLogId,
      memberId,
      clientId,
      caseId,
      parentId,
      ord,
      status,
    } = props;

    let serviceLogData1 = {
      id: workLogId,
      date,
      billingType,
      fixedRate,
      hourlyRate,
      hours,
      amount,
      activityLogType: activityL1,
      activityLogSubType: activityL2,
      activityLogSubSubType: activityL3,
      notes,
      startTime,
      endTime,
      caseId,
      memberId,
      clientId,
      status,
      ord,
      parentId,
      durationHour,
      durationMinutes,
      client: values.client,
      case_
    };
    // setIsOpenWaiting(true)

    if (billingType === "FIXED") {
      fixedRate = formatNumber_(fixedRate);
      amount = formatNumber_(fixedRate);
      startTime = null;
      endTime = null;
      if (hourlyRate) hourlyRate = formatNumber_(hourlyRate);
      if (hours) hours = formatNumber_(hours);
    } else if (billingType === "HOURLY") {
      hourlyRate = formatNumber_(hourlyRate);
      amount = formatNumber_(amount);
      startTime = startTime === "" ? null : startTime;
      endTime = endTime === "" ? null : endTime;
      hours = formatNumber_(hours);
      if (fixedRate) fixedRate = formatNumber_(fixedRate);
    } else if (billingType === "NO CHARGE") {
      startTime = startTime === "" ? null : startTime;
      endTime = endTime === "" ? null : endTime;
      hourlyRate = "0.00";
      amount = "0.00";
      hours = formatNumber_(hours);
      fixedRate = "0.00";
    }

    debugger;
    const { client } = props;
    var { data } = client
      .mutate({
        mutation: UPDATE_SERVICE_LOG,
        variables: {
          activityLogId: workLogId,
          //date: DateTime!
          billingType,
          fixedRate,
          hourlyRate,
          hours,
          amount,
          activityLogType: activityL1,
          activityLogSubType: activityL2,
          activityLogSubSubType: activityL3,
          notes,
          startTime,
          endTime,
          caseId,
          memberId,
          clientId,
          actionDate: new Date().toISOString(),
          ord,
          date,
          parentId,
        },
      })
      .then((response) => {
        const { updateActivityLog } = response.data;
        const {
          id,
          date,
          billingType,
          fixedRate,
          hourlyRate,
          hours,
          amount,
          activityLogType,
          activityLogSubType,
          activityLogSubSubType,
          notes,
          startTime,
          endTime,
          caseId,
          memberId,
          clientId,
          actionDate,
          status,
          ord,
          parentId,
        } = updateActivityLog;

        let serviceLogData = {
          id,
          date,
          billingType,
          fixedRate,
          hourlyRate,
          hours,
          amount,
          activityLogType,
          activityLogSubType,
          activityLogSubSubType,
          notes,
          startTime,
          endTime,
          caseId: serviceLogData1.caseId,
          memberId,
          clientId: serviceLogData1.clientId,
          actionDate,
          status,
          ord,
          parentId,
          durationHour: serviceLogData1.durationHour,
          durationMinutes: serviceLogData1.durationMinutes,
          client: serviceLogData1.client,
          case_: serviceLogData1.case_
        };
        props.setIsOpenViewLog(false);
        props.setRowNode(props.selectedRowData);
        props.refreshViewLogs(serviceLogData);
        props.reArrange(props.setRowData);
        setIsConfirmingEdit(false);

        // setIsOpenWaiting(false)
        setIsOpenWaitingOnConfirm(false);
        // props.setOpenConfirmUpdateServiceLog(true)
        // props.setOpenUpdateServiceLog(false)
        console.log(
          "end ------------------------------ handleCloseConfirmUpdateServiceLog"
        );
      });
  }

  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={props.openUpdateServiceLog}
        onClose={handleCloseUpdateServiceLog}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 600}px` }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              {!isConfirmingEdit ? (
                <div
                  style={{
                    margin: `10px 0px 20px  0`,
                    color: primaryColor[0],
                    fontSize: "30px",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  Edit Log
                </div>
              ) : (
                <div
                  style={{
                    margin: `10px 0px 20px  0`,
                    color: primaryColor[0],
                    fontSize: "30px",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  Confirm Edit
                </div>
              )}
              <Tooltip
                id="tooltip-top"
                title={"Home"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{
                    margin: `-60px  ${size.width <= 414 ? -10 : -20}px 0 0`,
                  }}
                  className={classes.modalCloseButtonBYN}
                  onClick={e => {
                    e.preventDefault()
                    props.history.push("/app/dashboard")
                  }}
                >
                  <img
                    style={{ width: "50px" }}
                    src={circlelogo}
                    alt="billyounow.com"
                  />
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{ width: `${size.width <= 414 ? size.width - 30 : 600}px` }}
          >
            <div id="waiting" style={{ marginBottom: "70px", marginTop: 20 }}>
              <Waiting
                Transition={Transition}
                isOpenWaiting={isOpenWaiting}
              ></Waiting>
            </div>
            <div id="mainScreenSvc" style={{ marginTop: "-50px" }}>
              <Formik
                innerRef={formRef}
                initialValues={props.initialValues_service}
                validate={validate}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                  debugger;
                  handleOpenConfirmUpdateServiceLog(values);
                }}
              >
                {({ values, setFieldValue, touched, errors, handleSubmit }) => (
                  <>
                    <div
                      style={{
                        width: `${size.width <= 320 ? size.width - 60 :
                          size.width <= 360 ? size.width - 60 :
                            size.width <= 375 ? size.width - 60 :
                              size.width <= 414 ? size.width - 60 : 600
                          }px`,
                      }}

                    >
                      <div
                        className={classes.container}
                        style={{
                          overflow: "auto",
                          width: `${size.width <= 320 ? size.width - 40 :
                            size.width <= 360 ? size.width - 40 :
                              size.width <= 375 ? size.width - 40 :
                                size.width <= 414 ? size.width - 40 : 600
                            }px`,
                          height: `${size.height - 170}px`,
                        }}
                      >
                        <div
                          style={{
                            margin: "0 auto 0 auto",
                            width: `${size.width <= 320 ? size.width - 80 :
                              size.width <= 360 ? size.width - 80 :
                                size.width <= 375 ? size.width - 80 :
                                  size.width <= 414 ? size.width - 80 : 370
                              }px`,
                          }}

                        >
                          <div style={style.step3Client480}>
                            <ThemeProvider theme={sameTheme}>
                              <FormControl className={classes.formControl}>
                                <TextField
                                  fullWidth
                                  labelId="client_"
                                  id="client_"
                                  value={props.rowNode.client}
                                  variant="outlined"
                                  label="Client"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onChange={(e) => {
                                    e.target.value = props.rowNode.client;
                                  }}
                                />
                              </FormControl>
                            </ThemeProvider>
                          </div>
                          <div style={style.step3Case480}>
                            <ThemeProvider theme={sameTheme}>
                              <FormControl className={classes.formControl}>
                                <TextField
                                  fullWidth
                                  labelId="case_"
                                  id="case_"
                                  value={props.rowNode.case}
                                  variant="outlined"
                                  label="Case"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  onChange={(e) => {
                                    e.target.value = props.rowNode.case;
                                  }}
                                />
                              </FormControl>
                            </ThemeProvider>
                          </div>
                          <div style={style.step3Date}>
                            <div></div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider
                                theme={
                                  !isConfirmingEdit
                                    ? values.date !==
                                      props.initialValues_service.date
                                      ? changedTheme
                                      : dateMaterialTheme
                                    : values.date !==
                                      props.initialValues_service.date
                                      ? changedTheme
                                      : viewLogMaterialTheme
                                }
                              >
                                <KeyboardDatePicker
                                  open={!isConfirmingEdit ? openLogDate : false}
                                  onClose={() => {
                                    setOpenLogDate(false);
                                  }}
                                  name="date"
                                  margin="normal"
                                  keyboard
                                  autoOk
                                  label="Work Date"
                                  maxDate={constraintDate(new Date())}
                                  value={values.date}
                                  format="MMM dd, yyyy"
                                  onChange={(value) => {
                                    setFieldValue("date", value);
                                    handleDateChange2(value, values, setFieldValue);
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  TextFieldComponent={(props) =>
                                    !isConfirmingEdit ? (
                                      <TextFieldComponent
                                        {...props}
                                        setOpen={setOpenLogDate}
                                      />
                                    ) : (
                                      <TextFieldComponent
                                        {...props}
                                        setOpen={setOpenLogDate}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    )
                                  }
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                          </div>
                          <div style={style.step3DateError}>
                            {" "}
                            <div />
                            <FormHelperText>
                              <div style={{ color: primaryColor[0] }}>
                                {errors.date && errors.date}
                              </div>
                            </FormHelperText>
                          </div>

                          <div style={style.step2ActivityL1480}>
                            <ThemeProvider
                              theme={
                                !isConfirmingEdit
                                  ? values.activityL1 !==
                                    props.initialValues_service.activityL1
                                    ? changedTheme
                                    : defaultMaterialTheme
                                  : values.activityL1 !==
                                    props.initialValues_service.activityL1
                                    ? changedTheme
                                    : viewLogMaterialTheme
                              }
                            >
                              <FormControl className={classes.formControl}>
                                <TextField
                                  labelId="activityL1"
                                  id="activityL1"
                                  value={values.activityL1}
                                  variant="outlined"
                                  select
                                  label="Category"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  required={!isConfirmingEdit && (values.activityL1.length === 0 || values.activityL2.includes("?") || values.activityL3.includes("?"))}
                                  InputProps={{
                                    readOnly: isConfirmingEdit ? true : false,
                                  }}
                                  onChange={(e) => {
                                    handleActivityL1(
                                      values,
                                      e.target.value,
                                      setFieldValue
                                    );
                                  }}
                                >
                                  {values.activity1Options.map((activity) => (
                                    <MenuItem value={activity.value}
                                      style={{
                                        color: `${activity.label.endsWith('>') ? primaryColor[0] : grayColor[1]}`,
                                        fontWeight: `${activity.label.endsWith('>') ? '500' : '400'}`
                                      }}>
                                      {activity.label}
                                    </MenuItem>
                                  ))}
                                </TextField>

                                <FormHelperText>
                                  <div style={{ color: primaryColor[0] }}>
                                    {errors.activityL1 &&
                                      touched.activityL1 &&
                                      errors.activityL1}
                                  </div>
                                  {/* {errors.activityL1 && touched.activityL1 && errors.activityL1} */}
                                </FormHelperText>
                              </FormControl>
                            </ThemeProvider>
                          </div>

                          {values.activity2Options.length > 1 ? (
                            <div style={style.step2Activity480}>
                              <ThemeProvider
                                theme={
                                  !isConfirmingEdit
                                    ? values.activityL2 !==
                                      props.initialValues_service.activityL2
                                      ? changedTheme
                                      : defaultMaterialTheme
                                    : values.activityL2 !==
                                      props.initialValues_service.activityL2
                                      ? changedTheme
                                      : viewLogMaterialTheme
                                }
                              >
                                <FormControl className={classes.formControl}>
                                  <TextField
                                    labelId="activityL2"
                                    id="activityL2"
                                    value={values.activityL2}
                                    variant="outlined"
                                    select
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={!isConfirmingEdit && values.activityL2.includes("?")}
                                    InputProps={{
                                      readOnly: isConfirmingEdit ? true : false,
                                    }}
                                    onChange={(e) => {
                                      handleActivityL2(
                                        values,
                                        e.target.value,
                                        setFieldValue
                                      );
                                    }}
                                  >
                                    {values.activity2Options.map((activity) => (
                                      <MenuItem value={activity.value}
                                        style={{
                                          color: `${activity.label.endsWith('>') ? primaryColor[0] : grayColor[1]}`,
                                          fontWeight: `${activity.label.endsWith('>') ? '500' : '400'}`
                                        }}>
                                        {activity.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <FormHelperText>
                                    <div style={{ color: primaryColor[0] }}>
                                      {errors.activityL2 &&
                                        touched.activityL2 &&
                                        errors.activityL2}
                                    </div>
                                  </FormHelperText>
                                </FormControl>
                              </ThemeProvider>
                            </div>
                          ) : (
                            <div />
                          )}

                          {values.activity3Options.length > 1 ? (
                            <div style={style.step2Activity480}>
                              <ThemeProvider
                                theme={
                                  !isConfirmingEdit
                                    ? values.activityL3 !==
                                      props.initialValues_service.activityL3
                                      ? changedTheme
                                      : defaultMaterialTheme
                                    : values.activityL3 !==
                                      props.initialValues_service.activityL3
                                      ? changedTheme
                                      : viewLogMaterialTheme
                                }
                              >
                                <FormControl className={classes.formControl}>
                                  <TextField
                                    labelId="activityL3"
                                    id="activityL3"
                                    value={values.activityL3}
                                    variant="outlined"
                                    select
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={!isConfirmingEdit && values.activityL3.includes("?")}
                                    InputProps={{
                                      readOnly: isConfirmingEdit ? true : false,
                                    }}
                                    onChange={(e) => {
                                      handleActivityL3(
                                        e.target.value,
                                        setFieldValue
                                      );
                                    }}
                                  >
                                    {values.activity3Options.map((activity) => (
                                      <MenuItem value={activity.value}>
                                        {activity.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <FormHelperText>
                                    <div style={{ color: primaryColor[0] }}>
                                      {errors.activityL3 &&
                                        touched.activityL3 &&
                                        errors.activityL3}
                                    </div>
                                  </FormHelperText>
                                </FormControl>
                              </ThemeProvider>
                            </div>
                          ) : (
                            <div />
                          )}
                          <div style={style.step3Notes}>
                            <div></div>
                            <div style={{ margin: "2px 0 0px 0" }}>
                              <ThemeProvider
                                theme={
                                  !isConfirmingEdit
                                    ? values.notes !==
                                      props.initialValues_service.notes
                                      ? changedTheme
                                      : defaultMaterialTheme
                                    : values.notes !==
                                      props.initialValues_service.notes
                                      ? changedTheme
                                      : viewLogMaterialTheme
                                }
                              >
                                <TextField
                                  fullWidth
                                  multiline="true"
                                  rowsMax="3"
                                  name="notes"
                                  label="Notes"
                                  variant="outlined"
                                  onChange={(event) => {
                                    handleNotes(
                                      event.target.value.toUpperCase(),
                                      setFieldValue
                                    );
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: isConfirmingEdit ? true : false,
                                  }}
                                  helperText={errors.notes ? errors.notes : ""}
                                  error={errors.notes}
                                  touched={touched.notes}
                                  value={values.notes}
                                />
                              </ThemeProvider>
                            </div>
                          </div>
                          <div style={style.step3billing}>
                            <ThemeProvider
                              theme={
                                !isConfirmingEdit
                                  ? values.billingType !==
                                    props.initialValues_service.billingType
                                    ? changedTheme
                                    : defaultMaterialTheme
                                  : values.billingType !==
                                    props.initialValues_service.billingType
                                    ? changedTheme
                                    : viewLogMaterialTheme
                              }
                            >
                              <FormControl className={classes.formControl}>
                                <TextField
                                  labelId="billingType"
                                  id="billingType"
                                  value={billingType}
                                  variant="outlined"
                                  select
                                  label="Bill Type"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    readOnly: isConfirmingEdit ? true : false,
                                  }}
                                  onChange={(e) => {
                                    handleClickBillingType(
                                      e.target.value,
                                      setFieldValue,
                                      values
                                    );
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                >
                                  {["FIXED", "HOURLY", "NO CHARGE"].map(
                                    (bt, index) => {
                                      return <MenuItem value={bt}>{bt}</MenuItem>;
                                    }
                                  )}
                                </TextField>
                                <FormHelperText>
                                  <div style={{ color: primaryColor[0] }}>
                                    {errors.billingType &&
                                      touched.billingType &&
                                      errors.billingType}
                                  </div>
                                </FormHelperText>
                              </FormControl>
                            </ThemeProvider>
                          </div>
                          {billingType && billingType === "FIXED" ? (
                            <div id="divFixed">
                              <div style={style.step3fixedRate}>
                                <ThemeProvider
                                  theme={
                                    !isConfirmingEdit
                                      ? values.fixedRate !==
                                        props.initialValues_service.fixedRate
                                        ? changedTheme
                                        : defaultMaterialTheme
                                      : values.fixedRate !==
                                        props.initialValues_service.fixedRate
                                        ? changedTheme
                                        : viewLogMaterialTheme
                                  }
                                >
                                  <TextField
                                    name="fixedRate"
                                    label="Fixed Amount"
                                    placeholder="0.00"
                                    variant="outlined"
                                    onChange={(e) => {
                                      if (checkMoney(e.target.value)) {
                                        values.fixedRate = e.target.value;
                                        setFieldValue("fixedRate", e.target.value);
                                        values.amount = formatMoney(
                                          formatNumber_(e.target.value)
                                        );
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={!isConfirmingEdit && values.billingType === "FIXED" && (formatNumber_(values.amount) === "0.00" || formatNumber_(values.amount) === 0)}
                                    InputProps={{
                                      readOnly: isConfirmingEdit ? true : false,
                                    }}
                                    onFocus={(e) => {
                                      if (Number.parseFloat(removeComma(values.fixedRate)) === 0) {
                                        values.fixedRate = ""
                                      } else {
                                        values.fixedRate = removeComma(
                                          values.fixedRate
                                        );
                                        setFieldValue("fixedRate", values.fixedRate);
                                      }
                                    }}
                                    onBlur={(e) => {
                                      if (Number.parseFloat(removeComma(values.fixedRate)) === 0 || values.fixedRate === "") {
                                        values.fixedRate = "0.00"
                                      } else {
                                        values.fixedRate = formatMoney(
                                          formatNumber_(values.fixedRate)
                                        );
                                        setFieldValue("fixedRate", values.fixedRate);
                                      }
                                    }}
                                    helperText={
                                      errors.fixedRate ? errors.fixedRate : ""
                                    }
                                    error={errors.fixedRate}
                                    touched={touched.fixedRate}
                                    value={values.fixedRate}
                                  />
                                </ThemeProvider>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {(billingType && billingType === "HOURLY") ||
                            billingType === "NO CHARGE" ? (
                            <div id="divHourly">
                              {billingType === "HOURLY" ? (
                                <div style={style.step3hourly}>
                                  <ThemeProvider
                                    theme={
                                      !isConfirmingEdit
                                        ? values.hourlyRate !==
                                          props.initialValues_service.hourlyRate
                                          ? changedTheme
                                          : defaultMaterialTheme
                                        : values.hourlyRate !==
                                          props.initialValues_service.hourlyRate
                                          ? changedTheme
                                          : viewLogMaterialTheme
                                    }
                                  >
                                    <TextField
                                      name="hourlyRate"
                                      label="Hourly Rate"
                                      placeholder="0.00"
                                      variant="outlined"
                                      onChange={(e) => {
                                        if (checkMoney(e.target.value)) {
                                          if (values.hours !== "") {
                                            setFieldValue(
                                              "amount",
                                              formatMoney(
                                                formatNumber_(
                                                  values.hours * e.target.value
                                                )
                                              )
                                            );
                                          }
                                          values.hourlyRate = e.target.value;
                                          setFieldValue(
                                            "hourlyRate",
                                            e.target.value
                                          );
                                        }
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      required={!isConfirmingEdit && values.billingType !== "NO CHARGE" && (formatNumber_(values.hourlyRate) === "0.00" || formatNumber_(values.hourlyRate) === 0)}
                                      InputProps={{
                                        readOnly: isConfirmingEdit ? true : false,
                                      }}
                                      onFocus={(e) => {
                                        if (Number.parseFloat(removeComma(values.hourlyRate)) === 0) {
                                          values.hourlyRate = ""
                                        } else {
                                          values.hourlyRate = removeComma(
                                            values.hourlyRate
                                          );
                                          setFieldValue("hourlyRate", values.hourlyRate);
                                        }
                                      }}
                                      onBlur={(e) => {
                                        if (Number.parseFloat(removeComma(values.hourlyRate)) === 0 || values.hourlyRate === "") {
                                          values.hourlyRate = "0.00"
                                        } else {
                                          values.hourlyRate = formatMoney(
                                            formatNumber_(values.hourlyRate)
                                          );
                                          setFieldValue("hourlyRate", values.hourlyRate);
                                        }
                                      }}
                                      helperText={
                                        errors.hourlyRate ? errors.hourlyRate : ""
                                      }
                                      error={errors.hourlyRate}
                                      touched={touched.hourlyRate}
                                      value={values.hourlyRate}
                                    />
                                  </ThemeProvider>
                                </div>
                              ) : (
                                ""
                              )}
                              <>
                                <div style={style.step3duration}>
                                  <ThemeProvider
                                    theme={
                                      !isConfirmingEdit
                                        ? values.hours !==
                                          props.initialValues_service.hours
                                          ? changedTheme
                                          : defaultMaterialTheme
                                        : values.hours !==
                                          props.initialValues_service.hours
                                          ? changedTheme
                                          : viewLogMaterialTheme
                                    }
                                  >
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        display: "inline-flex",
                                      }}
                                    >
                                      {errors.hours ? (
                                        <Typography
                                          variant="caption"
                                          style={{ color: primaryColor[0] }}
                                        >
                                          Duration
                                        </Typography>
                                      ) : (
                                        <Typography variant="body1">
                                          Duration
                                        </Typography>
                                      )}
                                    </div>
                                  </ThemeProvider>
                                </div>

                                <ThemeProvider
                                  theme={
                                    !isConfirmingEdit
                                      ? defaultMaterialTheme
                                      : viewLogMaterialTheme
                                  }
                                >
                                  <div style={style.step3hrmin}>
                                    <ThemeProvider
                                      theme={
                                        !isConfirmingEdit
                                          ? values.durationHour !==
                                            props.initialValues_service.durationHour
                                            ? changedTheme
                                            : defaultMaterialTheme
                                          : values.durationHour !==
                                            props.initialValues_service.durationHour
                                            ? changedTheme
                                            : viewLogMaterialTheme
                                      }
                                    >
                                      <FormControl style={{ textAlign: "center" }}>
                                        <TextField
                                          labelId="durationHour"
                                          id="durationHour"
                                          value={durationHour}
                                          select
                                          label="Hours"
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          required={!isConfirmingEdit && values.billingType !== "NO CHARGE" && (formatNumber_(values.hours) === "0.00" || formatNumber_(values.hours) === 0)}
                                          InputProps={{
                                            readOnly: isConfirmingEdit
                                              ? true
                                              : false,
                                          }}
                                          onChange={(e) => {
                                            handleDurationHourChange(
                                              e.target.value,
                                              values,
                                              setFieldValue
                                            );
                                          }}
                                        >
                                          {popHours().map((hr, index) => (
                                            <MenuItem value={hr} key={index}>
                                              {hr}
                                            </MenuItem>
                                          ))}
                                        </TextField>

                                        <FormHelperText
                                          style={{ color: primaryColor[0] }}
                                        >
                                          {errors.durationHours &&
                                            touched.durationHours &&
                                            errors.durationHours}
                                        </FormHelperText>
                                      </FormControl>
                                    </ThemeProvider>
                                    <div />
                                    <FormControl style={{ textAlign: "center" }}>
                                      <ThemeProvider
                                        theme={
                                          !isConfirmingEdit
                                            ? values.durationMinutes !==
                                              props.initialValues_service
                                                .durationMinutes
                                              ? changedTheme
                                              : defaultMaterialTheme
                                            : values.durationMinutes !==
                                              props.initialValues_service
                                                .durationMinutes
                                              ? changedTheme
                                              : viewLogMaterialTheme
                                        }
                                      >
                                        <TextField
                                          labelId="durationMinutes"
                                          id="durationMinutes"
                                          value={durationMinutes}
                                          select
                                          label="Minutes"
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          required={!isConfirmingEdit && values.billingType !== "NO CHARGE" && (formatNumber_(values.hours) === "0.00" || formatNumber_(values.hours) === 0)}
                                          InputProps={{
                                            readOnly: isConfirmingEdit
                                              ? true
                                              : false,
                                          }}
                                          onChange={(e) => {
                                            handleDurationMinutesChange(
                                              e.target.value,
                                              values,
                                              setFieldValue
                                            );
                                          }}
                                        >
                                          {popMinutes().map((min, index) => (
                                            <MenuItem
                                              value={min === 0 ? "00" : min}
                                              key={index}
                                            >
                                              {min === 0 ? "00" : min}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </ThemeProvider>
                                      <FormHelperText
                                        style={{ color: primaryColor[0] }}
                                      >
                                        {errors.durationMinutes &&
                                          touched.durationMinutes &&
                                          errors.durationMinutes}
                                      </FormHelperText>
                                      {/* </div> */}
                                    </FormControl>
                                    <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                                      <Alert onClose={handleCloseAlert} severity="warning">
                                        <AlertTitle>Alert</AlertTitle>
                                        Duration exceeds 8 hours.
                                      </Alert>
                                    </Snackbar>
                                  </div>
                                </ThemeProvider>

                                <ThemeProvider
                                  theme={
                                    !isConfirmingEdit
                                      ? defaultMaterialTheme
                                      : viewLogMaterialTheme
                                  }
                                >
                                  <FormHelperText
                                    style={{
                                      margin: "0 0 0 10px",
                                      color: primaryColor[0],
                                    }}
                                  >
                                    {errors.hours && touched.hours && errors.hours}
                                  </FormHelperText>
                                </ThemeProvider>
                              </>
                              {values.hours &&
                                values.hours !== 0 &&
                                (billingType === "HOURLY" ||
                                  billingType === "NO CHARGE") ? (
                                <>
                                  <div style={style.step3startTimeRender480}>
                                    <ThemeProvider
                                      theme={
                                        !isConfirmingEdit
                                          ? values.startTime !==
                                            props.initialValues_service.startTime
                                            ? changedTheme
                                            : defaultMaterialTheme
                                          : values.startTime !==
                                            props.initialValues_service.startTime
                                            ? changedTheme
                                            : viewLogMaterialTheme
                                      }
                                    >
                                      <Datetime
                                        inputProps={{
                                          name: "startTime",
                                          id: "startTime",
                                          placeholder: "",
                                        }}
                                        isValidDate={enableStartTimeDates}
                                        viewMode="time"
                                        dateFormat=""
                                        timeFormat={true}
                                        value={values.startTime}
                                        logDate={values.date}
                                        timeConstraints={{
                                          hours: { min: 0, max: 23, step: 1 },
                                          minutes: { min: 0, max: 45, step: 15 },
                                          seconds: { min: 0, max: 0, step: 0 },
                                          milliseconds: { min: 0, max: 0, step: 0 },
                                        }}
                                        onChange={(value) => {
                                          handleStartTime(value);
                                          handleStartTimeChange(
                                            value,
                                            values,
                                            setFieldValue
                                          );
                                          if (selectedTime === "")
                                            setSelectedTime("start");
                                        }}
                                        renderInput={function (props, openCalendar) {
                                          return (
                                            <div
                                              style={{
                                                marginTop: "5px",
                                                display: "grid",
                                                gridTemplateColumns: `${size.width <= 320 ? size.width - 90 :
                                                  size.width <= 360 ? size.width - 90 :
                                                    size.width <= 375 ? size.width - 90 :
                                                      size.width <= 414 ? size.width - 90 : 324
                                                  }px`,
                                                gridTemplateRows: "auto auto",
                                              }}
                                            >
                                              <TextField
                                                {...props}
                                                label="Start time (Optional)"
                                                variant="outlined"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                InputProps={{
                                                  readOnly: isConfirmingEdit
                                                    ? true
                                                    : false,
                                                }}
                                              />
                                              {!isConfirmingEdit ? (
                                                <IconButton
                                                  disabled={
                                                    selectedTime === "end"
                                                      ? true
                                                      : false
                                                  }
                                                  size="small"
                                                  color="transparent"
                                                  style={{
                                                    margin: `-60px 0 0 ${size.width <= 320 ? 180 :
                                                      size.width <= 360 ? 220 :
                                                        size.width <= 375 ? 235 :
                                                          size.width <= 414 ? 274 : 274}px`,
                                                  }}
                                                  onClick={openCalendar}
                                                >
                                                  <Icon
                                                    style={{ color: grayColor[0] }}
                                                  >
                                                    schedule
                                                  </Icon>
                                                </IconButton>
                                              ) : null}
                                            </div>
                                          );
                                        }}
                                      />
                                    </ThemeProvider>
                                  </div>

                                  <FormHelperText>
                                    <div
                                      style={{
                                        margin: "0 0 20px 10px",
                                        color: primaryColor[0],
                                      }}
                                    >
                                      {startExceeds.lesserOrGreater === "lesser" &&
                                        startExceeds !== ""
                                        ? `Note: Start time day precedes expense date day (${startExceeds.dateString}).`
                                        : startExceeds.lesserOrGreater ===
                                          "greater" && startExceeds !== ""
                                          ? `Note: Start time day exceeds expense date day (${startExceeds.dateString}).`
                                          : ""}
                                    </div>
                                  </FormHelperText>

                                  <FormHelperText>
                                    <div
                                      style={{
                                        margin: "10px 0 10px 10px",
                                        color: primaryColor[0],
                                      }}
                                    >
                                      {errors.startTime &&
                                        touched.startTime &&
                                        errors.startTime}
                                    </div>
                                  </FormHelperText>

                                  <div style={style.step3endTimeRender480}>
                                    <ThemeProvider
                                      theme={
                                        !isConfirmingEdit
                                          ? values.endTime !==
                                            props.initialValues_service.endTime
                                            ? changedTheme
                                            : defaultMaterialTheme
                                          : values.endTime !==
                                            props.initialValues_service.endTime
                                            ? changedTheme
                                            : viewLogMaterialTheme
                                      }
                                    >
                                      <Datetime
                                        inputProps={{
                                          name: "endTime",
                                          id: "endTime",
                                          placeholder: "",
                                        }}
                                        isValidDate={enableEndTimeDates}
                                        viewMode="time"
                                        dateFormat=""
                                        timeFormat={true}
                                        value={values.endTime}
                                        logDate={values.date}
                                        timeConstraints={{
                                          minutes: { min: 0, max: 45, step: 15 },
                                          seconds: { min: 0, max: 0, step: 0 },
                                          milliseconds: { min: 0, max: 0, step: 0 },
                                        }}
                                        onChange={(value) => {
                                          handleEndTime(value);
                                          handleEndTimeChange(
                                            value,
                                            values,
                                            setFieldValue
                                          );
                                          if (selectedTime === "")
                                            setSelectedTime("end");
                                        }}
                                        renderInput={function (props, openCalendar) {
                                          return (
                                            <div
                                              style={{
                                                // style.step3TimeRenderInput480
                                                marginTop: "5px",
                                                display: "grid",
                                                gridTemplateColumns: `${size.width <= 320 ? size.width - 90 :
                                                  size.width <= 360 ? size.width - 90 :
                                                    size.width <= 375 ? size.width - 90 :
                                                      size.width <= 414 ? size.width - 90 : 324
                                                  }px`,
                                                gridTemplateRows: "auto auto",
                                              }}
                                            >
                                              <TextField
                                                {...props}
                                                label="End time (Optional)"
                                                variant="outlined"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                InputProps={{
                                                  readOnly: isConfirmingEdit
                                                    ? true
                                                    : false,
                                                }}
                                              />
                                              {!isConfirmingEdit ? (
                                                <IconButton
                                                  disabled={
                                                    selectedTime === "start"
                                                      ? true
                                                      : false
                                                  }
                                                  size="small"
                                                  color="transparent"
                                                  style={{
                                                    margin: `-60px 0 0 ${size.width <= 320 ? 180 :
                                                      size.width <= 360 ? 220 :
                                                        size.width <= 375 ? 235 :
                                                          size.width <= 414 ? 274 : 274}px`,
                                                  }}
                                                  onClick={openCalendar}
                                                >
                                                  <Icon
                                                    style={{ color: grayColor[0] }}
                                                  >
                                                    schedule
                                                  </Icon>
                                                </IconButton>
                                              ) : null}
                                            </div>
                                          );
                                        }}
                                      />
                                    </ThemeProvider>
                                  </div>

                                  <FormHelperText>
                                    <div
                                      style={{
                                        margin: "-10px 0 0 10px",
                                        color: primaryColor[0],
                                      }}
                                    >
                                      {errors.endTime &&
                                        touched.endTime &&
                                        errors.endTime}
                                    </div>
                                  </FormHelperText>

                                  {billingType === "HOURLY" ? (
                                    <div style={style.step3amount}>
                                      <ThemeProvider
                                        theme={
                                          !isConfirmingEdit
                                            ? values.amount !==
                                              props.initialValues_service.amount
                                              ? changedTheme
                                              : defaultMaterialTheme
                                            : values.amount !==
                                              props.initialValues_service.amount
                                              ? changedTheme
                                              : viewLogMaterialTheme
                                        }
                                      >
                                        <TextField
                                          name="amount"
                                          label="Amount"
                                          variant="outlined"
                                          onChange={(e) => {
                                            e.target.value = values.amount;
                                            setAmount(e.target.value);
                                          }}
                                          value={values.amount}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                      </ThemeProvider>
                                    </div>
                                  ) : null}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <Typography
                            style={{
                              textAlign: "center", fontSize: '16px', color: primaryColor[0], marginTop: '10px',
                              display: (
                                !isConfirmingEdit &&
                                (
                                  (values.activityL1.length === 0 || values.activityL2.includes("?") || values.activityL3.includes("?")) ||
                                  values.activityL2.includes("?") ||
                                  values.activityL3.includes("?") ||
                                  (values.billingType === "FIXED" && (formatNumber_(values.amount) === "0.00" || formatNumber_(values.amount) === 0)) ||
                                  (values.billingType === "HOURLY" && (formatNumber_(values.hourlyRate) === "0.00" || formatNumber_(values.hourlyRate) === 0)) ||
                                  (values.billingType === "HOURLY" && (formatNumber_(values.hours) === "0.00" || formatNumber_(values.hours) === 0))
                                )

                              )
                                ? 'block' : 'none'
                            }}>
                            <em>* Required</em>
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <DialogActions>
                      <ThemeProvider theme={defaultBtnMaterialTheme}>
                        {/* {!isConfirmingEdit ? */}
                        <>
                          <Button
                            style={{ width: "60%" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault()
                              handleSubmit();
                            }}
                            disabled={
                              !isConfirmingEdit &&
                              (
                                (values.activityL1.length === 0 || values.activityL2.includes("?") || values.activityL3.includes("?")) ||
                                values.activityL2.includes("?") ||
                                values.activityL3.includes("?") ||
                                (values.billingType === "FIXED" && (formatNumber_(values.amount) === "0.00" || formatNumber_(values.amount) === 0)) ||
                                (values.billingType === "HOURLY" && (formatNumber_(values.hourlyRate) === "0.00" || formatNumber_(values.hourlyRate) === 0)) ||
                                (values.billingType === "HOURLY" && (formatNumber_(values.hours) === "0.00" || formatNumber_(values.hours) === 0))
                              )
                            }
                          >
                            Save
                          </Button>
                          <Button
                            style={{ width: "40%" }}
                            variant="outlined"
                            color="primary"
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault()
                              setIsConfirmingEdit(false);
                              handleCloseUpdateServiceLog();
                            }}
                          >
                            Cancel
                          </Button>
                        </>

                      </ThemeProvider>
                    </DialogActions>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </ThemeProvider>
      </Dialog>

      <ConfirmServiceUpdate
        Transition={props.Transition}
        openConfirmUpdateServiceLog={openConfirmUpdateServiceLog}
        handleCancelConfirmUpdateServiceLog={
          props.handleCancelConfirmUpdateServiceLog
        }
        setOpenConfirmUpdateServiceLog={setOpenConfirmUpdateServiceLog}
        setOpenUpdateServiceLog={props.setOpenUpdateServiceLog}
        setIsOpenViewLog={props.setIsOpenViewLog}
        setIsOpenViewUpdate={props.setIsOpenViewUpdate}
        handleCloseUpdateServiceLog={handleCloseUpdateServiceLog}
        isOpenWaitingOnConfirm={isOpenWaitingOnConfirm}
        setIsOpenWaitingOnConfirm={setIsOpenWaitingOnConfirm}
      />
    </>
  );
};

export const UPDATE_SERVICE_LOG = gql`
  mutation updateActivityLog(
    $activityLogId: ID!
    $date: DateTime!
    $billingType: String
    $fixedRate: String
    $hourlyRate: String
    $hours: String
    $amount: String
    $activityLogType: String!
    $activityLogSubType: String
    $activityLogSubSubType: String
    $notes: String
    $startTime: DateTime
    $endTime: DateTime
    $caseId: ID!
    $memberId: ID!
    $clientId: ID!
    $actionDate: DateTime
    $action: String
    $parentId: ID
    $status: String
    $ord: Int
  ) {
    updateActivityLog(
      activityLogId: $activityLogId
      date: $date
      billingType: $billingType
      fixedRate: $fixedRate
      hourlyRate: $hourlyRate
      hours: $hours
      amount: $amount
      activityLogType: $activityLogType
      activityLogSubType: $activityLogSubType
      activityLogSubSubType: $activityLogSubSubType
      notes: $notes
      startTime: $startTime
      endTime: $endTime
      caseId: $caseId
      memberId: $memberId
      clientId: $clientId
      actionDate: $actionDate
      action: $action
      parentId: $parentId
      status: $status
      ord: $ord
    ) {
      id
      date
      billingType
      fixedRate
      hourlyRate
      hours
      amount
      activityLogType
      activityLogSubType
      activityLogSubSubType
      notes
      startTime
      endTime
      parentId
      ord
      status
    }
  }
`;

export default withStyles(style)(
  withStyles(javascriptStyles)(
    withStyles(basicsStyle)(withStyles(modalStyle)(ServiceUpdate))
  )
);
