import React, { useState } from "react";
// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";
import Paper from "@material-ui/core/Paper";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import useWindowSize from 'utils/useWindowSize';

import {
  Typography,
  Button,
  debounce,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import Search from "./Search";
// import ConfirmDialog from "./ConfirmDialog";
import EditCase from "./EditCase";
import {
  UPDATE_CASE_MUTATION,
  DELETE_CASE_MUTATION,
  CREATE_CASE_MUTATION,
} from "utils/Graphql/mutations";
import {
  CASES_BY_CLIENT_NOLOGS_QUERY,
  CASE_FILTER,
} from "utils/Graphql/queries";
// import FailDialog from "./failDialog";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
// import errorMaterialTheme from "utils/Themes/errorMaterialTheme";
// import FormHelperText from "@material-ui/core/FormHelperText";
import DialogActions from "@material-ui/core/DialogActions";
import circlelogo from "assets/img/byn-logo-circle.png";
// import { useMediaQuery } from "react-responsive";
import { primaryColor, grayColor } from "assets/jss/material-kit-pro-react.js";
import { Formik, Form } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import Transition from "utils/Transition";
import TextField from "@material-ui/core/TextField";
import { createUserLog } from "utils/CreateUserLog";



var _CasesPortfolio = {
  caseCode: "",
  caseTitle: "",
  line: [],
  cl: "",
};

const style = {
  grid: {
    width: "80%",
  },
  step1: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
  },
  step1Client: {
    display: "grid",
    gridTemplateColumns: "1fr ",
    gridTemplateRows: "auto auto",
    marginBottom: "40px",
  },
};

const noData = "Client has no active case.";
const CasesPortfolio = (props) => {
  const {
    authUser,
    member,
    // clientData,
    clientCases,
    classes,
    client,
    // setOpenCases,
    // reFetchClientsByEmail,
    reFetchCases,
    setPage,
    currentClient,
    hasDeleteRequest,
  } = props;

  const size = useWindowSize()

  const [openNotification, setOpenNotification] = useState(false);
  const [openEditNotification, setOpenEditNotification] = useState(false);
  const [openDeleteNotification, setOpenDeleteNotification] = useState(false);
  // const [openStatusNotification, setOpenStatusNotification] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openEditSuccess, setOpenEditSuccess] = useState(false);
  const [openNoChange, setOpenNoChange] = useState(false);
  const [line1, setLine1] = useState("");
  const [line3, setLine3] = useState("");
  // const [logoLeftMargin, setLogoLeftMargin] = useState("0px");
  // const [closeRightMargin, setCloseRightMargin] = useState("0px");
  const [cases, setCases] = useState(clientCases);
  const [openEditCase, setOpenEditCase] = useState(false);
  // const [openDeleteCase, setOpenDeleteCase] = useState(false);
  const [openAddCase, setOpenAddCase] = useState(false);
  const [currentCase, setCurrentCase] = useState("");
  const [values, setValues] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("Are you sure?");
  const [dialogTitle, setDialogTitle] = useState("Confirmation");
  // const [openEditClient, setOpenEditClient] = useState(false);
  // const [openDeleteClient, setOpenDeleteClient] = useState(false);
  const [action, setAction] = useState("");
  const [noDataMessage, setNoDataMessage] = useState(noData);
  const [openFail, setOpenFail] = useState(false);
  const [failMessage, setFailMessage] = useState("");
  const [failTitle, setFailTitle] = useState("");
  const [editValues, setEditValues] = useState({});

  const getActiveCases = (cases) => {
    const isActive = (_case) => {
      return _case === "ACTIVE";
    };
  };

  const getLogs = (_case) => {
    const activityLogs = _case.activityLog.filter((cl, index) => {
      return cl.status === "ACTIVE";
    });
    console.log(activityLogs);

    const expenseLogs = _case.expenseLog.filter((cl, index) => {
      return cl.status === "ACTIVE";
    });
    console.log(expenseLogs);

    return activityLogs.length + expenseLogs.length;
  };

  const getActivityLogs = (_case) => {
    const activityLogs = _case.activityLog.filter((cl, index) => {
      return cl.status === "ACTIVE";
    });
    console.log(activityLogs);
    return activityLogs.length;
  };

  const getExpenseLogs = (_case) => {
    const expenseLogs = _case.expenseLog.filter((cl, index) => {
      return cl.status === "ACTIVE";
    });
    console.log(expenseLogs);
    return expenseLogs.length;
  };

  const addCase = async (values) => {
    const { caseCode, caseTitle } = values;
    const memberId = member.id;
    const clientId = currentClient.id;

    let fixedRate = 0.0;
    try {
      const response = client.mutate({
        mutation: CREATE_CASE_MUTATION,
        variables: {
          caseCode: caseCode.toUpperCase(),
          caseTitle: caseTitle.toUpperCase(),
          billingType: "",
          fixedRate,
          memberId,
          clientId,
        },
      });
      createUserLog(props, "CREATE NEW CASE", "Created a new Case", "")
      return response;
    } catch (error) {
      throw error;
    }
  };

  const searchCases = async (filter) => {
    const clientId = currentClient.id;
    console.log(clientId);
    try {
      const response = await client.query({
        query: CASE_FILTER,
        variables: {
          clientId,
          filter,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = debounce((filter) => {
    console.log(filter);
    searchCases(filter)
      .then((response) => {
        createUserLog({ authUser, client }, "CASE", "Search", filter)
        console.log(response);
        if (response.data) {
          const filteredCases = response.data.casesFilter;
          if (clientCases.length > 0) {
            if (filteredCases.length === 0)
              setNoDataMessage("No search results");
          } else {
            setNoDataMessage(noData);
          }
          setCases(filteredCases);
        } else {
          console.log("haha backend or network error");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  }, 250);

  const handleCloseNotification = () => {
    debugger;
    setOpenNotification(false);
  };

  const handleSearchFilter = (e) => {
    // console.log(e.target.value)
    onSearch(e.target.value);
  };

  const handleSubmit = () => {
    if (action === "edit") {
      handleSubmitEditCase();
    } else if (action === "predelete") {
      handleOpenDeleteCaseConfirm(_CasesPortfolio.cl);
    } else if (action === "delete") {
      handleSubmitDeleteCase();
    } else if (action === "add") {
      handleSubmitAddCase();
    }
  };

  const handleOpenEditCaseConfirm = (values) => {
    handleSubmitEditCase(values);
  };

  const handleSubmitEditCase = (values) => {
    setOpenEditCase(false);
    setEditValues(values);
    updateCase(values)
      .then((response) => {
        createUserLog({ authUser, client }, "CASE", "Edited case data", "")
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        setOpenNoChange(true);
      })
      .finally(() => {
        // reFetchCases();
      });
  };
  const handleSubmitAddCase = () => {
    console.log(values);
    setOpenConfirm(false);
    setOpenAddCase(false);
    addCase(values)
      .then((response) => {
        createUserLog({ authUser, client }, "CASE", "Added a case", "")
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        reFetchCases();
      });
  };

  const handleSubmitDeleteCase = () => {
    console.log("delete test");
    setOpenConfirm(false);
    deleteCase()
      .then((response) => {
        createUserLog({ authUser, client }, "CASE", "Deleted a case", "")
        console.log(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        reFetchCases();
      });
  };

  const handleCloseEditCase = () => {
    setOpenEditCase(false);
  };

  const handleOpenAddCase = async () => {
    setOpenAddCase(true);
  };

  const handleCloseConfirmEdit = () => {
    setOpenConfirm(false);
  };

  const handleCloseAddCase = () => {
    setOpenAddCase(false);
  };

  const handleClickBack = () => {
    // reFetchClientsByEmail();
    // setOpenCases(false)
    setPage("clients");
  };

  const handleOpenFail = () => {
    setFailMessage("This case has logs.");
    setFailTitle("Delete Case");
    setOpenFail(true);
  };

  const handleCloseFail = () => {
    setOpenFail(false);
  };

  const handleOpenPreDeleteClientConfirm = (_case) => {
    debugger;
    setDialogTitle("Delete Client");
    setAction("predelete");
    setCurrentCase(_case);
    setDialogMessage("Are you sure you want to delete?");
    setOpenConfirm(true);
  };

  const handleOpenDeleteCaseConfirm = (_case) => {
    debugger;
    // setAction("delete");
    // setDialogTitle("Confirmation...");
    // setCurrentCase(_case);
    // setDialogMessage(
    //   "Deleted data cannot be recovered.  Are you sure you want to delete?"
    // );
    // setOpenConfirm(true);
    setOpenDeleteNotification(true);
  };

  const handleOpenAddCaseConfirm = (values) => {
    setAction("add");
    setValues(values);
    setDialogTitle("Add Case");
    setDialogMessage("Are you sure you want to add this case?");
    setOpenConfirm(true);
  };

  const callCreateCase = async (values, setFieldValue) => {
    return new Promise((resolve, reject) => {
      const { caseCode, caseTitle } = values;
      const { id } = member;
      const clientId = props.currentClient.id;
      debugger;
      client
        .query({
          query: CASES_BY_CLIENT_NOLOGS_QUERY,
          variables: {
            clientId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { casesByClient } = response.data;
          // console.log("casesByClient");
          console.log(casesByClient);

          debugger;
          let case_ = [];
          casesByClient
            .filter(
              (s) =>
                (s.status === "ACTIVE" || s.status === "INACTIVE")
            )
            .filter(
              (s) =>
                (s.caseCode === caseCode && s.caseTitle === caseTitle)
            )
            .map((s) => {
              case_.push({
                caseCode: s.codeCode,
                caseTitle: s.caseTitle,
              });
            });

          if (case_.length === 1) {
            let s = "";
            if (caseCode && caseTitle) {
              s = s + caseTitle + " (" + caseCode + ")";
            } else if (caseCode && !caseTitle) {
              s = s + " (" + caseCode + ")";
            } else if (!caseCode && caseTitle) {
              s = s + caseTitle
            } else if (!caseCode && !caseTitle) {
              s = s + "";
            }
            setLine1(s);
            reject(new Error(s));
          } else {
            let cases_ = [];
            debugger
            casesByClient
              .filter(
                (s) =>
                  (s.status === "ACTIVE" || s.status === "INACTIVE")
              )
              .filter(
                (s) =>
                  (s.caseCode === caseCode) ||
                  (s.caseTitle &&
                    caseTitle &&
                    s.caseTitle.length > 0 &&
                    caseTitle.length > 0 &&
                    (nrmlizeTitle(s.caseTitle) === nrmlizeTitle(caseTitle))
                  )
              )
              .map((s) => {
                cases_.push({
                  caseCode: s.caseCode,
                  caseTitle: s.caseTitle,
                  status: s.status
                });
              });
            debugger
            if (cases_.length > 0) {
              let s = "";
              if (caseCode && caseTitle) {
                s = caseTitle + " (" + caseCode + ")";
              } else if (caseCode && !caseTitle) {
                s = " (" + caseCode + ")";
              } else if (!caseCode && caseTitle) {
                s = caseTitle
              } else if (!caseCode && !caseTitle) {
                s = "";
              }
              console.log('s entered reference');
              console.log(s);
              setLine3(s);
              let ts = "";
              console.log('cases_');
              console.log(cases_);
              debugger
              _CasesPortfolio.line = [];
              for (let i = 0; i < cases_.length; i++) {
                if (cases_[i].caseCode && cases_[i].caseTitle) {
                  ts = cases_[i].caseTitle + " (" + cases_[i].caseCode + ")";
                } else if (cases_[i].caseCode && !cases_[i].caseTitle) {
                  ts = " (" + cases_[i].caseCode + ")";
                } else if (!cases_[i].caseCode && cases_[i].caseTitle) {
                  ts = cases_[i].caseTitle;
                }

                if (_CasesPortfolio.line.filter((ss) => ss === ts).length === 0)
                  _CasesPortfolio.line.push(ts);
              }
              setOpenNotification(true);
            } else {
              let fixedRate = 0.0;
              client
                .mutate({
                  mutation: CREATE_CASE_MUTATION,
                  variables: {
                    caseCode,
                    caseTitle,
                    billingType: "",
                    fixedRate,
                    memberId: id,
                    clientId,
                  },
                })
                .then((response) => {
                  setOpenAddCase(false);
                  setOpenSuccess(true);
                });
              debugger;
            }
          }
        });
    });
  };

  const createCase = async (values) => {
    const { id } = member;
    const clientId = props.currentClient.id;
    let fixedRate = 0.0;

    debugger;
    return await client
      .mutate({
        mutation: CREATE_CASE_MUTATION,
        variables: {
          caseCode: _CasesPortfolio.caseCode.toUpperCase(),
          caseTitle: _CasesPortfolio.caseTitle.toUpperCase(),
          billingType: "",
          fixedRate,
          memberId: id,
          clientId,
        },
      })
      .then((response) => {
        createUserLog(props, "CREATE NEW CASE", "Created a new Case", "")
        debugger;
        console.log(response);
        const { id } = response.data.createCase;
        debugger;
        setOpenAddCase(false);
        setOpenSuccess(true);
      });
    debugger;
  };

  // function nrmlizeTitle(caseTitle) {
  //   let list_phils = [
  //     "PHILS.", "PHILS",
  //     "PHL", "PHL.",
  //     "PHIL", "PHIL.",
  //     "PH", "PH.",
  //     "PHILIPPINES"
  //   ]
  //   let list_people = [
  //     "PEOPLE", "PEEPL", "PEEPLE", "PEOPL", "PEPLE"]
  //   let list_vs = [
  //     "VS", "VS.",
  //     "V", "V."]

  //   let people = false
  //   let of_ = false
  //   let ofOrd = 0
  //   let the = false
  //   let theOrd = 0
  //   let phils = false
  //   let vs = false
  //   let retValue = ''
  //   let temp = caseTitle.toUpperCase().split(" ")
  //   for (let i = 0; i < temp.length; ++i) {
  //     let t = temp[i].trim()
  //     for (let j = 0; j < list_people.length; j++) {
  //       if (t === list_people[j]) {
  //         people = true
  //         break
  //       }
  //     }
  //     if (t === "OF") {
  //       of_ = true
  //       ofOrd = i
  //     }
  //     if (t === "THE") {
  //       the = true
  //       theOrd = i
  //     }
  //     for (let j = 0; j < list_phils.length; j++) {
  //       if (t === list_phils[j]) {
  //         phils = true
  //         break
  //       }
  //     }
  //     for (let j = 0; j < list_vs.length; j++) {
  //       if (t === list_vs[j]) {
  //         vs = true
  //         break
  //       }
  //     }


  //     if (!(phils || people || vs || (people && of_ && the && (theOrd - ofOrd === 1)) || (of_ && the && phils && (theOrd - ofOrd === 1)))) {
  //       retValue = retValue + t + " "
  //     }
  //   }
  //   return retValue.trim()

  // }

  function nrmlizeTitle(caseTitle) {
    console.log("")
    console.log('nrmlizeTitle')
    console.log(caseTitle)
    let list_phils = [
      "PHILS.", "PHILS",
      "PHL", "PHL.",
      "PHIL", "PHIL.",
      "PH", "PH.",
      "PHILIPPINES"
    ]
    let list_people = [
      "PEOPLE", "PEEPL", "PEEPLE", "PEOPL", "PEPLE"]
    let list_vs = [
      "VS", "VS.",
      "V", "V."]

    let people = false
    let peopleOrd = 0
    let ofthe = false
    let oftheOrd = 0
    let phils = false
    let philsOrd = 0
    let vs = false
    let vsOrd = 0
    let retValue = ''
    let temp = caseTitle.toUpperCase().split(" ")
    for (let i = 0; i < temp.length; ++i) {
      let latest = false
      let t = temp[i].trim()
      console.log('-----iter; ' + i + " " + t)
      for (let j = 0; j < list_people.length; j++) {
        if (t === list_people[j]) {
          people = true
          peopleOrd = i
          latest = true
          break
        }
      }
      if (t === "OF" && (((i + 1) < temp.length) && (temp[i + 1] === "THE"))) {
        ofthe = true
        oftheOrd = (i + 1)
        i = i + 1
        latest = true
      }
      for (let j = 0; j < list_phils.length; j++) {
        if (t === list_phils[j]) {
          phils = true
          philsOrd = i
          latest = true
          break
        }
      }
      for (let j = 0; j < list_vs.length; j++) {
        if (t === list_vs[j]) {
          vs = true
          vsOrd = i
          latest = true
          break
        }
      }

      console.log('  people: ' + people + ' ' + peopleOrd)
      console.log('  ofthe: ' + ofthe + ' ' + oftheOrd)
      console.log('  phils: ' + phils + ' ' + philsOrd)
      console.log('  vs: ' + vs + ' ' + vsOrd)

      if (people || phils ||
        vs
        ||
        (people && vs && (vsOrd - peopleOrd === 1)) ||
        (phils && vs && (vsOrd - philsOrd === 1)) ||
        (people && vs && (peopleOrd - vsOrd === 1)) ||
        (phils && vs && (philsOrd - vsOrd === 1)) ||
        // of the
        (people && ofthe && (oftheOrd - peopleOrd === 1)) ||
        (ofthe && phils && (philsOrd - oftheOrd === 1))
      ) {
        if (!latest) {
          retValue = retValue + t + " "
          console.log('  YES')
          console.log('   ' + retValue.trim())
        } else {
          console.log('  NO..no...')
          console.log('  ' + retValue.trim())
        }
      } else {
        if (i === 0) {
          retValue = retValue + t + " "
          console.log('  YES..yes..')
          console.log('  ' + retValue.trim())
        } else {
          console.log('  NO...')
          console.log('  ' + retValue.trim())
        }
      }
    }
    console.log('======' + retValue.trim())
    return retValue.trim()
  }

  const updateCase = async (values, setFieldValue) => {
    return new Promise((resolve, reject) => {
      // const { _client, _member } = props;
      const { id } = member;
      const { caseCode, caseTitle } = values;
      const clientId = props.currentClient.id;
      const caseId = currentCase.id;

      debugger;
      client
        .query({
          query: CASES_BY_CLIENT_NOLOGS_QUERY,
          variables: {
            clientId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { casesByClient } = response.data;
          console.log("casesByClient");
          console.log(casesByClient);

          debugger;
          let case_ = [];
          casesByClient
            .filter(
              (s) =>
                (s.status === "ACTIVE" || s.status === "INACTIVE") &&
                s.id === caseId &&
                s.caseCode === caseCode &&
                s.caseTitle === caseTitle
            )
            .map((s) => {
              case_.push({
                id: s.id,
                caseCode: s.codeCode,
                caseTitle: s.caseTitle,
              });
            });

          debugger;
          if (case_.length === 1) {
            debugger;
            let s = "";
            if (caseCode && caseTitle) {
              s = s + caseTitle + " (" + caseCode + ")";
            } else if (caseCode && !caseTitle) {
              s = s + " (" + caseCode + ")";
            } else if (!caseCode && caseTitle) {
              s = s + caseTitle
            } else if (!caseCode && !caseTitle) {
              s = s + "";
            }
            setLine1(s);
            reject(new Error(s));
          } else {
            debugger;
            let cases_ = [];
            casesByClient
              .filter(
                (s) =>
                  (s.status === "ACTIVE" || s.status === "INACTIVE")
              )
              .filter(
                (s) =>
                  (s.caseCode === caseCode) ||
                  (s.caseTitle &&
                    caseTitle &&
                    s.caseTitle.length > 0 &&
                    caseTitle.length > 0 &&
                    (nrmlizeTitle(s.caseTitle) === nrmlizeTitle(caseTitle))
                  )
              )
              .map((s) => {
                cases_.push({
                  id: s.id,
                  caseCode: s.caseCode,
                  caseTitle: s.caseTitle,
                  status: s.status
                });
              });

            debugger
            if (cases_.length > 0) {

              if (cases_.length === 1 && caseId === cases_[0].id) {
                debugger;
                let fixedRate = 0.0;
                const { caseCode, caseTitle, status } = values;
                const { id, member, parentId, ord } = currentCase;

                client
                  .mutate({
                    mutation: UPDATE_CASE_MUTATION,
                    variables: {
                      memberId: member.id,
                      clientId: currentCase.client.id,
                      caseId: id,
                      caseCode: caseCode.toUpperCase(),
                      caseTitle: caseTitle.toUpperCase(),
                      parentId,
                      ord,
                      status,
                      actionDate: new Date().toISOString(),
                    },
                  })
                  .then((response) => {
                    createUserLog(props, "EDIT CASE", "Edited Case info", "")
                    setOpenEditCase(false);
                    setOpenEditSuccess(true);
                  });
                debugger;
              }
              else {

                let s = "";
                if (caseCode && caseTitle) {
                  s = caseTitle + " (" + caseCode + ")";
                } else if (caseCode && !caseTitle) {
                  s = " (" + caseCode + ")";
                } else if (!caseCode && caseTitle) {
                  s = caseTitle
                } else if (!caseCode && !caseTitle) {
                  s = "";
                }
                console.log('s entered reference');
                console.log(s);
                setLine3(s);
                let ts = "";
                console.log('cases_');
                console.log(cases_);
                debugger
                _CasesPortfolio.line = [];
                for (let i = 0; i < cases_.length; i++) {
                  if (cases_[i].caseCode && cases_[i].caseTitle) {
                    ts = cases_[i].caseTitle + " (" + cases_[i].caseCode + ")";
                  } else if (cases_[i].caseCode && !cases_[i].caseTitle) {
                    ts = " (" + cases_[i].caseCode + ")";
                  } else if (!cases_[i].caseCode && cases_[i].caseTitle) {
                    ts = cases_[i].caseTitle
                  }

                  if (_CasesPortfolio.line.filter((ss) => ss === ts).length === 0)
                    _CasesPortfolio.line.push(ts);
                }
                setLine1(ts);
                setEditValues(values);
                setOpenEditNotification(true);
              }
            } else {
              debugger;
              let fixedRate = 0.0;
              const { caseCode, caseTitle, status } = values;
              const { id, member, parentId, ord } = currentCase;

              client
                .mutate({
                  mutation: UPDATE_CASE_MUTATION,
                  variables: {
                    memberId: member.id,
                    clientId: currentCase.client.id,
                    caseId: id,
                    caseCode: caseCode.toUpperCase(),
                    caseTitle: caseTitle.toUpperCase(),
                    parentId,
                    ord,
                    status,
                    actionDate: new Date().toISOString(),
                  },
                })
                .then((response) => {
                  createUserLog(props, "EDIT CASE", "Edited Case info", "")
                  setOpenEditCase(false);
                  setOpenEditSuccess(true);
                });
              debugger;
            }
          }
        });
    });
  };

  const directUpdateCase = async () => {
    const { caseCode, caseTitle, status } = editValues;
    const { id, member, parentId, ord } = currentCase;
    console.log(currentCase);
    console.log(id, member, client);

    try {
      const response = await client.mutate({
        mutation: UPDATE_CASE_MUTATION,
        variables: {
          memberId: member.id,
          clientId: currentCase.client.id,
          caseId: id,
          caseCode: caseCode.toUpperCase(),
          caseTitle: caseTitle.toUpperCase(),
          parentId,
          ord,
          status,
          actionDate: new Date().toISOString(),
        },
      });
      createUserLog(props, "EDIT CASE", "Edited Case info", "")
      return response;
    } catch (error) {
      throw error;
    }
  };

  const deleteCase = async () => {
    console.log(currentCase);
    const { id } = currentCase;
    console.log(id);
    try {
      const response = await client.mutate({
        mutation: DELETE_CASE_MUTATION,
        variables: {
          caseId: id,
        },
      });
      createUserLog(props, "DELETE CASE", "Deleted a Case", "")
      return response;
    } catch (error) {
      throw error;
    }
  };

  function casesOnGrid(item) {
    return item.caseCode && item.caseTitle
      ? item.caseCode + " - " + item.caseTitle
      : item.caseCode && !item.caseTitle
        ? item.caseCode
        : !item.caseCode && item.caseTitle
          ? item.caseTitle
          : "-";
  }

  function clientOnGrid(item) {
    return item.lastName && item.firstName
      ? item.lastName + ", " + item.firstName
      : item.lastName && !item.firstName
        ? item.lastName
        : !item.lastName && item.firstName
          ? item.firstName
          : null;
  }

  const spreadCases = () => {
    console.log(cases);
    const activeCases = cases.filter((cl) => {
      if (cl) {
        if (cl.status === "ACTIVE" || cl.status === "INACTIVE") return cl;
      }
    });

    console.log(activeCases);
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={true}
        // TransitionComponent={props.Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0px`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Cases
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -12}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>

              <div
                style={{
                  marginLeft: `-10px`,
                }}
              >
                {/* <div
                style={{
                  marginLeft: `${size.width <= 320
                    ? -10
                    : size.width <= 360
                      ? -10
                      : size.width <= 375
                        ? -10
                        : size.width <= 414
                          ? -10
                          : -10
                    }px`,
                }}
              > */}
                <div
                  className={classes.container}
                  style={{
                    width: `${size.width <= 414 ? size.width - 40 : 360}px`,
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "center",
                      margin: "20px  0 0 0",
                    }}
                  >
                    {clientOnGrid(currentClient)
                      ? clientOnGrid(currentClient)
                      : "----------"}
                  </Typography>
                  <Typography
                    style={{ textAlign: "center", margin: "0 0 20px 0" }}
                  >
                    {currentClient.company
                      ? "(" + currentClient.company + ")"
                      : "---------"}
                  </Typography>
                </div>
              </div>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <div
          className={classes.container}
          style={{
            width: `${size.width <= 414 ? size.width - 40 : 360}px`,
          }}
        >
          {hasDeleteRequest ? (
            <Typography style={{ margin: "30px 70px -30px 70px" }}>
              You have a pending delete request. Cancel the delete request to
              add, edit, and delete clients and cases.
            </Typography>
          ) : null}

          <div
            style={{
              overflow: "auto",
              height: `${size.height - 279}px`,
            }}
          >
            {activeCases && activeCases.length > 0 ? (
              activeCases
                .sort(function (a, b) {
                  let namea = a.caseCode + a.caseTitle;
                  let nameb = b.caseCode + b.caseTitle;
                  return namea.localeCompare(nameb);
                })
                .map((cl, index) => {
                  console.log(cl);
                  if (cl)
                    return (
                      <Paper
                        className={classes.container}
                        style={{
                          padding: "10px 0 10px 0",
                          margin: "0 0 15px 20px",
                          width: `${size.width <= 414 ? size.width - 90 : 290
                            }px`,
                        }}
                      >
                        <Typography style={{ textAlign: "center" }}>
                          {cl.caseTitle ? cl.caseTitle : null}
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            marginBottom: "10px",
                          }}
                        >
                          {cl.caseCode ? "(" + cl.caseCode + ")" : null}
                        </Typography>
                        <Typography style={{ textAlign: "center" }}>
                          Status: {cl.status}
                        </Typography>
                        <Typography style={{ textAlign: "center" }}>
                          Logs: {getLogs(cl)}
                        </Typography>

                        <ThemeProvider theme={defaultBtnMaterialTheme}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0 10px 0",
                            }}
                          >
                            <Button
                              style={{ margin: "0 10px 0 0" }}
                              variant="contained"
                              color="primary"
                              round
                              disableElevation
                              onClick={() => {
                                setCurrentCase(cl);
                                setOpenEditCase(true);
                              }}
                              disabled={hasDeleteRequest}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              round
                              disableElevation
                              disabled={hasDeleteRequest}
                              onClick={() => {
                                // if (
                                //   getActivityLogs(cl) +
                                //     getExpenseLogs(cl) >
                                //   0
                                // )
                                //   handleOpenFail();
                                // else {
                                _CasesPortfolio.cl = cl;
                                setCurrentCase(cl);
                                handleOpenDeleteCaseConfirm(cl);
                                // }
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </ThemeProvider>
                      </Paper>
                    );
                })
            ) : (
              <Typography style={{ fontSize: "26px", textAlign: "center" }}>
                {noDataMessage}
              </Typography>
            )}
          </div>

          <DialogActions className={classes.modalFooterCenter}>
            <Search
              handleSearchFilter={handleSearchFilter}
              placeHolder="Code or Title"
            />
          </DialogActions>
          <DialogActions className={classes.modalFooter}>
            <ThemeProvider theme={defaultBtnMaterialTheme}>
              <Button
                // style={{ width: "150px" }}
                style={{ width: "67%" }}

                variant="contained"
                color="primary"
                size="medium"
                onClick={handleOpenAddCase}
                disabled={hasDeleteRequest}
              >
                Add Case
            </Button>
              <Button
                style={{ width: "33%" }}
                variant="outlined"
                color="primary"
                size="medium"
                onClick={handleClickBack}
                disabled={hasDeleteRequest}
              >
                Back
            </Button>
            </ThemeProvider>
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      {spreadCases()}

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        // TransitionComponent={props.Transition}
        keepMounted
        open={openAddCase}
        onClose={props.handleCloseCase}
        scroll={props.scroll}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Add Case
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <Formik
              validateOnChange={true}
              validateOnBlur={true}
              enableReinitialize={true}
              initialValues={{
                caseCode: "",
                caseTitle: "",
              }}
              validate={(values) => {
                // debugger
                console.log("validate...");
                const errors = {};

                if (!values.caseCode && !values.caseTitle) {
                  errors.caseTitle = "Please fill at least one field.";
                }
                console.log("errors");
                console.log(errors);
                console.log("...validate");
                return errors;
              }}
              onSubmit={(values, { setSubmitting, setFieldValue }) => {
                debugger;
                setSubmitting(true);
                callCreateCase(values, setFieldValue).catch((e) => {
                  setSubmitting(false);
                  setOpenDuplicate(true);
                });
              }}
              render={({
                values,
                isSubmitting,
                setSubmitting,
                handleChange,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form mode="themed">
                  <div
                    className={classes.container}
                    style={{
                      width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                      overflow: "auto",
                      height: `${size.height - 135}px`,
                    }}
                  >
                    <div className={classes.container}>
                      <Typography
                        style={{
                          textAlign: "center",
                          margin: "20px 0 0 0",
                          fontSize: "16px",
                        }}
                      >
                        {clientOnGrid(currentClient)}
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          margin: "0 0 0 0px",
                          fontSize: "16px",
                        }}
                      >
                        {currentClient.company
                          ? "(" + currentClient.company + ")"
                          : null}
                      </Typography>
                    </div>

                    <TextField
                      style={{
                        margin: "40px 0 0 0",
                        textTranform: "uppercase",
                      }}
                      fullWidth
                      name="caseCode"
                      label="Case Code"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.caseCode.length === 0 && values.caseTitle.length === 0}
                      onChange={(event) => {
                        values.caseCode = event.target.value.toUpperCase();
                        setFieldValue(
                          "caseCode",
                          event.target.value.toUpperCase()
                        );
                        _CasesPortfolio.caseCode = event.target.value.toUpperCase();
                      }}
                      value={values.caseCode}
                    />
                    <TextField
                      style={{
                        margin: "20px 0 20px 0",
                        textTranform: "uppercase",
                      }}
                      fullWidth
                      name="caseTitle"
                      label="Case Title"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={values.caseCode.length === 0 && values.caseTitle.length === 0}
                      onChange={(event) => {
                        values.caseTitle = event.target.value.toUpperCase();
                        setFieldValue(
                          "caseTitle",
                          event.target.value.toUpperCase()
                        );
                        _CasesPortfolio.caseTitle = event.target.value.toUpperCase();
                      }}
                      value={values.caseTitle}
                    />

                    <Typography
                      style={{
                        textAlign: "center", fontSize: '16px', color: primaryColor[0],
                        display: values.caseCode === "" && values.caseTitle === "" ? 'block' : 'none'
                      }}>
                      <em>* Required</em>
                    </Typography>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: grayColor[1],
                        fontStyle: "italic",
                      }}
                    >
                      Fill in at least one field.
                    </Typography>
                  </div>

                  <DialogActions className={classes.modalFooter}>
                    <ThemeProvider theme={defaultBtnMaterialTheme}>
                      <Button
                        style={{ width: "60%" }}
                        disabled={
                          isSubmitting ||
                          (!isSubmitting &&
                            ((values.caseCode === "" || values.caseCode.length < 2) &&
                              (values.caseTitle === "" || values.caseTitle.length < 2))
                          )
                        }
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="md"
                      >
                        Save
                      </Button>
                      <Button
                        style={{ width: "40%" }}
                        variant="outlined"
                        color="primary"
                        size="md"
                        onClick={() => setOpenAddCase(false)}
                      >
                        Cancel
                      </Button>
                    </ThemeProvider>
                  </DialogActions>
                </Form>
              )}
            ></Formik>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Verify
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                The following case/s
              </Typography>
              <Typography
                style={{
                  margin: "0 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                have similar title/s
              </Typography>
              <Typography
                style={{
                  margin: "0 0 20px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                and/or code/s:
              </Typography>

              {_CasesPortfolio.line.map((s) => (
                <Typography
                  style={{
                    margin: "0 0 0 0",
                    fontSize: "16px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  {s}
                </Typography>
              ))}
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "16px",
                  color: primaryColor[0],
                  textAlign: "center",
                }}
              >
                {line3}
              </Typography>
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Verify case addition.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    createCase();
                    setOpenNotification(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenNotification(false);
                  }}
                >
                  No
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openDuplicate}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Add Case
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "80px 0 10px 0",
                  fontSize: "18px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Case already exists, cannot add same case
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenDuplicate(false);
                  }}
                >
                  Back
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openNoChange}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Notification
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 0px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                No changes have been
              </Typography>

              <Typography
                style={{
                  margin: "0px 0 0px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                made to the case.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenNoChange(false);
                    reFetchCases();
                  }}
                >
                  Back
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openSuccess}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 10px 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Case added.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenSuccess(false);
                    reFetchCases();
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openEditNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Verify
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 136}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                The following case/s
              </Typography>
              <Typography
                style={{
                  margin: "0 0 -10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                have similar title/s
              </Typography>
              <Typography
                style={{
                  margin: "0 0 20px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                and/or code/s:
              </Typography>

              {_CasesPortfolio.line.map((s) => (
                <Typography
                  style={{
                    margin: "-10px 0 10px 0",
                    fontSize: "16px",
                    color: grayColor[1],
                    textAlign: "center",
                  }}
                >
                  {s}
                </Typography>
              ))}
              <Typography
                style={{
                  margin: "20px 0 0 0",
                  fontSize: "16px",
                  color: primaryColor[0],
                  textAlign: "center",
                }}
              >
                {line3}
              </Typography>
              <Typography
                style={{
                  margin: "20px 0 10px 0",
                  fontSize: "24px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Verify case edit.
              </Typography>

            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "60%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    directUpdateCase().then((response) => {
                      // reFetchClientsByEmail()
                      setOpenEditNotification(false);
                      setOpenEditSuccess(true);
                    });
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ width: "40%" }}
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenEditNotification(false);
                  }}
                >
                  No
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openEditSuccess}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseDuplicate}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Confirmation
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <div
              className={classes.container}
              style={{
                width: `${size.width <= 414 ? size.width - 60 : 340}px`,
                overflow: "auto",
                height: `${size.height - 135}px`,
              }}
            >
              <Typography
                style={{
                  margin: "60px 0 0 0",
                  fontSize: "26px",
                  color: grayColor[1],
                  textAlign: "center",
                }}
              >
                Case edited.
              </Typography>
            </div>
            <DialogActions className={classes.modalFooter}>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  size="md"
                  onClick={() => {
                    setOpenEditSuccess(false);
                    reFetchCases();
                  }}
                >
                  Done
                </Button>
              </ThemeProvider>
            </DialogActions>
          </div>
        </ThemeProvider>
      </Dialog>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLog,
        }}
        open={openDeleteNotification}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseNotification}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        maxWidth="lg"
        fullWidth
        fullScreen
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <div
                style={{
                  margin: `10px 0 0 0`,
                  color: primaryColor[0],
                  fontSize: "30px",
                  fontWeight: "400",
                  textAlign: "left",
                }}
              >
                Delete Case
              </div>
              <ThemeProvider theme={defaultBtnMaterialTheme}>
                <Tooltip
                  id="tooltip-top"
                  title={"Home"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{
                      margin: `-42px ${size.width <= 414 ? -10 : -20}px 0 0 `,
                    }}
                    className={classes.modalCloseButtonBYN}
                    onClick={e => {
                      e.preventDefault()
                      props.history.push("/app/dashboard")
                    }}
                  >
                    <img
                      style={{ width: "50px" }}
                      src={circlelogo}
                      alt="billyounow.com"
                    />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </ThemeProvider>
          </div>
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <div
            className={classes.container}
            style={{
              width: `${size.width <= 414 ? size.width - 30 : 370}px`,
            }}
          >
            <Formik
              initialValues={{
                delete: "",
              }}
              // validationSchema={}
              onSubmit={async (values, { setSubmitting }) => { }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div
                    className={classes.container}
                    style={{
                      width: `${size.width <= 414 ? size.width - 80 : 300}px`,
                      marginLeft: "20px",
                      overflow: "auto",
                      height: `${size.height - 135}px`,
                    }}
                  >
                    <Typography
                      style={{
                        margin: "40px 0 -10px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      Deleted case data
                    </Typography>
                    <Typography
                      style={{
                        margin: "0 0 -10px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      cannot be recovered,
                    </Typography>
                    <Typography
                      style={{
                        margin: "0 0 0px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      including logs.
                    </Typography>

                    <Typography
                      style={{
                        margin: "30px 0 -10px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      Please confirm request
                    </Typography>
                    <Typography
                      style={{
                        margin: "0 0 20px 0",
                        fontSize: "22px",
                        color: grayColor[1],
                        textAlign: "center",
                      }}
                    >
                      by typing "DELETE"
                    </Typography>

                    <TextField
                      fullWidth
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue("delete", e.target.value);
                      }}
                      value={values.delete}
                      required={values.delete.length === 0}
                    />
                    <Typography
                      style={{
                        textAlign: "center", fontSize: '16px', color: primaryColor[0],
                        display: values.email === "" || values.userName === "" || values.password === "" || values.passwordOne === "" ? 'block' : 'none'
                      }}>
                      <em>* Required</em>
                    </Typography>
                  </div>
                  <DialogActions className={classes.modalFooter}>
                    <ThemeProvider theme={defaultBtnMaterialTheme}>
                      <Button
                        disabled={values.delete !== "DELETE"}
                        style={{ width: "60%" }}
                        variant="contained"
                        color="primary"
                        size="md"
                        onClick={() => {
                          const { id, member, parentId, ord } = currentCase;

                          client
                            .mutate({
                              mutation: UPDATE_CASE_MUTATION,
                              variables: {
                                memberId: member.id,
                                clientId: currentCase.client.id,
                                caseId: id,

                                parentId,
                                ord,
                                status: "XXXXXX",
                                actionDate: new Date().toISOString(),
                              },
                            })
                            .then((response) => {
                              console.log("...directUpdateClient");
                              reFetchCases();
                            })
                            .catch((error) => {
                              console.log("...directUpdateClient");
                            });
                          setOpenDeleteNotification(false);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        style={{ width: "40%" }}
                        variant="outlined"
                        color="primary"
                        size="md"
                        onClick={() => {
                          setOpenDeleteNotification(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </ThemeProvider>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </div>
        </ThemeProvider>
      </Dialog>

      <EditCase
        history={props.history}
        client={client}
        classes={classes}
        Transition={Transition}
        currentClient={props.currentClient}
        open={openEditCase}
        currentCase={currentCase}
        onClose={handleCloseEditCase}
        handleOpenEditCaseConfirm={handleOpenEditCaseConfirm}
        getActivityLogs={getActivityLogs}
        getExpenseLogs={getExpenseLogs}
        reFetchCases={reFetchCases}
      />

      {/* <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirmEdit}
        message={dialogMessage}
        title={dialogTitle}
        submit={handleSubmit}
      /> */}
      {/* <FailDialog
        message={failMessage}
        title={failTitle}
        open={openFail}
        onClose={handleCloseFail}
      /> */}
    </>
  );
};

export default CasesPortfolio;
